import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {useIsFetching} from '@tanstack/react-query';

export const usePerformersDetailsIsLoading = () => {
	const isFetchingUsers = !!useIsFetching({queryKey: restDictionariesQueryKeys.users()});
	const isFetchingWorkingGroups = !!useIsFetching({queryKey: workingGroupsQueryKeys.list()});

	return isFetchingUsers || isFetchingWorkingGroups;
};
