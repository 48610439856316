import useAppSelector from '@src/core/hooks/useAppSelector';
import {ISpacesPageSettingsState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {useMemo} from 'react';
import {filterSpace} from '../utils/filterSpaces';
import {useObject, useObjectsMap, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesGroupedByTypes} from '@src/core/hooks/queries/spaces/hooks';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';

export interface IReportsTableRow {
	id: string;
	name: string;
	data?: Record<string, number>;
	subRows?: IReportsTableRow[];
	isTotal?: boolean;
}

const fillTotalObjectsRow = (
	totalObjectsRow: IReportsTableRow,
	...items: Array<{field: string; value: number}>
) => {
	for (const {field, value} of items) {
		totalObjectsRow.data = {
			...totalObjectsRow.data,
			[field]: totalObjectsRow.data?.[field] ? totalObjectsRow.data[field] + value : value
		};
	}

	return totalObjectsRow;
};

const countSelectedPercent = (selected?: number, total?: number) =>
	selected !== undefined && total !== undefined ? Math.floor((selected * 100) / total) || 0 : 100;

const formSubRows = (
	spacesGroupedByObjects: Record<string, IListSpace[]>,
	parentObject: IObject,
	objectsMap: Record<string, IObject>,
	fieldName: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>,
	pageSettings: ISpacesPageSettingsState
): IReportsTableRow[] => {
	const subRows: IReportsTableRow[] = [];
	let totalObjectsRow: IReportsTableRow = {
		id: parentObject.id,
		name: `${parentObject.name} (итого)`,
		isTotal: true,
		data: {}
	};

	for (const [objectId, spaces] of Object.entries(spacesGroupedByObjects)) {
		const filtersObjects = pageSettings.filters.objects;
		const object = objectsMap[objectId];
		const filteredSpaces = spaces.filter(space => {
			const problemsStatuses: string[] = space.problems?.problems
				? Object.values(space.problems.problems)
						.map(el => Object.keys(el))
						.flat()
				: [];
			return filterSpace({
				space,
				spaceIndicators: space.indicators || [],
				spaceStatus: space.status,
				spaceProblems: problemsStatuses,
				filters: pageSettings.filters
			});
		});

		const subRow: IReportsTableRow = {
			id: object.id,
			name: object.name,
			data: {}
		};

		let selectedCount = 0;
		let totalCount = 0;
		for (const item of fieldArr) {
			const spaceFilter = (space: IListSpace) => space[fieldName] === item;

			const count = filteredSpaces.filter(spaceFilter).length;
			totalCount += spaces.filter(spaceFilter).length;

			if (fieldMap[item]) {
				selectedCount += count;
			}
			totalObjectsRow = fillTotalObjectsRow(totalObjectsRow, {field: item, value: count});
			subRow.data = {...subRow.data, [item]: count};
		}
		subRow.data = {
			...subRow.data,
			selected: selectedCount,
			total: totalCount,
			selectedPercent: countSelectedPercent(selectedCount, totalCount)
		};
		totalObjectsRow = fillTotalObjectsRow(
			totalObjectsRow,
			{field: 'selected', value: selectedCount},
			{field: 'total', value: totalCount}
		);

		if (filtersObjects?.length && !filtersObjects.includes(objectId)) {
			continue;
		}

		subRows.push(subRow);
	}

	const selectedPercent = countSelectedPercent(
		totalObjectsRow.data?.selected,
		totalObjectsRow.data?.total
	);
	totalObjectsRow = fillTotalObjectsRow(totalObjectsRow, {
		field: 'selectedPercent',
		value: selectedPercent
	});

	if (Object.keys(spacesGroupedByObjects).length > 1) {
		subRows.push(totalObjectsRow);
	}

	return subRows;
};

const formRowData = (
	spacesGroupedByTypes: Record<string, Record<string, IListSpace[]>>,
	spaceTypeMap: Record<SpaceTypeId, ISpaceType> | undefined,
	object: IObject,
	objectsMap: Record<string, IObject>,
	fieldName: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>,
	pageSettings: ISpacesPageSettingsState
): IReportsTableRow[] => {
	const rowData: IReportsTableRow[] = [];

	const filtersTypes = pageSettings.filters.types;
	for (const typeKey of Object.keys(spacesGroupedByTypes)) {
		if (filtersTypes?.length && !filtersTypes.includes(typeKey)) {
			continue;
		}

		if (!spaceTypeMap) {
			continue;
		}

		const type = spaceTypeMap[typeKey as SpaceTypeId];
		const spacesGroupedByObjects = spacesGroupedByTypes[typeKey];

		const subRows = formSubRows(
			spacesGroupedByObjects,
			object,
			objectsMap,
			fieldName,
			fieldArr,
			fieldMap,
			pageSettings
		);

		const row: IReportsTableRow = {
			id: type.id,
			name: type.name,
			subRows
		};

		rowData.push(row);
	}
	return rowData;
};

export const useReportsTable = (
	objectId: string,
	field: string,
	fieldArr: string[],
	fieldMap: Record<string, unknown>
): IReportsTableRow[] => {
	const {data: targetObjects} = useTargetObjects(objectId, true);
	const {data: object} = useObject(objectId);
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const spacesGroupedByTypes = useSpacesGroupedByTypes(targetObjects);

	const {data: spaceTypeMap} = useExtractSpaceTypesById();
	const translatedSpaceTypeMap = useTranslatedDictionaryMap('spaceTypes', spaceTypeMap);
	const objectsMap = useObjectsMap();

	const rowData = useMemo(() => {
		if (!object || !objectsMap || !spaceTypeMap) {
			return [];
		}
		return formRowData(
			spacesGroupedByTypes,
			translatedSpaceTypeMap,
			object,
			objectsMap,
			field,
			fieldArr,
			fieldMap,
			pageSettings
		);
	}, [
		spacesGroupedByTypes,
		translatedSpaceTypeMap,
		object,
		objectsMap,
		field,
		fieldArr,
		fieldMap,
		pageSettings
	]);

	return rowData;
};
