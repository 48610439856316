import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useParams} from 'react-router-dom';
import {useFilteredCompaniesContractorsAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';

export const ContractorsFilterPage = () => {
	const {t} = useTranslation();
	const {entity = 'documents'} = useParams<{entity?: string}>();
	const {data: contractors} = useFilteredCompaniesContractorsAsArray();

	return (
		<StatusesFilterPage
			entity={entity}
			statuses={contractors}
			label={t('entitiesFilters.contractorsFilterMobile.label')}
			filterName="contractorIds"
		/>
	);
};
