import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

import {
	IGetListUnitIndicatorsSetsResponse,
	IGetPaginateUnitIndicatorsSetsResponse,
	requestGetListUnitIndicatorsSets,
	requestGetPaginateUnitIndicatorsSets
} from '@src/api/backend/unitIndicatorsSets';

import {unitIndicatorsSetsQueryKeys} from '@src/api/cache/unitIndicatorsSets/keys';
import {useUnitIndicatorsSetsPermissions} from '../../permissions/useUnitIndicatorsSetsPermissions';
import {
	extractUnitIndicatorsSetsAsArray,
	extractUnitIndicatorsSetsByCompanyIdAsArray,
	filterByRelationsAndStage
} from './selectors';
import {useUnitIndicatorsAsArray} from '../unitIndicators/hooks';
import {useCallback} from 'react';
import {IUnitIndicatorsSetWithIndicators} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {IGetUnitIndicatorsSetResponse} from '@src/api/backend/unitIndicatorsSet';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObject} from '../objects/hooks';

export const useUnitIndicatorsSetsPaginate = <T = IGetPaginateUnitIndicatorsSetsResponse>(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number,
	select?: (data: IGetPaginateUnitIndicatorsSetsResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();

	return useQuery<IGetPaginateUnitIndicatorsSetsResponse, IError, T>({
		queryKey: unitIndicatorsSetsQueryKeys.paginate(filters, sort, offset, limit),
		queryFn: () => requestGetPaginateUnitIndicatorsSets(filters, sort, offset, limit),
		meta: {
			error: 'ошибка при получении наборов индикаторов помещений'
		},
		enabled: permissions.canView,
		select
	});
};

export const useUnitIndicatorsSetsList = <T = IGetListUnitIndicatorsSetsResponse>(
	filters?: Record<string, unknown>,
	select?: (data: IGetListUnitIndicatorsSetsResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();

	return useQuery<IGetListUnitIndicatorsSetsResponse, IError, T>({
		queryKey: unitIndicatorsSetsQueryKeys.list(filters),
		queryFn: () => requestGetListUnitIndicatorsSets(filters),
		meta: {
			error: 'ошибка при получении наборов индикаторов помещений'
		},
		enabled: permissions.canView,
		select
	});
};

export const useUnitIndicatorSet = <T = IGetUnitIndicatorsSetResponse>(
	unitIndicatorSetId?: string,
	select?: (data: IGetUnitIndicatorsSetResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();

	return useQuery<IGetUnitIndicatorsSetResponse, IError, T>({
		queryKey: [...unitIndicatorsSetsQueryKeys.details(), unitIndicatorSetId],
		meta: {
			error: 'ошибка при получении данных индикатора'
		},
		enabled: permissions.canView && !!unitIndicatorSetId,
		select
	});
};

export const useUnitIndicatorsSetsByCompanyId = (companyId?: string) => {
	const selector = useCallback(
		(data: IGetListUnitIndicatorsSetsResponse) =>
			extractUnitIndicatorsSetsByCompanyIdAsArray(data, companyId),
		[companyId]
	);
	return useUnitIndicatorsSetsList({}, selector);
};

export const useActualUnitIndicatorsSetsExtended = (
	objectId: string,
	stage?: ObjectStageIds,
	withOutArchieved = false
) => {
	const {data: indicatorsData} = useUnitIndicatorsAsArray();
	const {data: object} = useObject(objectId);

	const selector = useCallback(
		(data: IGetListUnitIndicatorsSetsResponse): IUnitIndicatorsSetWithIndicators[] => {
			const sets = extractUnitIndicatorsSetsAsArray(data);

			return filterByRelationsAndStage(sets, object, stage).map(set => ({
				...set,
				indicators:
					indicatorsData?.filter(
						indicator =>
							indicator.indicatorsSetId === set.id &&
							(!withOutArchieved || indicator.deletedBy === null)
					) || []
			}));
		},
		[object, stage, indicatorsData, withOutArchieved]
	);

	return useUnitIndicatorsSetsList({}, selector);
};
