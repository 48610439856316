import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IPreparedInternalAcceptance} from '../interfaces/IPreparedInternalAcceptance';
import {getPermissionsForInternalAcceptance} from './getPermissionsForInternalAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntityInspectionsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityInspectionsData';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export interface IConvertedProblemsData {
	key: string;
	value: number;
	percent: number;
	color: string;
	critical?: boolean;
	startPoint: number;
}

/**
 * Возвращает количества нарушений по статусам для текущей стадии, если стадия не указана, то по всем стадиям
 *
 * @param problemsStats данные по нарушениям из помещения
 * @param stage текущая стадия
 * @param processId текущий процесс
 */
const getProblemsDataByProcess = (
	problemsStats: IEntityProblemsData,
	stage?: ObjectStageIds,
	processId?: ProcessIds
): Record<string, {count: number; critical: boolean}> | undefined => {
	const problems = problemsStats.problems;

	if (stage && processId) {
		return problems[stage]?.[processId];
	}

	return problems
		? Object.values(problems).reduce<Record<string, {count: number; critical: boolean}>>(
				(prev, stageItem) => {
					for (const processItem of Object.values(stageItem)) {
						for (const status in processItem) {
							if (processItem.hasOwnProperty(status)) {
								if (prev[status]) {
									prev[status].count += processItem[status].count;
									prev[status].critical =
										prev[status].critical || processItem[status].critical;
								} else {
									prev = {
										...prev,
										[status]: {
											count: processItem[status].count,
											critical: processItem[status].critical
										}
									};
								}
							}
						}
					}
					return prev;
				},
				{}
		  )
		: undefined;
};

const convertProblemsData = (
	problemsStats: IEntityProblemsData | undefined,
	problemStatuses: INormalizedData<IProblemStatus>,
	selectedStage?: ObjectStageIds
): IConvertedProblemsData[] => {
	if (!problemsStats) {
		return [];
	}

	let total = 0;
	const result = [];

	const stageProblems = getProblemsDataByProcess(problemsStats, selectedStage);
	if (stageProblems) {
		for (const statusId of problemStatuses.allIds) {
			if (stageProblems.hasOwnProperty(statusId) && stageProblems[statusId].count > 0) {
				result.push({
					key: statusId,
					value: stageProblems[statusId].count,
					percent: 0,
					color: problemStatuses.byId[statusId]?.color || '#00000000',
					startPoint: 0,
					critical:
						statusId !== ProblemStatusId.FIXED
							? stageProblems[statusId].critical
							: undefined
				});
				total += stageProblems[statusId].count;
			}
		}
		let offset = 0;
		for (const stat of result) {
			stat.percent = (stat.value / total) * 100;
			stat.startPoint = -offset;
			offset += stat.percent;
		}
	}
	return result;
};
/**
 * Преобразовывает внутренние приёмки в необходимый для вывода формат
 *
 * @param internalAcceptances массив проверок
 * @param problemStats: статистика по нарушениям
 * @param useProblemStatuses: статусы нарушений
 * @param inspectionStats: статистика по осмотрам
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 */
export const convertInternalAcceptances = (
	internalAcceptances: IListInternalAcceptance[],
	problemStats: Record<string, IEntityProblemsData> | undefined,
	problemStatuses: INormalizedData<IProblemStatus> | undefined,
	inspectionStats: Record<string, IEntityInspectionsData> | undefined,
	networkStatus: boolean,
	user?: IUser
): IPreparedInternalAcceptance[] =>
	internalAcceptances.map(item => ({
		id: item.id,
		description: item.description,
		object: item.object,
		number: item.number,
		localNumber: item.localNumber,
		createdBy: item.createdBy,
		modifiedBy: item.modifiedBy,
		createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
		modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
		stats: {
			problems:
				problemStats &&
				problemStatuses &&
				convertProblemsData(problemStats[item.id], problemStatuses),
			inspections:
				inspectionStats && inspectionStats[item.id]
					? Object.values(inspectionStats[item.id]).reduce(
							(sum, current) => sum + current.count,
							0
					  )
					: undefined
		},
		...getPermissionsForInternalAcceptance(item, networkStatus, item.objectId, user)
	}));
