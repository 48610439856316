export enum AttachmentFileDestination {
	INSPECTION = 'inspection',
	COMMENT_OR_REPLY = 'commentOrReply',
	PROBLEM = 'problem',
	WORK_ACCEPTANCE = 'workAcceptance',
	WORK_ACCEPTANCE_COMMENT_OR_REPLY = 'workAcceptanceCommentOrReply',
	LOCAL_PROBLEM = 'localProblem',
	TEMP = 'temp',
	OBJECT = 'object',
	CONTRACT = 'contract',
	PLAN = 'plan',
	WARRANTY_CLAIM = 'warrantyClaim',
}
