import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {sortByCreation} from '../../../../utils/sortByCreation';

export class EntitiesMap {
	private data: Map<string, IStructureEntity[]> = new Map();

	get(key: string) {
		return this.data.get(key)?.sort(sortByCreation);
	}

	add(key: string, value: IStructureEntity) {
		const prevStructureEntity = this.data.get(key);

		if (prevStructureEntity) {
			prevStructureEntity.push(value);
			this.data.set(key, prevStructureEntity);
		} else {
			this.data.set(key, [value]);
		}
	}
}
