import {IGetInternalAcceptanceInspectionsResponse} from '@src/api/backend/internalAcceptances/inspectionsData';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useQuery} from '@tanstack/react-query';
import {IEntityInspectionsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityInspectionsData';
import IError from '@tehzor/tools/interfaces/IError';
import {useInternalAcceptancesForStats} from '../hooks';
import {IGetInternalAcceptanceProblemsResponse} from '@src/api/backend/internalAcceptances/problemsData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export const useInspectionsStats = <T = Record<string, IEntityInspectionsData>>(
	objectId: string,
	select?: (data: IGetInternalAcceptanceInspectionsResponse) => T
) => {
	const {data: keyData} = useInternalAcceptancesForStats(objectId);
	return useQuery<IGetInternalAcceptanceInspectionsResponse, IError, T>({
		queryKey: [
			...internalAcceptancesQueryKeys.inspectionsStatsList({internalAcceptances: keyData})
		],
		enabled: !!keyData,
		select
	});
};

export const useProblemsStats = <T = Record<string, IEntityProblemsData>>(
	objectId: string,
	select?: (data: IGetInternalAcceptanceProblemsResponse) => T
) => {
	const {data: keyData} = useInternalAcceptancesForStats(objectId);
	return useQuery<IGetInternalAcceptanceProblemsResponse, IError, T>({
		queryKey: [
			...internalAcceptancesQueryKeys.problemsStatsList({internalAcceptances: keyData})
		],
		enabled: !!keyData,
		select
	});
};
