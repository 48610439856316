import {useCallback, useMemo, useState} from 'react';
import {IListSpaceWithUploadData, IUploadData} from '../TableUpload';
import {IGetSpacesResponse} from '@src/api/backend/spaces';

export interface IUploadError {
	data: string;
}

export const useAnalysis = (
	uploadString: string,
	uploadData: IUploadData[],
	allSpacesByTargetObjects: IGetSpacesResponse
) => {
	const [uploadErrors, setUploadErrors] = useState<IUploadError[]>([]);
	const [uploadDataForSpaces, setUploadDataForSpaces] = useState<
		Record<string, {uploadExternalId: string}>
	>({});
	const [spaceDataForUploads, setSpaceDataForUploads] = useState<
		Record<string, {spaceId: string}>
	>({});

	const spaces: IListSpaceWithUploadData[] = useMemo(
		() =>
			allSpacesByTargetObjects.allIds.map((id: string) => {
				const space = allSpacesByTargetObjects.byId[id];
				const uploadDataForSpace = uploadDataForSpaces[space.name];

				const uploadStatus = uploadDataForSpace
					? uploadDataForSpace.uploadExternalId === space.externalId
						? 'Уже загружено'
						: 'Готово к загрузке'
					: '';

				return {
					...space,
					uploadExternalId: uploadDataForSpace?.uploadExternalId,
					uploadStatus
				};
			}),
		[allSpacesByTargetObjects, uploadDataForSpaces]
	);

	const analysis = useCallback(() => {
		if (!uploadString) {
			setUploadErrors([]);
			setSpaceDataForUploads({});
			setUploadDataForSpaces({});
			return;
		}

		const errors: IUploadError[] = [];

		const dataForSpaces: Record<
			string,
			{
				uploadExternalId: string;
			}
		> = {};

		const dataForUploads: Record<
			string,
			{
				spaceId: string;
			}
		> = {};

		const set: Record<string, {spaceCount?: number; uploadCount?: number}> = {};

		uploadData.forEach(el => {
			const uploadCount = (set[el.name]?.uploadCount ?? 0) + 1;

			if (set[el.name]) {
				set[el.name].uploadCount = uploadCount;
			} else {
				set[el.name] = {uploadCount};
			}
		});

		spaces.forEach(el => {
			const spaceCount = (set[el.name]?.spaceCount ?? 0) + 1;

			if (set[el.name]) {
				set[el.name].spaceCount = spaceCount;
			} else {
				set[el.name] = {spaceCount};
			}
		});

		const names = Object.keys(set);

		names.forEach(name => {
			const setData = set[name];

			if (
				setData.spaceCount &&
				setData.spaceCount === 1 &&
				setData.uploadCount &&
				setData.uploadCount === 1
			) {
				const spaceData = spaces.find(space => space.name === name);
				const uploadElData = uploadData.find(el => el.name === name);

				dataForSpaces[name] = {
					uploadExternalId: uploadElData?.id ?? ''
				};

				dataForUploads[name] = {
					spaceId: spaceData?.id ?? ''
				};
			} else {
				errors.push({
					data: `Помещение ${name} в tehzor ${setData.spaceCount ?? 0}. в файле ${
						setData.uploadCount ?? 0
					}`
				});
			}
		});
		setUploadErrors(errors);
		setSpaceDataForUploads(dataForUploads);
		setUploadDataForSpaces(dataForSpaces);
	}, [uploadString, uploadData, spaces]);

	const tableUploadData: IUploadData[] = useMemo(
		() =>
			uploadData.map(el => {
				const spaceData = spaceDataForUploads[el.name];
				return {...el, ...spaceData};
			}),
		[uploadData, spaceDataForUploads]
	);

	return {analysis, uploadErrors, spaces, tableUploadData};
};
