import INormalizedData from '@/shared/types/INormalizedData';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useQuery} from '@tanstack/react-query';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {structuresQueryKeys} from '../../../../../Structures/model/cache/keys/index';

export type IGetStructureProblemRepliesResponse = INormalizedData<IComment>;

export const useStructureProblemReplies = <T = IGetStructureProblemRepliesResponse>(
	objectId: string,
	structureId: string,
	permission?: boolean,
	select?: (data: IGetStructureProblemRepliesResponse) => T
) => {
	const roles = useAppSelector(extractUserRoles);
	const canView = useMemo(() => hasPermission(roles, 'repliesView'), [roles]);
	const response = useQuery({
		queryKey: structuresQueryKeys.structureProblemReplies(objectId, structureId),
		meta: {
			error: 'при загрузке ответов'
		},
		select,
		enabled: permission || canView
	});
	return response;
};
