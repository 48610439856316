export enum ObjectSettingIds {
	// Запрет добавления нарушений после истечения срока гарантии
	RESTRICT_PROBLEM_CREATION_AFTER_WARRANTY_EXPIRATION = 'restrictProblemCreationAfterWarrantyExpiration'
}

export type IObjectSetting = {
	[key in ObjectSettingIds]?: boolean;
};
export default interface IObjectSettings {
	id: string;
	objectId: string;
	settings: IObjectSetting;
}
