import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IAddWorkAcceptanceResponse,
	makeWorkAcceptanceAddRequest
} from '@src/api/backend/workAcceptance';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {structuresQueryKeys} from '@src/fsd/src/entities/Structures';
import {t} from 'i18next';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddWorkAcceptanceResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: structuresQueryKeys.workAcceptancesStatsAll()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithWorkAcceptanceStats()});

	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.addWorkAcceptance.error.title')});
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет приёмку работ
 *
 * @param objectId id объекта
 * @param fields данные приёмки работ
 */
export const addWorkAcceptance = (objectId: string, fields: ISavingWorkAcceptance) =>
	createApiAction<IAddWorkAcceptanceResponse>(request, success, failure, () =>
		makeWorkAcceptanceAddRequest(objectId, fields)
	);
