import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProblemInfoMobile} from './ProblemInfoMobile';

export const ProblemInfoMobileCell = (props: IProblemCellProps<{data: IProblem}>) => (
	<ProblemInfoMobile
		problem={props.row.original.data}
		depth={props.row.depth}
		replies={props.replies}
		allProblemTags={props.allProblemTags}
		getToggleRowSelectedProps={props.row.getToggleRowSelectedProps}
		positionFilesCount={props.positionFilesCount}
	/>
);
