import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {useTranslation} from 'react-i18next';
import {CellProps} from 'react-table';

export const LocationCell = ({row}: CellProps<IPreparedOwnerAcceptance>) => {
	const {t} = useTranslation();
	const {space} = row.original;
	const spaceType = useTranslatedDictionaryById(dictionaryKeys.spaceTypesSingular, space?.type);

	if (!space) {
		return null;
	}

	return (
		<div className="owner-acceptances-page__d-table-location">
			{space?.name && space.type && (
				<div>{`${space?.name} ${spaceType}${space?.floor ? ',' : ''}`}</div>
			)}
			{space?.floor && <div>{`${space?.floor} ${t('locationCell.floor')}`}</div>}
		</div>
	);
};
