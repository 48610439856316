import {memo, useCallback, useEffect} from 'react';
import {SetValueOptions} from './WarrantyClaimForm';
import {useController, useFormContext} from 'react-hook-form';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';

interface IClaimerNameProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	defaultValue?: string;
}

export const ClaimerName = memo(
	<S extends {claimerName: string}, E>({
		className,
		style,
		label = 'Заявитель',
		name,
		onSetValue,
		required,
		disabled,
		defaultValue,
		...restProps
	}: IClaimerNameProps<S, E>) => {
		const {control} = useFormContext();

		const {
			field: {value},
			fieldState: {invalid}
		} = useController({
			name,
			control,
			rules: {required}
		});

		const handleChange = useCallback(
			(v: string) => {
				if (onSetValue) {
					onSetValue(name, v);
				}
			},
			[onSetValue, name]
		);

		useEffect(() => {
			if (defaultValue && onSetValue) {
				onSetValue(name, defaultValue);
			}
		}, [defaultValue]);

		return (
			<div
				className={className}
				style={style}
				{...restProps}
			>
				<EditableFieldLabel>{label}</EditableFieldLabel>

				<TextField
					value={value}
					elementType="input"
					error={invalid ? 'Введите имя заявителя' : undefined}
					disabled={disabled}
					onChange={handleChange}
				/>
			</div>
		);
	}
);
