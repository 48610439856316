import {useMemo} from 'react';
import './WorkAcceptancesSpace.less';
import classNames from 'classnames';
import {Indicators} from '../Indicators/Indicators';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import {SpaceDiagram} from './components/SpaceDiagram/SpaceDiagram';
import {Checkbox} from '../../inputs';

const declaredTypes = Object.values(SpaceTypeId);

export const WorkAcceptancesSpace = ({
	className,
	disabled,
	space,
	altNamesVisible,
	hasFixedWidth,
	onClick,
	chooseSpacesVisible,
	choosenSpaces
}: ISpaceComponent) => {
	const classes = classNames(
		className,
		'work-acceptances-space',
		`work-acceptances-space_${space.type}`,
		{
			'work-acceptances-space__wide': hasFixedWidth
		},
		{
			'work-acceptances-space_disabled': disabled,
			'work-acceptances-space_default': !declaredTypes.includes(space.type),
			[`problems-space_${space.type}--checked`]:
				!disabled && chooseSpacesVisible && choosenSpaces?.includes(space.id)
		}
	);

	const name = useMemo(
		() => (altNamesVisible ? space.altName ?? '' : space.name),
		[space.altName, space.name, altNamesVisible]
	);

	return (
		<div className={classes}>
			<>
				{chooseSpacesVisible && !disabled && (
					<div className="problems-space__space-checkbox">
						<Checkbox
							checked={choosenSpaces?.includes(space.id)}
							onClick={() => onClick?.(space)}
						/>
					</div>
				)}
				<SpaceDiagram
					key={space.id}
					space={space}
					name={name}
					disabled={disabled}
					onClick={onClick}
				/>
			</>

			<div className="space-indicators__wrapper">
				{space.indicators?.length ? <Indicators indicators={space.indicators}/> : null}
			</div>
		</div>
	);
};
