import {useMemo} from 'react';
import IUser from '@tehzor/tools/interfaces/IUser';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IPreparedOwnerAcceptance} from '../interfaces/IPreparedOwnerAcceptance';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {IOwnerAcceptanceResolution} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {getPermissionsForOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/utils/getPermissionsForOwnerAcceptance';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface IConvertOwnerAcceptancesArgs {
	ownerAcceptances?: IListOwnerAcceptance[];
	localOwnerAcceptances?: IListOwnerAcceptance[];
	resolutions?: IOwnerAcceptanceResolution[];
	networkStatus: boolean;
	statuses?: IOwnerAcceptanceStatus[];
	user?: IUser;
}

export const useConvertOwnerAcceptances = (
	args: IConvertOwnerAcceptancesArgs
): IPreparedOwnerAcceptance[] | undefined => {
	const {
		ownerAcceptances,
		localOwnerAcceptances,
		user,
		networkStatus,
		resolutions,
		statuses
	} = args;

	let mergedOwnerAcceptances: IListOwnerAcceptance[] = [];

	if (ownerAcceptances?.length) {
		mergedOwnerAcceptances = [...ownerAcceptances];
	}

	if (localOwnerAcceptances?.length) {
		mergedOwnerAcceptances = [...localOwnerAcceptances, ...mergedOwnerAcceptances];
	}
	const translatedResolutions = useTranslatedDictionaryArray(
		dictionaryKeys.ownerAcceptanceResolutions,
		resolutions
	);

	return useMemo(
		() =>
			mergedOwnerAcceptances.map(item => {
				const preparedResolution = translatedResolutions?.find(
					resolution => resolution.id === item.resolution
				)?.name;
				const preparedStatus = statuses?.find(status => status.id === item.status);

				return {
					id: item.id,
					object: item.object,
					number: item.number,
					localNumber: item.localNumber,
					status: preparedStatus,
					resolution: preparedResolution,
					space: item.space,
					spaceOwner: item?.spaceOwner,
					createdBy: item.createdBy,
					modifiedBy: item.modifiedBy,
					createdAt: item.createdAt
						? format(item.createdAt, dateTimeCommaSeparatedFormat)
						: '',
					modifiedAt: item.modifiedAt
						? format(item.modifiedAt, dateTimeCommaSeparatedFormat)
						: '',
					...getPermissionsForOwnerAcceptance(item, networkStatus, item.objectId, user)
				};
			}),
		[networkStatus, user, translatedResolutions, mergedOwnerAcceptances]
	);
};
