import {createContext, PropsWithChildren} from 'react';
import type {IVirtualTreeProps} from '../interfaces/IVirtualTreeProps';
import {ITreeDataItem} from '../interfaces/ITreeDataItem';

export const VirtualTreeContext = createContext<IVirtualTreeProps<any> | null>(null);

/**
 * Контекст нужен для того чтобы включить виртуализацию в компоненте Tree
 * */
export const VirtualTreeProvider = <T extends ITreeDataItem>(
	props: PropsWithChildren<IVirtualTreeProps<T>>
) => (
	<VirtualTreeContext.Provider value={props}>{props.children}</VirtualTreeContext.Provider>
	);
