import StatusesFilterPage from '@src/pages/filters/StatusesFilterPage';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

const SpacesProblemStatusesFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: statuses} = useProblemStatusesAsArray();

	const translatedOptions = useTranslatedDictionaryArray(
		dictionaryKeys.problemStatuses,
		statuses
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedOptions}
			label={t('entitiesFilters.statusesFilter.problemStatuses.label')}
			filterName="problemStatuses"
		/>
	);
};

export default SpacesProblemStatusesFilterPage;
