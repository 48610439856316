import {useMemo} from 'react';
import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import './WorkAcceptanceScope.less';
import {useStructuresListAsArray} from '@/entities/Structures';
import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';

interface IWorkAcceptanceFrontProps {
	item: IListStructure;
	objectId: string;
	frontType: WorkAcceptanceFrontTypeId;
}

const WorkAcceptanceFront = ({item, objectId, frontType}: IWorkAcceptanceFrontProps) => (
	<ClickPreventWrap>
		<WorkAcceptanceFrontItem
			item={item}
			objectId={objectId}
			frontType={frontType}
		/>
	</ClickPreventWrap>
);

interface IWorkAcceptanceScopeProps {
	objectId: string;
	structureIds: string[] | undefined;
	frontType: WorkAcceptanceFrontTypeId;
}

export const WorkAcceptanceScope = ({
	objectId,
	structureIds,
	frontType
}: IWorkAcceptanceScopeProps) => {
	const {data: structures} = useStructuresListAsArray(objectId);

	const workAcceptanceFront = useMemo(
		() =>
			frontType === WorkAcceptanceFrontTypeId.STRUCTURES
				? structures?.filter(structure => structureIds?.includes(structure.id))
				: undefined,
		[structures, structureIds, frontType]
	);

	return (
		<div className="work-acceptance-info-cell__scopes">
			{workAcceptanceFront &&
				workAcceptanceFront.slice(0, 3).map(item => (
					<WorkAcceptanceFront
						key={item.id}
						item={item}
						objectId={objectId}
						frontType={frontType}
					/>
				))}
			{workAcceptanceFront && workAcceptanceFront?.length > 3 && (
				<div className="work-acceptances-page__d-table__cell-rest">
					+{workAcceptanceFront.length - 3}
				</div>
			)}
		</div>
	);
};
