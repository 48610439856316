import {memo, useCallback} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useFilterPageTransition} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {ResponsiblesFilterMobile} from '@src/components/MobileEntitiesFilters/components/ResponsiblesFilterMobile';

interface ISubmittersFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const SubmittersFilterMobile = memo(
	({objectId, value, entity, label, onChange}: ISubmittersFilterMobileProps) => {
		const handleChange = useCallback(
			(v: string[] | undefined) => {
				onChange({submitters: v});
			},
			[onChange]
		);

		const handleExpand = useFilterPageTransition(objectId, entity, 'submitters');

		return (
			<ResponsiblesFilterMobile
				respUsers={value}
				label={label}
				onChange={handleChange}
				onExpand={handleExpand}
			/>
		);
	}
);
