import {forwardRef, LegacyRef, memo, useCallback} from 'react';
import type {ITreeItemProps as IVirtualTreeItemProps} from '../../interfaces/ITreeItemProps';
import classNames from 'classnames';
import IconButton from '../../../buttons/IconButton';
import Checkbox from '../../../inputs/checkbox/Checkbox';
import {useTreeCtx} from '../../utils/TreeCtx';
import type {ITreeDataItem} from '../../interfaces/ITreeDataItem';

const DefaultVirtualTreeItem = <T extends ITreeDataItem>(
	props: IVirtualTreeItemProps<T>,
	ref: LegacyRef<HTMLDivElement> | undefined
) => {
	const {data, checkable, disabled: allDisabled, level} = props;
	const {id, content, children, disabled} = data;

	const {isExpanded, isChecked, isSemiChecked, changeExpanded, changeChecked} = useTreeCtx();

	const expanded = isExpanded(id);
	const checked = isChecked(id);
	const semiChecked = isSemiChecked(id);

	const handleExpand = useCallback(() => {
		changeExpanded(id, !expanded);
	}, [id, expanded, changeExpanded]);

	const handleCheck = useCallback(() => {
		changeChecked(data, !checked);
	}, [data, checked, changeChecked]);

	return (
		<div
			ref={ref}
			className={classNames(
				'virtual-tree__item-inner',
				`virtual-tree__item-inner_${children ? 'children' : ''}`,
				`virtual-tree__item-inner_${children ? 'children' : ''}_level_${level}`,
				`virtual-tree__item-inner_level_${level}`
			)}
		>
			{children !== undefined && (
				<IconButton
					className="virtual-tree__item-expand-btn"
					type="transparent"
					onClick={handleExpand}
				>
					<i
						className={classNames(
							'tz-simple-arrow-24',
							'virtual-tree__item-expand-btn-icon',
							{
								'virtual-tree__item-expand-btn-icon_expanded': expanded
							}
						)}
					/>
				</IconButton>
			)}

			{checkable && !data.nonCheckable ? (
				<Checkbox
					className={{
						root: 'virtual-tree__item-checkbox',
						content: classNames(
							'virtual-tree__item-label',
							`virtual-tree__item-label_level_${level}`
						)
					}}
					checked={checked || semiChecked}
					semiChecked={semiChecked}
					disabled={disabled || allDisabled}
					onChange={handleCheck}
				>
					{content}
				</Checkbox>
			) : (
				<span
					className={classNames(
						'virtual-tree__item-label',
						`virtual-tree__item-label_level_${level}`
					)}
				>
					{content}
				</span>
			)}
		</div>
	);
};

export const VirtualTreeItem = memo(forwardRef(DefaultVirtualTreeItem));
