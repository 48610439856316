import {Column} from 'react-table';
import {SpaceTypeCell} from './spaceTypeCell';
import {IListSpaceWithUploadData} from '../TableUpload';

export const uploadSpaceColumns: Array<Column<IListSpaceWithUploadData>> = [
	{
		id: 'id',
		accessor: 'id',
		Header: 'Id',
		width: 110,
		minWidth: 80
	},
	{
		id: 'name',
		accessor: 'name',
		Header: 'Наименование',
		width: 110,
		minWidth: 80
	},
	{
		id: 'type',
		accessor: 'type',
		Header: 'Тип помещения',
		Cell: SpaceTypeCell,
		width: 80,
		minWidth: 80
	},

	{
		id: 'object',
		accessor: s => s.object?.name,
		Header: 'Объект',
		width: 110,
		minWidth: 80
	},

	{
		id: 'externalId',
		accessor: 'externalId',
		Header: 'Текущий внешний Id',
		width: 110,
		minWidth: 80
	},
	{
		id: 'uploadExternalId',
		accessor: 'uploadExternalId',
		Header: 'Новый внешний Id',
		width: 110,
		minWidth: 80
	},
	{
		id: 'uploadStatus',
		accessor: 'uploadStatus',
		Header: 'Статус загрузки',
		width: 110,
		minWidth: 80
	}
];
