import {ReactNode, useContext, useEffect, useRef, useState} from 'react';
import './ShadowBox.less';
import classNames from 'classnames';
import {VirtualTreeContext} from '../Tree/providers/VirtualTreeProvider';

interface IShadowBoxProps {
	children?: ReactNode;
	className?: string;
}

const ShadowBox = (props: IShadowBoxProps) => {
	const {children, className} = props;
	const [shadow, setShadow] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const virtual = useContext(VirtualTreeContext);

	useEffect(() => {
		if (ref.current && ref.current.scrollHeight > ref.current.clientHeight) {
			setShadow(true);
		} else {
			setShadow(false);
		}
	});

	return (
		<div
			ref={ref}
			className={classNames(className, 'shadowbox', {shadowbox_active: shadow})}
			style={virtual?.enableVirtual ? {overflowY: 'hidden'} : {}}
		>
			{children}
		</div>
	);
};
ShadowBox.displayName = 'ShadowBox';

export default ShadowBox;
