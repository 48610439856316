import {useMutation} from '@tanstack/react-query';
import {
	IAddMultipleOwnerAcptResponse,
	makeOwnerAcceptancesAddRequest
} from '@src/api/backend/ownerAcceptances/add';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';

export interface IAddMultipleOwnerAcceptancesParams {
	objectId: string;
	spaceIds: string[];
	links: IOwnerAcceptance['links'];
	savingData: ISavingOwnerAcceptance;
}
/**
 * Хук для добавления нескольких передач собственнику
 */
export const useAddMultipleOwnerAcceptances = () =>
	useMutation<IAddMultipleOwnerAcptResponse, Error, IAddMultipleOwnerAcceptancesParams>({
		mutationFn: async ({objectId, spaceIds, links, savingData}) =>
			makeOwnerAcceptancesAddRequest(objectId, spaceIds, links, savingData),
		onError: error => {
			console.error('Ошибка при создании передач собственнику:', error);
		}
	});
