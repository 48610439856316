import useAppSelector from '@src/core/hooks/useAppSelector';
import {usePermissions} from '@src/pages/ProblemsPage/utils/usePermissions';
import {useMemo} from 'react';

interface IUseProblemAdd {
	objectId?: string;
}

export const useCanProblemAdd = (props: IUseProblemAdd) => {
	const {objectId} = props;
	const user = useAppSelector(s => s.auth.profile);
	const permissions = usePermissions(user, objectId);

	return useMemo(
		() =>
			permissions.canAddProblem &&
			((permissions.canAddCheck && permissions.canAddCheckFromProblems) ||
				(permissions.canAddInternalAcceptance &&
					permissions.canAddInternalAcceptanceFromProblems) ||
				(permissions.canAddOwnerAcceptance &&
					permissions.canAddOwnerAcceptanceFromProblems) ||
				(permissions.canAddWarrantyClaim && permissions.canAddWarrantyClaimFromProblems) ||
				(permissions.canAddPresaleCheck && permissions.canAddPresaleCheckFromProblems)),
		[permissions]
	);
};
