import {getSelectionMessage} from '../../utils/getSelectionMessage';

interface ISelectionMessageProps {
	totalSelectedByCurrentType: number;
	nonEmptySelectedCount: number;
	typeCounts: number;
	restrictedAddProblemsAfterWarrantyExpiration: boolean;
}

export const SelectionMessage = ({
	totalSelectedByCurrentType,
	nonEmptySelectedCount,
	typeCounts,
	restrictedAddProblemsAfterWarrantyExpiration
}: ISelectionMessageProps) => {
	const message = getSelectionMessage({
		totalSelectedByCurrentType,
		nonEmptySelectedCount,
		typeCounts,
		restrictedAddProblemsAfterWarrantyExpiration
	});

	return message ? <p>{message}</p> : null;
};
