import {Column} from 'react-table';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import {extractCheckProblemCommentsByProblem} from '@src/store/modules/entities/check/selectors';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {ProblemInfoMobile} from '@src/components/tableCells/ProblemInfoMobileCell/ProblemInfoMobileCell';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';

const Wrap = (props: IProblemCellProps<{data: IProblem}>) => {
	const {row, replies} = props;
	const {data} = row.original;

	return (
		<ProblemInfoMobile
			problem={data}
			depth={row.depth}
			replies={replies}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
		/>
	);
};

export const columns: Array<Column<{data: IProblem}>> = [
	{
		id: 'info',
		Cell: ProblemInfoCellWrap(Wrap, extractCheckProblemCommentsByProblem)
	}
];
