import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './FieldAdedCell.module.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';

export const FieldAddedCell = memo(({row}: Pick<CellProps<IPreparedFieldSettingsSet>, 'row'>) => {
	const {createdAt, createdBy} = row.original;
	return (
		<div className={styles.created}>
			<div>
				{createdAt && (
					<div className={styles.createdAt}>
						{format(createdAt, dateTimeCommaSeparatedFormat)}
					</div>
				)}
				{createdBy && <div className={styles.createdUser}>{createdBy.displayName}</div>}
			</div>
		</div>
	);
});
