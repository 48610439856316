import {CellProps} from 'react-table';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {MobileCellInfo} from './MobileCellInfo';

export const MobileOwnerAcceptanceCellWithoutObject = ({
	row
}: CellProps<IPreparedOwnerAcceptance>) => {
	const {
		number,
		localNumber,
		resolution,
		status,
		space,
		spaceOwner,
		createdAt,
		modifiedAt,
		createdBy,
		modifiedBy
	} = row.original;
	return (
		<div className="owner-acceptances-page__mobile-cell-info">
			<ClickPreventWrap className="owner-acceptances-page__mobile-cell-select">
				<TableRowSelect {...row.getToggleRowSelectedProps()}/>
			</ClickPreventWrap>
			<div>
				<MobileCellInfo
					number={number}
					localNumber={localNumber}
					resolution={resolution}
					status={status}
					space={space}
					spaceOwner={spaceOwner}
					createdAt={createdAt}
					modifiedAt={modifiedAt}
					createdBy={createdBy}
					modifiedBy={modifiedBy}
				/>
			</div>
		</div>
	);
};
