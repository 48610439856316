import {CellProps} from 'react-table';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';

export const FixDateCell = ({row}: CellProps<IPreparedWorkAcceptance>) => (
	<div className="work-acceptances-page__d-table-plannedFixDate">
		{row.original.plannedAcceptanceDate}

		{compareFixDate(
			row.original.actualAcceptanceDateOriginal,
			row.original.plannedAcceptanceDateOriginal
		) &&
			row.original.plannedAcceptanceDateOriginal && (
				<FixDateTag
					className="fix-date-view-field__tag"
					plannedFixDate={row.original.plannedAcceptanceDateOriginal}
					actualFixDate={row.original.actualAcceptanceDateOriginal}
				/>
			)}
	</div>
);
