import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

export type IMoveInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Перемещает внутреннюю приёмку
 */
export const makeInternalAcceptanceMoveRequest = async (
	objectId: string,
	internalAcceptanceId: string,
	toObjectId: string,
	toSpaceId?: string
) => {
	const response = await httpRequests.withToken.post<IMoveInternalAcceptanceResponse>(
		`internal-acceptances/move/${internalAcceptanceId}`,
		{
			objectId,
			toObjectId,
			toSpaceId
		}
	);

	return response.data;
};
