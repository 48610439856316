import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IOwnerAcceptanceStatus} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

interface IMobileCellInfoProps {
	number?: number;
	localNumber?: string;
	resolution?: string;
	status?: IOwnerAcceptanceStatus;
	space?: {
		id: string;
		name: string;
		type: string;
		floor?: string;
	};
	spaceOwner?: {
		id: string;
		name: string;
		phone?: string;
		email?: string;
	};
	createdBy?: IBriefUser;
	createdAt: string;
	modifiedAt: string;
	modifiedBy?: IBriefUser;
}

export const MobileCellInfo = ({
	number,
	localNumber,
	resolution,
	status,
	space,
	spaceOwner,
	createdAt,
	modifiedAt,
	createdBy,
	modifiedBy
}: IMobileCellInfoProps) => {
	const {t} = useTranslation();
	const translatedStatus = useTranslatedDictionaryById(
		dictionaryKeys.ownerAcceptanceStatuses,
		status?.id
	);

	const spaceType = useTranslatedDictionaryById(dictionaryKeys.spaceTypesSingular, space?.type);

	return (
		<>
			<div className="owner-acceptances-page__mobile-cell-header">
				<div className="owner-acceptances-page__mobile-cell-number">
					№{number ?? localNumber}
				</div>
			</div>
			<div
				style={{color: status?.textColor}}
				className="owner-acceptances-page__mobile-cell-status"
			>
				{translatedStatus}
			</div>
			<div className="owner-acceptances-page__mobile-cell-resolution">
				<span className="owner-acceptances-page__mobile-cell-resolution-title">
					{t('ownerAcceptancesPage.table.columns.resolution.header')}:
				</span>
				<span>{resolution}</span>
			</div>
			{spaceOwner?.id && (
				<div className="owner-acceptances-page__mobile-cell-space-owner">
					<div className="owner-acceptances-page__mobile-cell-space-owner-label">
						{t('ownerAcceptancesPage.table.columns.spaceOwner.header')}:
					</div>
					<div className="owner-acceptances-page__mobile-cell-space-owner-container">
						{spaceOwner?.name}
						{spaceOwner?.phone && `, ${spaceOwner?.phone}`}
						{spaceOwner?.email && `, ${spaceOwner?.email}`}
					</div>
				</div>
			)}
			<div className="owner-acceptances-page__mobile-cell-created">
				<div className="owner-acceptances-page__mobile-cell-created-label">
					{t('ownerAcceptancesPage.table.columns.createdAt.header')}:
				</div>
				<div className="owner-acceptances-page__mobile-cell-created-container">
					<div className="owner-acceptances-page__mobile-cell-created-date">
						{createdAt}
					</div>
					<div className="owner-acceptances-page__mobile-cell-created-name">
						{createdBy?.fullName}
					</div>
				</div>
			</div>
			{modifiedBy ? (
				<div className="owner-acceptances-page__mobile-cell-created">
					<div className="owner-acceptances-page__mobile-cell-created-label">
						{t('ownerAcceptancesPage.table.columns.modifiedAt.header')}:
					</div>
					<div className="owner-acceptances-page__mobile-cell-created-container">
						<div className="owner-acceptances-page__mobile-cell-created-date">
							{modifiedAt}
						</div>
						<div className="owner-acceptances-page__mobile-cell-created-name">
							{modifiedBy?.fullName}
						</div>
					</div>
				</div>
			) : null}
			{space && (
				<div className="owner-acceptances-page__mobile-cell-location">
					<div className="owner-acceptances-page__mobile-cell-location-label">
						{t('ownerAcceptancesPage.table.columns.location.header')}:
					</div>
					<div className="owner-acceptances-page__mobile-cell-location-container">
						{space?.name && space.type && <span>{`${space?.name} ${spaceType}`}</span>}
						{space?.floor && (
							<span>{`, ${space?.floor} ${t('locationCell.floor')}`}</span>
						)}
					</div>
				</div>
			)}
		</>
	);
};
