import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editProfile, getProfile} from '@src/store/modules/auth/profile/actions';
import {addErrorToast, addSuccessToast, Button, ErrorBoundary, Plate} from '@tehzor/ui-components';
import {useCallback, useState} from 'react';
import {useEditableProfile} from './hooks/useEditableProfile';
import './ProfileEditPage.less';
import {useTranslation} from 'react-i18next';

export const ProfileEditPage = () => {
	const {goBack} = useChangePath();
	const {t} = useTranslation();
	const user = useAppSelector(s => s.auth.profile);

	useAppHeader(
		{
			title: t('profileEditPage.title'),
			showBackBtn: true,
			mobileRightButtons: null
		},
		[t]
	);

	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, isBlocking] = useEditableProfile(user);

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();

		try {
			if (savingData) {
				await dispatch(editProfile(savingData));
				await dispatch(getProfile());

				addSuccessToast({title: t('toast.updateProfile.success.title')});
			}
		} catch {
			addErrorToast({title: t('toast.updateProfile.error.title')});
		} finally {
			setSaving(false);
			goBack();
		}
	}, [dispatch, getSavingData]);

	return (
		<div className="page-cont profile-edit-page">
			<Plate>
				<ErrorBoundary label="ProfileEditPage">
					{fields}
					<Button
						className="profile-edit-page__save-btn"
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={isBlocking || saving}
						onClick={handleSave}
					/>
				</ErrorBoundary>
			</Plate>
		</div>
	);
};
