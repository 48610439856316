import {memo, useCallback, useEffect} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {SetValueOptions} from './WarrantyClaimForm';
import {useController, useFormContext} from 'react-hook-form';

const regEmail = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;

interface IReplyEmailProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	name: string;
	onSetValue?: (name: string, value: unknown, options?: SetValueOptions) => void;
	defaultValue?: string;
	required?: boolean;
	disabled?: boolean;
}

export const ReplyEmail = memo(
	<S extends {replyEmail: string}, E>({
		className,
		style,
		label = 'Email',
		name,
		onSetValue,
		defaultValue,
		required,
		disabled,
		...restProps
	}: IReplyEmailProps<S, E>) => {
		const {t} = useTranslation();
		const {control} = useFormContext();
		const {
			field: {value},
			fieldState: {invalid}
		} = useController({
			name,
			control,
			rules: {
				required,
				pattern: {
					value: regEmail,
					message: t('components.editableFields.phone.error')
				}
			}
		});

		const handleChange = useCallback(
			(v: string) => {
				if (onSetValue) {
					onSetValue(name, v);
				}
			},
			[onSetValue, name]
		);

		useEffect(() => {
			if (onSetValue && defaultValue) {
				onSetValue(name, defaultValue);
			}
		}, [defaultValue]);

		return (
			<div
				className={className}
				style={style}
				{...restProps}
			>
				<EditableFieldLabel>{label}</EditableFieldLabel>

				<TextField
					value={value || defaultValue}
					elementType="input"
					error={invalid ? t('components.editableFields.email.error') : undefined}
					disabled={disabled}
					onChange={handleChange}
				/>
			</div>
		);
	}
);
