import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	testInternalAcceptanceRestrictions,
	testInternalAcceptanceRestrictionsList
} from '@src/utils/testRestrictions';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import type {ITestInternalAcceptanceRestrictionsProps} from '@src/interfaces/ITestRestrictions';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canProblemsMove?: boolean;
	canMoveRestrictions?: {[key: string]: boolean};
}

/**
 * Возвращает полномочия для внутренней приёмки
 *
 * @param objectId id объекта
 * @param internalAcceptance внутренняя приёмка
 * @param problems нарушения
 */
export const useInternalAcceptancePermissions = (
	objectId: string,
	internalAcceptance?: IInternalAcceptance,
	problems?: IProblem[]
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		const testInternalAcceptanceRestrictionsParams: ITestInternalAcceptanceRestrictionsProps = {
			userId: user.id,
			userRoles: user.roles,
			permissionId: '',
			objectId,
			createdBy: internalAcceptance?.createdBy?.id,
			problems,
		};

		return {
			canEdit: testInternalAcceptanceRestrictions({
				...testInternalAcceptanceRestrictionsParams,
				permissionId: 'internalAcceptancesEdit',
			}),
			canDelete: testInternalAcceptanceRestrictions({
				...testInternalAcceptanceRestrictionsParams,
				permissionId: 'internalAcceptancesDelete',
			}),
			canExport: testInternalAcceptanceRestrictions({
				...testInternalAcceptanceRestrictionsParams,
				permissionId: 'internalAcceptancesExport',
			}),
			canMove: testInternalAcceptanceRestrictions({
				...testInternalAcceptanceRestrictionsParams,
				permissionId: 'checksMove',
			}),
			canProblemsMove: testInternalAcceptanceRestrictions({
				...testInternalAcceptanceRestrictionsParams,
				permissionId: 'internalAcceptanceProblemsMove',
			}),
			canMoveRestrictions: testInternalAcceptanceRestrictionsList(
				user.roles,
				'internalAcceptanceProblemsMove',
				objectId
			)
		};
	}, [objectId, internalAcceptance, user]);
};
