import {memo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {EntityAdding} from './actions/EntityAdding';
import {EntitiesVisibility} from './actions/EntitiesVisibility';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {MobileTable} from './table/MobileTable';
import {MobileInfoBlock} from './info/MobileInfoBlock';
import {SpacesPageVariants} from '@src/interfaces/SpacesPageVariants';
import {MobileWorkAcceptanceTable} from './workAcceptanceTable/MobileTable';
import type {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useObjectSettings} from '@src/core/hooks/settings/useObjectSettings';

interface IMobileProps {
	objectId: string;
	spaceId: string;
	space: ISpace;
	hasUnfixedProblems: boolean;
	hasNotCheckedCheckLists: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
	spacesPageVariants: SpacesPageVariants;
	settings?: IEntitySettings;
}

export const Mobile = memo(
	({
		objectId,
		spaceId,
		space,
		hasUnfixedProblems,
		hasNotCheckedCheckLists,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem,
		spacesPageVariants,
		settings
	}: IMobileProps) => {
		const {restrictedAddProblemsAfterWarrantyExpiration} = useObjectSettings(objectId);

		return (
			<div className="page-cont space-page">
				<PageBreadcrumbs
					objectId={objectId}
					spaceId={spaceId}
				/>
				{spacesPageVariants === SpacesPageVariants.PROBLEMS && (
					<>
						<MobileInfoBlock
							objectId={objectId}
							space={space}
							hasUnfixedProblems={hasUnfixedProblems}
							hasNotCheckedCheckLists={hasNotCheckedCheckLists}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
							settings={settings}
						/>

						<EntitiesVisibility className="space-page__m-visibility-filter" />

						<MobileTable
							objectId={objectId}
							spaceId={spaceId}
							space={space}
						/>
					</>
				)}

				{spacesPageVariants === SpacesPageVariants.WORK_ACCEPTANCES && (
					<MobileWorkAcceptanceTable
						objectId={objectId}
						spaceId={spaceId}
						space={space}
					/>
				)}

				{spacesPageVariants === SpacesPageVariants.PROBLEMS &&
					!restrictedAddProblemsAfterWarrantyExpiration && (
						<EntityAdding
							objectId={objectId}
							spaceId={spaceId}
						/>
					)}
			</div>
		);
	}
);
