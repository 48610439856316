import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeWorkAcceptanceDisplayModePayload, IGetWorkAcceptancesPayload} from '../actions';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';

export interface IWorkAcceptancesFiltersState extends Record<string, unknown> {
	objects?: string[];
	// Фильтр по объектам, который не отображается в интерфейсе. Нужен для реестров нарушений конкретных объектов
	invisibleObjects?: string[];
	statuses?: string[];
	types?: string[];
	frontTypes?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	modifiedAtFrom?: Date;
	modifiedAtTo?: Date;
	structureTypes?: string[];
	spaceIds?: string[];
	searchString?: string;
	acceptanceDateFrom?: Date;
	acceptanceDateTo?: Date;
	expired?: string[];
	acceptors?: string[];
	submitters?: string[];
	createdBy?: string[];
	categories?: string[];
	problemsStatuses?: string[];
	excludeDescendantsObjects?: boolean;
}

export interface IWorkAcceptancesSortState {
	[column: string]: boolean;
}

export interface IWorkAcceptancesPageSettingsState {
	displayMode: WorkAcceptanceDisplayMode;
	filters: IWorkAcceptancesFiltersState;
	sort: IWorkAcceptancesSortState;
	pageSize: number;
	offset: number;
	total: number;
	selectedRows: string[];
	isAllRowsSelected: boolean;
}

export type IWorkAcceptancesPagesSettingsState = Record<string, IWorkAcceptancesPageSettingsState>;

export const getInitialStateForPage = (): IWorkAcceptancesPageSettingsState => ({
	displayMode: WorkAcceptanceDisplayMode.DESCRIPTION,
	filters: {},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	total: 0,
	selectedRows: [],
	isAllRowsSelected: false
});

export const byPage = createReducer<IWorkAcceptancesPagesSettingsState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<IWorkAcceptancesFiltersState>}
		) => {
			const {filters, isDescendants} = payload;
			if (payload.objectId !== 'all' && !isDescendants) {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			const {objectId} = payload;
			state[objectId].filters = {invisibleObjects: state[objectId].filters.invisibleObjects};
		},
		[types.CHANGE_INVISIBLE_OBJECTS]: (
			state,
			action: {payload: {objectId: string; descendants?: string[]}}
		) => {
			const {objectId, descendants} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}

			if (objectId !== 'all') {
				state[objectId].filters = {
					...state[objectId].filters,
					invisibleObjects: descendants?.length ? [objectId, ...descendants] : [objectId]
				};
			} else {
				state[objectId].filters = {
					...state[objectId].filters,
					invisibleObjects: []
				};
			}
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<IWorkAcceptancesSortState>}
		) => {
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			state[payload.objectId].pageSize = payload.pageSize;
		},
		[types.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: IChangeWorkAcceptanceDisplayModePayload}
		) => {
			state[payload.objectId].displayMode = payload.displayMode;
		},
		[types.SET_INITIAL_STATE]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetWorkAcceptancesPayload}) => {
			const page = state[payload.objectId];
			page.offset = payload.offset;
			page.total = payload.total;
		},
		[types.CHANGE_OFFSET]: (
			state,
			{payload}: {payload: {objectId: string; offset: number}}
		) => {
			const page = state[payload.objectId];
			if (page) {
				page.offset = payload.offset;
			}
		},
		[types.CHANGE_SELECTED_ROWS]: (
			state,
			{payload}: {payload: {objectId: string; selectedRows: string[]}}
		) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		[types.TOGGLE_ALL_ROWS_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string; isAllRowsSelected?: boolean}}
		) => {
			const page = state[payload.objectId];
			page.isAllRowsSelected =
				payload.isAllRowsSelected !== undefined
					? payload.isAllRowsSelected
					: !page.isAllRowsSelected;
		}
	}
);
