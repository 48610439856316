import {StructureEntitiesVisibility} from '@src/interfaces/StructureEntitiesVisibility';
import * as types from '../constants';

/**
 * Изменяет фильтр отображения сущностей структуры
 *
 * @param value значение фильтра
 */
export const changeEntitiesVisibility = (value: StructureEntitiesVisibility[]) => ({
	type: types.CHANGE_ENTITIES_VISIBILITY,
	payload: value
});
