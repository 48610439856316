import {useCallback, useState} from 'react';
import './EditableSpaceDialog.less';
import {useAddSpace} from '@src/core/hooks/mutations/spaces/useAddSpace';
import {useEditSpace} from '@src/core/hooks/mutations/spaces/useEditSpace';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableSpace} from '@src/components/EditableSpace/hooks/useEditableSpace';
import {useSpace} from '@src/core/hooks/queries/space';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableSpaceDialogProps {
	objectId: string;
	spaceId?: string;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования помещения
 */
const EditableSpaceDialog = ({objectId, spaceId, isOpen, onClose}: IEditableSpaceDialogProps) => {
	const {t} = useTranslation();
	const {data: space} = useSpace(spaceId, objectId);
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableSpace(objectId, space, saving);
	const {mutateAsync: addSpace} = useAddSpace(objectId);
	const {mutateAsync: editSpace} = useEditSpace();

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();

		if (savingData && spaceId) {
			try {
				await editSpace({objectId, spaceId, fields: savingData});
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !spaceId) {
			try {
				await addSpace({objectId, fields: savingData});
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, spaceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-space-dialog"
			isOpen={isOpen}
			title={
				spaceId
					? t('spacePage.editableSpaceDialog.dialog.title.edit')
					: t('spacePage.editableSpaceDialog.dialog.title.save')
			}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			disableScrollBlock
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};

export default EditableSpaceDialog;
