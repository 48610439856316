import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IObjectSettings from '@tehzor/tools/interfaces/objects/IObjectSettings';

export type IGetObjectSettingsResponse = IObjectSettings;

/**
 * Получает настроки объекта по objectId
 */
export const requestObjectSettings = async (objectId: string) => {
	const response = await httpRequests.withToken.get<IGetObjectSettingsResponse>(
		`object-settings/${objectId}`
	);
	return response.data;
};
