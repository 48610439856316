import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/structure/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';
import {CSSProperties} from 'react';

interface IEntitiesVisibilityProps {
	className?: string;
	style?: CSSProperties;
}

export const EntitiesVisibility = ({className, style}: IEntitiesVisibilityProps) => {
	const {t} = useTranslation();
	const {entitiesVisibility} = useAppSelector(s => s.settings.pages.structure.byPage);

	const visibilityTitles = {
		problems: t('structurePage.actions.EntitiesVisibility.visibilityTitles.problems'),
		inspections: t('structurePage.actions.EntitiesVisibility.visibilityTitles.inspections')
	};

	return (
		<VisibilityFilter
			className={className}
			style={style}
			value={entitiesVisibility}
			titlesMap={visibilityTitles}
			onChange={changeEntitiesVisibility}
			labelValuesClassName="structure-page__d-visib-filter__label-values"
			labelClassName="structure-page__d-visib-filter__label"
		/>
	);
};
