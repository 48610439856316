import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './FieldModifiedCell.module.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';

export const FieldModifiedCell = memo(
	({row}: Pick<CellProps<IPreparedFieldSettingsSet>, 'row'>) => {
		const {modifiedAt, modifiedBy} = row.original;
		return (
			<div className={styles.modified}>
				<div>
					{modifiedAt && (
						<div className={styles.modifiedAt}>{format(modifiedAt, dateTimeCommaSeparatedFormat)}</div>
					)}

					{modifiedBy && (
						<div className={styles.modifiedBy}>{modifiedBy.displayName}</div>
					)}
				</div>
			</div>
		);
	}
);
