import ISavingComment from '@src/interfaces/ISavingComment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddCommentResponse = IComment;

/**
 * Добавляет комментарий
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментария
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export async function makeCommentAddRequest(
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) {
	const response = await httpRequests.withToken.post<IAddCommentResponse>('comments', {
		objectId,
		commentTypeId,
		links,
		...fields
	});
	return response.data;
}