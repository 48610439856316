import {useSpaceTitle} from '@src/core/hooks/spaces/useSpaceTitle';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useSpace} from '@src/core/hooks/queries/space';
import {useSpaceSectionsMenu} from '@src/core/hooks/useSpaceSectionsMenu';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import MobileMenu from '@src/pages/SpacePage/components/actions/Menu.mobile';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import CheckListsExportDialog from '@src/pages/CheckListPage/components/actions/CheckListsExportDialog';

interface IUseSpaceCheckListsHeaderParams {
	spaceId: string;
	objectId: string;
	stage: ObjectStageIds | 'all';
	processId: ProcessIds | 'all';
	listId?: string;
	checkListsIds?: string[];
}

export const useSpaceCheckListsHeader = ({
	spaceId,
	objectId,
	stage,
	processId,
	listId,
	checkListsIds = []
}: IUseSpaceCheckListsHeaderParams) => {
	const {t} = useTranslation();
	const translatedTitle = t('checkListPage.hooks.useSpaceCheckListsHeader.title');
	const {data: space} = useSpace(spaceId, objectId);
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const sections = useSpaceSectionsMenu(objectId, spaceId, stage, processId);
	const isDesktop = useIsDesktop();
	const permissions = useSpacesPermissions(objectId);

	const spaceTitle = useSpaceTitle(space);
	const title = isDesktop
		? space
			? `${translatedTitle} ${spaceTypes?.[space.type]?.name ?? ''} №${space.name}`
			: translatedTitle
		: spaceTitle;

	useAppHeader(
		{
			title,
			subTitle: isDesktop ? undefined : translatedTitle,
			showBackBtn: true,
			sectionsMenu: !listId ? sections : undefined,
			mobileRightButtons:
				!isDesktop && space ? (
					<>
						{permissions.canExport && (
							<CheckListsExportDialog
								spaceId={spaceId}
								objectId={objectId}
								checkListsIds={checkListsIds}
							/>
						)}
						<MobileMenu
							space={space}
							canDeleteSpace={permissions.canDelete}
							canEditSpace={permissions.canEdit}
						/>
					</>
				) : null
		},
		[isDesktop, listId, objectId, sections, space, title]
	);
};
