import {useStructureIsLoading} from './useStructureIsLoading';
import {useStructureWorkAcceptancesEntitiesIsLoading} from '../../workAcceptance/hooks/useStructureWorkAcceptancesEntitiesIsLoading';
import {useStructureProblemRepliesIsLoading} from '../../problemReplies/hooks/useStructureProblemRepliesIsLoading';
import {useStructureProblemsIsLoading} from '../../problems/hooks/useStructureProblemsIsLoading';
import {useStructureInspectionsIsLoading} from '../../inspections/hooks/useStructureInspectionIsLoading';
import {useStructureChecksIsLoading} from '../../checks/hooks/useStructureChecksIsLoading';
import {usePerformersDetailsIsLoading} from '@src/pages/StructurePage/hooks/usePerformersDetailsIsLoading';

export const useStructurePageIsLoading = (isLoading = false) => {
	const loadingStates = [
		useStructureIsLoading(),
		useStructureProblemsIsLoading(),
		useStructureInspectionsIsLoading(),
		useStructureChecksIsLoading(),
		useStructureWorkAcceptancesEntitiesIsLoading(),
		useStructureProblemRepliesIsLoading(),
		usePerformersDetailsIsLoading()
	];

	return isLoading || loadingStates.some(state => state);
};
