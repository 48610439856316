import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue/FixDateDisplayValue';
import ProblemReply from '../ProblemReply';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IProblemCellProps} from '@src/interfaces/IProblemCellProps';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {FilesCount, PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {TableToggleRowsSelectedProps} from 'react-table';
import ClickPreventWrap from '../ClickPreventWrap';
import {ProblemStatus} from '../ProblemStatusCell';
import './ProblemInfoMobileCell.less';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useTranslation} from 'react-i18next';

interface IProblemInfoMobileProps {
	problem: IListProblem;
	depth: number;
	subTitle?: React.ReactNode;
	replies?: Record<string, IComment>;
	allProblemTags?: Record<string, IProblemTag>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
	positionFilesCount?: 'subtitle' | 'after';
}

export const ProblemInfoMobile = ({
	problem,
	depth,
	subTitle,
	replies,
	allProblemTags,
	getToggleRowSelectedProps,
	positionFilesCount = 'subtitle'
}: IProblemInfoMobileProps) => {
	const {
		status,
		plannedFixDate,
		performers,
		performersActiveGroup,
		inspectors,
		inspectorsActiveGroup,
		description,
		critical,
		problemTags,
		actualFixDate
	} = problem;
	const reply = replies?.[problem.id];

	const [problemImagesAttachments] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);
	const {t} = useTranslation();

	const [imagesViewer, openProblemImage, openReplyImage] = useEntityAndReplyViewerMobile(
		problemImages,
		replyImages,
		AttachmentFileDestination.PROBLEM,
		AttachmentFileDestination.COMMENT_OR_REPLY,
		problemImagesData,
		replyImagesData,
		'problem',
		t('problem.imageTitle'),
		reply?.number,
		problem.id,
		reply?.id
	);
	const colors = allProblemTags ? problemTags?.map(id => allProblemTags[id]?.color) : undefined;

	const performersDisplayValue = useResponsibleDisplayValue(performers, performersActiveGroup);
	const inspectorsDisplayValue = useResponsibleDisplayValue(inspectors, inspectorsActiveGroup);

	const problemInfoSubTitle = useMemo(
		() => (
			<div>
				{subTitle}
				{positionFilesCount === 'subtitle' && (
					<FilesCount count={problem?.attachments?.length} />
				)}
			</div>
		),
		[subTitle, problem?.attachments?.length, positionFilesCount]
	);

	return (
		<div className="m-problem-info-cell">
			<div
				className={classNames('m-problem-info-cell__problem', {
					'm-problem-info-cell__problem_with-padding': depth > 0
				})}
			>
				<div className="m-problem-info-cell__problem-header">
					<ProblemInfo
						style={{marginBottom: depth > 0 ? '12px' : '16px'}}
						data={problem}
						depth={depth}
						subTitle={problemInfoSubTitle}
					/>
					<ClickPreventWrap>
						<TableRowSelect
							{...getToggleRowSelectedProps()}
							className="m-problem-info-cell__problem-header-check-box"
							style={{marginTop: depth > 0 ? '6px' : '12px'}}
						/>
					</ClickPreventWrap>
				</div>

				{description !== undefined && (
					<div className="m-problem-info-cell__problem-desc">{description}</div>
				)}
				{colors?.length ? (
					<div className="m-problem-info-cell__extra-info">
						<ProblemTagsMarker colors={colors} />
					</div>
				) : null}
				{plannedFixDate !== undefined && (
					<div className="m-problem-info-cell__extra-info">
						<i className="tz-calendar-20 m-problem-info-cell__extra-info-icon" />
						<FixDateDisplayValue
							className="m-problem-info-cell__extra-info-value m-problem-info-cell__problem-fix-date"
							plannedFixDate={plannedFixDate}
							status={status}
							critical={critical}
							actualFixDate={actualFixDate}
						/>
					</div>
				)}
				{performersDisplayValue ? (
					<div className="m-problem-info-cell__extra-info">
						<i className="tz-user-20 m-problem-info-cell__extra-info-icon" />
						<div className="m-problem-info-cell__extra-info-value">
							{performersDisplayValue}
						</div>
					</div>
				) : null}
				{inspectorsDisplayValue ? (
					<div className="m-problem-info-cell__extra-info">
						<i className="tz-user-20 m-problem-info-cell__extra-info-icon" />
						<div className="m-problem-info-cell__extra-info-value">
							{inspectorsDisplayValue}
						</div>
					</div>
				) : null}
				<div className="m-problem-info-cell__problem-status-row">
					<ProblemStatus problem={problem} />

					{problemImages.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								className="m-problem-info-cell__problem-image"
								url={problemImages[0]}
								data={0}
								onClick={openProblemImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>
			{reply !== undefined && (
				<ProblemReply
					className="m-problem-info-cell__reply"
					reply={reply}
					images={replyImages}
					openImage={openReplyImage}
				/>
			)}
			{problemImages.length || replyImages.length ? (
				<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
			) : null}
			{positionFilesCount === 'after' && <FilesCount count={problem?.attachments?.length} />}
		</div>
	);
};

const ProblemInfoMobileCell = (props: IProblemCellProps<{data: IProblem}>) => (
	<ProblemInfoMobile
		problem={props.row.original.data}
		depth={props.row.depth}
		replies={props.replies}
		allProblemTags={props.allProblemTags}
		getToggleRowSelectedProps={props.row.getToggleRowSelectedProps}
		positionFilesCount={props.positionFilesCount}
	/>
);

export default ProblemInfoMobileCell;
