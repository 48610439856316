import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, TreeSelect, TreeSelectTagOption} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useExtractProblemTagsForSetsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {makeTreeData} from '@src/utils/makeTreeData';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useFilteredProblemTagsSets} from '@src/core/hooks/queries/problemTagsSets/hooks';
import {useTranslation} from 'react-i18next';
import {useNoOptionsState} from '@src/core/hooks/filters/useNoOptionsState';

interface IProblemTagsSetFilterProps {
	objectsIds?: string[];
	stages?: string[];
	problemTags?: string[];
	label?: string;
}

export const ProblemTagsSetFilter = memo(
	({objectsIds, stages, problemTags, label = 'Метки'}: IProblemTagsSetFilterProps) => {
		const {dispatch} = useEntitiesFiltersCtx();
		const {t} = useTranslation();
		const [expandedTags, setExpandedTags] = useState<string[] | undefined>([]);
		const {
			selectedOptions: selectedTags,
			setSelectedOptions: setSelectedTags,
			noOptions: noTags,
			setNoOptions: setNoTags,
			toggleNoOptions: toggleNoTags
		} = useNoOptionsState(problemTags);

		const [search, setSearch] = useState('');
		const clearSearch = useCallback(() => setSearch(''), []);

		const {data: tagsSets} = useFilteredProblemTagsSets(objectsIds, stages);
		const tagsSetIds = useMemo(() => tagsSets?.map(set => set.id) || [], [tagsSets]);
		const {data: allTags} = useExtractProblemTagsForSetsAsArray(tagsSetIds);
		const treeData = useMemo(() => makeTreeData(tagsSets, allTags), [tagsSets, allTags]);

		const {filteredData, expanded} = useMemo(
			() => treeFilter(treeData, 'content', search),
			[search, treeData]
		);

		useUpdateEffect(() => {
			setExpandedTags(expanded?.map(item => item.id));
		}, [expanded]);

		const handleApply = useCallback(() => {
			dispatch({problemTags: selectedTags});
			clearSearch();
		}, [selectedTags, dispatch, clearSearch]);

		const handleClear = useCallback(() => {
			setSelectedTags([]);
			setNoTags(false);
			clearSearch();
		}, [clearSearch]);

		const handleFullClear = useCallback(() => {
			dispatch({problemTags: undefined});
			setExpandedTags([]);
			setSelectedTags([]);
			setNoTags(false);
			clearSearch();
		}, [dispatch, clearSearch]);

		const handleCancel = useCallback(() => {
			setSelectedTags(problemTags);
			setNoTags(false);
			clearSearch();
		}, [problemTags, clearSearch]);

		const handleSelectAll = useCallback(() => {
			clearSearch();
			setNoTags(false);
			setSelectedTags(allTags?.map(val => val.id));
		}, [allTags, clearSearch]);

		const handleTreeSelectChange = (value: string[] | undefined | null) => {
			setSelectedTags(value);
			setNoTags(false);
		};
		useUpdateEffect(() => {
			setExpandedTags([]);
			setSelectedTags(problemTags);
		}, [problemTags]);

		return (
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedTags?.length}
				count={selectedTags?.length}
				footer
				onSelectAll={handleSelectAll}
				noOptions
				noOptionsOnChange={toggleNoTags}
				noOptionsChecked={noTags}
				noOptionsContent={t('problemsPage.filters.noTags')}
				search={
					<TranslatedSelectSearch
						value={search}
						type="popup"
						onChange={setSearch}
					/>
				}
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(
							label,
							problemTags,
							treeData,
							false,
							false,
							t('problemsPage.filters.noTags')
						)}
						active={!!problemTags?.length || problemTags === null}
						onClear={handleFullClear}
					/>
				}
			>
				<TreeSelect
					data={filteredData}
					multiple
					value={selectedTags}
					onChange={handleTreeSelectChange}
					expandedValue={expandedTags}
					onExpand={setExpandedTags}
					TreeItem={TreeSelectTagOption}
				/>
			</TranslatedSelectPopup>
		);
	}
);

ProblemTagsSetFilter.displayName = 'ProblemTagsSetFilter';
