import type {ITreeItem} from '../../interfaces/ITreeItem';

/**
 * Возвращает глубину ноды
 * @param id нужная нода
 * @param trees
 * @param depth
 * @returns
 */
export function findNodeDepth<T extends ITreeItem<T>>(id: string, trees: T[], depth = 0): number {
	for (const tree of trees) {
		if (tree.id === id) {
			return depth;
		}
		if (tree.children && tree.children.length > 0) {
			const childDepth = findNodeDepth(id, tree.children, depth + 1);
			if (childDepth !== -1) {
				return childDepth;
			}
		}
	}
	return -1;
}
