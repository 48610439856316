import {useWorkAcceptancesForStructureAsArray} from '@src/core/hooks/queries/workAcceptances';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructureChecksAllIds} from '@/entities/Structure/model/queries/checks/hooks/useStructureChecksAllIds';
import {StructureEntitiesTabs} from '@src/interfaces/StructureEntitiesTab';
import {changeEntitiesTab} from '@src/store/modules/settings/pages/structure/actions';
import {TabLink, Tabs} from '@tehzor/ui-components';
import {memo, useCallback, useMemo, useTransition} from 'react';
import {useTranslation} from 'react-i18next';
import {useUpdateEffect} from 'react-use';

interface IEntitiesTabsProps {
	objectId: string;
	structureId: string;
}

interface ITabData {
	key: StructureEntitiesTabs;
	title: string;
	count?: number;
}

const classNames = {links: 'structure-page__tabs', link: 'structure-page__tab-link'};

export const EntitiesTabs = memo(({objectId, structureId}: IEntitiesTabsProps) => {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();
	const [, startTransition] = useTransition();

	const {entitiesTab: activeTab} = useAppSelector(state => state.settings.pages.structure.byPage);

	const {data: workAcceptances} = useWorkAcceptancesForStructureAsArray(objectId, structureId);
	const {data: checksAllIds} = useStructureChecksAllIds(objectId, structureId);
	const workAcceptancesAllIds = workAcceptances?.map(workAcceptance => workAcceptance.id);
	const [tabsData, tabsLinks] = useMemo<[ITabData[], JSX.Element[]]>(() => {
		const data: ITabData[] = [
			{key: StructureEntitiesTabs.ALL, title: t('structurePage.entitiesTabs.all.title')},
			{
				key: StructureEntitiesTabs.WORK_ACCEPTANCE,
				title: t('structurePage.entitiesTabs.workAcceptances.title'),
				count: workAcceptancesAllIds?.length
			},
			{
				key: StructureEntitiesTabs.CHECKS,
				title: t('structurePage.entitiesTabs.checks.title'),
				count: checksAllIds?.length
			}
		];

		const links = data.map(item => (
			<TabLink
				key={item.key}
				label={item.title}
				count={item.count}
			/>
		));
		return [data, links];
	}, [workAcceptancesAllIds?.length, checksAllIds?.length, t]);

	const handleTabChange = useCallback(
		(index: number) => {
			if (!tabsData[index]) return;

			startTransition(() => {
				dispatch(changeEntitiesTab(tabsData[index].key));
			});
		},
		[tabsData, dispatch]
	);

	useUpdateEffect(() => {
		handleTabChange(0);
	}, [checksAllIds?.length, workAcceptancesAllIds?.length]);

	const activeTabIndex = useMemo(() => {
		const index = tabsData.findIndex(tabData => tabData.key === activeTab);
		if (index === -1) return 0;
		return index;
	}, [activeTab, tabsData]);

	return (
		<Tabs
			className={classNames}
			activeTab={activeTabIndex}
			links={tabsLinks}
			onActiveTabChange={handleTabChange}
		/>
	);
});
