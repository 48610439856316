import {ITreeDataItem} from '../../../Tree';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {TreeButtonCondition} from '../interfaces';

export const getButtonCondition = <T extends ITreeDataItem>(
	tree: ITwoWayTreeItem<T>,
	selected?: string[]
): TreeButtonCondition => {
	let condition = TreeButtonCondition.INACTIVE;
	if (!selected || !selected.length) return condition;
	const checkTree = (item: ITwoWayTreeItem<T>) => {
		if (selected.includes(item.id)) {
			if (
				condition === TreeButtonCondition.PARTIAL_ACTIVE ||
				condition === TreeButtonCondition.ACTIVE
			) {
				condition = TreeButtonCondition.INACTIVE;
			} else {
				condition = TreeButtonCondition.ACTIVE;
			}
		}
	};
	const checkChildren = (item: ITwoWayTreeItem<T>) => {
		if (item.children) {
			for (const value of item.children) {
				if (value.children) {
					checkChildren(value);
				}
				if (selected.includes(value.id)) {
					if (
						condition === TreeButtonCondition.PARTIAL_ACTIVE ||
						condition === TreeButtonCondition.ACTIVE
					) {
						continue;
					}
					condition = TreeButtonCondition.ACTIVE;
				} else {
					if (condition === TreeButtonCondition.ACTIVE) {
						condition = TreeButtonCondition.PARTIAL_ACTIVE;
						break;
					}
				}
			}
		}
	};
	// Вычисляем статус кнопки фильтра если список одноуровневый
	checkTree(tree);
	// Вычисляем статус кнопки фильтра если список многоуровневый (у сущности есть ключ `children`)
	checkChildren(tree);

	return condition;
};
