import {ISavingComment} from '@src/interfaces/saving/ISavingComment';
import {IEditCommentResponse} from '../comment';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Изменяет официальный ответ
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export async function makeReplyEditRequest(
	objectId: string,
	commentId: string,
	fields: ISavingComment
) {
	const response = await httpRequests.withToken.patch<IEditCommentResponse>(
		`replies/${objectId}/${commentId}`,
		fields
	);
	return response.data;
}
