import {useStructureProblemReplies} from './useStructureProblemReplies';
import {extractStructureProblemCommentsByProblem} from '../selectors/extractStructureProblemCommentsByProblem';

export const useStructureProblemRepliesAsArray = (
	objectId: string,
	structureId: string,
	permission?: boolean
) =>
	useStructureProblemReplies(
		objectId,
		structureId,
		permission,
		extractStructureProblemCommentsByProblem
	);
