import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ICommentStory} from '@tehzor/tools/interfaces/comments/ICommentStory';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCommentsStoriesResponse = INormalizedData<ICommentStory>;

/**
 * Получает историю комментариев по id
 *
 * @param objectId id объекта
 * @param entityId id сущности
 * @param commentTypeId тип комментариев
 */
export async function requestCommentsStories(
	objectId: string,
	entityId: string,
	commentTypeId: ICommentTypeId
) {
	const response = await httpRequests.withToken.get<IGetCommentsStoriesResponse>(
		`comments-stories/${objectId}/${entityId}/${commentTypeId}`
	);
	return response.data;
}
