import {memo, useMemo} from 'react';
import {SpaceStatusSelect} from '@tehzor/ui-components';
import {IPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SpaceStatusHook} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useEditSpaceStatus} from '@src/core/hooks/mutations/spaces/useEditSpaceStatus';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesMap} from '@src/core/hooks/translations/useTranslatedObjectStagesMap';
import {useExtractSpaceStatusesDataByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {useIsMutating} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IStatusProps {
	objectId: string;
	spaceId: string;
	value: string;
	permissions: IPermissions;
	hasUnfixedProblems: boolean;
	hasNotCheckedCheckLists: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

export const Status = memo(
	({
		objectId,
		spaceId,
		value,
		permissions,
		hasUnfixedProblems,
		hasNotCheckedCheckLists,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem
	}: IStatusProps) => {
		const {t} = useTranslation();
		const actionInProgress = !!useIsMutating({
			mutationKey: spacesQueryKeys.status.edit(),
			predicate: mutation =>
				mutation.state.isPaused !== true && mutation.state.variables.spaceId === spaceId
		});
		const {data: statuses} = useExtractSpaceStatusesDataByObjectId(objectId);
		const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
		const stagesMap = useTranslatedObjectStagesMap();
		const online = useAppSelector(s => s.offlineMode.networkStatus);

		const editSpaceStatus = useEditSpaceStatus(objectId);

		const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog({
			title: t('spacePage.info.status.confirmDialogTitle'),
			message: t('spacePage.info.status.confirmDialogMessage')
		});

		const [, handleChange] = useAsyncFn(
			async (v: string) => {
				const nextStatus = statuses?.byId[v];

				// если указан хук - запрашиваем подтверждение действия
				if (
					nextStatus?.hooks?.includes(SpaceStatusHook.ACCEPT_CHECK_LISTS) &&
					!(await getClosingConfirmation())
				) {
					return;
				}
				await editSpaceStatus({objectId, pageObjectId, spaceId, status: v, online});
			},
			[objectId, spaceId, statuses, online]
		);

		const availableStatuses = useMemo(
			() =>
				statuses?.allIds.filter(statusId => {
					const status = statuses.byId[statusId];

					if (hasUnfixedProblems && status?.unavailableWithUnfixedProblems) {
						return false;
					}
					if (
						hasHandlingOrWaitingProblems &&
						status?.unavailableWithHandlingOrWaitingProblems
					) {
						return false;
					}
					if (
						status?.unavailableWithUnacceptedCheckItem &&
						(hasUnacceptedCheckItem || hasNotCheckedCheckLists)
					) {
						return false;
					}
					return true;
				}),
			[
				statuses,
				hasUnfixedProblems,
				hasHandlingOrWaitingProblems,
				hasUnacceptedCheckItem,
				hasNotCheckedCheckLists
			]
		);

		if (!stagesMap || !statuses) return null;
		return (
			<>
				<SpaceStatusSelect
					stagesMap={stagesMap}
					statuses={statuses}
					disabled={!permissions.canEditStatus}
					value={value}
					availableStatuses={availableStatuses}
					onChange={handleChange}
					actionInProgress={actionInProgress}
				/>

				{closingDialog}
			</>
		);
	}
);
