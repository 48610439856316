import {useMemo} from 'react';
import type {ITestProblemRestrictionsProps} from '@src/interfaces/ITestRestrictions';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {testProblemRestrictions} from '@src/utils/testRestrictions';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canViewCheck?: boolean;
	canViewPresaleCheck?: boolean;
	canViewSpace?: boolean;
	canViewWClaim?: boolean;
	canViewHistory?: boolean;
	canViewInternalAcceptance?: boolean;
	canViewOwnerAcceptance?: boolean;
	canViewTask?: boolean;
	canAddTask?: boolean;
	canViewWorkAcceptance?: boolean;
	canViewContract?: boolean;
	canSeeViews?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param problem нарушение
 */
export const usePermissions = (objectId: string, problem?: IPreparedProblem): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles || !problem) {
			return {};
		}

		const testProblemRestrictionsParams: ITestProblemRestrictionsProps = {
			userId: user.id,
			userRoles: user.roles,
			permissionId: '',
			objectId,
			createdBy: problem?.createdBy?.id,
			performers: problem?.performers,
			inspectors: problem?.inspectors,
			watchers: problem?.watchers,
			performersActiveGroupLeader: problem?.performersActiveGroupLeader,
			inspectorsActiveGroupLeader: problem?.inspectorsActiveGroupLeader
		};

		return {
			canEdit: testProblemRestrictions({
				...testProblemRestrictionsParams,
				permissionId: 'problemsEdit'
			}),
			canDelete: testProblemRestrictions({
				...testProblemRestrictionsParams,
				permissionId: 'problemsDelete'
			}),
			canExport: testProblemRestrictions({
				...testProblemRestrictionsParams,
				permissionId: 'problemsExport'
			}),
			canViewCheck: hasPermission(user.roles, 'checksView', UserRoleScopes.OBJECT, objectId),
			canViewPresaleCheck: hasPermission(
				user.roles,
				'presale-checks-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewInternalAcceptance: hasPermission(
				user.roles,
				'internal-acceptances-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewOwnerAcceptance: hasPermission(
				user.roles,
				'owner-acceptances-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewSpace: hasPermission(user.roles, 'spaces-view', UserRoleScopes.OBJECT, objectId),
			canViewWorkAcceptance: hasPermission(
				user.roles,
				'work-acceptances-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewWClaim: hasPermission(
				user.roles,
				'warranty-claims-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewContract: hasPermission(
				user.roles,
				'contracts-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewHistory: hasPermission(
				user.roles,
				'problems-history-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewTask: hasPermission(user.roles, 'tasks-view', UserRoleScopes.OBJECT, objectId),
			canAddTask: hasPermission(user.roles, 'tasks-add', UserRoleScopes.OBJECT, objectId),
			canSeeViews: hasPermission(
				user.roles,
				'list-users-viewed-problem',
				UserRoleScopes.OBJECT,
				objectId
			)
		};
	}, [objectId, problem, user]);
};
