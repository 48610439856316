import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IEditProblemStatusResponse, makeProblemStatusEditRequest} from '@src/api/backend/problem';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {invalidateLinkedProblemsQueries} from '@src/cache/actions/problem/invalidateLinkedProblemsQueries';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {structuresQueryKeys} from '@/entities/Structures/model/cache/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export type IEditProblemStatusPayload = IEditProblemStatusResponse;

const request = () => ({type: types.EDIT_STATUS_REQUEST});

const success = (response: IEditProblemStatusResponse) => ({
	type: types.EDIT_STATUS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editProblemStatus.error.title')});
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет статус нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param status статус
 */
export const editProblemStatus = (objectId: string, problemId: string, status: ProblemStatusId) =>
	createApiAction<IEditProblemStatusResponse>(
		request,
		res => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: [...problemsQueryKeys.detail(problemId), objectId]
			});
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.problemsStats()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.problemsStatsAll()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.problems()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.listWithWorkAcceptanceStats()
			});
			void invalidateLinkedProblemsQueries(objectId);
			return success(res);
		},
		failure,
		() => makeProblemStatusEditRequest(objectId, problemId, status)
	);
