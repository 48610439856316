import {
	addErrorToast,
	addSuccessToast,
	Button,
	CircleLoader,
	EntitiesTable,
	Plate,
	SimpleTable,
	TextField
} from '@tehzor/ui-components';
import {useSpacesUnlimitedList} from '@src/core/hooks/queries/spaces/hooks';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {changeSort} from '@src/store/modules/settings/pages/spaces/actions';
import {makeSpaceEditRequest} from '@src/api/backend/space';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {IGetSpacesResponse} from '@src/api/backend/spaces';
import {uploadSpaceColumns} from './components/uploadSpaceColumns';
import {uploadDataColumns} from './components/uploadDataColumns';
import {uploadErrorsColumns} from './components/uploadErrorsColumns';
import './TableUpload.less';
import {useAnalysis} from './components/useAnalysis';

interface ISpacesTableUploadProps {
	objectId: string;
}

export interface IListSpaceWithUploadData extends IListSpace {
	uploadExternalId?: string;
	uploadStatus?: string;
}

export interface IUploadData {
	name: string;
	id: string;
	spaceId?: string;
	uploadStatus?: string;
}

export const SpacesTableUpload = ({objectId}: ISpacesTableUploadProps) => {
	const {data: targetObjects} = useTargetObjects(objectId, true);
	const allSpacesByTargetObjects: IGetSpacesResponse = useSpacesUnlimitedList(objectId, {
		objects: targetObjects
	});
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [uploadString, setUploadString] = useState<string>('');

	const uploadData: IUploadData[] = useMemo(() => {
		const rows = uploadString.split(String.fromCharCode(10));
		const data = rows.map(row => {
			const rowData = row.split(String.fromCharCode(9));
			return {
				name: rowData[0] ?? '',
				id: rowData[1] ?? ''
			};
		});

		return data;
	}, [uploadString]);

	const {pushPath} = useChangePath();

	const {sort} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const {analysis, uploadErrors, tableUploadData, spaces} = useAnalysis(
		uploadString,
		uploadData,
		allSpacesByTargetObjects
	);

	const handleRowClick = useCallback(
		(space: IListSpaceWithUploadData) => {
			pushPath(formSpaceLink(space.objectId, space.id));
		},
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => {
			dispatch(changeSort(objectId, value));
		},
		[objectId, dispatch]
	);

	useEffect(() => {
		analysis();
	}, [uploadData]);

	const handleUpload = async () => {
		setIsLoading(true);
		try {
			const spacesForUpload = spaces.filter(
				space => space.uploadExternalId && space.uploadExternalId !== space.externalId
			);

			for (const space of spacesForUpload) {
				await makeSpaceEditRequest(space.objectId, space.id, {
					externalId: space.uploadExternalId
				});
			}

			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.details()});
			addSuccessToast({title: 'Данные успешно загружены'});
		} catch (err) {
			addErrorToast({title: 'Ошибка при загрузке данных'});
		}
		setIsLoading(false);
	};

	const resultData = useMemo(() => {
		if (uploadErrors.length) {
			return uploadErrors;
		}
		return uploadString ? [{data: 'Ошибок не обнаружено'}] : [{data: 'Нет данных для анализа'}];
	}, [uploadErrors, uploadString]);

	return (
		<div className="table-upload-page">
			<Plate withoutPadding>
				<EntitiesTable
					columns={uploadSpaceColumns}
					data={spaces}
					sort={sort}
					headVisible
					onRowClick={handleRowClick}
					onSortChange={handleSortChange}
				/>
			</Plate>
			<Plate withoutPadding>
				<SimpleTable
					columns={uploadDataColumns}
					data={tableUploadData}
				/>
			</Plate>
			<Plate withoutPadding>
				<TextField
					elementType="textarea"
					elementProps={{minRows: 5}}
					value={uploadString}
					onChange={setUploadString}
					className="table-upload-page__result"
				/>
			</Plate>
			<Plate withoutPadding>
				<Button
					className="table-upload-page__button"
					onClick={handleUpload}
					label="Загрузить данные"
					type="bordered"
					disabled={isLoading}
					rightIcon={isLoading && <CircleLoader />}
				/>

				<SimpleTable
					columns={uploadErrorsColumns}
					data={resultData}
				/>
			</Plate>
		</div>
	);
};
