import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IStructureChecksQueryKey,
	IStructureInspectionsQueryKey,
	IStructureProblemRepliesQueryKey,
	IStructureProblemsQueryKey,
	IStructureQueryKey,
	IStructuresListQueryKey,
	IStructuresPaginateQueryKey,
	IStructuresProblemsStatsQueryKey,
	IStructuresWorkAcceptanceStatsQueryKey,
	structuresQueryKeys
} from '../keys/index';
import {getStructuresListRequest} from '@/shared/api/structures/getStructuresList';
import {getStructuresProblemsDataRequest} from '@/shared/api/structuresProblemData/getStructuresProblemdata';
import {getStructuresPaginateRequest} from '@/shared/api/structures/getStructuresPaginate';
import {getStructuresWorkAcceptancesDataRequest} from '@/shared/api/structuresWorkAcceptancesData/getStructuresWorkAcceptancesData';
import {requestListProblems} from '@src/api/backend/problems';
import {requestListInspections} from '@src/api/backend/inspections';
import {requestStructure} from '@/shared/api/structure/getStructure';
import {requestChecks} from '@src/api/backend/checks';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';

export const useStructuresQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(structuresQueryKeys.list(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructuresListQueryKey>) => {
			const [, , filters, sort] = queryKey;
			return getStructuresListRequest(filters, sort);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(structuresQueryKeys.paginate(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructuresPaginateQueryKey>) => {
			const [, , , filters, sort, offset, limit] = queryKey;
			return getStructuresPaginateRequest(filters, sort, offset, limit);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.problemsStatsAll(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructuresProblemsStatsQueryKey>) => {
			const [, , , objectId, structuresIds] = queryKey;
			return getStructuresProblemsDataRequest({objectId, structuresIds});
		},
		staleTime: 1000 * 60 * 24, // 1 День
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.workAcceptancesStatsAll(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructuresWorkAcceptanceStatsQueryKey>) => {
			const [, , , objectId, structuresIds] = queryKey;
			return getStructuresWorkAcceptancesDataRequest({objectId, structuresIds});
		},
		staleTime: 1000 * 60 * 24, // 1 День
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
	queryClient.setQueryDefaults(structuresQueryKeys.one(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructureQueryKey>) => {
			const [, objectId, structureId] = queryKey;
			return requestStructure(objectId, structureId);
		},
		staleTime: 1000 * 60 * 24, // 1 День
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.problems(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructureProblemsQueryKey>) => {
			const [, , objectId, structureId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					structures: [structureId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.inspections(), {
		queryFn: ({queryKey}: QueryFunctionContext<IStructureInspectionsQueryKey>) => {
			const [, , objectId, structureId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					structures: [structureId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.checks(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IStructureChecksQueryKey>) => {
			const [, , objectId, structureId] = queryKey;
			return requestChecks(
				{
					objects: [objectId],
					structures: [structureId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(structuresQueryKeys.problemReplies(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IStructureProblemRepliesQueryKey>) => {
			const [, , objectId, structureId] = queryKey;
			return requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					structures: [structureId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
