import {Column} from 'react-table';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {TFunction} from 'i18next';
import {PerformersDesktopCell} from '../cells/PerformersDesktopCell';
import {WorkAcceptanceStatusCell} from '../cells/WorkAcceptanceStatusCell';
import CheckInfoDesktopCell from '@src/components/tableCells/CheckInfoDesktopCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {WorkAcceptanceDesktopCell} from '../cells/WorkAcceptanceDesktopCell';
import {WorkAcceptanceDeadlineCell} from '../cells/WorkAcceptanceDeadlineCell';
import {ProblemFixCell} from '../cells/ProblemFixCell';
import {WorkAcceptanceDesktopStageCell} from '@src/components/tableCells/WorkAcceptanceDesktopStageCell';

export const getDesktopColumns = (
	tFunc: TFunction,
	replies?: Record<string, IComment>
): Array<Column<IStructureEntity>> => [
	{
		id: 'number',
		Header: HeaderCellWithRowSelectWrap('№'),
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(ProblemInfoDesktopCell, undefined, replies),
			inspection: InspectionInfoDesktopCell,
			'work-acceptance': WorkAcceptanceDesktopCell,
			check: CheckInfoDesktopCell
		}),
		width: 200,
		minWidth: 200
	},
	{
		id: 'status',
		Header: tFunc('structurePage.problemsTableColumns.status.header'),
		Cell: CellWrap({
			problem: ProblemStatusCell,
			'work-acceptance': WorkAcceptanceStatusCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'category',
		Header: tFunc('structurePage.problemsTableColumns.category.header'),
		Cell: CellWrap({
			problem: CategoryCell,
			'work-acceptance': WorkAcceptanceDesktopStageCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'fix',
		Header: tFunc('structurePage.problemsTableColumns.fix.header'),
		Cell: CellWrap({
			problem: ProblemFixCell,
			'work-acceptance': WorkAcceptanceDeadlineCell
		}),
		width: 80,
		minWidth: 80
	},
	{
		id: 'performers',
		Header: tFunc('problemsPage.problemsTable.header.performers'),
		Cell: CellWrap({
			problem: PerformersDesktopCell,
			inspection: PerformersDesktopCell
		}),
		width: 80,
		minWidth: 80
	}
];
