import {QueueObject} from 'async';
import queue from 'async-es/queue';
import IUploadingFile from '../../interfaces/IUploadingFile';
import {MutableRefObject, useEffect, useRef} from 'react';
import {UploadingFileStatus} from '../../enums/UploadingFileStatus';
import {AddTempFileFn} from '../withFileUploader';

/**
 * Хук. Создает очередь.
 * Вешает на неё обработчик и возвращает объект очереди.
 * @param {(key: string, data: Partial<IUploadingFile>) => void} updateFile
 * @param {(key: string) => IUploadingFile | undefined} findFile
 * @param {AddTempFileFn} onAddTempFile
 * @param {() => void} abortFilesUploading
 * @param {MutableRefObject<string[]>} processingKeys
 */
export const useQueueHandler = (
	updateFile: (key: string, data: Partial<IUploadingFile>) => void,
	findFile: (key: string) => IUploadingFile | undefined,
	onAddTempFile: AddTempFileFn,
	abortFilesUploading: () => void,
	processingKeys: MutableRefObject<string[]>
) => {
	/**
	 * Обработчик очереди.
	 * Отправляет файлы на загрузку, меняет status и progress у файлов в очереди
	 */
	const uploadFile = (key: string, callback: (error?: unknown, result?: unknown) => void) => {
		const file = findFile(key);
		if (file === undefined || file.sizeError) {
			return callback();
		}
		updateFile(key, {status: UploadingFileStatus.STARTED});
		onAddTempFile(
			file.original,
			file.destination,
			(abort: () => void) => updateFile(key, {abort}),
			(progress: number) => updateFile(key, {progress}),
			// TODO: Добавить корректную передачу параметров(объект в AddTempFileFn),
			//  чтобы location устанавливался правильно(сейчас он устанавливается в original)
			undefined,
			undefined
		)
			.then(tempFile => {
				const index = processingKeys.current.indexOf(key);
				if (index !== -1) {
					processingKeys.current.splice(index, 1);
				}
				updateFile(key, {
					status: UploadingFileStatus.FINISHED,
					tempFile,
					progress: 100,
					abort: undefined
				});
				callback();
			})
			.catch((error: unknown) => {
				if (!(error instanceof Error) || error.name !== 'CancellationError') {
					const index = processingKeys.current.indexOf(key);
					if (index !== -1) {
						processingKeys.current.splice(index, 1);
					}
					updateFile(key, {
						status: UploadingFileStatus.ERROR,
						progress: 0,
						abort: undefined
					});
				}
				callback(error);
			});
	};

	const queueHandler = useRef<QueueObject<string>>(queue(uploadFile, 1));
	useEffect(() => {
		queueHandler.current.resume();
		queueHandler.current = queue(uploadFile, 1);
	}, [uploadFile]);

	useEffect(
		() => () => {
			queueHandler.current.pause();
			abortFilesUploading();
		},
		[]
	);

	return queueHandler;
};
