import {IProblemsPageSettingsState} from '@src/store/modules/settings/pages/problems/interfaces';
import {getInitialStateForPage} from '@src/store/modules/settings/pages/problems/slice';

export const problemsPageMigration = (state: Record<string, IProblemsPageSettingsState>) =>
	Object.keys(state).reduce<Record<string, IProblemsPageSettingsState>>((acc, objectId) => {
		acc[objectId] = {
			...getInitialStateForPage(),
			filtersPatterns: state[objectId].filtersPatterns
		};
		return acc;
	}, {});
