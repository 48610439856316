import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export type IAddInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Добавляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 * @param localId localId для синхронизации данных оффлайн сущностей и онлайн
 */
export const makeInternalAcceptanceAddRequest = async (
	objectId: string,
	links: IInternalAcceptance['links'] | undefined,
	stage: ObjectStageIds = ObjectStageIds.ACCEPTANCE,
	localId?: string
) => {
	const res = await httpRequests.withToken.post<IAddInternalAcceptanceResponse>(
		'/internal-acceptances',
		{
			objectId,
			links,
			stage,
			localId
		}
	);
	return res.data;
};
