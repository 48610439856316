import {useMemo} from 'react';
import './StructurePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {getCheckRecords, getProblems} from '@src/store/modules/pages/checkLists/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptancesForStructureAsArray} from '@src/core/hooks/queries/workAcceptances';
import {useStructure} from '@/entities/Structure';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {LoadingPanel} from '@tehzor/ui-components';
import {useStructurePageIsLoading} from '@/entities/Structure';

export const StructurePage = () => {
	useScrollRestoration();

	const {objectId, spaceId, structureId} = useStrictParams<{
		objectId: string;
		spaceId: string;
		structureId: string;
	}>();

	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {data: structure} = useStructure(objectId);

	const structureType = useMemo(() => (structure ? [structure.type] : []), [structure]);

	const checkLists = useAvailableCheckLists(
		objectId,
		spaceId,
		ObjectStageIds.BUILDING,
		ProcessIds.ACCEPTANCE_CONTROL,
		structureType
	);

	const {data: workAcceptances} = useWorkAcceptancesForStructureAsArray(objectId, structure?.id);
	const workAcceptancesIds = useMemo(
		() => workAcceptances?.map(workAcceptance => workAcceptance.id),
		[workAcceptances]
	);

	useAsync(async () => {
		if (checkLists.length) {
			const ids = checkLists.map(l => l.id);
			await dispatch(getProblems(ids, undefined, workAcceptancesIds));
			await dispatch(getCheckRecords(ids, [objectId], undefined, workAcceptancesIds));
		}
	}, [objectId, checkLists, workAcceptancesIds]);

	const isLoading = useStructurePageIsLoading();

	return (
		<LoadingPanel
			className="structure-page__loading-panel"
			active={isLoading}
		>
			{isDesktop ? (
				<Desktop
					objectId={objectId}
					structureId={structureId}
					structure={structure}
					checkLists={checkLists}
				/>
			) : (
				<Mobile
					objectId={objectId}
					structureId={structureId}
					structure={structure}
					checkLists={checkLists}
				/>
			)}
		</LoadingPanel>
	);
};
