import {addTempFiles} from '@src/api/cache/utils/addTempFiles';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export interface IAddMultipleInternalAcceptancesResponse {
	internalAcceptances: IInternalAcceptance[];
	failedCreations: string[];
	problems: IProblem[];
	inspections: IInspection[];
}
/**
 * Отправляет запрос на добавления нескольких внутренних приемок
 */
export const makeInternalAcceptancesAddRequest = async (
	objectId: string,
	spaceIds: string[],
	links: IInternalAcceptance['links'] | undefined,
	entityType: IInternalAcceptanceAddingEntityType,
	savingData: ISavingProblem | ISavingInspection
) => {
	savingData.newAttachments = await addTempFiles(savingData.newAttachments, TempFileLocation.S3);
	const response = await httpRequests.withToken.post<IAddMultipleInternalAcceptancesResponse>(
		'internal-acceptances/add-with-entities',
		{
			objectId,
			spaceIds,
			links,
			entityType,
			savingData: {
				...savingData,
				objectId,
				stage: ObjectStageIds.ACCEPTANCE,
				processId: ProcessIds.INTERNAL_ACCEPTANCE,
				newAttachmentsIds: savingData.newAttachments?.map(item => item.id)
			}
		}
	);

	return response.data;
};
