import {EditableFieldLabel, IconButton, TextField} from '@tehzor/ui-components';
import classNames from 'classnames';
import {memo, useCallback, useState} from 'react';
import styles from './AddFiltersPattren.module.less';

interface IAddFiltersPatternProps {
	onAddPattern: (name: string) => void;
}

const iconAdd = <i className={classNames('tz-mark-24')} />;

export const AddFiltersPattern = memo(({onAddPattern}: IAddFiltersPatternProps) => {
	const [patternName, setPatternName] = useState('');

	const handleAddPattern = useCallback(() => {
		if (!patternName) return;
		onAddPattern(patternName);
		setPatternName('');
	}, [onAddPattern, patternName]);

	return (
		<div>
			<p className={styles.title}>Создать новый шаблон фильтров</p>
			<div>
				<EditableFieldLabel>Название шаблона:</EditableFieldLabel>
				<div className={styles.fieldContainer}>
					<TextField
						value={patternName}
						onChange={setPatternName}
						elementType="input"
					/>
					<IconButton
						onClick={handleAddPattern}
						disabled={!patternName}
						type="inline-green"
					>
						{iconAdd}
					</IconButton>
				</div>
			</div>
		</div>
	);
});
