import {useMutation} from '@tanstack/react-query';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {
	IAddMultiplePresaleChecksResponse,
	requestAddMultiplePresaleChecks
} from '@/shared/api/presaleChecks/addMultiplePresaleChecksWithEntities';
import {ISavingProblem} from '../../../../../../../interfaces/saving/ISavingProblem';
import {ISavingInspection} from '../../../../../../../interfaces/saving/ISavingInspection';
import {IPresaleCheckAddingEntityType} from '../../slice/presaleCheckAdding/interfaces';

export interface IAddMultiplePresaleChecksWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: IPresaleCheck['links'];
	savingData: ISavingProblem | ISavingInspection;
	presaleCheckType: IPresaleCheckAddingEntityType;
}

/**
 * Хук для добавления нескольких предпродажных проверок в рамках различных сущностей
 */
export const useAddMultiplePresaleChecksWithEntities = () =>
	useMutation<
		IAddMultiplePresaleChecksResponse,
		Error,
		IAddMultiplePresaleChecksWithEntitiesParams
	>({
		mutationFn: async ({objectId, spaceIds, links, presaleCheckType, savingData}) =>
			requestAddMultiplePresaleChecks(
				objectId,
				spaceIds,
				links,
				savingData,
				presaleCheckType
			),
		onError: error => {
			console.error('Ошибка при создании предпродажных проверок:', error);
		}
	});
