import {Column} from 'react-table';
import {IUploadData} from '../TableUpload';

export const uploadDataColumns: Array<Column<IUploadData>> = [
	{
		id: 'name',
		accessor: 'name',
		Header: 'Наименование',
		width: 80,
		minWidth: 50
	},
	{
		id: 'id',
		accessor: 'id',
		Header: 'Внешний id',
		width: 80,
		minWidth: 80
	},
	{
		id: 'spaceId',
		accessor: 'spaceId',
		Header: 'Id помещения',
		width: 80,
		minWidth: 80
	}
];
