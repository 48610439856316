import {Suspense, useMemo} from 'react';
import {CellProps} from 'react-table';
import {TableRowSelect, FilesCount} from '@tehzor/ui-components';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {useWorkAcceptanceAcceptors} from '@src/pages/SpacePage/hooks/useWorkAcceptanceAcceptors';
import {useWorkAcceptanceCompany} from '@src/pages/SpacePage/hooks/useWorkAcceptanceCompany';
import {useCheckListStatuses} from '@src/pages/SpacePage/hooks/useCheckListStatuses';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {WorkAcceptanceAcceptors} from '@src/components/tableCells/WorkAcceptanceAcceptors';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import WorkAcceptanceInfo from '@src/components/tableCells/WorkAcceptanceInfo';
import {WorkAcceptanceCheckListStatuses} from '@src/components/tableCells/WorkAcceptanceCheckListStatuses';
import {WorkAcceptanceScope} from '../WorkAcceptanceScope';

export const WorkAcceptanceDesktopCell = ({row}: CellProps<{data: IListWorkAcceptance}>) => {
	const {
		id,
		objectId,
		stage,
		acceptorsActiveGroup,
		acceptors,
		attachments,
		description,
		frontType,
		categoryId,
		structureIds
	} = row.original.data;

	const {data: workAcceptance} = useWorkAcceptance(id, objectId);

	const structureTypes = useMemo(
		() =>
			workAcceptance?.structures
				? Array.from(new Set(workAcceptance.structures.map(str => str.type)))
				: undefined,
		[workAcceptance?.structures]
	);

	const {acceptorsGroup, acceptors: currentAcceptors} = useWorkAcceptanceAcceptors(
		objectId,
		stage,
		acceptors,
		acceptorsActiveGroup
	);
	const company = useWorkAcceptanceCompany(acceptorsActiveGroup, acceptorsGroup);

	const checkLists = useAvailableCheckLists(
		objectId,
		undefined,
		stage,
		ProcessIds.ACCEPTANCE_CONTROL,
		structureTypes,
		categoryId
	);

	const checkListsStatuses = useCheckListStatuses(checkLists, id);

	return (
		<div className="work-acceptance-info-cell">
			<ClickPreventWrap>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>

			<div className="work-acceptance-info-cell__info">
				<WorkAcceptanceInfo data={row.original.data} />

				{description !== undefined && (
					<div className="work-acceptance-info-cell__desc">{description}</div>
				)}

				{acceptorsGroup && currentAcceptors && company && (
					<div className="work-acceptance-info-cell__acceptors">
						<WorkAcceptanceAcceptors
							acceptorsGroup={acceptorsGroup}
							currentAcceptors={currentAcceptors}
							company={company}
						/>
					</div>
				)}

				{structureIds && (
					<Suspense>
						<WorkAcceptanceScope
							objectId={objectId}
							structureIds={structureIds}
							frontType={frontType}
						/>
					</Suspense>
				)}

				<div className="work-acceptance-info-cell__indicators">
					{attachments && <FilesCount count={attachments?.length} />}
					{checkListsStatuses && (
						<WorkAcceptanceCheckListStatuses checkListStatuses={checkListsStatuses} />
					)}
				</div>
			</div>
		</div>
	);
};