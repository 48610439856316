import {memo, useCallback, useDeferredValue, useMemo} from 'react';
import {
	CheckListsSpace,
	ProblemsSpace,
	SpacesBoard,
	WorkAcceptancesSpace
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {formCheckListsLink, formSpaceLink} from '@tehzor/tools/utils/links';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeSchemaView,
	selectSpaceInSchema,
	selectSpacesMultiple
} from '@src/store/modules/settings/pages/spaces/actions';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {filterCheckLists, filterSpace} from '../../utils/filterSpaces';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesAsArrayWithFilteredByObject} from '@src/core/hooks/queries/spaces/hooks';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {convertWorkAcceptancesForSpaceSchemaData} from '../../utils/convertWorkAcceptances';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {useShowStageByProcessId} from '@src/core/hooks/processes/useShowStageByProcessId';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {useSpacesSelection} from '../../hooks/useSpacesSelection';

const getSpaceComponent = (schemaView: SpacesSchemaVariants) => {
	switch (schemaView) {
		case SpacesSchemaVariants.CHECK_LISTS:
			return CheckListsSpace;
		case SpacesSchemaVariants.WORK_ACCEPTANCES:
			return WorkAcceptancesSpace;
		case SpacesSchemaVariants.PROBLEMS:
		default:
			return ProblemsSpace;
	}
};

interface ISingleSchemaProps {
	objectId: string;
	hideTitle?: boolean;
	isTitleClickable?: boolean;
}

export const SingleSchema = memo(
	({
		objectId,
		hideTitle,
		isTitleClickable
	}: ISingleSchemaProps) => {
		const {t} = useTranslation();

		const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
		const pageSetting = useAppSelector(s => extractSpacesPageSettings(s, pageObjectId));
		const pageSettings = useDeferredValue(pageSetting);

		const {data: spaces} = useSpacesAsArrayWithFilteredByObject(
			objectId,
			pageSetting.type,
			pageSetting.schemaView
		);

		const {isChoosingVisible} = useSpacesSelection(objectId);

		const {pushPath} = useChangePath();
		const {data: object} = useObject(objectId);
		const {data: problemStatuses} = useProblemStatuses();
		const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
		const {data: checkListsData} = useCheckLists();
		const {data: checkRecordStatuses} = useCheckRecordsStatuses();

		const stageId = useShowStageByProcessId(pageSetting.processId as string | undefined);
		const {data: indicatorsSetsWithIndicators} = useActualUnitIndicatorsSetsExtended(
			objectId,
			stageId ?? undefined,
			false
		);

		const translatedCheckRecordStatuses = useTranslatedDictionary(
			'checkRecordStatus',
			checkRecordStatuses
		);

		const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();

		const sharedSettings = useAppSelector(s => s.settings.pages.spaces.shared);

		const dispatch = useAppDispatch();

		// Переход на страницу шахматку объекта

		const goToObject = useCallback(() => {
			if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
				dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
			} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
				dispatch(changeSchemaView(objectId, SpacesSchemaVariants.CHECK_LISTS));
			}
			pushPath(`/objects/${objectId}/spaces`);
		}, [objectId, pageSettings, dispatch, pushPath]);

		// Переход на страницу помещения
		const goToSpace = useCallback(
			(space: IConvertedSpace) => {
				if (pageSettings.schemaView === SpacesSchemaVariants.PROBLEMS) {
					pushPath(formSpaceLink(space.objectId, space.id));
				} else if (pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES) {
					pushPath(formSpaceLink(space.objectId, space.id, 'work-acceptances'));
				} else if (pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS) {
					const checkListsLink = formCheckListsLink({
						objectId,
						spaceId: space.id,
						stage: pageSettings.stage ?? 'all',
						processId: pageSettings.processId ?? 'all'
					});
					if (checkListsLink) {
						pushPath(checkListsLink);
					}
				}
			},
			[objectId, pageSettings, pushPath]
		);

		// Функция выбора одного помещения
		//  передается id родительского объекта, дочернего, id помещения и тип помещения. В случае отсутствия дочернего помещения - вместо него передается id родительского)
		const chooseSpace = useCallback(
			(space: IConvertedSpace) => {
				dispatch(selectSpaceInSchema(pageObjectId, space.objectId, space.id, space.type));
			},
			[dispatch, pageObjectId]
		);

		// Отфильтрованные помещения у дочерних объектов по выбранному типу
		const filteredSpaces = useMemo(
			() => spaces?.filter((space: IEnrichedSpace) => space.type === pageSettings.type),
			[pageSettings.type, spaces]
		);

		const checkIndicatorAvailability = useMemo(
			() => (indicator: ISpaceIndicator) =>
				indicatorsSetsWithIndicators?.some(set =>
					set.indicators.some(setIndicator => setIndicator.id === indicator.id)
				),
			[indicatorsSetsWithIndicators]
		);

		const convertedSpaces: IConvertedSpace[] | undefined = useMemo(
			() =>
				filteredSpaces?.map(space => {
					const convertedSpace: IConvertedSpace = {
						...space,
						indicators: space.indicators?.filter(indicator =>
							checkIndicatorAvailability(indicator)
						),
						problems: undefined,
						checkLists: undefined,
						workAcceptances: undefined,
						sortBase: space.name
					};

					convertedSpace.problems = convertProblemsData(
						space.problems,
						problemStatuses,
						pageSettings.stage,
						pageSettings.processId
					);

					convertedSpace.workAcceptancesForSchema =
						convertWorkAcceptancesForSpaceSchemaData(
							space.workAcceptancesForSpaceSchema,
							space.workAcceptancesProblems,
							workAcceptanceStatuses,
							problemStatuses
						);

					const filteredCheckLists = checkListsData
						? filterCheckLists(
								checkListsData,
								object?.companyId || '',
								space.objectId,
								space.type,
								pageSettings.stage,
								pageSettings.processId,
								pageSettings.filters.checkListIds,
								space.typeDecoration
						  )
						: [];
					convertedSpace.checkLists = convertCheckListsData(
						filteredCheckLists,
						checkItemsByLists,
						translatedCheckRecordStatuses,
						pageSettings.filters.checkListCategory,
						space.checkLists
					);

					return convertedSpace;
				}),

			[
				checkItemsByLists,
				checkListsData,
				translatedCheckRecordStatuses,
				workAcceptanceStatuses,
				filteredSpaces,
				object?.companyId,
				pageSettings.filters.checkListIds,
				pageSettings.filters.checkListCategory,
				pageSettings.stage,
				pageSettings.processId,
				problemStatuses,
				checkIndicatorAvailability
			]
		);

		const filteredConvertedSpaces: IConvertedSpace[] | undefined = useMemo(
			() =>
				convertedSpaces?.map(space => {
					const filtered = filterSpace({
						space,
						spaceIndicators: space.indicators?.map(indicator => indicator.id) || [],
						spaceStatus: space.status?.id,
						spaceProblems: space.problems?.map(el => el.key) || [],
						filters: pageSettings.filters,
						checkLists: checkListsData,
						schemaView: pageSetting.schemaView,
						spaceWorkAcceptances:
							space.workAcceptancesForSchema?.items?.map(el => el.status) || [],
						workAcceptanceProblems:
							space.workAcceptancesForSchema?.problems?.map(el => el.key) || [],
						workAcceptanceCategories:
							space.workAcceptancesForSchema?.items?.map(el => el.categoryId) || []
					});

					return {
						...space,
						filtered
					};
				}),
			[convertedSpaces, pageSettings.filters, pageSetting.schemaView, checkListsData]
		);

		// Функция для выбора множества помещений у дочернего объекта на шахматке.
		//  Выбирает/убирает все помещения у объекта при нажатии на чекбокс
		const chooseAllSpacesByObject = () => {
			const filteredConvertedSpacesIds = filteredConvertedSpaces
				.filter(space => space.objectId === objectId && space.filtered === true)
				.map(space => space.id);
			dispatch(
				selectSpacesMultiple(
					pageObjectId,
					objectId,
					filteredConvertedSpacesIds,
					pageSettings?.type as string
				)
			);
		};

		// Все id выбранных помещений у дочернего объекта (с соответсвующим типом помещений)
		const choosenSpacesIds = useMemo(() => {
			const selectedSpaces = pageSettings.selectedSubObjects[objectId] || [];
			return selectedSpaces
				.filter(space => space.type === pageSettings.type)
				.map(space => space.id);
		}, [pageSettings.selectedSubObjects, pageSettings.type, objectId]);

		if (!filteredConvertedSpaces || !filteredConvertedSpaces.length) {
			return null;
		}

		return (
			<SpacesBoard
				className="spaces-page__schema-list-item"
				data={filteredConvertedSpaces}
				title={
					hideTitle ? undefined : (
						<>
							{object?.name}{' '}
							<span className="spaces-page__schema-list-item_subtitle">
								({filteredConvertedSpaces.length})
							</span>
						</>
					)
				}
				reverseFloorSort={pageSettings.reverseFloorSort}
				SpaceComponent={getSpaceComponent(pageSettings.schemaView)}
				checkListPopupContentSpaceNameTitle={t(
					'spacesPage.schema.singleSchema.spacesBoard.checkListPopupContentSpaceNameTitle'
				)}
				spaceIndicatorsPopupContentTitle={t(
					'spacesPage.schema.singleSchema.spacesBoard.spaceIndicatorsPopupContentTitle'
				)}
				spaceIndicatorsPopupContentHide={t(
					'spacesPage.schema.singleSchema.spacesBoard.spaceIndicatorsPopupContentHide'
				)}
				onSpaceClick={goToSpace}
				onSpaceChooseClick={chooseSpace}
				altNamesVisible={sharedSettings.altNamesVisible}
				choosenSpaces={choosenSpacesIds}
				chooseSpacesVisible={
					isChoosingVisible && pageSettings.schemaView !== SpacesSchemaVariants.REPORTS
				}
				onTitleClick={isTitleClickable && !isChoosingVisible ? goToObject : undefined}
				onTitleCheckBoxClick={chooseAllSpacesByObject}
			/>
		);
	}
);
