import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {Dispatch, ReactNode, Ref, SetStateAction} from 'react';
import {useSpaceLocation} from './useSpaceLocation';
import {EntityTypeSelect} from '../components/EntityTypeSelect';
import {useLatestInternalAcceptances} from './useLatestInternalAcceptances';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useExtractLatestInternalAcceptancesAsArray} from '@src/core/hooks/queries/internalAcceptances/latest/hooks';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useInternalAcceptance} from '@src/core/hooks/queries/internalAcceptance/hook';

interface IUseInternalAcceptanceMaps {
	objectId: string;
	internalAcceptanceId?: string;
	stage: ObjectStageIds;
	processId: ProcessIds;
	links?: IInternalAcceptance['links'];
	availableTypes: IInternalAcceptanceAddingEntityType[];
	type?: IInternalAcceptanceAddingEntityType;
	addingEntityRef: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>;
	problemToCopyId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedInternalAcceptanceId?: string;
	setSelectedInternalAcceptanceId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;

	onClose: () => void;
}

export const useInternalAcceptanceMaps = (
	props: IUseInternalAcceptanceMaps
): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {t} = useTranslation();
	const {
		objectId,
		internalAcceptanceId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedInternalAcceptanceId,
		setSelectedInternalAcceptanceId,
		onClose
	} = props;

	const {data: internalAcceptance} = useInternalAcceptance(objectId, internalAcceptanceId);

	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	const [defaultProblemData, defaultInspectionData, loading] = useSpaceLocation(
		objectId,
		selectedInternalAcceptanceId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	const {data: latestInternalAcceptances} = useExtractLatestInternalAcceptancesAsArray(
		objectId,
		links?.spaceId
	);

	useLatestInternalAcceptances(
		objectId,
		setSelectedInternalAcceptanceId,
		selectedInternalAcceptanceId,
		links
	);

	contentMap[stage] = {} as Record<ProcessIds, ReactNode>;
	contentMap[stage][processId] = (
		<>
			<div className="adding-internal-acceptance-dialog__title">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					defaultData={problemToCopyId ? props.defaultProblemData : defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					problemToCopyId={problemToCopyId}
					isLoading={loading}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					isLoading={loading}
				/>
			) : null}
		</>
	);

	entitiesSelectMapProps[stage] = {} as Record<ProcessIds, IEntitySelectProps>;
	entitiesSelectMapProps[stage][processId] = {
		name: t('addingInternalAcceptanceDialog.useInternalAcceptanceMaps.acceptance.name'),
		newName: t('addingInternalAcceptanceDialog.useInternalAcceptanceMaps.acceptance.newName'),
		selected: selectedInternalAcceptanceId,
		onEntityChange: setSelectedInternalAcceptanceId,
		withoutNew: !!internalAcceptance,
		entities: internalAcceptance ? [internalAcceptance] : latestInternalAcceptances,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
