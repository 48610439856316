import {FixDateTag} from '@src/components/FixDateTag';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';

interface IMobilePlannedFixDateProps {
	plannedFixDate: number;
	actualFixDate?: number;
}
export const MobilePlannedFixDate = ({
	plannedFixDate,
	actualFixDate
}: IMobilePlannedFixDateProps) => {
	const {t} = useTranslation();

	return (
		<div className="structure-page__m-table-plannedFixDate">
			{t('structurePage.problemsTableColumns.fix.header')}: &nbsp;
			{format(plannedFixDate, dateTimeCommaSeparatedFormat)}
			{compareFixDate(actualFixDate, plannedFixDate) && (
				<FixDateTag
					plannedFixDate={plannedFixDate}
					actualFixDate={actualFixDate}
				/>
			)}
		</div>
	);
};
