import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {useIsFetching} from '@tanstack/react-query';
import {useWarrantyClaimSettings} from '../../../core/hooks/settings/useWarrantyClaimSettings';
import {FormProvider, useForm} from 'react-hook-form';
import {useDefaultWarrantyClaimData} from '../hooks/useDefaultWarrantyClaimData';
import {WarrantyClaimAttachmentForm} from '../components/WarrantyClaimAttachmentForm';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

export interface IAddWarrantyClaimForm extends ISavingWarrantyClaim {
	templateId?: string;
}

export const useEditableWarrantyClaimAttachmentForm = (
	objectId: string,
	claim: IWarrantyClaim | undefined,
	saving: boolean
): [
	React.ReactNode,
	boolean,
	(useLocalFiles?: boolean) => Promise<ISavingWarrantyClaim | undefined>,
	() => void
] => {
	const fieldsSettings = useWarrantyClaimSettings(objectId);
	const {builtin} = fieldsSettings;

	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	const defaultValues = useDefaultWarrantyClaimData(undefined, claim);

	const formMethods = useForm<IAddWarrantyClaimForm>({
		defaultValues,
		mode: 'onChange'
	});

	useNavigationPrevent(isBlocking);

	const {formState, reset, trigger, getValues} = formMethods;

	const {dirtyFields} = formState;
	const isDirtyForm = Object.keys(dirtyFields).length > 0;

	useEffect(() => {
		setIsBlocking(isDirtyForm || isFilesExist(uploadingFilesState.value));
	}, [isDirtyForm, uploadingFilesState.value]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			const isValid = await trigger();

			const formData = getValues();
			const {...restFormData} = formData;

			const isAttachmentsInvalid =
				builtin.attachments &&
				builtin.attachments.required &&
				!uploadingFilesState.value.length &&
				!formData.attachments?.length;

			if (!isValid || isAttachmentsInvalid) {
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}

			if (!isDirtyForm && (!isFilesExist(files) || someFilesHaveError(files))) {
				return undefined;
			}

			return {
				...restFormData,
				newAttachments: useLocalFiles
					? convertToLocalSave(files)
					: convertFilesToSave(files)
			};
		},
		[
			waitUploading,
			uploadingFilesState,
			builtin,
			uploadingFilesDispatch,
			isDirtyForm,
			getValues,
			trigger
		]
	);

	const resetForm = useCallback(() => {
		reset(defaultValues);
		uploadingFilesDispatch({type: 'reset'});
	}, [claim]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [claim]);

	const isWarrantyClaimLoading = !!useIsFetching({queryKey: warrantyClaimsQueryKeys.one()});

	const form = (
		<FormProvider {...formMethods}>
			<WarrantyClaimAttachmentForm
				uploadingFilesState={uploadingFilesState}
				uploadingFilesDispatch={uploadingFilesDispatch}
				saving={saving || isWarrantyClaimLoading}
				warrantyClaimId={claim?.id}
			/>
		</FormProvider>
	);

	return [form, isBlocking, getSavingData, resetForm];
};
