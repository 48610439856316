import './Indicators.less';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';

interface IIndicatorsProps {
	indicators: ISpaceIndicator[];
}
export const Indicators = ({indicators}: IIndicatorsProps) => (
	<div className="space-indicators__wrapper">
		<div className="space-indicators__tags">
			{indicators.slice(0, 3).map((indicator, index) => (
				<svg
					key={`${indicator.id}-${index}`}
					className="space-indicators__tag"
					viewBox="0 0 6 6"
				>
					<circle
						cx="3"
						cy="3"
						r="3"
						fill={indicator.color}
					/>
				</svg>
			))}
		</div>
		{ indicators?.length > 3 && (
			<div className="space-indicators__rest">+{indicators.length - 3}</div>
		)}
	</div>
);
