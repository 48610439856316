import {useCallback} from 'react';
import {EntitiesTable} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SelectionRow} from './SelectionRow';
import {useEnrichedList} from '../../hooks/useEnrichedList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSort, selectSpaces} from '@src/store/modules/settings/pages/spaces/actions';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useExtractCheckListsAsArray} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {convertWorkAcceptancesData} from '../../utils/convertWorkAcceptances';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {batch} from 'react-redux';
import {useSpacesSelection} from '../../hooks/useSpacesSelection';
import {convertTableValueToSpaces} from '../../utils/convertTableValuesToSpaces';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<IConvertedSpace>>;
	hideHead?: boolean;
	noRowBorder?: boolean;
	selectable?: boolean;
	allSpacesById?: Record<string, IListSpace>;
}

const Table = (props: IDesktopTableProps) => {
	const {objectId, columns, hideHead, noRowBorder, selectable, allSpacesById} = props;
	const {pushPath} = useChangePath();
	// Включить режим выбора помещений
	const {toggleChoosing} = useSpacesSelection(objectId);

	const {sort, selected, chooseSpacesVisible} = useAppSelector(s =>
		extractSpacesPageSettings(s, objectId)
	);

	const spaces = useEnrichedList(objectId);
	const dispatch = useAppDispatch();

	const {data: problemStatuses} = useProblemStatuses();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
	const {data: checkListsAsArray} = useExtractCheckListsAsArray();
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();

	const convertedSpaces: IConvertedSpace[] = spaces?.map(space => ({
		...space,
		problems: convertProblemsData(space.problems, problemStatuses),
		workAcceptances: convertWorkAcceptancesData(space.workAcceptances, workAcceptanceStatuses),
		checkLists: convertCheckListsData(
			checkListsAsArray,
			checkItemsByLists,
			checkRecordStatuses,
			undefined,
			space.checkLists
		)
	}));

	const handleRowClick = useCallback(
		(space: IConvertedSpace) => {
			pushPath(formSpaceLink(space.objectId, space.id));
		},
		[objectId]
	);

	const handleSelection = useCallback(
		(value: string[]) => {
			const convertedSelectedSpaces = convertTableValueToSpaces(value, allSpacesById || {});
			batch(() => {
				dispatch(selectSpaces(objectId, value, convertedSelectedSpaces));
				toggleChoosing(true);
			});
		},
		[dispatch, allSpacesById, toggleChoosing, objectId]
	);

	const handleSortChange = useCallback(
		(value: Record<string, boolean>) => {
			dispatch(changeSort(objectId, value));
		},
		[objectId, dispatch]
	);

	return (
		<EntitiesTable
			columns={columns}
			data={convertedSpaces}
			sort={sort}
			selectedRows={selected}
			selectable={selectable}
			noRowBorder={noRowBorder}
			headVisible={!hideHead}
			onRowClick={handleRowClick}
			onSelectedRowsChange={handleSelection}
			onSortChange={handleSortChange}
			renderSelectionRow={SelectionRow}
			isMultipleSpacesSelectionActive={chooseSpacesVisible}
		/>
	);
};

export default Table;
