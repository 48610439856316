import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateTag} from '@src/components/FixDateTag';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadlineMobile = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {plannedAcceptanceDate, actualAcceptanceDate} = workAcceptance;
	const {t} = useTranslation();
	return plannedAcceptanceDate ? (
		<div className="structure-page__m-table-plannedFixDate">
			{t('structurePage.workAcceptanceTableColumns.date.header')}
			{format(plannedAcceptanceDate, dateTimeCommaSeparatedFormat)}
			<FixDateTag
				className="fix-date-view-field__tag"
				plannedFixDate={plannedAcceptanceDate}
				actualFixDate={actualAcceptanceDate}
			/>
		</div>
	) : null;
};
