import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {deleteLinkedLocalInspections} from '../inspection/deleteLinkedLocalInspections';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export const deleteLocalInternalAcceptance = async (acpId: string) => {
	queryClient.removeQueries({queryKey: internalAcceptancesQueryKeys.detail(acpId)});
	await queryClient.refetchQueries({
		queryKey: internalAcceptancesQueryKeys.localList()
	});

	await deleteLinkedLocalProblems('internalAcceptanceId', acpId);
	await deleteLinkedLocalInspections('internalAcceptanceId', acpId);
	deleteMutation(acpId);
};
