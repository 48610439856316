import {IGetProblemStatusesResponse} from '@src/api/backend/problemStatuses';
import {IGetWorkAcceptanceStatusesResponse} from '@src/api/backend/workAcceptanceStatuses';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {
	IEntityWorkAcceptanceStatusData,
	IEntityWorkAcceptanceProblemsData,
	IEntityWorkAcceptancesData
} from '@tehzor/tools/interfaces/entititesComputedData/IEntityWorkAcceptancesData';
import {IConvertedWorkAcceptancesSpaceData} from '@tehzor/tools/interfaces/spaces/IConvertedWorkAcceptancesData';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';

export const convertWorkAcceptancesData = (
	workAcceptances: IEntityWorkAcceptancesData | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus> | undefined
): IConvertedWorkAcceptancesSpaceData[] => {
	if (!workAcceptances || !workAcceptanceStatuses) {
		return [];
	}

	let total = 0;
	const result: IConvertedWorkAcceptancesSpaceData[] = [];

	Object.keys(workAcceptances).forEach(statusId => {
		result.push({
			key: statusId,
			value: workAcceptances[statusId]?.count,
			percent: 0,
			color: workAcceptanceStatuses.byId[statusId]?.color || '#00000000',
			startPoint: 0
		});
		total += workAcceptances[statusId].count;
	});

	let offset = 0;
	for (const stat of result) {
		stat.percent = (stat.value / total) * 100;
		stat.startPoint = -offset;
		offset += stat.percent;
	}

	return result;
};

const convertWorkAcceptances = (
	items: IEntityWorkAcceptanceStatusData[] | undefined,
	statuses: IGetWorkAcceptanceStatusesResponse | undefined
) => {
	const result: Array<{status: string; color: string; categoryId: string}> = [];

	if (!items || !statuses) {
		return result;
	}

	items.forEach(({status, categoryId}) => {
		const {color} = statuses.byId[status];

		if (color) {
			result.push({status, color, categoryId});
		}
	});

	return result;
};

const convertWorkAcceptancesProblems = (
	problems: IEntityWorkAcceptanceProblemsData[] | undefined,
	statuses: IGetProblemStatusesResponse | undefined
) => {
	const result: IConvertedWorkAcceptancesSpaceData[] = [];

	if (!problems || !statuses) {
		return result;
	}

	let total = 0;

	for (const problem of problems) {
		const {status, count} = problem;

		result.push({
			key: status,
			value: count,
			percent: 0,
			color: statuses.byId[status]?.color || '#00000000',
			startPoint: 0
		});

		total += count;
	}

	let offset = 0;

	for (const stat of result) {
		stat.percent = (stat.value / total) * 100;
		stat.startPoint = -offset;
		offset += stat.percent;
	}

	return result;
};

export const convertWorkAcceptancesForSpaceSchemaData = (
	workAcceptances: IEntityWorkAcceptanceStatusData[] | undefined,
	problems: IEntityWorkAcceptanceProblemsData[] | undefined,
	workAcceptanceStatuses: IGetWorkAcceptanceStatusesResponse | undefined,
	problemStatuses: IGetProblemStatusesResponse | undefined
) => {
	const was = convertWorkAcceptances(workAcceptances, workAcceptanceStatuses);
	const prs = convertWorkAcceptancesProblems(problems, problemStatuses);

	return {items: was, problems: prs};
};
