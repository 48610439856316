import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IGetInternalAcceptanceProblemRepliesPayload} from './hooks';

export const extractInternalAcceptanceProblemRepliesAsArray = (
	data: IGetInternalAcceptanceProblemRepliesPayload
) => {
	if (!data) return undefined;
	return data.allIds.map(id => data.byId[id]);
};

export const extractInternalAcceptanceProblemCommentsByProblem = (
	data: IGetInternalAcceptanceProblemRepliesPayload
) => {
	const array = extractInternalAcceptanceProblemRepliesAsArray(data);

	if (!array) return undefined;

	return array.reduce<Record<string, IComment>>((prev, item) => {
		if (item.links?.problemId && !prev[item.links.problemId]) {
			prev[item.links.problemId] = item;
		}
		return prev;
	}, {});
};
