import {useCallback} from 'react';
import {useLocation, useMatch} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {ObjectsFilterMobile} from '@src/components/MobileEntitiesFilters/components/ObjectsFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {CategoriesSetFilterMobile, ProblemStatusesFilterMobile} from '@src/components/MobileEntitiesFilters';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {SpacesStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/SpacesStatusesFilterMobile';
import {SpacesIndicatorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/SpacesIndicatorsFilterMobile';
import {SpaceTypesFilterMobile} from '@src/components/MobileEntitiesFilters/components/SpaceTypesFilterMobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {CheckListsFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListsFilterMobile';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {SpaceTypeDecorationFilterMobile} from '@src/components/MobileEntitiesFilters/components/SpaceTypeDecorationFilterMobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {CheckListStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListStatuses';
import {CheckListCategoryStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListCategoryStatuses';
import {CheckListCategoryFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListCategory';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListsAsArrayFilteredBySelectedSpaceType} from '@src/core/hooks/queries/checkLists/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';

const SpacesFiltersPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: targetObjects} = useTargetObjects(objectId);
	const {displayMode, schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<ISpacesFiltersState>();

	const {data: checkLists} = useExtractCheckListsAsArrayFilteredBySelectedSpaceType(objectId);

	const checkList =
		checkLists && checkLists.length === 1
			? checkLists[0].id
			: state?.checkListIds?.length === 1
			? state.checkListIds[0]
			: '';

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [canGoBack, apply]);

	useAppHeader(
		{
			title: t('spacesFiltersPage.useAppHeader.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<ISpacesFiltersState>(state) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId, mobileClear]
	);

	useRouting(!canGoBack, objectId, entity);

	return objectId && entity ? (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
			acceptBtnLabel={t('spacesFiltersPage.filterPage.acceptButtonLabel')}
		>
			{targetObjects && targetObjects.length > 1 && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
					pageObjectId={objectId}
					targetObjects={targetObjects}
				/>
			)}

			<SpaceTypesFilterMobile
				objectId={objectId}
				value={state?.types}
				entity={entity}
				onChange={change}
			/>

			{schemaView === SpacesSchemaVariants.PROBLEMS && objectId !== undefined && (
				<SpacesStatusesFilterMobile
					objectId={objectId}
					value={state?.statuses}
					entity={entity}
					onChange={change}
				/>
			)}

			{displayMode === SpacesDisplayMode.SCHEMA &&
				schemaView !== SpacesSchemaVariants.CHECK_LISTS && (
					<ProblemStatusesFilterMobile
						objectId={objectId}
						value={state?.problemStatuses}
						entity={entity}
						onChange={change}
						label={t('spacesFiltersPage.problemStatusesFilterMobile.label')}
						filterName="problemStatuses"
					/>
				)}

			<SpaceTypeDecorationFilterMobile
				objectId={objectId}
				value={state?.typeDecoration}
				entity={entity}
				onChange={change}
			/>

			{objectId !== undefined && (
				<SpacesIndicatorsFilterMobile
					objectId={objectId}
					value={state?.indicators}
					entity={entity}
					onChange={change}
				/>
			)}

			{/* TODO Убрать проверку после добавления стадии в помещение */}
			{checkLists && schemaView === SpacesSchemaVariants.CHECK_LISTS && (
				<CheckListsFilterMobile
					objectId={objectId}
					checkLists={checkLists}
					value={state?.checkListIds}
					entity={entity}
					onChange={change}
				/>
			)}

			{schemaView === SpacesSchemaVariants.CHECK_LISTS && (
				<CheckListStatusesFilterMobile
					objectId={objectId}
					value={state?.checkListStatuses}
					entity={entity}
					onChange={change}
				/>
			)}

			{schemaView === SpacesSchemaVariants.CHECK_LISTS && (
				<CheckListCategoryStatusesFilterMobile
					objectId={objectId}
					value={state?.checkListCategoryStatuses}
					entity={entity}
					onChange={change}
				/>
			)}

			{schemaView === SpacesSchemaVariants.CHECK_LISTS &&
				((checkLists && checkLists.length === 1) || state?.checkListIds?.length === 1) && (
					<CheckListCategoryFilterMobile
						objectId={objectId}
						value={state?.checkListCategory}
						entity={entity}
						checkList={checkList}
						onChange={change}
					/>
				)}

			{schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES &&
				<CategoriesSetFilterMobile
					objectsIds={state.objects ? state.objects : [objectId]}
					currentObject={objectId}
					stages={state.stages}
					value={state?.categories}
					entity={entity}
					onChange={change}
				/>
			}
		</TranslatedFilterPage>
	) : null;
};

export default SpacesFiltersPage;
