import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
	IEditWarrantyClaimRequest,
	IEditWarrantyClaimResponse,
	makeWarrantyClaimEditRequest
} from '@src/api/backend/warrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import IError from '@tehzor/tools/interfaces/IError';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';

export type IEditWarrantyClaimPayload = IEditWarrantyClaimResponse;

export const useEditWarrantyClaim = () => {
	const queryClient = useQueryClient();

	return useMutation<IEditWarrantyClaimPayload, IError, IEditWarrantyClaimRequest>({
		mutationKey: warrantyClaimsQueryKeys.edit(),
		mutationFn: async ({objectId, claimId, data}) =>
			makeWarrantyClaimEditRequest({
				objectId,
				claimId,
				data: {...data, newAttachments: await addTempFiles(data.newAttachments)}
			} as IEditWarrantyClaimRequest),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.lists()});
			void queryClient.invalidateQueries({
				queryKey: warrantyClaimsQueryKeys.details()
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.editWarrantyClaim.error.title')});
		}
	});
};
