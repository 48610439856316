import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {ISettingsPageStructureState} from '../interfaces';
import {StructureEntitiesTabs} from '@src/interfaces/StructureEntitiesTab';
import {StructureEntitiesVisibility} from '@src/interfaces/StructureEntitiesVisibility';

const initialState: ISettingsPageStructureState = {
	entitiesVisibility: [
		StructureEntitiesVisibility.INSPECTIONS,
		StructureEntitiesVisibility.PROBLEMS
	],
	entitiesTab: StructureEntitiesTabs.ALL
};

export const byPage = createReducer<ISettingsPageStructureState>(initialState, {
	[types.CHANGE_ENTITIES_VISIBILITY]: (
		state,
		{payload}: {payload: StructureEntitiesVisibility[]}
	) => {
		state.entitiesVisibility = payload;
	},
	[types.CHANGE_ENTITIES_TAB]: (state, {payload}: {payload: StructureEntitiesTabs}) => {
		state.entitiesTab = payload;
	}
});
