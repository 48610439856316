import {IGetPlansResponse} from '@src/api/backend/plans';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import IShape from '@tehzor/tools/interfaces/plans/IShape';

/**
 * Извлекает планы для конкретного объекта в виде массива
 */
export const extractPlansAsArray = (data: IGetPlansResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

/**
 * Извлекает планы для массива объектов в виде массива
 */

export const extractPlansByObjectIdsAsArray = (data: IGetPlansResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

/**
 * Извлекает план по id
 */
export const extractPlan = (data: IGetPlansResponse, planId?: string): IPlan | undefined =>
	data && planId ? data.byId[planId] : undefined;

/**
 * Извлекает планы по массиву id
 */

export const extractPlansByIds = (
	data: IGetPlansResponse,
	planIds?: string[]
): IPlan[] | undefined =>
	data && planIds && planIds.length ? planIds.map((id: string) => data.byId[id]) : [];

/**
 * Извлекает секторы плана
 */

export const extractShapes = (data: IGetPlansResponse, planId?: string): IShape[] | undefined => {
	const plan = extractPlan(data, planId);

	if (!plan || !plan?.layers) {
		return undefined;
	}
	const layers = Object.values(plan.layers);
	return layers.reduce((acc: IShape[], layer) => {
		if (layer.shapes) {
			acc.push(...layer.shapes);
		}
		return acc;
	}, []);
};

/**
 * Извлекает сектор плана по id
 */

export const extractShape = (
	data: IGetPlansResponse,
	planId?: string,
	shapeId?: string
): IShape | undefined => {
	const shapes = extractShapes(data, planId);
	return shapes?.find(shape => shape.id === shapeId);
};
