import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IGetSpacesResponse} from "@src/api/backend/spaces/get";

/**
 * Возвращает список помещений со статистикой по приемкам работ
 */
export const requestSpacesWithWorkAcceptanceStats = async (
	filters?: Record<string, unknown>,
	limit?: number,
) => {
	const params = {
		filters,
		limit,
	};
	const response = await httpRequests.withToken.post<IGetSpacesResponse>('spaces/get-work-acceptance-stats', params);
	return response.data;
};
