import {useCallback} from 'react';
import {useLocation, useMatch} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
    CategoriesSetFilterMobile,
    ProblemStatusesFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {CheckListsFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListsFilterMobile';
import {CheckListStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/CheckListStatuses';
import {
    WorkAcceptanceStatusesFilterMobile
} from '@src/components/MobileEntitiesFilters/components/WorkAcceptanceStatusesFilterMobile';
import {useExtractCheckListsAsArrayForWorkAcceptances} from '@src/core/hooks/queries/checkLists/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useEntitiesFiltersCtx} from '@/shared/lib/EntitiesFilters/hooks/useEntitiesFiltersCtx';
import {IStructuresFiltersState} from '@/entities/Structures';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';

export const StructuresFiltersPage = () => {
    const {t} = useTranslation();
    const {goBack} = useChangePath();
    const match = useMatch('/objects/:objectId/:entity/*');
    const objectId = match?.params.objectId;
    const entity = match?.params.entity;
    const {canGoBack} = useLocation().state as { canGoBack?: boolean };
    const {data: checkLists} = useExtractCheckListsAsArrayForWorkAcceptances(objectId);
    const {state, isStateChanged, change, mobileClear, apply} =
        useEntitiesFiltersCtx<IStructuresFiltersState>();
    const {data: targetObjects} = useTargetObjectIdsHaveStructures({objectId});

    const handleApply = useCallback(() => {
        if (canGoBack) {
            apply();
            goBack();
        }
    }, [canGoBack, apply, goBack]);

    useAppHeader(
        {
            title: t('filtersPage.title'),
            showBackBtn: true,
            mobileRightButtons: isCleanable<IStructuresFiltersState>(state) ? (
                <LinkButton
                    label={t('filterPage.resetFiltersBtn.label')}
                    onClick={mobileClear}
                />
            ) : null
        },
        [state, objectId, mobileClear]
    );

    useRouting(!canGoBack, objectId, entity);

    return targetObjects && entity ? (
        <TranslatedFilterPage
            applyDisabled={!isStateChanged}
            onApplyClick={handleApply}
        >
            <ProblemStatusesFilterMobile
                objectId={objectId}
                value={state?.problemStatuses}
                entity={entity}
                onChange={change}
                label={t('entitiesFilters.statusesFilter.problemStatuses.label')}
                filterName="problemStatuses"
            />

            {checkLists && (
                <CheckListsFilterMobile
                    objectId={objectId}
                    checkLists={checkLists}
                    value={state?.checkListIds}
                    entity={entity}
                    onChange={change}
                />
            )}

            {objectId && (
                <CheckListStatusesFilterMobile
                    objectId={objectId}
                    value={state?.checkListStatuses}
                    entity={entity}
                    onChange={change}
                />
            )}

            {objectId && (
                <CategoriesSetFilterMobile
                    objectsIds={objectId ? [objectId] : undefined}
                    currentObject={objectId}
                    value={state?.categories}
                    entity={entity}
                    onChange={change}
                />
            )}

            <WorkAcceptanceStatusesFilterMobile
                objectId={objectId}
                value={state?.workAcceptanceStatuses}
                entity={entity}
                onChange={change}
            />
        </TranslatedFilterPage>
    ) : null;
};
