import {useQuery} from '@tanstack/react-query';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {
	extractInternalAcceptanceProblemCommentsByProblem,
	extractInternalAcceptanceProblemRepliesAsArray
} from './selectors';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export type IGetInternalAcceptanceProblemRepliesPayload = INormalizedData<IComment>;

export const useInternalAcceptanceProblemsReplies = <
	T = IGetInternalAcceptanceProblemRepliesPayload
>(
	internalAcceptanceId?: string,
	objectId?: string,
	select?: (data: IGetInternalAcceptanceProblemRepliesPayload) => T
) =>
	useQuery({
		queryKey: internalAcceptancesQueryKeys.internalAcceptanceIdProblemsReplies(
			objectId as string,
			internalAcceptanceId as string
		),
		select,
		enabled: !!(internalAcceptanceId && objectId)
	});

export const useInternalAcceptanceProblemsRepliesAsArray = (
	internalAcceptanceId?: string,
	objectId?: string
) =>
	useInternalAcceptanceProblemsReplies(
		internalAcceptanceId,
		objectId,
		extractInternalAcceptanceProblemRepliesAsArray
	);

export const useInternalAcceptanceProblemCommentsByProblem = (
	internalAcceptanceId?: string,
	objectId?: string
) =>
	useInternalAcceptanceProblemsReplies(
		internalAcceptanceId,
		objectId,
		extractInternalAcceptanceProblemCommentsByProblem
	);
