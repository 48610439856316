import {IconButton, IconMenu, MenuItem, RadioButton} from '@tehzor/ui-components';
import styles from './FiltersPattern.module.less';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';
import classNames from 'classnames';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';

interface IFiltersPatternsProps {
	pattern: IFiltersPattern;
	active: boolean;
	onChange: (pattern: IFiltersPattern) => void;
	onDelete: (patternId: number) => void;
	onAccept: (pattern: IFiltersPattern) => void;
}

const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

export const FiltersPattern = ({
	pattern,
	active,
	onChange,
	onDelete,
	onAccept
}: IFiltersPatternsProps) => {
	const isDesktop = useIsDesktop();

	const acceptHandler = () => {
		onAccept(pattern);
	};

	const changeHandler = () => {
		onChange(pattern);
	};

	const deleteHandler = () => {
		onDelete(pattern.id);
	};

	return (
		<div className={styles.wrapper}>
			<RadioButton
				onClick={acceptHandler}
				checked={active}
			/>
			<p className={styles.title}>{pattern.name}</p>
			{isDesktop ? (
				<div className={styles.bar}>
					<IconButton
						type="transparent"
						onClick={changeHandler}
						className={styles.button}
					>
						<i className={classNames('tz-edit-20', styles.buttonIcon)} />
					</IconButton>
					<IconButton
						type="transparent"
						onClick={deleteHandler}
						className={styles.button}
					>
						<i className={classNames('tz-delete', styles.buttonIcon)} />
					</IconButton>
				</div>
			) : (
				<IconMenu>
					<MenuItem
						key="edit"
						icon={editIcon}
						onClick={changeHandler}
					>
						Редактировать
					</MenuItem>
					<MenuItem
						key="delete"
						icon={deleteIcon}
						onClick={deleteHandler}
					>
						Удалить
					</MenuItem>
				</IconMenu>
			)}
		</div>
	);
};
