import {memo, useMemo, useState} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {BaseListFilterMobile} from './BaseListFilterMobile';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useFilteredCompaniesContractorsAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface IContractorsFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	filterName?: string;

	onChange: (changes: IChanges) => void;
}

export const ContractorsFilterMobile = memo((props: IContractorsFilterMobileProps) => {
	const {t} = useTranslation();
	const {label, filterName, ...restProps} = props;

	const [search, setSearch] = useState('');
	const {data: contractors} = useFilteredCompaniesContractorsAsArray();
	const filteredData = useMemo(
		() => (contractors ? flatFilter(contractors, 'name', search) : []),
		[contractors, search]
	);

	return (
		<BaseListFilterMobile
			{...restProps}
			options={filteredData}
			label={label || t('entitiesFilters.contractorsFilterMobile.label')}
			filterName={filterName || 'contractorIds'}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		/>
	);
});
