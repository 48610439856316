import {IGetCategoriesSetsResponse} from '@src/api/backend/categoriesSets';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {IFullCategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/IFullCategoriesSet';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {handleSort} from './utils/handleSort';
import {handleFilter} from './utils/handleFilter';
import {handlePagination} from './utils/handlePagination';

export interface ICategoriesSetsFilters extends Record<string, unknown> {
	objects?: string[];
	createdBy?: string[];
	createdAtFrom?: Date;
	createdAtTo?: Date;
	stages?: string[];
}

export type ICategoriesSetsSort = Record<string, boolean>;

export interface ICategoriesSetsPageSettings {
	filters: ICategoriesSetsFilters;
	sort: ICategoriesSetsSort;
	pageSize: number;
	offset: number;
}

export const extractCategoriesSetById = (
	data: IGetCategoriesSetsResponse,
	categoriesSetId?: string
) => (categoriesSetId ? data.byId?.[categoriesSetId] : undefined);

export const extractFullCategoriesSetsData = (
	data: IGetCategoriesSetsResponse,
	allCategories?: Record<string, ICategory[]>
) => {
	const {allIds, byId} = data;
	const total = allIds.length;
	const fullById: {[id: string]: IFullCategoriesSet} = {};

	allIds?.forEach(id => {
		fullById[id] = {
			...byId[id],
			categories: (allCategories && allCategories[id]) ?? []
		};
	});

	return {allIds, byId: fullById, total};
};

export const extractFullCategoriesSetsAsArray = (
	data: IGetCategoriesSetsResponse,
	allCategories: Record<string, ICategory[]> | undefined
) => {
	const fullCategoriesSetsData = extractFullCategoriesSetsData(data, allCategories);
	return fullCategoriesSetsData.allIds?.map(id => ({...fullCategoriesSetsData.byId[id]}));
};

export const extractFullCategoriesSetsAsArrayByObjectId = (
	data: IGetCategoriesSetsResponse,
	objectId: string,
	allCategories?: Record<string, ICategory[]>
) => {
	const fullCategoriesSetsDataAsArray = extractFullCategoriesSetsAsArray(data, allCategories);
	return fullCategoriesSetsDataAsArray?.filter(
		set => set.objects.includes(objectId) || set.shared
	);
};

export const extractCategoriesSetsForPage = (
	data: IGetCategoriesSetsResponse,
	objectId: string,
	settings: ICategoriesSetsPageSettings,
	allCategories?: Record<string, ICategory[]>
) => {
	const {offset, pageSize, sort, filters} = settings;
	const fullCategoriesSetsDataAsArray = extractFullCategoriesSetsAsArray(data, allCategories);
	const scopeFilters: ICategoriesSetsFilters = {
		objects: objectId !== 'all' ? [objectId] : []
	};

	const filteredArr = handleSort(fullCategoriesSetsDataAsArray, sort)
		.filter(item => handleFilter(item, scopeFilters))
		.filter(item => handleFilter(item, filters));

	const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

	return {
		data: paginatedArr,
		pageCount: Math.ceil(filteredArr.length / pageSize),
		currentPage: Math.floor(offset / pageSize),
		total: filteredArr.length
	};
};

export const extractFilteredCategoriesSetsAsArray = (
	data: IGetCategoriesSetsResponse,
	filters: ICategoriesSetsFilters,
	allCategories?: Record<string, ICategory[]>
) => {
	const fullCategoriesSetsDataAsArray = extractFullCategoriesSetsAsArray(data, allCategories);
	return fullCategoriesSetsDataAsArray.filter(item => handleFilter(item, filters));
};

export const extractCategoriesSetsAsArray = (
	data: IGetCategoriesSetsResponse,
	objectId: string,
	stage?: ObjectStageIds,
	allCategories?: Record<string, ICategory[]>,
	objectsIds?: string[]
) => {
	const filters = {
		objects: objectId !== 'all' ? objectsIds ?? [objectId] : [],
		stages: stage ? [stage] : undefined
	};
	return extractFilteredCategoriesSetsAsArray(data, filters, allCategories);
};

export const extractCategoriesSetsByObjectsAndStages = (
	data: IGetCategoriesSetsResponse,
	stages?: string[],
	objectsIds?: string[]
) => {
	const filters = {
		objects: objectsIds,
		stages
	};
	return extractFilteredCategoriesSetsAsArray(data, filters);
};

export const extractCommonCategoriesSetsAsArray = (
	data: IGetCategoriesSetsResponse,
	objectIds?: string[]
) => {
	if (!objectIds || objectIds.length === 0) {
		return [];
	}
	const filters = {
		objects: objectIds
	};
	const sets = extractFilteredCategoriesSetsAsArray(data, filters);
	const result = sets.filter(set => objectIds.every(objectId => set.objects.includes(objectId)));
	return result;
};
