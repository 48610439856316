import {useMutation} from '@tanstack/react-query';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {
	IAddMultipleInternalAcceptancesResponse,
	makeInternalAcceptancesAddRequest
} from '@src/api/backend/internalAcceptances/add';

export interface IAddMultipleInternalAcceptancesWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: IInternalAcceptance['links'];
	savingData: ISavingProblem | ISavingInspection;
	acceptanceType: IInternalAcceptanceAddingEntityType;
}

/**
 * Хук для добавления нескольких предпродажных проверок в рамках различных сущностей
 */
export const useAddMultipleInternalAcceptancesWithEntities = () =>
	useMutation<
		IAddMultipleInternalAcceptancesResponse,
		Error,
		IAddMultipleInternalAcceptancesWithEntitiesParams
	>({
		mutationFn: async ({objectId, spaceIds, links, acceptanceType, savingData}) =>
			makeInternalAcceptancesAddRequest(
				objectId,
				spaceIds,
				links,
				acceptanceType,
				savingData
			),
		onError: error => {
			console.error('Ошибка при создании предпродажных проверок:', error);
		}
	});
