import {IGetSpacesTypesCountResponse} from '@src/api/backend/spaces/spacesTypesCount';
import {ISpaceType, SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export const extractTypesAsArray = (spaceTypes?: INormalizedData<ISpaceType>) => {
	if (!spaceTypes) return undefined;
	return spaceTypes.allIds.map(id => spaceTypes.byId[id]);
};

export const extractTypesDataByObjects = (
	data: IGetSpacesTypesCountResponse,
	objectIds?: string[]
) => objectIds?.map(id => data.byId[id]).filter(id => !!id);

export const extractFilteredSpacesTypes = (
	data: IGetSpacesTypesCountResponse,
	spaceTypes?: ISpaceType[],
	objectIds?: string[]
) => {
	const typesData = extractTypesDataByObjects(data, objectIds);
	const countByType = typesData?.reduce(
		(acc, item) => {
			for (const type of Object.keys(item.typesData) as SpaceTypeId[]) {
				if (!acc[type]) acc[type] = 0;
				acc[type] += item.typesData[type]?.count || 0;
			}
			return acc;
		},
		{} as {[key in SpaceTypeId]: number}
	);
	const spaceTypesFiltered = countByType
		? spaceTypes?.filter(type => Object.keys(countByType).includes(type.id))
		: [];

	const types: Array<[ISpaceType, {spaces: number; filtred: number}]> | undefined =
		spaceTypesFiltered?.map(type => [
			type,
			{
				spaces: countByType ? countByType[type.id] : 0,
				filtred: countByType ? countByType[type.id] : 0
			}
		]);
	return types;
};

export const extractObjectsSpacesFilteredByType = (
	data: IGetSpacesTypesCountResponse,
	objectIds?: string[],
	type?: string
) => {
	const typesCountData = extractTypesDataByObjects(data, objectIds);
	if (!typesCountData || !type) return objectIds;
	return typesCountData.filter(item => !!item.typesData[type as SpaceTypeId]).map(i => i.id);
};

export const extractSpacesSchemaTypes = (
	data: IGetSpacesTypesCountResponse,
	spaceTypes?: ISpaceType[],
	objectIds?: string[]
) => {
	const typesData = extractTypesDataByObjects(data, objectIds);

	const enrichedCounts = typesData
		? spaceTypes
			? spaceTypes.filter(st => !!typesData.find(data => data.typesData[st.id]))
					.map(type => {
						const countByTypes = typesData.reduce<number>((acc, item) => {
							if (item.typesData[type.id]) {
								return acc + (item.typesData[type.id]?.count || 0);
							}
							return acc;
						}, 0);
						return [type, countByTypes];
					})
					.filter(([, count]) => count)
			: []
		: [];
	return enrichedCounts as [[ISpaceType, number]];
};
