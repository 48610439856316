import {IGetObjectSettingsResponse, requestObjectSettings} from '@src/api/backend/objectSettings';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useFetchObjectSettings = <T = IGetObjectSettingsResponse>(
	objectId: string,
	select?: (data: IGetObjectSettingsResponse) => T
) =>
	useQuery<IGetObjectSettingsResponse, IError, T>({
		queryKey: ['object-settings', objectId],
		queryFn: () => requestObjectSettings(objectId),
		enabled: !!objectId,
		meta: {
			error: 'Ошибка в получении настроек объекта'
		},
		select
	});
