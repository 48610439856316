import {useMemo} from 'react';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {IAddWarrantyClaimForm} from '../useEditableWarrantyClaimForm/useEditableWarrantyClaimForm';

export const useDefaultWarrantyClaimData = (
	defaultData?: ISavingWarrantyClaim,
	warrantyClaim?: Omit<
		IWarrantyClaim,
		'createdBy' | 'modifiedBy' | 'initialGroupLeader' | 'activeGroupLeader'
	>
): IAddWarrantyClaimForm | undefined => {
	return useMemo(
		() => ({
			spaceOwnerId: warrantyClaim?.spaceOwner?.id || defaultData?.spaceOwnerId || undefined,
			claimerName: warrantyClaim?.claimerName || defaultData?.claimerName || undefined,
			replyEmail: warrantyClaim?.replyEmail || defaultData?.replyEmail || undefined,
			replyPhone: warrantyClaim?.replyPhone || defaultData?.replyPhone || undefined,
			registrationDate:
				warrantyClaim?.registrationDate || defaultData?.registrationDate || undefined,
			attachments: warrantyClaim?.attachments || defaultData?.attachments || [],
			customFields: warrantyClaim?.customFields || defaultData?.customFields || {},
			templateId: undefined
		}),
		[defaultData, warrantyClaim]
	);
};
