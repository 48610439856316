import {ResponsiblesFilter} from '@src/components/EntitiesFilters/components/ResponsiblesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useSubmittersGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {memo, useCallback} from 'react';

interface ISubmittersFilterProps {
	objectId: string;
	submitters?: string[];
	label?: string;
}

export const SubmittersFilter = memo(({objectId, submitters, label}: ISubmittersFilterProps) => {
	const {data: workingGroups} = useSubmittersGroupsAsArray(objectId);

	const {dispatch} = useEntitiesFiltersCtx();

	const handleChange = useCallback(
		(value?: string[]) => {
			dispatch({submitters: value});
		},
		[dispatch]
	);

	return (
		<ResponsiblesFilter
			respUsers={submitters}
			workingGroups={workingGroups}
			onChange={handleChange}
			label={label}
		/>
	);
});
