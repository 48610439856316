import {useCallback} from 'react';
import classNames from 'classnames';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {WorkAcceptancesDiagram} from '../WorkAcceptanceDiagram/WorkAcceptancesDiagram';

interface ISpaceDiagramProps {
	space: IConvertedSpace;
	name: string;
	disabled?: boolean;
	onClick?: (space: IConvertedSpace) => void;
}

const getTitle = (name: string, isTitleInline: boolean) => (
	<text
		className={classNames({
			'work-acceptances-space__number': isTitleInline,
			'work-acceptances-space__number-outside': !isTitleInline
		})}
		x="50%"
		y="50%"
		dominantBaseline="middle"
		textAnchor="middle"
	>
		{name}
	</text>
);

export const SpaceDiagram = ({space, name, onClick, disabled}: ISpaceDiagramProps) => {
	const {workAcceptancesForSchema} = space;

	const isTitleInline = name.length < 6;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [onClick, space]);

	return (
		<div
			className='work-acceptances-space__status-wrap'
		>
			<WorkAcceptancesDiagram
				workAcceptances={workAcceptancesForSchema}
				disabled={disabled}
				onClick={handleClick}
			>
				{isTitleInline ? getTitle(name, isTitleInline) : <></>}
			</WorkAcceptancesDiagram>
			{!isTitleInline && getTitle(name, isTitleInline)}
		</div>
	);
};
