import {useMemo, Suspense, useDeferredValue, useCallback} from 'react';
import {Button, Checkbox, HoverPopup, IconButton, LoadingPanel} from '@tehzor/ui-components';
import {ToolBarDialog} from '@tehzor/ui-components/src/components/dialogs/ToolBarDialog/ToolBarDialog';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractSelectedSpacesForObjectIds,
	extractSpacesPageSettings
} from '@src/store/modules/settings/pages/spaces/selectors';
import {useDispatch} from 'react-redux';
import {selectAllVisibleSpaces} from '@src/store/modules/settings/pages/spaces/actions';
import {useSpacesStageSelectionDialog} from '../SpacesStageSelectionDialog/useSpacesStageSelectionDialog';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertWorkAcceptancesData} from '../../utils/convertWorkAcceptances';
import {filterCheckLists, filterSpace} from '../../utils/filterSpaces';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {groupFilteredSpacesByObjectId} from '../../utils/groupFilteredSpacesByObjectId';
import {SelectionMessage} from './SelectionMessage';
import {useMultipleEntitiesAddingPermissions} from '@src/core/hooks/permissions/useMultipleEntitiesAddingPermissions';
import {useObjectSettings} from '@src/core/hooks/settings/useObjectSettings';

interface SpaceChoosingToolbarDesktopProps {
	isToolbarVisible: boolean;
	totalSelectedCount: number;
	totalSelectedByCurrentType: number;
	onClose: () => void;
	targetObjects: string[];
	allSelectableSpacesByObjectsAndTypes: Record<string, IEnrichedSpace[]>;
}

export const SpaceChoosingToolbarDesktop = ({
	isToolbarVisible,
	totalSelectedCount,
	totalSelectedByCurrentType,
	onClose,
	targetObjects,
	allSelectableSpacesByObjectsAndTypes
}: SpaceChoosingToolbarDesktopProps) => {
	const dispatch = useDispatch();

	const {objectId} = useStrictParams<{objectId: string}>();
	const {restrictedAddProblemsAfterWarrantyExpiration} = useObjectSettings(objectId);

	const pageSetting = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const pageSettings = useDeferredValue(pageSetting);

	const {selectedSpaces, nonEmptySelectedCount, singleObjectId, typeCounts} = useAppSelector(
		state => extractSelectedSpacesForObjectIds(state, objectId, targetObjects || [])
	);

	const {data: object} = useObject(objectId);
	const {data: problemStatuses} = useProblemStatuses();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
	const {data: checkListsData} = useCheckLists();
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();

	const {canAddMultipleEntities} = useMultipleEntitiesAddingPermissions();
	const translatedCheckRecordStatuses = useTranslatedDictionary(
		'checkRecordStatus',
		checkRecordStatuses
	);

	const {spaceStageSelectionDialog, openStageSelectionDialog, globalDialog, openGlobalDialog} =
		useSpacesStageSelectionDialog({
			objectId: selectedSpaces.length >= 1 ? singleObjectId || objectId : objectId
		});

	const allSpaceIdsForVisibleObjects = Object.values(allSelectableSpacesByObjectsAndTypes).flat();

	const filteredSpaces = useMemo(
		() =>
			allSpaceIdsForVisibleObjects?.filter(
				(space: IEnrichedSpace) => space.type === pageSettings.type
			),
		[pageSettings.type, allSpaceIdsForVisibleObjects]
	);

	const convertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			filteredSpaces?.map(space => {
				const convertedSpace: IConvertedSpace = {
					...space,
					problems: undefined,
					checkLists: undefined,
					workAcceptances: undefined,
					sortBase: space.name
				};

				convertedSpace.problems = convertProblemsData(
					space.problems,
					problemStatuses,
					pageSettings.stage,
					pageSettings.processId
				);

				convertedSpace.workAcceptances = convertWorkAcceptancesData(
					space.workAcceptances,
					workAcceptanceStatuses
				);

				const filteredCheckLists = checkListsData
					? filterCheckLists(
							checkListsData,
							object?.companyId || '',
							space.objectId,
							space.type,
							pageSettings.stage,
							pageSettings.processId,
							pageSettings.filters.checkListIds,
							space.typeDecoration
					  )
					: [];
				convertedSpace.checkLists = convertCheckListsData(
					filteredCheckLists,
					checkItemsByLists,
					translatedCheckRecordStatuses,
					pageSettings.filters.checkListCategory,
					space.checkLists
				);

				return convertedSpace;
			}),
		[
			checkItemsByLists,
			checkListsData,
			translatedCheckRecordStatuses,
			workAcceptanceStatuses,
			filteredSpaces,
			object?.companyId,
			pageSettings.filters.checkListIds,
			pageSettings.filters.checkListCategory,
			pageSettings.stage,
			pageSettings.processId,
			problemStatuses
		]
	);

	const filteredConvertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			convertedSpaces?.map(space => {
				const filtered = filterSpace({
					space,
					spaceIndicators: space.indicators as unknown as string[],
					spaceStatus: undefined,
					spaceProblems: space.problems?.map(el => el.key) || [],
					filters: pageSettings.filters,
					checkLists: checkListsData,
					schemaView: undefined,
					spaceWorkAcceptances:
						space.workAcceptancesForSchema?.items?.map(el => el.status) || [],
					workAcceptanceProblems:
						space.workAcceptancesForSchema?.problems?.map(el => el.key) || [],
					workAcceptanceCategories:
						space.workAcceptancesForSchema?.items?.map(el => el.categoryId) || []
				});
				return {
					...space,
					filtered
				};
			}),
		[convertedSpaces, pageSettings.filters, checkListsData]
	);

	// Количество помещений по видимым объектам и типу
	const totalSpacesCountWithCurrentType = useMemo(
		() => Object.values(allSelectableSpacesByObjectsAndTypes || {}).flat().length,
		[allSelectableSpacesByObjectsAndTypes]
	);

	// Группировка отфильтрованных помещений по объектам
	const filteredSpacesForChoosingAll = useMemo(
		() => groupFilteredSpacesByObjectId(filteredConvertedSpaces || []),
		[filteredConvertedSpaces]
	);

	// Действие по нажатию на чекбокс (выбрать всё)
	const handleSelectAll = useCallback(() => {
		dispatch(selectAllVisibleSpaces(objectId, filteredSpacesForChoosingAll));
	}, [dispatch, filteredSpacesForChoosingAll, objectId]);

	// Открытие окна выбора стадии (при множественном добавлении) или GlobalAddingEntity - при единичном
	const handleOpenStageSelection = useCallback(() => {
		if (selectedSpaces.length > 1) {
			openStageSelectionDialog(true);
		} else if (selectedSpaces.length === 1) {
			openGlobalDialog();
		}
	}, [selectedSpaces.length, openStageSelectionDialog, openGlobalDialog]);

	// Полностью выбранный / частично выбранный чекбокс
	const [isChecked, isSemiChecked] = useMemo(() => {
		const allSelected =
			totalSelectedByCurrentType <= totalSpacesCountWithCurrentType &&
			totalSelectedByCurrentType > 0;
		const partiallySelected =
			totalSelectedByCurrentType > 0 &&
			totalSelectedByCurrentType < totalSpacesCountWithCurrentType;
		return [allSelected, partiallySelected];
	}, [totalSelectedByCurrentType, totalSpacesCountWithCurrentType]);

	if (!isToolbarVisible) return null;

	return (
		<>
			<ToolBarDialog
				isOpen={isToolbarVisible}
				className="spaces-page__toolbar-dialog"
				onRequestClose={onClose}
			>
				<div className="spaces-page__toolbar">
					<div className="spaces-page__toolbar-count">
						<Checkbox
							checked={isChecked || isSemiChecked}
							semiChecked={isSemiChecked}
							onChange={handleSelectAll}
						/>
						<span>Выбрано: {totalSelectedCount}</span>
					</div>
					<div className="spaces-page__toolbar-btns">
					{canAddMultipleEntities && (
							<div>
								{nonEmptySelectedCount !== 1 ||
								typeCounts !== 1 ||
								restrictedAddProblemsAfterWarrantyExpiration ? (
									<HoverPopup
										className={{
											root: 'spaces-page__toolbar-btns--hover-popup-root',
											content:
												'spaces-page__toolbar-btns--hover-popup-content'
										}}
										placement="top-end"
										trigger={({ref}, hoverProps) => (
											<div
												ref={ref}
												{...hoverProps}
											>
												<Button
													type="accent-blue"
													label="Добавить"
													disabled
													leftIcon={<i className="tz-plus-20" />}
												/>
											</div>
										)}
										arrowVisible
									>
										<SelectionMessage
											totalSelectedByCurrentType={totalSelectedByCurrentType}
											nonEmptySelectedCount={nonEmptySelectedCount}
											typeCounts={typeCounts}
											restrictedAddProblemsAfterWarrantyExpiration={
												restrictedAddProblemsAfterWarrantyExpiration
											}
										/>
									</HoverPopup>
								) : (
									<Button
										type="accent-blue"
										label="Добавить"
										leftIcon={<i className="tz-plus-20" />}
										onClick={handleOpenStageSelection}
									/>
								)}
							</div>
						)}
						<IconButton
							type="inline-red-accent"
							onClick={onClose}
						>
							<i className="tz-close-20" />
						</IconButton>
					</div>
				</div>
			</ToolBarDialog>
			<Suspense fallback={<LoadingPanel />}>
				{singleObjectId && selectedSpaces.length > 1 && spaceStageSelectionDialog}
				{singleObjectId && selectedSpaces.length === 1 && globalDialog}
			</Suspense>
		</>
	);
};
