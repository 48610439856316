import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage} from '@src/store/modules/settings/pages/ownerAcceptances/slice';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractOwnerAcceptancesPageSettings = createSelector(
	(state: IState) => state.settings.pages.ownerAcceptancesPageSettings,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);
