import {memo} from 'react';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {MobileInfo} from '@src/pages/SpacePage/components/info/Info.mobile';
import {AdditionalInfo} from '@src/pages/SpacePage/components/info/AdditionalInfo';
import {MetersBlock} from './MetersBlock';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import type {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';

interface IMobileInfoBlockProps {
	space: ISpace;
	objectId: string;
	hasUnfixedProblems: boolean;
	hasNotCheckedCheckLists: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
	settings?: IEntitySettings;
}

export const MobileInfoBlock = memo((props: IMobileInfoBlockProps) => {
	const {
		objectId,
		space,
		hasUnfixedProblems,
		hasNotCheckedCheckLists,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem,
		settings
	} = props;
	const showAdditionalInfo =
		space.areaBTI ||
		space.floorBTI ||
		space.numberBTI ||
		space.contractForm ||
		space.markupForRegistration ||
		space.customFields;
	const permissions = useSpacesPermissions(objectId);

	return (
		<>
			<MobileInfo
				objectId={objectId}
				space={space}
				hasUnfixedProblems={hasUnfixedProblems}
				hasNotCheckedCheckLists={hasNotCheckedCheckLists}
				hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
				hasUnacceptedCheckItem={hasUnacceptedCheckItem}
			/>
			{permissions.canMetersView && (
				<MetersBlock
					objectId={objectId}
					spaceId={space.id}
				/>
			)}

			{showAdditionalInfo && (
				<AdditionalInfo
					space={space}
					settings={settings}
				/>
			)}
		</>
	);
});
