import {Dispatch, ReactNode, RefObject, SetStateAction} from 'react';
import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {EntityTypeSelect} from '../components/EntityTypeSelect';
import {useSpaceLocation} from './useSpaceLocation';
import {useLatestChecks} from './useLatestChecks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useExtractLatestChecksAsArray} from '@src/core/hooks/queries/checks/latest/hooks';
import {useTranslation} from 'react-i18next';
import {useCheck} from '@src/core/hooks/queries/check/hook';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IUseCheckMapsProps {
	objectId: string;
	checkId?: string;
	stage: ObjectStageIds;
	processId: ProcessIds;
	links?: ICheck['links'];
	availableTypes: ICheckAddingEntityType[];
	type?: ICheckAddingEntityType;
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	problemToCopyId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedCheckId?: string;
	setSelectedCheckId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;

	onClose: () => void;
}

export const useCheckMaps = (
	props: IUseCheckMapsProps
): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {
		objectId,
		checkId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedCheckId,
		setSelectedCheckId,
		onClose
	} = props;
	const {t} = useTranslation();
	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	const [defaultProblemData, defaultInspectionData, isLoading] = useSpaceLocation(
		objectId,
		selectedCheckId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	const {data: latestChecks} = useExtractLatestChecksAsArray(objectId, links?.spaceId);
	useLatestChecks(objectId, setSelectedCheckId, selectedCheckId, links);

	contentMap[stage] = {} as Record<ProcessIds, ReactNode>;
	contentMap[stage][processId] = (
		<>
			<div className="adding-check-dialog__title">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					isLoading={isLoading}
					stage={stage}
					processId={processId}
					defaultData={problemToCopyId ? props.defaultProblemData : defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					problemToCopyId={problemToCopyId}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					isLoading={isLoading}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const {data: check} = useCheck(checkId, objectId);

	entitiesSelectMapProps[stage] = {} as Record<ProcessIds, IEntitySelectProps>;
	entitiesSelectMapProps[stage][processId] = {
		name: t('addingCheckDialog.useCheckMaps.building.name'),
		newName: t('addingCheckDialog.useCheckMaps.building.newName'),
		selected: selectedCheckId,
		onEntityChange: setSelectedCheckId,
		withoutNew: !!check,
		entities: check ? [check] : latestChecks,
		localEntities: []
	};

	return [contentMap, entitiesSelectMapProps];
};
