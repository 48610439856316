import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import {queryClient} from '@src/api/QueryClient';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';
import {workAcceptancesTasksDataQueryKeys} from '@src/api/cache/workAcceptancesTasksData/keys';

export const useAddWorkAcceptanceTaskDialog = (workAcceptance: IWorkAcceptance) => {
	const {id, objectId, plannedAcceptanceDate, acceptors, acceptorsActiveGroup} = workAcceptance;

	const onSuccess = () => {
		const params: IWorkAcceptancesStatsDataRequest = {
			workAcceptances: [{objectId, workAcceptanceId: id}]
		};
		void queryClient.refetchQueries({
			queryKey: workAcceptancesTasksDataQueryKeys.data(params)
		});
		void queryClient.refetchQueries({queryKey: tasksQueryKeys.lists()});
	};

	const isTaskExpired = plannedAcceptanceDate && plannedAcceptanceDate < Date.now();

	const taskDialogArgs = {
		objectId,
		taskType: TaskTypeId.ACCEPTANCE_CALL,
		links: {workAcceptanceId: id},
		startTime: !isTaskExpired && plannedAcceptanceDate ? Date.now().toString() : undefined,
		endTime: !isTaskExpired && plannedAcceptanceDate ? plannedAcceptanceDate.toString() : undefined,
		activeGroup: acceptorsActiveGroup,
		respUsers: acceptors,
		onSuccess
	};

	return useAddingTaskDialog(taskDialogArgs);
};
