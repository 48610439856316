import {Column} from 'react-table';
import {IUploadError} from './useAnalysis';

export const uploadErrorsColumns: Array<Column<IUploadError>> = [
	{
		id: 'data',
		accessor: 'data',
		Header: 'Результат анализа:',
		width: 110,
		minWidth: 80
	}
];
