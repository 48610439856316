import {memo, useMemo} from 'react';
import './CheckListPage.less';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from '@src/pages/CheckListPage/components/Desktop';
import {Mobile} from '@src/pages/CheckListPage/components/Mobile';
import {useAsync, useUnmount} from 'react-use';
import {RESET} from '@src/store/modules/pages/checkLists/constants/page';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	getCheckRecords,
	getProblems,
	getInspections
} from '@src/store/modules/pages/checkLists/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {onlineManager, useIsFetching} from '@tanstack/react-query';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckItemsIsLoading} from '@src/core/hooks/queries/checkItems/hooks';
import {useWorkAcceptances} from '@src/core/hooks/queries/workAcceptances';
import {useSpaceIsLoading} from '@src/core/hooks/queries/space';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {useTasksByLinks} from '@src/core/hooks/queries/tasks/hooks';

export const CheckListPage = memo(() => {
	const {objectId, spaceId, stage} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		stage: ObjectStageIds | 'all';
	}>();
	const externalLoading = useSpaceIsLoading();
	const isDesktop = useIsDesktop();
	const dispatch = useAppDispatch();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const {data: workAcceptances} = useWorkAcceptances({
		objectId,
		argsFilters: {
			spaceIds: [spaceId],
			objects: [objectId]
		}
	});

	const checkLists = useAvailableCheckLists(objectId, spaceId, stage);
	const checkListsIds = useMemo(() => checkLists.map(cl => cl.id), [checkLists]);

	const {isLoading: loadingCheckListTasks} = useTasksByLinks(
		{
			checkListIds: checkListsIds
		},
		{
			spaceId
		}
	);

	const {loading: entitiesLoading} = useAsync(async () => {
		// Получение нарушений 	и записей только если загружены и есть чек-листы
		if (checkListsIds.length && onlineManager.isOnline()) {
			await Promise.allSettled([
				dispatch(getProblems(checkListsIds, spaceId)),
				dispatch(getInspections(checkListsIds, spaceId))
			]);
		}
	}, [spaceId, checkListsIds]);

	useAsync(async () => {
		if (spaceId && checkLists.length && (onlineManager.isOnline() || isOfflineModeAvailable)) {
			await dispatch(
				getCheckRecords(undefined, [objectId], spaceId, workAcceptances?.allIds)
			);
		}
	}, [objectId, spaceId, workAcceptances, checkLists, isOfflineModeAvailable]);

	useUnmount(() => {
		dispatch({type: RESET});
	});
	const checkListsIsLoading = useCheckListsIsLoading();
	const checkItemsLoading = useCheckItemsIsLoading();
	const checkRecordsLoading = !!useIsFetching({queryKey: checkRecordsQueryKeys.list()});
	const loading =
		externalLoading ||
		checkListsIsLoading ||
		checkItemsLoading ||
		entitiesLoading ||
		checkRecordsLoading ||
		loadingCheckListTasks;

	if (isDesktop) {
		return <Desktop loading={loading} />;
	}
	return <Mobile loading={loading} />;
});

CheckListPage.displayName = 'CheckListPage';
