import {useMemo} from 'react';
import CellWrap from '@src/components/tableCells/CellWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {Column} from 'react-table';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import {useInternalAcceptanceProblemCommentsByProblem} from '@src/core/hooks/queries/internalAcceptance/problemsReplies/hooks';

interface IUseDesktopColumnsProps {
	objectId: string;
	internalAcceptanceId: string;
}

export const useMobileColumns = ({objectId, internalAcceptanceId}: IUseDesktopColumnsProps) => {
	const {data: internalAcceptanceProblemCommentsByProblem} =
		useInternalAcceptanceProblemCommentsByProblem(objectId, internalAcceptanceId);

	return useMemo<Array<Column<IInternalAcceptanceEntity>>>(
		() => [
			{
				id: 'info',
				Cell: CellWrap({
					problem: ProblemInfoCellWrap(
						ProblemInfoMobileCell,
						undefined,
						internalAcceptanceProblemCommentsByProblem
					),
					inspection: InspectionInfoMobileCell
				})
			}
		],
		[internalAcceptanceProblemCommentsByProblem]
	);
};
