import {memo} from 'react';
import {CircleLoader, EntityGrid, EntityGridItem, Plate} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {Status} from './Status';
import {Area} from './Area';
import {SpaceOwners} from './SpaceOwners';
import {MobileDocuments} from './Documents.mobile';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';
import {AdditionalName} from './AdditionalName';
import {TypeDecor} from './TypeDecor';
import {useEditableSpaceAttachmentsDialog} from '@src/components/EditableSpaceAttachmentsDialog/hooks/useEditableSpaceAttachmentsDialog';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {SpaceTasksLinksMobile} from '@src/pages/SpacePage/components/info/SpaceTasksLinks.mobile';
import {useSpaceTasksAsArray} from '@src/core/hooks/queries/space';
import {useIsMutating} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from 'react-i18next';
import {IndicatorsSelect} from './IndicatorsSelect/IndicatorsSelect';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {IndicatorsSets} from './IndicatorsSets/IndicatorsSets';
import {useUnitIndicatorsSetsPermissions} from '@src/core/hooks/permissions/useUnitIndicatorsSetsPermissions';
import {useSpaceStatusById} from '@src/core/hooks/queries/spaceStatuses/hooks';

interface IMobileInfoProps {
	objectId: string;
	space: ISpace;
	hasUnfixedProblems: boolean;
	hasNotCheckedCheckLists: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

const statusIcon = <i className="tz-status-20" />;
const indicatorsIcon = <i className="tz-indicator-20" />;
const areaIcon = <i className="tz-area-20" />;
const spaceTypeIcon = <i className="tz-space-type-20" />;

export const MobileInfo = memo(
	({
		objectId,
		space,
		hasUnfixedProblems,
		hasNotCheckedCheckLists,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem
	}: IMobileInfoProps) => {
		const {t} = useTranslation();

		const [editingAttachmentsDialog, openEditAttachmentsDialog] =
			useEditableSpaceAttachmentsDialog(objectId, space.id, space);
		const permissions = useSpacesPermissions(objectId);
		const indicatorsPermissions = useUnitIndicatorsSetsPermissions();
		const {data: tasks} = useSpaceTasksAsArray(space.id);

		const {data: status} = useSpaceStatusById(space.status);
		const {data: indicatorsSets} = useActualUnitIndicatorsSetsExtended(
			objectId,
			undefined,
			false
		);

		const inProgress = !!useIsMutating({
			mutationKey: spacesQueryKeys.indicators.edit(),
			predicate: mutation => mutation.state.isPaused !== true
		});

		return (
			<Plate>
				<EntityGrid withBorders>
					{space.altName ? <AdditionalName space={space} /> : null}

					<EntityGridItem
						icon={statusIcon}
						label={t('spacePage.info.status')}
						inline
						fullRow
					>
						<Status
							objectId={objectId}
							spaceId={space.id}
							value={space.status}
							permissions={permissions}
							hasUnfixedProblems={hasUnfixedProblems}
							hasNotCheckedCheckLists={hasNotCheckedCheckLists}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
						/>
					</EntityGridItem>
					{indicatorsPermissions.canView && (
						<EntityGridItem
							icon={indicatorsIcon}
							className={{content: 'space-page__entity-grid-content-sets'}}
							label={t('spacePage.info.indicators')}
							buttons={
								indicatorsPermissions.canEdit &&
								(!inProgress ? (
									<IndicatorsSelect
										objectId={objectId}
										spaceId={space.id}
										stage={status?.stage}
										value={space.indicators}
									/>
								) : inProgress ? (
									<CircleLoader />
								) : null)
							}
							fullRow
						>
							{space.indicators !== undefined && indicatorsSets && (
								<IndicatorsSets
									indicatorsSets={indicatorsSets}
									value={space.indicators}
									mobile
								/>
							)}
						</EntityGridItem>
					)}
				</EntityGrid>
				<ExpandableMobileInfo>
					<EntityGrid withBorders>
						{space.plannedArea || space.actualArea ? (
							<EntityGridItem
								label={t('spacePage.info.plannedArea')}
								fullRow
								icon={areaIcon}
							>
								<Area
									planned={space.plannedArea}
									actual={space.actualArea}
								/>
							</EntityGridItem>
						) : null}

						{space.typeDecoration ? (
							<EntityGridItem
								label={t('spacePage.info.typeDecoration')}
								fullRow
								icon={spaceTypeIcon}
							>
								<TypeDecor name={space.typeDecoration} />
							</EntityGridItem>
						) : null}

						<SpaceOwners
							objectId={objectId}
							spaceId={space.id}
						/>

						<MobileDocuments
							space={space}
							openDialog={openEditAttachmentsDialog}
							canEdit={permissions.canEdit}
						/>
						{tasks && tasks.length > 0 && <SpaceTasksLinksMobile tasks={tasks} />}
					</EntityGrid>
				</ExpandableMobileInfo>
				{permissions.canEdit && editingAttachmentsDialog}
			</Plate>
		);
	}
);
