import {IGetChecksResponse} from '@src/api/backend/checks';
import {useQuery} from '@tanstack/react-query';
import {structuresQueryKeys} from '../../../../../Structures/model/cache/keys/index';

export const useStructureChecks = <T = IGetChecksResponse>(
	objectId: string,
	structureId: string,
	select?: (data: IGetChecksResponse) => T
) =>
	useQuery({
		queryKey: structuresQueryKeys.checksById(objectId, structureId),
		meta: {
			error: 'при загрузке проверок'
		},
		select
	});
