import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {MobileFilter} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import debounce from 'lodash/debounce';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useFilteredProblemTagsSets} from '@src/core/hooks/queries/problemTagsSets/hooks';
import {useNoOptionsState} from '@src/core/hooks/filters/useNoOptionsState';

interface IProblemTagsSetFilterMobileProps {
	objectsIds?: string[];
	stages?: string[];
	currentObjectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
	isCleared: boolean;
	setIsCleared: (v: boolean) => void;
}

/**
 * Фильтр по меткам для мобильных устройств
 */
export const ProblemTagsSetFilterMobile = memo(
	({
		objectsIds,
		stages,
		currentObjectId,
		value,
		entity,
		onChange,
		label,
		isCleared,
		setIsCleared
	}: IProblemTagsSetFilterMobileProps) => {
		const [selected, setSelected] = useState<string[] | undefined>([]);
		const [search, setSearchWrapper] = useState('');
		const {t} = useTranslation();
		const setSearch = useMemo(() => debounce(setSearchWrapper, 300), []);

		const {data: tagSets} = useFilteredProblemTagsSets(objectsIds, stages);
		const {data: allTags} = useExtractProblemTagsAsArray();

		const {
			setSelectedOptions: setSelectedTags,
			noOptions: noTags,
			setNoOptions: setNoTags,
			toggleNoOptions: toggleNoTags
		} = useNoOptionsState(value);

		useEffect(() => {
			if (isCleared) {
				setSelectedTags(undefined);
				setNoTags(false);
			}
		}, [isCleared]);
		/**
		 * Особый режим отображения:
		 * — Если доступно несколько наборов меток, показываем только названия наборов.
		 * При выборе набора выбираются сразу все метки из этого набора.
		 * — Если активирован поиск, переключаемся в обычный режим и смешиваем все найденные метки из разных наборов.
		 */
		const isMultiTagSetMode = tagSets ? tagSets?.length > 1 && !search : false;

		const elements = useMemo(() => {
			if (!tagSets?.length) {
				return [];
			}

			if (isMultiTagSetMode) {
				return tagSets.map(set => ({id: set.id, name: set.name}));
			}

			const tagSetsList = tagSets.map(item => item.id);
			return allTags
				?.filter(item => tagSetsList.includes(item.problemTagsSetId))
				.map(item => ({id: item.id, name: item.name}));
		}, [tagSets, allTags, isMultiTagSetMode]);

		const filteredElements = useMemo(
			() => (elements ? flatFilter(elements, 'name', search) : undefined),
			[elements, search]
		);

		useEffect(() => {
			if (isMultiTagSetMode) {
				const selectedSets = tagSets
					?.filter(
						set =>
							allTags?.some(
								tag => tag.problemTagsSetId === set.id && value?.includes(tag.id)
							)
					)
					.map(set => set.id);
				setSelected(selectedSets);
				return;
			}
			setSelected(value);
		}, [value, tagSets, allTags, isMultiTagSetMode]);

		const handleChange = useCallback(
			(v: string[] | undefined) => {
				const problemTags: string[] | undefined = v ? [] : undefined;

				if (v && problemTags && allTags) {
					if (isMultiTagSetMode) {
						v.forEach(id =>
							problemTags.push(
								...allTags
									.filter(tag => tag.problemTagsSetId === id)
									.map(item => item.id)
							)
						);
					} else {
						problemTags.push(...v);
					}
				}

				setSelected(problemTags);
				onChange({problemTags});
				setNoTags(false);
			},
			[onChange, isMultiTagSetMode, allTags]
		);

		const handleExpand = useFilterPageTransition(currentObjectId, entity, 'problemTags');

		const noOptionsOnChange = () => {
			toggleNoTags(newNoTags => {
				onChange({problemTags: newNoTags ? null : undefined});
			});
			setIsCleared(false);
		};
		return (
			<MobileFilter
				label={label}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
				noOptions
				noOptionsContent={t('problemsPage.filters.noTags')}
				noOptionsOnChange={noOptionsOnChange}
				noOptionsChecked={noTags}
			/>
		);
	}
);

ProblemTagsSetFilterMobile.displayName = 'ProblemTagsSetFilterMobile';
