import React, {useMemo} from 'react';
import {CommentsCtx} from './CommentsCtx';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {Tree} from 'array-to-tree';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';
import {IEntityCommentsPermissions} from '@tehzor/tools/interfaces/permissions/IEntityCommentsPermissions';
import {ICommentPermissions} from '../hooks/useProblemCommentPermissions';
import ISavingComment from '@src/interfaces/ISavingComment';

interface ICommentsProviderProps {

	children?: React.ReactNode;
	hasMore: boolean;
	visibility: ICommentVariants[];
	changeCommentsVisibility: (value: ICommentVariants[]) => {
		type: string;
		payload: ICommentVariants[];
	};
	transferStatus?: OfflineDataTransferStatus;
	entityPermissions: IEntityCommentsPermissions;
	commentPermissions: (comment: IComment) => ICommentPermissions;
	links?: IComment['links'];
	comments: Array<Tree<IComment>>;
	getComments: () => void;
	addReply: (savingData: ISavingComment) => Promise<IComment>;
	editReply: (commentId: string, savingData: ISavingComment) => Promise<IComment>;
	addComment: (savingData: ISavingComment) => Promise<IComment>;
	editComment: (commentId: string, savingData: ISavingComment) => Promise<IComment>;
	getEntity?: () => void;
	resetComments: () => void;
	deleteComment: (commentId: string) => void;
	deleteReply: (commentId: string) => void;
	getLastReply: () => void;
	lastReply?: IComment;
}

export interface IChanges {
	[field: string]: unknown | undefined;
}

const CommentsProvider = ({
	children,
	hasMore,
	visibility,
	changeCommentsVisibility,
	transferStatus,
	entityPermissions,
	commentPermissions,
	links,
	comments,
	getComments,
	addReply,
	editReply,
	addComment,
	editComment,
	getEntity,
	resetComments,
	deleteComment,
	deleteReply,
	getLastReply,
	lastReply
}: ICommentsProviderProps) => {
	const ctx = useMemo(
		() => ({
			hasMore,
			visibility,
			changeCommentsVisibility,
			transferStatus,
			entityPermissions,
			commentPermissions,
			links,
			comments,
			getComments,
			addReply,
			editReply,
			addComment,
			getEntity,
			editComment,
			resetComments,
			deleteComment,
			deleteReply,
			getLastReply,
			lastReply
		}),
		[
			hasMore,
			visibility,
			changeCommentsVisibility,
			transferStatus,
			entityPermissions,
			commentPermissions,
			links,
			comments,
			getComments,
			addReply,
			editReply,
			addComment,
			editComment,
			getEntity,
			resetComments,
			deleteComment,
			deleteReply,
			getLastReply,
			lastReply
		]
	);
	return <CommentsCtx.Provider value={ctx}>{children}</CommentsCtx.Provider>;
};

export default CommentsProvider;
