import {Dispatch, RefObject, SetStateAction} from 'react';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IAddingAcceptanceRefProps} from '../components/AddingAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IAddMultipleOwnerAcceptancesParams} from '@src/core/hooks/mutations/ownerAcceptances/useAddMultipleOwnerAcceptances';
import {IAddMultipleProblemsParams} from '@src/core/hooks/mutations/problems/useAddMultipleProblems';
import {IAddMultipleOwnerAcptResponse} from '@src/api/backend/ownerAcceptances/add';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';

interface ISaveMultipleOwnerAcceptancesProps {
	objectId: string;
	spaceIds: string[];
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingAcceptanceRefProps>;
	addMultipleOwnerAcceptances: (
		params: IAddMultipleOwnerAcceptancesParams
	) => Promise<IAddMultipleOwnerAcptResponse>;
	addMultipleProblems: (params: IAddMultipleProblemsParams) => Promise<IProblem[]>;
	temporaryOwnerAcceptances: string[] | undefined;
	setTemporaryOwnerAcceptances: Dispatch<SetStateAction<undefined | string[]>>;
	links: IOwnerAcceptance['links'];
	setOwnerAcceptanceView: (view: 'acceptance' | 'problem') => void;
	onClose: () => void;
	setSaving: (state: boolean) => void;
	ownerAcceptanceView?: 'acceptance' | 'problem';
}

export const saveMultipleOwnerAcceptances = async ({
	objectId,
	spaceIds,
	addingEntityRef,
	addMultipleOwnerAcceptances,
	addMultipleProblems,
	temporaryOwnerAcceptances,
	setTemporaryOwnerAcceptances,
	links,
	setOwnerAcceptanceView,
	onClose,
	setSaving,
	ownerAcceptanceView
}: ISaveMultipleOwnerAcceptancesProps) => {
	if (!addingEntityRef.current) {
		setSaving(false);
		onClose();
		return;
	}

	const savingData = await addingEntityRef.current.getSavingData(true);
	if (!savingData) {
		setSaving(false);
		return;
	}

	let ownerAcceptanceIds: string[] = [];

	if (ownerAcceptanceView === 'acceptance') {
		try {
			setSaving(true);
			const {ownerAcceptances}: IAddMultipleOwnerAcptResponse =
				await addMultipleOwnerAcceptances({
					objectId,
					spaceIds,
					links,
					savingData: savingData as ISavingOwnerAcceptance
				});

			if (
				ownerAcceptances?.length &&
				ownerAcceptances[0].resolution !== OwnerAcceptanceResolutionId.ACCEPTED
			) {
				ownerAcceptanceIds = ownerAcceptances?.map(acceptance => acceptance?.id);
				setTemporaryOwnerAcceptances(ownerAcceptanceIds);
				setOwnerAcceptanceView('problem');
			} else {
				setSaving(false);
			}
		} catch (error) {
			console.error('Ошибка при добавлении передач собственникам:', error);
			setSaving(false);
			return;
		}
	}

	if (ownerAcceptanceView === 'problem') {
		try {
			onClose();
			await addMultipleProblems({
				objectId,
				spaceIds,
				ownerAcceptanceIds: temporaryOwnerAcceptances,
				links,
				fields: savingData as ISavingProblem,
				processId: ProcessIds.UNITS_HANDOVER,
				stage: ObjectStageIds.TRANSFER,
				parentEntityType: 'owner-acceptance'
			});
		} catch (error) {
			console.error('Ошибка при создании нарушений:', error);
		} finally {
			setSaving(false);
		}
	}
};
