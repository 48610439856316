import {IGetSpacesResponse} from '@src/api/backend/spaces';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';

export const extractSpacesAsArray = (response: IGetSpacesResponse): IListSpace[] =>
	response.allIds.map((id: string) => response.byId[id]).sort((a, b) => +a.name - +b.name);

export const enrichSpaces = (
	spaces: IListSpace[],
	spaceStatuses: Record<string, ISpaceStatus>,
	spaceIndicators: Record<string, ISpaceIndicator>
): IEnrichedSpace[] =>
	spaces.map(space => {
		const {status, indicators, ...restData} = space;
		return {
			...restData,
			status: spaceStatuses[status],
			indicators: indicators?.map(id => spaceIndicators[id]).filter(item => item)
		};
	});

export const enrichSpacesWithOutIndicatorsPermission = (
	spaces: IListSpace[],
	spaceStatuses: Record<string, ISpaceStatus>
): IEnrichedSpace[] =>
	spaces.map(space => {
		const {status, indicators, ...restData} = space;
		return {
			...restData,
			status: spaceStatuses[status]
		};
	});

export const extractSpacesAsArrayWithFilteredByObject = (
	response: IGetSpacesResponse,
	indicators?: INormalizedData<IUnitIndicator>,
	spaceStatuses?: INormalizedData<ISpaceStatus>
): IEnrichedSpace[] => {
	const spaces = extractSpacesAsArray(response);
	if (indicators) {
		return spaces && spaceStatuses
			? enrichSpaces(spaces, spaceStatuses.byId, indicators.byId)
			: [];
	}

	return spaces && spaceStatuses
		? enrichSpacesWithOutIndicatorsPermission(spaces, spaceStatuses.byId)
		: [];
};

export const extractSpacesData = (response: IGetSpacesResponse): Record<string, IListSpace> =>
	response.byId;

export const extractSelectedSpacesAsArray = (
	response: IGetSpacesResponse,
	selectedSpaces: string[]
): IListSpace[] => {
	if (!response || !response.byId || selectedSpaces.length === 0) return [];

	const selectedSet = new Set(selectedSpaces);

	return Object.values(response.byId).filter(space => selectedSet.has(space.id));
};
