/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {useTasksSchedulePopup} from '@src/components/TasksSchedulePopup/hooks/useTasksSchedulePopup';
import declination from '@tehzor/tools/utils/declination';
import {ITasksDayCell} from '../utils/convertTasksToScheduleFormat';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IUseDayTasksProps {
	tasks: ITasksDayCell;
	objectId?: string;
	popupTitle?: string;
	rowIndex: number;
	startTime?: number;
	endTime?: number;
}

interface IUseDayTasks {
	dayTasks: JSX.Element;
}

export const useDayTasks = (props: IUseDayTasksProps): IUseDayTasks => {
	const {tasks, objectId, popupTitle, startTime, endTime} = props;
	const {pushPath} = useChangePath();

	const visibleTasks = tasks?.visibleTasks || [];
	const invisibleTasks = tasks?.unvisibleTasks || [];

	const [TasksSchedulePopup, openTasksSchedulePopup] = useTasksSchedulePopup(
		invisibleTasks,
		popupTitle,
		objectId,
		startTime,
		endTime
	);

	const finalTasks = visibleTasks.map(t => {
		const width = t.duration * 123 - 6;
		const topHeight = (t.rowSpan - 1) * 28;

		return (
			<div
				key={t.id}
				className="manage-task-schedule-page__day-cell-tasks-item"
				style={{
					width,
					backgroundColor: t.status?.scheduleTableColor,
					borderLeft: `4px solid ${t.taskType?.color}`,
					top: topHeight
				}}
				onClick={() => pushPath(`/tasks/${t.id}`)}
			>
				{t.name}
			</div>
		);
	});

	const tasksCountStr =
		!!invisibleTasks?.length &&
		`+ ${invisibleTasks?.length} ${declination(invisibleTasks?.length, [
			'задача',
			'задачи',
			'задач'
		])}`;

	return {
		dayTasks: (
			<>
				{finalTasks}
				{tasksCountStr && (
					<span
						onClick={openTasksSchedulePopup}
						className="manage-task-schedule-page__day-cell-tasks-item-hidden"
					>
						{tasksCountStr}
					</span>
				)}
				{TasksSchedulePopup}
			</>
		)
	};
};
