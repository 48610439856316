import {combineReducers} from 'redux';
import checkLists from './checkLists/reducers';
import problem from './problem/reducers';
import workAcceptances from './workAcceptances/reducers';

export default combineReducers({
	checkLists,
	problem,
	workAcceptances
});
