import {IGetMetersResponse} from '@src/api/backend/meters';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractMetersAsArrayForSpace} from '@src/core/hooks/queries/meters/selectors';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {useCallback} from 'react';
import {useSpacesPermissions} from '../../permissions/useSpacesPermissions';

export const useMeters = <T = IGetMetersResponse>(
	spaceId: string,
	objectId: string,
	select?: (data: IGetMetersResponse) => T
) => {
	const permissions = useSpacesPermissions(objectId);

	return useQuery<IGetMetersResponse, IError, T>({
		queryKey: metersQueryKeys.list(spaceId),
		meta: {
			error: 'Ошибка получения приборов учёта'
		},
		select,
		enabled: permissions.canMetersView && !!spaceId
	});
};

export const useMetersAsArrayForSpace = (spaceId: string, objectId: string) => {
	const select = useCallback(
		(data: IGetMetersResponse) => extractMetersAsArrayForSpace(data, spaceId),
		[spaceId]
	);

	return useMeters(spaceId, objectId, select);
};
