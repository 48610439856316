import {ChangeEvent, ReactNode, useCallback, useMemo, useRef} from 'react';
import {useUpdateEffect} from 'react-use';
import {
	CellProps,
	Column,
	useFlexLayout,
	useTable,
	useSortBy,
	useRowSelect,
	useResizeColumns,
	useExpanded,
	useGroupBy,
	useGlobalFilter,
	Row,
	RowPropGetter
} from 'react-table';
import Table from '../Table';
import TableHead from '../Table/components/TableHead';
import TableRow from '../Table/components/TableRow';
import TableBody from '../Table/components/TableBody';
import TableCell from '../TableCell';
import TableWrap from '../Table/components/TableWrap';
import TableRowSelect from '../Table/components/TableRowSelect';
import TableHeadCell from '../TableHeadCell';
import TableHeadRow from '../Table/components/TableHeadRow';
import classNames from 'classnames';
import TableMobileCell from './components/TableMobileCell';
import './EntitiesTable.less';
import TableRowWrap from '../Table/components/TableRowWrap';
import {ISelectionRowProps} from '../shared/interfaces';
import {convertSelectedRowsFromInternal} from '../shared/utils/convertSelectedRowsFromInternal';
import {convertSortFromInternal} from '../shared/utils/convertSortFromInternal';
import {convertSortToInternal} from '../shared/utils/convertSortToInternal';
import TableHeadSelectionRow from '../TableHeadSelectionRow';
import {useMemoizedSelectedRows} from '../shared/hooks/useMemoizedSelectedRows';

const getRowId = (originalRow: {id: string}) => originalRow.id;

interface IEntitiesTableProps<D extends {id: string; children?: D[]}> {
	className?: string;
	columns: Array<Column<D>>;
	data: D[];
	headVisible?: boolean;
	canExpandRow?: boolean;
	selectedRows?: string[];
	sort?: Record<string, boolean>;
	selectable?: boolean;
	autoResetSelectedRows?: boolean;
	responsive?: boolean;
	noRowBorder?: boolean;
	renderSelectionRow?: (props: ISelectionRowProps<D>) => ReactNode;
	hideLastHeaderCell?: boolean;
	filterValue?: string;
	onRowClick?: (data: D) => void;
	onSelectedRowsChange?: (value: string[]) => void;
	onSortChange?: (value: Record<string, boolean>) => void;
	rowProps?: (row: Row<D>) => RowPropGetter<D>;
	rowWithHover?: boolean;
	isMultipleSpacesSelectionActive?: boolean;
}

const EntitiesTable = <D extends {id: string; children?: D[]}>(props: IEntitiesTableProps<D>) => {
	const {
		className,
		columns,
		data,
		headVisible = true,
		selectedRows,
		sort,
		selectable,
		canExpandRow,
		autoResetSelectedRows = false,
		responsive,
		renderSelectionRow,
		onRowClick,
		onSelectedRowsChange,
		onSortChange,
		noRowBorder,
		filterValue,
		hideLastHeaderCell,
		rowProps,
		rowWithHover,
		isMultipleSpacesSelectionActive
	} = props;

	/**
	 * Ref для хранения статуса состояния «промежуточного» выбора записей на странице
	 */
	const selectIndeterminateStatus = useRef(false);
	/**
	 * Ref для хранения идентификаторов записей при использовании чекбокса группового выбора.
	 * Хранит идентификаторы отключенных записей на странице.
	 */
	const unselectedPageRowIds = useRef<string[]>([]);
	/**
	 * Ref для проверки типа элемента события выделения (снятия выделения) записей.
	 * `true` - если событие было выполнено на элементе группового выбора (`checkbox`)
	 * `false` - если событие было выполнено на элементе снятия "галочек" со всех записей  (`button - cancel`)
	 */
	const isToggleElementCheckbox = useRef(true);

	const isRowsClickable = !!onRowClick;

	const memoizedSelectedRows = useMemoizedSelectedRows(selectedRows);

	const memoizedSort = useMemo(() => (sort ? convertSortToInternal(sort) : undefined), [sort]);

	const {
		getTableProps,
		getTableBodyProps,
		toggleAllRowsSelected,
		toggleAllRowsExpanded,
		headerGroups,
		prepareRow,
		selectedFlatRows,
		setGlobalFilter,
		rows,
		state: {selectedRowIds}
	} = useTable<D>(
		{
			columns,
			data,
			// функция поиска во всех дочерних компонентах
			globalFilter: (rows, ids, filteredValue) =>
				rows.filter((row: Row<D>) => {
					let result = false;

					function func(item: Row<D>) {
						if (item.values.name.toLowerCase().includes(filteredValue.toLowerCase())) {
							result = true;
						} else if (item.subRows) {
							item.subRows.forEach((el: any) => func(el));
						}
					}

					if (row.values.name.toLowerCase().includes(filteredValue.toLowerCase())) {
						result = true;
					} else if (row.subRows) {
						row.subRows.forEach((child: Row<D>) => {
							func(child);
						});
					}
					return result;
				}),
			getRowId,
			getSubRows: row => row.children || [],
			manualSortBy: true,
			disableSortRemove: true,
			autoResetSortBy: false,
			autoResetSelectedRows,
			initialState: {
				selectedRowIds: memoizedSelectedRows ?? {},
				sortBy: memoizedSort ?? []
			},
			// Передача controlled значений в state таблицы
			useControlledState: state =>
				useMemo(
					() => ({
						...state,
						selectedRowIds: memoizedSelectedRows ?? state.selectedRowIds,
						sortBy: memoizedSort ?? state.sortBy
					}),
					[state, memoizedSelectedRows, memoizedSort]
				),
			// Передача controlled значений из state наружу
			stateReducer: (newState, action, prevState) => {
				let newSelectedRowIds: Record<string, boolean> = newState.selectedRowIds;

				switch (action.type) {
					case 'toggleRowSelected': {
						if (onSelectedRowsChange) {
							const selectedForStore = action.value
								? convertSelectedRowsFromInternal({
										...newSelectedRowIds,
										[action.id]: true
								  })
								: convertSelectedRowsFromInternal({
										...newSelectedRowIds,
										[action.id]: false
								  });

							// `setTimeout` необходим для избежания вызова хуков в методе рендера и получения ошибки:
							// 'Cannot update a component while rendering a different component'
							setTimeout(() => onSelectedRowsChange(selectedForStore));
						}
						break;
					}
					case 'toggleAllRowsSelected': {
						if (onSelectedRowsChange) {
							let selectedForStore: string[] = [];

							switch (true) {
								case !action.value && !isToggleElementCheckbox.current: {
									// Событие «снять выделение» со всех записей в таблице
									// (`Click` на элементе кн. "Сбросить" в заголовке таблицы)
									newSelectedRowIds = {};
									unselectedPageRowIds.current = [];
									selectedForStore = [];
									break;
								}
								case action.value: {
									// Событие «выделить» все записи на странице таблицы
									selectedForStore =
										convertSelectedRowsFromInternal(newSelectedRowIds);
									break;
								}
								case !action.value && isToggleElementCheckbox.current: {
									// Событие «снять выделение» со всех записей на странице таблицы
									selectedForStore = convertSelectedRowsFromInternal(
										prevState.selectedRowIds
									).filter(
										rowId => !unselectedPageRowIds.current.includes(rowId)
									);
									break;
								}
							}

							// `setTimeout` необходим для избежания вызова хуков в методе рендера и получения ошибки:
							// 'Cannot update a component while rendering a different component'
							setTimeout(() => onSelectedRowsChange(selectedForStore));
							if (selectedForStore.length === 0) {
								newSelectedRowIds = {};
							}
						}
						break;
					}
					case 'resetSelectedRows': {
						if (onSelectedRowsChange) {
							unselectedPageRowIds.current = [];
							newSelectedRowIds = {};

							// `setTimeout` необходим для избежания вызова хуков в методе рендера и получения ошибки:
							// 'Cannot update a component while rendering a different component'
							setTimeout(() => onSelectedRowsChange([]));
						}
						break;
					}
					case 'toggleSortBy': {
						if (onSortChange) {
							// `setTimeout` необходим для избежания вызова хуков в методе рендера и получения ошибки:
							// 'Cannot update a component while rendering a different component'
							setTimeout(() =>
								onSortChange(convertSortFromInternal(newState.sortBy))
							);
						}
						break;
					}
				}

				return {...newState, selectedRowIds: newSelectedRowIds};
			}
		},
		useFlexLayout,
		useGroupBy,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		useRowSelect,
		useResizeColumns,
		hooks => {
			hooks.visibleColumns.push(cols => {
				const result = [] as Array<Column<D>>;
				if (selectable) {
					result.push({
						id: 'selection',
						Header: responsive
							? ''
							: ({getToggleAllRowsSelectedProps}) => {
									selectIndeterminateStatus.current =
										getToggleAllRowsSelectedProps().indeterminate || false;

									/**
									 * Обработчик действия элемента группового выбора (`checkbox`) в заголовке таблицы.
									 * Если `input` в состоянии `indeterminate` добавляет записи из таблице к уже выбранным.
									 * Иначе меняет значения записей на странице на противоположные.
									 */
									const onChange = (e: ChangeEvent<HTMLInputElement>) => {
										unselectedPageRowIds.current = selectedFlatRows.map(
											row => row.id
										);
										isToggleElementCheckbox.current = true;

										if (selectIndeterminateStatus.current) {
											toggleAllRowsSelected(true);
											return;
										}

										toggleAllRowsSelected(!!e.target.checked);
									};

									const p = {...getToggleAllRowsSelectedProps(), onChange};
									delete p.style;

									return <TableRowSelect {...p} />;
							  },
						Cell: ({row}: CellProps<D>) => {
							const p = {...row.getToggleRowSelectedProps()};
							delete p.style;
							return (
								<div>
									<div
										style={{
											display: 'flex',
											paddingLeft: `${row.depth * 5}px`
										}}
									>
										<TableRowSelect {...p} />
										{row.canExpand ? (
											<span
												{...row.getToggleRowExpandedProps({
													style: {
														// We can even use the row.depth property
														// and paddingLeft to indicate the depth
														// of the row
														// paddingLeft: `${row.depth * 2}rem`
													}
												})}
											>
												{row.isExpanded
													? canExpandRow && (
															<div style={{color: '#3391FF'}}>
																<i
																	className="tz-simple-arrow-24"
																	style={{
																		borderRadius: '6px',
																		backgroundColor: '#E3ECF5',
																		transform: 'rotate(180deg)',
																		display: 'block',
																		color: '#3391FF'
																	}}
																/>
															</div>
													  )
													: canExpandRow && (
															<div>
																<i
																	className="tz-simple-arrow-24"
																	style={{
																		color: '#3391FF'
																	}}
																/>
															</div>
													  )}
											</span>
										) : null}
									</div>
								</div>
							);
						},
						minWidth: responsive ? 40 : 50,
						maxWidth: responsive ? 40 : 60,
						disableResizing: true,
						className: classNames('table2__cell_selection', {
							'me-table__row-select-cell': responsive
						}),
						isNonClickable: true
					});
				}
				result.push(...cols);
				return result;
			});
		}
	);

	const onChange = (value: string | undefined) => {
		setGlobalFilter(value || undefined);
		if (value) {
			toggleAllRowsExpanded(true);
		} else {
			toggleAllRowsExpanded(false);
		}
	};

	/**
	 * Обработчик действия снятия "выделения" со всех выбранных записей в таблице.
	 * Меняет флаг `isToggleElementCheckbox` чтобы можно было отличить действие снятия "выделения"
	 * со всех выбранных записей, от добавления(снятия) "выделения" записей на странице.
	 * Инициирует событие `toggleAllRowsSelected` со значение `false`.
	 */
	const clearAllSelectionHandler = useCallback(
		(value: boolean) => {
			isToggleElementCheckbox.current = false;
			toggleAllRowsSelected(value);
		},
		[toggleAllRowsSelected]
	);

	useUpdateEffect(() => {
		const timeOutId = setTimeout(() => onChange(filterValue), 800);
		return () => clearTimeout(timeOutId);
	}, [filterValue]);

	return (
		<TableWrap className={className}>
			<Table
				{...getTableProps()}
				dontSetWidth={responsive}
			>
				{headVisible && !responsive && (
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableHeadRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<TableHeadCell
										{...column.getHeaderProps(column.getSortByToggleProps())}
										canSort={column.canSort}
										isSorted={column.isSorted}
										isSortedDesc={column.isSortedDesc}
										className={classNames({
											'me-table__hidden-cell':
												index === headerGroup.headers.length - 1 &&
												hideLastHeaderCell
										})}
									>
										{column.render('Header')}
									</TableHeadCell>
								))}
							</TableHeadRow>
						))}
						{renderSelectionRow && (
							<TableHeadSelectionRow
								visible={
									selectedFlatRows.length > 0 ||
									(isMultipleSpacesSelectionActive &&
										selectedRows &&
										selectedRows.length > 0)
								}
								selectedRows={selectedRows}
								selectedRowIds={selectedRowIds}
								selectedFlatRows={selectedFlatRows}
								toggleAllRowsSelected={clearAllSelectionHandler}
								renderSelectionRow={renderSelectionRow}
							/>
						)}
					</TableHead>
				)}
				<TableBody {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						const {
							key,
							className: advancedClassName,
							...additionalRowProps
						} = row.getRowProps(rowProps && rowProps(row));

						return (
							<TableRowWrap
								key={key}
								className={classNames({
									'table2__row-wrap_selected': row.isSelected,
									'table2__row-wrap_with-hover': rowWithHover,
									'table2__row-wrap_expanded': row.isExpanded
								})}
							>
								<TableRow
									{...additionalRowProps}
									className={classNames(
										{'me-table__row': responsive},
										{table2__row_expanded: row.isExpanded},
										advancedClassName
									)}
									clickable={isRowsClickable}
									noBorder={noRowBorder}
								>
									{row.cells.map((cell, index) =>
										responsive ? (
											<TableMobileCell
												data-testid={`EntitiesTableCell_${index}`}
												{...cell.getCellProps()}
												className={cell.column.className}
												data={row.original}
												header={cell.column.render('Header')}
												nonClickable={cell.column.isNonClickable}
												onClick={onRowClick}
											>
												{cell.render('Cell')}
											</TableMobileCell>
										) : (
											<TableCell
												data-testid={`EntitiesTableCell_${index}`}
												{...cell.getCellProps()}
												className={cell.column.className}
												data={row.original}
												nonClickable={cell.column.isNonClickable}
												onClick={onRowClick}
											>
												{cell.render('Cell')}
											</TableCell>
										)
									)}
								</TableRow>
							</TableRowWrap>
						);
					})}
				</TableBody>
			</Table>
		</TableWrap>
	);
};

EntitiesTable.displayName = 'EntitiesTable';

export default EntitiesTable;
