import {useState} from 'react';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {MobileRightButtons} from './actions/RightButtons.mobile';
import {MobileInfo} from './info/Info.mobile';
import {Plate} from '@tehzor/ui-components';
import {ListDetail, MobileItemDetail} from './detail';
import {Table} from './table/Table';
import {getMobileColumns} from './table/columns/columns.mobile';
import {EntityAdding} from './actions/EntityAdding';
import {EntitiesVisibility} from './actions/EntitiesVisibility';
import {PageBreadcrumbs} from './PageBreadcrumbs';
import {Outlet, Route, Routes} from 'react-router-dom';
import {CheckLists} from './list';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useTranslation} from 'react-i18next';
import {getMenuSections} from '@src/pages/StructurePage/utils/getMenuSections';
import {EntitiesTabs} from '@src/pages/StructurePage/components/table/EntitiesTabs';
import {useStructureProblemRepliesAsArray} from '@/entities/Structure/model/queries/problemReplies/hooks/useStructureProblemRepliesAsArray';

interface IMobileProps {
	objectId: string;
	structureId: string;
	structure?: IStructure;
	checkLists: ICheckList[];
}

export const Mobile = ({objectId, structureId, structure, checkLists}: IMobileProps) => {
	const {t} = useTranslation();

	const sectionsMenu = getMenuSections(objectId, structureId, t);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	useAppHeader(
		{
			title: structure?.name,
			showBackBtn: true,
			sectionsMenu,
			mobileLeftButton:
				selectedRows.length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: structure ? (
				<MobileRightButtons
					objectId={objectId}
					structure={structure}
					selectedRows={selectedRows}
				/>
			) : null
		},
		[structure, selectedRows]
	);

	const {data: replies} = useStructureProblemRepliesAsArray(objectId, structureId);

	return structure ? (
		<div className="page-cont structure-page">
			<PageBreadcrumbs
				objectId={objectId}
				structure={structure}
			/>
			<MobileInfo structure={structure} />
			<div className="page-cont check-list-page structure-page__check-list__m-container">
				<Routes>
					<Route path="check-lists">
						<Route
							path=":listId"
							element={<ListDetail structureType={structure.type} />}
						/>

						<Route
							path=":listId/:pathParam?/:itemId?"
							element={<MobileItemDetail />}
						/>

						<Route
							path=""
							element={<CheckLists checkLists={checkLists} />}
						/>
					</Route>
				</Routes>
			</div>
			<Routes>
				<Route
					path=""
					element={
						<>
							<EntitiesVisibility className="structure-page__m-visibility-filter" />

							<Plate withoutPadding>
								<EntitiesTabs
									structureId={structureId}
									objectId={objectId}
								/>
								<Table
									objectId={objectId}
									columns={getMobileColumns(replies)}
									hideHead
									structureId={structureId}
									selectedRows={selectedRows}
									onSelectedRowsChange={setSelectedRows}
								/>
							</Plate>

							<EntityAdding
								objectId={objectId}
								structureId={structureId}
							/>
						</>
					}
				/>
			</Routes>
			<Outlet />
		</div>
	) : null;
};
