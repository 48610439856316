import {memo, useCallback} from 'react';
import {
	ExpandablePlate,
	InlineButton,
	Select2,
	SelectDialog,
	SelectOption,
	Tag
} from '@tehzor/ui-components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useEditSpaceIndicators} from '@src/core/hooks/mutations/spaces/useEditSpaceIndicators';
import useAppSelector from '@src/core/hooks/useAppSelector';

import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useTranslation} from 'react-i18next';
import {useStagesTitlesMap} from '@src/pages/SpacesPage/hooks/useStagesTitlesMap';
import './IndicatorsSelect.less';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

interface IIndicatorsSelectProps {
	objectId: string;
	spaceId: string;
	stage?: ObjectStageIds;
	value?: string[];
}

const editIcon = <i className="tz-edit-20" />;

export const IndicatorsSelect = memo(
	({objectId, spaceId, stage, value}: IIndicatorsSelectProps) => {
		const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
		const {t} = useTranslation();
		const online = useAppSelector(s => s.offlineMode.networkStatus);
		const editSpaceIndicators = useEditSpaceIndicators(objectId);
		const stages: Record<string, string> = useStagesTitlesMap();
		const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

		const {data: setWithIndicatorsArray} = useActualUnitIndicatorsSetsExtended(
			objectId,
			undefined,
			true
		);

		const handleChange = useCallback(
			(v: string[]) => {
				editSpaceIndicators({objectId, pageObjectId, spaceId, indicators: v, online});
			},
			[objectId, pageObjectId, spaceId, online, editSpaceIndicators]
		);

		const getStageNames = (stageIds: string[]): string => {
			const translatedStages = stageIds
				.map(stageId => stages[stageId])
				.filter(Boolean)
				.join(', ');

			return translatedStages;
		};

		return (
			<SelectDialog
				title={t('spacePage.info.indicatorsSelect.title')}
				value={value}
				className={{body: 'select-dialog__body--indicators'}}
				acceptBtnLabel={t('spacePage.info.indicatorsSelect.acceptBtnLabel')}
				rejectBtnLabel={t('spacePage.info.indicatorsSelect.rejectBtnLabel')}
				trigger={
					<InlineButton
						type="accent"
						leftIcon={editIcon}
						className="space-page__info-edit-icon"
					/>
				}
				onChange={handleChange}
			>
				<Select2 multiple>
					{setWithIndicatorsArray
						?.filter(set => set.indicators.length)
						.map(set => (
							<ExpandablePlate
								key={set.id}
								className={{
									root: 'indicators-select__expandable-plate',
									content: 'indicators-select__expandable-plate-content',
									expandButton: 'indicators-select__expandable-plate-expand-btn'
								}}
								title={
									<div className="indicators-select__expandable-plate-expand-btn--indicator-set-item">
										<div className="indicators-select__expandable-plate-expand-btn--indicator-set-item_name">
											{set.name}
										</div>
										<div className="indicators-select__expandable-plate-expand-btn--indicator-set-item_stages">
											{`Стадия: ${getStageNames(set.stageIds)}`}
										</div>
									</div>
								}
								initiallyClosed={
									!(
										pageSettings.stage &&
										set.stageIds.includes(pageSettings.stage)
									)
								}
							>
								{set.indicators?.map(indicator => (
									<SelectOption
										key={indicator.id}
										itemKey={indicator.id}
										content={
											<Tag
												color={`${indicator.color}33`}
												icon={
													<div
														className="space-page__indicator-icon"
														style={{backgroundColor: indicator.color}}
													/>
												}
												label={indicator.name}
											/>
										}
									/>
								))}
							</ExpandablePlate>
						))}
				</Select2>
			</SelectDialog>
		);
	}
);
