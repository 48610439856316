import {Dispatch, memo, useCallback, useEffect} from 'react';
import './WarrantyClaimForm.less';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from './Attachments/Attachments';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {editableWarrantyClaimFields} from '../useEditableWarrantyClaimForm/editableWarrantyClaimFields';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';

export type SetValueOptions = Partial<{
	shouldValidate: boolean;
	shouldDirty: boolean;
	shouldTouch: boolean;
}>;

interface IEditableWarrantyClaimProps {
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	warrantyClaimId: string | undefined;
	saving: boolean;
	creating?: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */

export const WarrantyClaimAttachmentForm = memo((props: IEditableWarrantyClaimProps) => {
	const {uploadingFilesState, uploadingFilesDispatch, warrantyClaimId, saving, creating} = props;

	const {reset, setValue} = useFormContext();

	useEffect(() => {
		if (!creating) {
			reset();
			uploadingFilesDispatch({type: 'reset'});
		}
	}, [creating, reset, uploadingFilesDispatch]);

	const changeHandler = useCallback(
		(name: editableWarrantyClaimFields, value: string, options: SetValueOptions) => {
			const defaultOptions = {shouldDirty: true, shouldValidate: true};
			setValue(name, value, options || defaultOptions);
		},
		[setValue]
	);

	const {t} = useTranslation();

	return (
		<div className="editable-warranty-claim">
			<Attachments
				data-testid="EditableWarrantyClaimAttachments"
				name="attachments"
				className={{
					root: 'editable-warranty-claim__attachments',
					scrollArea: 'editable-warranty-claim__attachments-scroll-area',
					files: 'editable-warranty-claim__attachments-files',
					file: 'editable-warranty-claim__attachments-file'
				}}
				attachmentsDestination={AttachmentFileDestination.WARRANTY_CLAIM}
				entityId={warrantyClaimId}
				uploadingFilesDispatch={uploadingFilesDispatch}
				uploadingFilesState={uploadingFilesState}
				waitForUploading={false}
				label={t('editableWarrantyClaim.attachments.label')}
				disabled={saving}
				canDraw={false}
				showAttachBtn
				onSetValue={changeHandler}
			/>
		</div>
	);
});
