import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useSubmittersGroupsAsArray} from '@src/core/hooks/queries/workingGroups/hooks';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {ResponsiblesFilterPage} from '@src/pages/filters/ResponsiblesFilterPage';

export const SubmittersFilterPage = () => {
	const {t} = useTranslation();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity?: string}>();
	const {state, change} = useEntitiesFiltersCtx<{submitters?: string[]}>();
	const {data: groups} = useSubmittersGroupsAsArray(objectId);

	const handleChange = useCallback(
		(value?: string[]) => {
			change({submitters: value});
		},
		[change]
	);

	return (
		<ResponsiblesFilterPage
			objectId={objectId}
			entity={entity}
			respUsers={state.submitters}
			label={t('entitiesFilters.submitters.label')}
			workingGroups={groups}
			onChange={handleChange}
		/>
	);
};
