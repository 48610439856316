import {IStructuresFiltersState, IStructuresSortState} from '../../slice/interfaces';

export type IStructuresListQueryKey = [] & {
	0: string;
	1: string;
	2: IStructuresFiltersState;
	3: IStructuresSortState;
	4: number;
	5: number;
};

export type IStructuresPaginateQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: IStructuresFiltersState;
	4: IStructuresSortState;
	5: number;
	6: number;
};

export type IStructuresProblemsStatsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string; // objectId
	4: string[]; // structuresIds
};

export type IStructuresWorkAcceptanceStatsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string; // objectId
	4: string[]; // structuresIds
};
export type IStructureQueryKey = [] & {
	0: string;
	1: string; // objectId
	2: string; // structureId
};

export type IStructureProblemsQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // structureId
};

export type IStructureInspectionsQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // structureId
};

export type IStructureChecksQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // structureId
};

export type IStructureProblemRepliesQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // structureId
};

export const structuresQueryKeys = {
	all: () => ['structures'],
	one: () => ['structure'],
	list: () => [...structuresQueryKeys.all(), 'list'],
	paginate: () => [...structuresQueryKeys.list(), 'paginate'],
	localList: () => [...structuresQueryKeys.all(), 'local-list'],
	stats: () => [...structuresQueryKeys.all(), 'stats'],
	problemsStatsAll: () => [...structuresQueryKeys.stats(), 'problems'],
	problemsStats: (objectId: string, structuresIds?: string[]) => [
		...structuresQueryKeys.problemsStatsAll(),
		objectId,
		structuresIds
	],
	workAcceptancesStatsAll: () => [...structuresQueryKeys.stats(), 'work-acceptances'],
	workAcceptancesStats: (objectId: string, structuresIds?: string[]) => [
		...structuresQueryKeys.workAcceptancesStatsAll(),
		objectId,
		structuresIds
	],
	oneById: (objectId: string, structureId: string) => [
		...structuresQueryKeys.one(),
		objectId,
		structureId
	],
	problems: () => [...structuresQueryKeys.one(), 'problems'],
	problemsById: (objectId: string, structureId: string) => [
		...structuresQueryKeys.problems(),
		objectId,
		structureId
	],
	inspections: () => [...structuresQueryKeys.one(), 'inspections'],
	inspectionsById: (objectId: string, structureId: string) => [
		...structuresQueryKeys.inspections(),
		objectId,
		structureId
	],
	checks: () => [...structuresQueryKeys.one(), 'checks'],
	checksById: (objectId: string, structureId: string) => [
		...structuresQueryKeys.checks(),
		objectId,
		structureId
	],
	edit: () => [...structuresQueryKeys.one(), 'edit'],
	add: () => [...structuresQueryKeys.one(), 'add'],
	delete: () => [...structuresQueryKeys.one(), 'delete'],
	problemReplies: () => [...structuresQueryKeys.one(), 'problemReplies'],
	structureProblemReplies: (objectId: string, structureId: string) => [
		...structuresQueryKeys.problemReplies(),
		objectId,
		structureId
	]
};
