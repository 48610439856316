import {useCallback, useMemo, useState} from 'react';
import {useLocation, useMatch} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Select2, SelectOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useTranslation} from 'react-i18next';

const PlansFilterPage = () => {
	const {goBack} = useChangePath();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {t} = useTranslation();

	const {state, change} = useEntitiesFiltersCtx<{plans?: string[]}>();
	const [selected, setSelected] = useState(state.plans);
	const [search, setSearch] = useState('');

	const {data: plans} = usePlansAsArray(objectId);
	const filteredData = useMemo(
		() => (plans ? flatFilter(plans, 'name', search) : []),
		[plans, search]
	);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({plans: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: t('entitiesFilters.PlansFilter.label'),
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);
	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.plans, selected)}
			onApplyClick={handleApply}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		>
			<Select2
				multiple
				value={selected}
				onChange={setSelected}
			>
				{filteredData.map(item => (
					<SelectOption
						key={item.id}
						itemKey={item.id}
						content={item.name}
					/>
				))}
			</Select2>
		</TranslatedFilterPage>
	);
};

export default PlansFilterPage;
