import {testProblemRestrictions} from '@src/utils/testRestrictions';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Возвращает полномочия для конкретного нарушения
 *
 * @param problem нарушение
 * @param networkStatus статус соединения
 * @param objectId id объекта
 * @param user текущий пользователь
 */
export const getPermissionsForProblem = (
	problem: IListProblem,
	networkStatus: boolean,
	objectId?: string,
	user?: IUser
) => {
	if (!user || !user.roles?.length) {
		return {canDelete: false, canCopy: false};
	}

	const canCopy =
		hasPermission(user.roles, 'problems-add', UserRoleScopes.OBJECT, objectId) &&
		(!!problem.links?.checkId || !!problem.links?.internalAcceptanceId);

	const canDelete = testProblemRestrictions({
		userId: user.id,
		userRoles: user.roles,
		permissionId: 'problemsDelete',
		objectId,
		createdBy: problem.createdBy,
		performers: problem.performers,
		inspectors: problem.inspectors,
		watchers: problem.watchers
	});

	return {canDelete, canCopy};
};
