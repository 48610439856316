import {makeProblemAddRequest, makeProblemEditRequest} from '@src/api/backend/problem';
import {invalidateLinkedProblemsQueries} from '@src/cache/actions/problem/invalidateLinkedProblemsQueries';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {
	addProblemActions,
	deleteProblemActions,
	editProblemActions
} from '@src/store/modules/pages/problem/actions';
import {useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {addTempFiles} from '../../utils/addTempFiles';
import {problemsQueryKeys} from '../keys';
import {formProblemLink} from '@tehzor/tools/utils/links';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {addLinkToast} from '@/shared/lib/Toasts/LinkToast/addLinkToast';
import {useTranslation} from 'react-i18next';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IAddProblemParams {
	objectId: string;
	links: IProblem['links'] | undefined;
	stage: ObjectStageIds | undefined;
	processId: ProcessIds;
	fields: ISavingProblem;
	key: string;
}

export interface IEditProblemParams {
	problemId: string;
	objectId: string;
	fields: ISavingProblem;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 *        то для повтра мутации будет использована mutationFn отсюда
 */
export const useProblemsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const {t} = useTranslation();

	const dispatch = useAppDispatch();
	const {updateEntity} = useUpdateEntity(problemsQueryKeys);
	queryClient.setMutationDefaults(problemsQueryKeys.add(), {
		onMutate: (variables: IAddProblemParams) => ({variables}),
		mutationFn: async (params: IAddProblemParams) => {
			const {objectId, links, stage, processId, fields, key} = params;
			return makeProblemAddRequest(
				objectId,
				links,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments, TempFileLocation.S3)
				},
				processId,
				key,
				stage
			);
		},
		onSuccess: (newProblem: IProblem, {key, objectId, links}: IAddProblemParams) => {
			// Удаляем кешированные данные локального нарушения
			queryClient.removeQueries({queryKey: problemsQueryKeys.savingData(key)});

			updateEntity<IProblem>(
				newProblem,
				deleteProblemActions.success,
				addProblemActions.success,
				key
			);
			void invalidateLinkedProblemsQueries(objectId, links);
			void queryClient.refetchQueries({
				queryKey: problemsQueryKeys.localList()
			});
			void queryClient.invalidateQueries({
				queryKey: [...problemsQueryKeys.list(), objectId]
			});
			addLinkToast({
				title: `${t('toast.addProblem.success.title', {number: newProblem.number})}`,
				link: formProblemLink(objectId, newProblem.id)
			});
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.problemsStats()});
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.listWithWorkAcceptanceStats()
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.addProblem.error.title')});
		},
		retry: false
	});

	queryClient.setMutationDefaults(problemsQueryKeys.edit(), {
		mutationFn: async ({problemId, objectId, fields}: IEditProblemParams) => {
			void queryClient.cancelQueries({queryKey: problemsQueryKeys.detail(problemId)});
			return makeProblemEditRequest(objectId, problemId, {
				...fields,
				newAttachments: await addTempFiles(fields.newAttachments, TempFileLocation.S3)
			});
		},
		onError: err => {
			dispatch(editProblemActions.failure(err as IError));
		},
		onSuccess: (data: ILinkedProblem, {problemId, objectId}: IEditProblemParams) => {
			dispatch(editProblemActions.success(data));
			void invalidateLinkedProblemsQueries(objectId);
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.detail(problemId)});
			void queryClient.invalidateQueries({queryKey: [...problemsQueryKeys.list(), objectId]});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					problemId,
					workingGroupType: WorkingGroupTypeId.PERFORMERS
				})
			});
			addSuccessToast({
				title: `${t('toast.editProblem.success.title', {number: data.number})}`
			});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId,
					problemId,
					workingGroupType: WorkingGroupTypeId.INSPECTORS
				})
			});
		}
	});
};
