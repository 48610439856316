import {memo, useCallback} from 'react';
import {EntityInnerTable} from '@tehzor/ui-components';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	formCheckLink,
	formInspectionLink,
	formProblemLink,
	formWarrantyClaimLink,
	formOwnerAcceptanceLink,
	formInternalAcceptanceLink,
	formWorkAcceptanceLink
} from '@tehzor/tools/utils/links';
import {Column} from 'react-table';
import SelectionRow from './SelectionRow';
import {useSpaceEntities} from '@src/core/hooks/queries/space';
import {onlineManager} from '@tanstack/react-query';
import {formPresaleCheckLink} from '@tehzor/tools/utils/links/formPresaleCheckLink';

interface IDesktopTableProps {
	objectId: string;
	columns: Array<Column<ISpaceEntity>>;
	hideHead?: boolean;
	spaceId: string;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}
type LinkFormers = Record<
	ISpaceEntity['type'],
	((objectId: string, entityId: string) => string) | null
>;

const linkFormers: LinkFormers = {
	check: formCheckLink,
	'presale-check': formPresaleCheckLink,
	problem: formProblemLink,
	inspection: formInspectionLink,
	'warranty-claim': formWarrantyClaimLink,
	'work-acceptance': formWorkAcceptanceLink,
	'owner-acceptance': formOwnerAcceptanceLink,
	'internal-acceptance': formInternalAcceptanceLink,
	'local-problem': formProblemLink,
	'local-inspection': formInspectionLink,
	'local-internal-acceptance': null,
	'local-owner-acceptance': null,
	'local-warranty-claim': null,
	'local-work-acceptance': null,
	'local-check': null,
	'local-presale-check': null
};

export const Table = memo((props: IDesktopTableProps) => {
	const {objectId, columns, hideHead, spaceId, selectedRows, onSelectedRowsChange} = props;
	const {pushPath} = useChangePath();
	const entities = useSpaceEntities(objectId, spaceId);
	const handleRowClick = useCallback(
		(entity: ISpaceEntity) => {
			const formLink = linkFormers[entity.type];
			if (!formLink) return undefined;
			return pushPath(formLink(objectId, entity.data.id));
		},
		[objectId, pushPath]
	);

	return (
		<EntityInnerTable
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable={onlineManager.isOnline()}
			onRowClick={handleRowClick}
			selectedRows={selectedRows}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={renderProps => (
				<SelectionRow
					{...renderProps}
					objectId={objectId}
					spaceId={spaceId}
				/>
			)}
		/>
	);
});
