import {Dispatch, RefObject, SetStateAction} from 'react';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IAddingWarrantyClaimRefProps} from '../components/AddingWarrantyClaim';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog';
import {IAddMultipleWarrantyClaimsParams} from '@src/core/hooks/mutations/warrantyClaims/useAddMultipleWarrantyClaims';
import {IAddMultipleProblemsParams} from '@src/core/hooks/mutations/problems/useAddMultipleProblems';
import {IAddWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';

interface ISaveMultipleWarrantyClaimsProps {
	objectId: string;
	spaceIds: string[];
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingWarrantyClaimRefProps>;
	addMultipleWarrantyClaims: (
		params: IAddMultipleWarrantyClaimsParams
	) => Promise<IAddWarrantyClaimsResponse>;
	addMultipleProblems: (params: IAddMultipleProblemsParams) => Promise<IProblem[]>;
	temporaryWarrantyClaims: string[] | undefined;
	setTemporaryWarrantyClaims: Dispatch<SetStateAction<undefined | string[]>>;
	links: IWarrantyClaim['links'];
	setWarrantyClaimView: (view: 'claim' | 'problem') => void;
	onClose: () => void;
	setSaving: (state: boolean) => void;
	warrantyClaimView?: AddingWarrantyClaimView;
}

export const saveMultipleWarrantyClaims = async ({
	objectId,
	spaceIds,
	addingEntityRef,
	addMultipleWarrantyClaims,
	addMultipleProblems,
	temporaryWarrantyClaims,
	setTemporaryWarrantyClaims,
	links,
	setWarrantyClaimView,
	onClose,
	setSaving,
	warrantyClaimView
}: ISaveMultipleWarrantyClaimsProps) => {
	if (!addingEntityRef.current) {
		setSaving(false);
		onClose();
		return;
	}

	const savingData = await addingEntityRef.current.getSavingData(true);
	if (!savingData) {
		console.error('Не удалось получить данные для сохранения');
		setSaving(false);
		return;
	}

	let warrantyClaimIds: string[] = [];

	if (warrantyClaimView === 'claim') {
		try {
			setSaving(true);
			const result = await addMultipleWarrantyClaims({
				objectId,
				spaceIds,
				links,
				savingData: savingData as ISavingWarrantyClaim
			});

			if (result?.length) {
				warrantyClaimIds = result.map(claim => claim.id);
				setTemporaryWarrantyClaims(warrantyClaimIds);
				setWarrantyClaimView('problem');
			}
		} catch (error) {
			console.error('Ошибка при добавлении гарантийных обращений:', error);
			setSaving(false);
			return;
		}
	}

	if (warrantyClaimView === 'problem') {
		try {
			onClose();
			await addMultipleProblems({
				objectId,
				spaceIds,
				warrantyClaimIds: temporaryWarrantyClaims,
				links,
				fields: savingData as ISavingProblem,
				processId: ProcessIds.WARRANTY_SERVICE,
				stage: ObjectStageIds.WARRANTY,
				parentEntityType: 'warranty-claim'
			});
		} catch (error) {
			console.error('Ошибка при создании нарушений:', error);
		} finally {
			setSaving(false);
		}
	}
};
