import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import type {ITestCheckRestrictionsProps} from '@src/interfaces/ITestRestrictions';
import {testCheckRestrictions, testCheckRestrictionsList} from '@src/utils/testRestrictions';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canProblemsMove?: boolean;
	canMoveRestrictions?: {[key: string]: boolean};
	canProblemsMoveRestrictions?: {[key: string]: boolean};
}

/**
 * Возвращает полномочия для проверки
 *
 * @param objectId id объекта
 * @param check проверка
 * @param problems нарушения
 */
export const useCheckPermissions = (
	objectId: string,
	check?: ICheck,
	problems?: IProblem[]
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		const testCheckRestrictionsParams: ITestCheckRestrictionsProps = {
			userId: user.id,
			userRoles: user.roles,
			permissionId: '',
			objectId,
			createdBy: check?.createdBy?.id,
			problems,
		};

		return {
			canDelete: testCheckRestrictions({
				...testCheckRestrictionsParams,
				permissionId: 'checksDelete',
			}),
			canExport: testCheckRestrictions({
				...testCheckRestrictionsParams,
				permissionId: 'checksExport',
			}),
			canEdit: testCheckRestrictions({
				...testCheckRestrictionsParams,
				permissionId: 'checksEdit',
			}),
			canMove: testCheckRestrictions({
				...testCheckRestrictionsParams,
				permissionId: 'checksMove',
			}),
			canMoveRestrictions: testCheckRestrictionsList(user.roles, 'checksMove', objectId),
			canProblemsMove: testCheckRestrictions({
				...testCheckRestrictionsParams,
				permissionId: 'checkProblemsMove',
			}),
			canProblemsMoveRestrictions: testCheckRestrictionsList(
				user.roles,
				'checkProblemsMove',
				objectId
			)
		};
	}, [objectId, check, user]);
};
