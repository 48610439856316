import {useSpaceProblemsAsArray} from '@src/core/hooks/queries/space';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useMemo} from 'react';

export const useHasUnfinished = (checkListsIds: string[], objectId: string, spaceId: string) => {
	const {data: spaceProblemsData} = useSpaceProblemsAsArray(objectId, spaceId);
	const allSpaceCheckRecords = useAppSelector(extractCheckRecordsAsArray);

	// Фильтрует записи по актуальным лдя помещения чек-листам
	const spaceCheckRecordsData = useMemo(
		() =>
			allSpaceCheckRecords.filter(
				record =>
					checkListsIds.includes(record.links.checkListId) &&
					record.links.spaceId === spaceId &&
					record.objectId === objectId
			),
		[checkListsIds, allSpaceCheckRecords, objectId, spaceId]
	);

	const hasUnfixedProblems: boolean = useMemo(() => {
		if (!spaceProblemsData) return false;

		return (
			spaceProblemsData.filter(problem => problem.status !== ProblemStatusId.FIXED).length > 0
		);
	}, [spaceProblemsData]);

	const hasHandlingOrWaitingProblems: boolean = useMemo(() => {
		if (!spaceProblemsData) return false;

		return (
			spaceProblemsData.filter(
				problem =>
					problem.status === ProblemStatusId.WAITING ||
					problem.status === ProblemStatusId.HANDLING
			).length > 0
		);
	}, [spaceProblemsData]);

	const hasUnacceptedCheckItem: boolean = useMemo(
		() =>
			spaceCheckRecordsData.filter(
				record =>
					record.status === CheckRecordStatusId.REJECTED ||
					record.status === CheckRecordStatusId.WIP
			).length > 0,
		[spaceCheckRecordsData]
	);
	return [hasUnfixedProblems, hasHandlingOrWaitingProblems, hasUnacceptedCheckItem];
};
