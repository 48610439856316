import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IEntityInspectionsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityInspectionsData';

export type IGetInternalAcceptanceInspectionsResponse = IEntityInspectionsData;

/**
 * Возвращает статистику осмотров по внутренним приемкам
 */
export const requestInternalAcceptancesInspectionsData = async (
	requestData: IInternalAcceptancesStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetInternalAcceptanceInspectionsResponse>(
		'internal-acceptances/get-inspections-data',
		requestData
	);
	return response.data;
};
