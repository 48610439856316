import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {FixDateTag} from '@src/components/FixDateTag';
import {format} from 'date-fns';

interface IWorkAcceptanceDeadlineProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceDeadlineDesktop = ({workAcceptance}: IWorkAcceptanceDeadlineProps) => {
	const {plannedAcceptanceDate, actualAcceptanceDate} = workAcceptance;

	return plannedAcceptanceDate ? (
		<>
			{format(plannedAcceptanceDate, dateTimeCommaSeparatedFormat)}
			<FixDateTag
				className="fix-date-view-field__tag"
				plannedFixDate={plannedAcceptanceDate}
				actualFixDate={actualAcceptanceDate}
			/>
		</>
	) : null;
};
