import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingComment} from '@src/interfaces/saving/ISavingComment';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IAddCommentResponse} from '@src/api/backend/comment';
import {makeReplyAddRequest} from '@src/api/backend/reply';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {structuresQueryKeys} from '@/entities/Structures';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCommentResponse) => {
	if (response.links?.problemId) {
		void queryClient.invalidateQueries({
			queryKey: [...problemsQueryKeys.detail(response.links.problemId), response.objectId]
		});
	}
	void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: problemsQueryKeys.comments()});
	if (response.links?.warrantyClaimId)
		void queryClient.invalidateQueries({
			queryKey: warrantyClaimsQueryKeys.claimProblemsReplies(
				response.links.warrantyClaimId,
				response.objectId
			)
		});
	if (response.links?.internalAcceptanceId)
		void queryClient.invalidateQueries({
			queryKey: internalAcceptancesQueryKeys.internalAcceptanceIdProblemsReplies(
				response.objectId,
				response.links.internalAcceptanceId
			)
		});
		if (response.links?.structureId) {
		void queryClient.invalidateQueries({
			queryKey: structuresQueryKeys.structureProblemReplies(
				response.objectId,
				response.links.structureId
			)
		});
	}
	if (response.links?.spaceId) {
		void queryClient.invalidateQueries({
			queryKey: spacesQueryKeys.spaceProblemReplies(response.objectId, response.links.spaceId)
		});
	}
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => ({
	type: types.ADD_FAILURE,
	payload: error
});

/**
 * Добавляет ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export const addProblemReply = (
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) =>
	createApiAction<IAddCommentResponse>(request, success, failure, () =>
		makeReplyAddRequest(objectId, commentTypeId, links, fields)
	);
