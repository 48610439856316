import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetProblemTagsSetsResponse} from '@src/api/backend/problemTagsSets';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {
	extractFilteredTagsSets,
	extractProblemTagsSetById,
	extractProblemTagsSetsAsArray,
	extractProblemTagsSetsAsArrayForObject,
	extractProblemTagsSetsAsFilteredArray
} from './selectors';
import {useCallback} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';

export const useProblemTagsSets = <T = IGetProblemTagsSetsResponse>(
	select?: (data: IGetProblemTagsSetsResponse) => T,
	enabled?: boolean
) =>
	useQuery<IGetProblemTagsSetsResponse, IError, T>({
		queryKey: problemTagsSetsQueryKeys.list(),
		meta: {
			error: 'при загрузке наборов меток нарушений'
		},
		select,
		enabled
	});

export const useExtractProblemTagsSetsAsArray = () =>
	useProblemTagsSets(extractProblemTagsSetsAsArray);

export const useExtractProblemTagsSetsAsArrayForObject = (objectId: string) => {
	const selector = useCallback(
		(data: IGetProblemTagsSetsResponse) =>
			extractProblemTagsSetsAsArrayForObject(data, objectId),
		[objectId]
	);
	return useProblemTagsSets(selector, !!objectId);
};

export const useFilteredProblemTagsSets = (objectIds?: string[], stages?: string[]) => {
	const selector = useCallback(
		(data: IGetProblemTagsSetsResponse) => extractFilteredTagsSets(data, objectIds, stages),
		[objectIds, stages]
	);
	return useProblemTagsSets(selector);
};

export const useExtractProblemTagsSetsAsFilteredArray = (
	objectId: string,
	stage: ObjectStageIds
) => {
	const selector = useCallback(
		(data: IGetProblemTagsSetsResponse) =>
			extractProblemTagsSetsAsFilteredArray(data, objectId, stage),
		[objectId, stage]
	);
	return useProblemTagsSets(selector, !!objectId && !!stage);
};

export const useExtractProblemTagsSetById = (setId?: string) => {
	const selector = useCallback(
		(data: IGetProblemTagsSetsResponse) => extractProblemTagsSetById(data, setId),
		[setId]
	);
	return useProblemTagsSets(selector, !!setId);
};
