import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {useMemo} from 'react';

export const usePerformersDetails = (performers?: string[], performersActiveGroup?: string) => {
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();

	const detailPerformers = useMemo(() => {
		if (!usersMap || !performers) return [];
		return performers.map(userId => usersMap[userId]);
	}, [usersMap, performers]);

	const activeGroup =
		groupsMap && performersActiveGroup ? groupsMap[performersActiveGroup] : undefined;

	const performersCount = Math.max(0, (performers?.length || 0) - 1);

	return {
		detailPerformers,
		activeGroup,
		performersCount
	};
};
