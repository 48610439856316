import {useCallback, useState} from 'react';
import './EditableInternalAcceptanceAttachmentsDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useEditableInternalAcceptanceAttachments} from '../EditableInternalAcceptanceAttachments/hooks/useEditableInternalAcceptanceAttachments';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useEditInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useEditInternalAcceptance';

interface IEditableInternalAcceptanceAttachmentsDialogProps {
	objectId: string;
	internalAcceptanceId: string;
	internalAcceptance: IInternalAcceptance;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений внутренней приемки
 */
export const EditableInternalAcceptanceAttachmentsDialog = ({
	objectId,
	internalAcceptanceId,
	internalAcceptance,
	isOpen,
	onClose
}: IEditableInternalAcceptanceAttachmentsDialogProps) => {
	const {t} = useTranslation();
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const {mutateAsync: editInternalAcceptance} = useEditInternalAcceptance();
	const [saving, setSaving] = useState(false);

	const [fields, getSavingData, reset, isBlocking] = useEditableInternalAcceptanceAttachments(
		objectId,
		internalAcceptance,
		saving
	);
	const save = useCallback(async () => {
		setSaving(true);
		if (internalAcceptanceId) {
			const savingData = await getSavingData();
			if (savingData) {
				if (!networkStatus) {
					// Локальное сохранение
					// const savingData = convertToSave(editingState, problem, true);
					// savingData.newAttachments = convertToLocalSave(files);
					// TODO Локальное сохранение
				} else {
					try {
						await editInternalAcceptance({
							objectId,
							internalAcceptanceId,
							fields: savingData
						});
						onClose();
					} catch (error) {
						console.log(error);
						// TODO Локальное сохранение
					}
				}
			}
		}
		setSaving(false);
	}, [networkStatus, objectId, internalAcceptanceId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	return (
		<Dialog
			className="editable-internal-acceptance-attachments-dialog"
			isOpen={isOpen}
			title={t(
				'internalAcceptancePage.info.editableInternalAcceptanceAttachmentsDialog.label'
			)}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
