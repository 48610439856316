import React from 'react';
import {Button} from '@tehzor/ui-components';

interface SpaceChoosingToolbarTableProps {
	isVisible: boolean | undefined;
	disabled: boolean;
	selectedCount: number;
	openStageSelectionDialog: () => void;
	restrictedAddProblemsAfterWarrantyExpiration?: boolean;
}

export const SpaceChoosingToolbarTable: React.FC<SpaceChoosingToolbarTableProps> = ({
	isVisible,
	disabled,
	selectedCount,
	openStageSelectionDialog,
	restrictedAddProblemsAfterWarrantyExpiration
}) => {
	if (!isVisible) return null;
	return (
		<>
			<div>{selectedCount}</div>
			{!restrictedAddProblemsAfterWarrantyExpiration && (
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20" />}
					disabled={disabled}
					label="Добавить"
					onClick={openStageSelectionDialog}
				/>
			)}
		</>
	);
};
