import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {useQuery} from '@tanstack/react-query';
import {structuresQueryKeys} from '../../../../../Structures/model/cache/keys/index';

export const useStructureProblems = <T = IGetListProblemsResponse>(
	objectId: string,
	structureId: string,
	select?: (data: IGetListProblemsResponse) => T[]
) =>
	useQuery({
		queryKey: structuresQueryKeys.problemsById(objectId, structureId),
		meta: {
			error: 'при загрузке нарушений'
		},
		select,
		enabled: !!structureId && !!objectId
	});


