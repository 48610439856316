import {StatusCell} from '@src/pages/OwnerAcceptancesPage/components/table/cells/StatusCell';
import {IPreparedOwnerAcceptance} from '@src/pages/OwnerAcceptancesPage/interfaces/IPreparedOwnerAcceptance';
import {TFunction} from "i18next";
import {Column} from 'react-table';
import {CreatedCell} from '../cells/CreatedCell';
import {LocationCell} from '../cells/LocationCell';
import {MenuCell} from '../cells/MenuCell';
import {ModifiedCell} from '../cells/ModifiedCell';
import {NumberCell} from '../cells/NumberCell';
import {OwnerCell} from '../cells/OwnerCell';

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IPreparedOwnerAcceptance>> => (
	[
		{
			Header: '№',
			Cell: NumberCell,
			width: 60
		},
		{
			id: 'object',
			Header: tFunc('ownerAcceptancesPage.table.columns.object.header'),
			accessor: row => row.object?.name,
			width: 130,
			defaultCanSort: false
		},
		{
			id: 'location',
			Header: tFunc('ownerAcceptancesPage.table.columns.location.header'),
			Cell: LocationCell,
			width: 100,
			disableSortBy: true,
			defaultCanSort: false
		},
		{
			id: 'status',
			Header: tFunc('ownerAcceptancesPage.table.columns.status.header'),
			Cell: StatusCell,
			width: 100,
			disableSortBy: true,
			defaultCanSort: false
		},
		{
			id: 'resolution',
			Header: tFunc('ownerAcceptancesPage.table.columns.resolution.header'),
			accessor: row => (row?.resolution),
			width: 100,
			disableSortBy: true,
			defaultCanSort: false
		},
		{
			id: 'spaceOwner',
			Header: tFunc('ownerAcceptancesPage.table.columns.spaceOwner.header'),
			accessor: row => row.spaceOwner?.name,
			Cell: OwnerCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: 'createdAt',
			Header: tFunc('ownerAcceptancesPage.table.columns.createdAt.header'),
			accessor: row => row.createdAt,
			Cell: CreatedCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: 'modifiedAt',
			Header: tFunc('ownerAcceptancesPage.table.columns.modifiedAt.header'),
			accessor: row => row.modifiedAt,
			Cell: ModifiedCell,
			width: 100,
			sortDescFirst: true
		},
		{
			id: '_menu',
			Cell: MenuCell,
			className: 'owner-acceptances-page__d-table-menu',
			width: 'auto',
			defaultCanSort: false,
			disableResizing: true
		}
	]
)

export const getDesktopColumnsWithoutObject = (tFunc: TFunction) => getDesktopColumns(tFunc).filter(item => item.id !== 'object');
