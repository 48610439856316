import {useMutation} from '@tanstack/react-query';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {
	IAddWarrantyClaimsResponse,
	makeWarrantyClaimsAddRequest
} from '@src/api/backend/warrantyClaims';

export interface IAddMultipleWarrantyClaimsParams {
	objectId: string;
	spaceIds: string[];
	links: IWarrantyClaim['links'];
	savingData: ISavingWarrantyClaim;
}
/**
 * Хук для добавления нескольких гарантийных обращений
 */
export const useAddMultipleWarrantyClaims = () =>
	useMutation<IAddWarrantyClaimsResponse, Error, IAddMultipleWarrantyClaimsParams>({
		mutationFn: async ({objectId, spaceIds, links, savingData}) =>
			makeWarrantyClaimsAddRequest(objectId, spaceIds, links, savingData),
		onError: error => {
			console.error('Ошибка при создании гарантийных обращений:', error);
		}
	});
