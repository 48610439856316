import {IInternalAcceptancesStatsDataRequest} from '@src/interfaces/IInternalAcceptancesStatsData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';

export type IGetInternalAcceptanceProblemsResponse = IEntityProblemsData;

/**
 * Возвращает статистику нарушений по внутренним приемкам
 */
export const requestInternalAcceptancesProblemsData = async (
	requestData: IInternalAcceptancesStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetInternalAcceptanceProblemsResponse>(
		'internal-acceptances/get-problems-data',
		requestData
	);
	return response.data;
};
