import {useMemo, useState, useCallback} from 'react';
import './ProblemsSpace.less';
import classNames from 'classnames';
import {Indicators} from '../Indicators/Indicators';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import {SpaceCircle} from './components/SpaceCircle';
import {SpaceRectangle} from './components/SpaceRectangle';
import {HoverPopup} from '../../containers';
import {LegendGroup} from '../../LegendPopup';
import {ILegendItem} from '../../LegendPopup/interfaces';
import {InlineButton} from '../../buttons';
import {Checkbox} from '../../inputs';
import {useIsTablet} from '../../../utils/mediaQueries';

const MAX_VISIBLE_INDICATORS = 9;

const getPropsByType = (type: SpaceTypeId) => {
	switch (type) {
		case SpaceTypeId.COMMERCIAL_SPACES:
		case SpaceTypeId.UNDERGROUND_PARKING:
		case SpaceTypeId.GROUND_PARKING:
		case SpaceTypeId.STOREROOMS:
			return {
				width: 74 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.COMMON_AREAS:
		case SpaceTypeId.TECHNICAL_SPACES:
		case SpaceTypeId.TECHNICAL_UNDERGROUND:
		case SpaceTypeId.SYSTEMS:
		case SpaceTypeId.REST:
			return {
				width: 298 + 12 + 12,
				height: 46 + 12 + 12,
				radius: 20
			};
		case SpaceTypeId.APARTMENTS:
		case SpaceTypeId.FLATS:
		default:
			return {
				width: 52 + 10 + 10,
				height: 52 + 10 + 10,
				radius: 50
			};
	}
};

const declaredTypes = Object.values(SpaceTypeId);

export const ProblemsSpace = ({
	className,
	disabled,
	space,
	choosenSpaces,
	chooseSpacesVisible,
	altNamesVisible,
	spaceIndicatorsPopupContentTitle,
	spaceIndicatorsPopupContentHide,
	onClick
}: ISpaceComponent) => {
	const [expanded, setExpanded] = useState(false);
	const isTablet = useIsTablet();
	const classes = classNames(className, 'problems-space', `problems-space_${space.type}`, {
		'problems-space_disabled': disabled,
		'problems-space_default': !declaredTypes.includes(space.type),
		[`problems-space_${space.type}--checked`]:
			!disabled && chooseSpacesVisible && choosenSpaces?.includes(space.id)
	});

	const props = getPropsByType(space.type);

	const name = useMemo(
		() => (altNamesVisible ? space.altName ?? '' : space.name),
		[space.altName, space.name, altNamesVisible]
	);

	const SpaceComponent =
		space.type === SpaceTypeId.APARTMENTS ||
		space.type === SpaceTypeId.FLATS ||
		space.type === SpaceTypeId.HOUSES ||
		space.type === SpaceTypeId.LAND_LOTS ? (
			<>
				{chooseSpacesVisible && !disabled && (
					<div className="problems-space__space-checkbox">
						<Checkbox
							checked={choosenSpaces?.includes(space.id)}
							onClick={() => onClick?.(space)}
						/>
					</div>
				)}
				<SpaceCircle
					key={space.id}
					{...props}
					space={space}
					name={name}
					onClick={onClick}
				/>
			</>
		) : (
			<>
				{chooseSpacesVisible && !disabled && (
					<div className="problems-space__space-checkbox">
						<Checkbox
							checked={choosenSpaces?.includes(space.id)}
							onClick={() => onClick?.(space)}
						/>
					</div>
				)}
				<SpaceRectangle
					key={space.id}
					{...props}
					space={space}
					name={name}
					onClick={onClick}
				/>
			</>
		);

	const legendItems = useMemo<ILegendItem[]>(
		() =>
			space.indicators?.map(indicator => ({
				id: indicator.id,
				title: indicator.name,
				icon: (
					<div
						className="space-indicator-tag__icon"
						style={{backgroundColor: indicator.color}}
					/>
				)
			})) ?? [],
		[space.indicators]
	);

	const toggleExpanded = useCallback(() => {
		setExpanded(prev => !prev);
	}, []);

	const visibleLegendItems = expanded
		? legendItems
		: legendItems.slice(0, MAX_VISIBLE_INDICATORS);

	return (
		<div className={classes}>
			{space && space.indicators && isTablet && !chooseSpacesVisible ? (
				<HoverPopup
					className={{
						root: 'problems-space__popup--root-class'
					}}
					placement="right-start"
					trigger={({ref}, hoverProps) =>
						space.indicators && space.indicators.length > 0 && !chooseSpacesVisible ? (
							<div
								ref={ref}
								{...hoverProps}
							>
								{SpaceComponent}
							</div>
						) : (
							<div>{SpaceComponent}</div>
						)
					}
					arrowVisible
				>
					<div>
						<LegendGroup
							title={spaceIndicatorsPopupContentTitle}
							items={visibleLegendItems}
						/>
						{legendItems.length > MAX_VISIBLE_INDICATORS && (
							<InlineButton
								type="accent"
								label={
									expanded
										? spaceIndicatorsPopupContentHide
										: `+${legendItems.length - MAX_VISIBLE_INDICATORS}`
								}
								onClick={toggleExpanded}
							/>
						)}
					</div>
				</HoverPopup>
			) : (
				SpaceComponent
			)}
			<div className="space-indicators__wrapper">
				{space.indicators?.length ? <Indicators indicators={space.indicators} /> : null}
			</div>
		</div>
	);
};
