import {
    ActionButtons, addSuccessToast,
    Button,
    EditableFieldLabel,
    ModalDialog,
    TextField
} from '@tehzor/ui-components';
import styles from './AddFiltersPatternMobile.module.less';
import {useEffect, useState} from 'react';
import {checkFiltersIsEmpty} from '@src/components/FiltersPatterns/utils/checkFiltersIsEmpty';

interface IAddFiltersPatternMobileProps {
    isStateChanged: boolean;
    filters: Record<string, unknown>;
    onAddPattern: (name: string, filters: Record<string, unknown>) => void;
}

export const AddFiltersPatternMobile = ({
                                            filters,
                                            onAddPattern,
                                            isStateChanged
                                        }: IAddFiltersPatternMobileProps) => {
    const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);
    const [newPatternName, setNewPatternName] = useState('');
    const [patternReadyToSave, setPatternReadyToSave] = useState(false);

    useEffect(() => {
        if (isStateChanged && !checkFiltersIsEmpty(filters)) {
            setPatternReadyToSave(true);
        } else {
            setPatternReadyToSave(false);
        }
    }, [isStateChanged, filters]);

    const handleOpenDialog = () => {
        setAddDialogIsOpen(true);
    };

    const handleCloseDialog = () => {
        setNewPatternName('');
        setAddDialogIsOpen(false);
    };

    const handleConfirm = () => {
        if (!newPatternName) return;
        onAddPattern(newPatternName, filters);
        setAddDialogIsOpen(false);
        setPatternReadyToSave(false);
        setNewPatternName('');
        addSuccessToast({title: 'Шаблон успешно добавлен'});
    };

    return (
        <div className={styles.wrapper}>
            <Button
                type="bordered"
                label="Создать новый шаблон"
                className={{root: styles.button}}
                disabled={!patternReadyToSave}
                onClick={handleOpenDialog}
            />
            <ModalDialog
                className={{root: styles.dialog}}
                open={addDialogIsOpen}
                title="Создать новый шаблон:"
                footer={
                    <ActionButtons>
                        <Button
                            type="cancel"
                            label="Отменить"
                            onClick={handleCloseDialog}
                        />
                        <Button
                            type="accent-blue"
                            label="Сохранить"
                            onClick={handleConfirm}
                        />
                    </ActionButtons>
                }
            >
                <EditableFieldLabel>Название шаблона:</EditableFieldLabel>
                <TextField
                    value={newPatternName}
                    onChange={setNewPatternName}
                    elementType="input"
                    placeholder="Введите название"
                />
            </ModalDialog>
        </div>
    );
};
