import {ISavingComment} from '@src/interfaces/saving/ISavingComment';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

export type IEditCommentResponse = IComment;

/**
 * Изменяет комментарий
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export async function makeCommentEditRequest(
	objectId: string,
	commentId: string,
	fields: ISavingComment
) {
	const response = await httpRequests.withToken.patch<IEditCommentResponse>(
		`comments/${objectId}/${commentId}`, fields
	);
	return response.data;
}
