import {Checkbox} from '@tehzor/ui-components';
import {useMemo, useCallback, useDeferredValue} from 'react';
import {
	extractSelectedSpacesForObjectIds,
	extractSpacesPageSettings
} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {selectAllVisibleSpaces} from '@src/store/modules/settings/pages/spaces/actions';
import {useSpacesGroupedByObjectsWithOneType} from '@src/core/hooks/queries/spaces/hooks';
import {useVisibleObjects} from '../../hooks/useVisibleObjects';
import {convertProblemsData} from '../../utils/convertProblems';
import {convertWorkAcceptancesData} from '../../utils/convertWorkAcceptances';
import {filterCheckLists, filterSpace} from '../../utils/filterSpaces';
import {convertCheckListsData} from '../../utils/convertCheckListsData.';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useExtractCheckItemsGroupedByLists} from '@src/core/hooks/queries/checkItems/hooks';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {groupFilteredSpacesByObjectId} from '../../utils/groupFilteredSpacesByObjectId';

interface IMobileSelectAllSpacesProps {
	objectId: string;
	targetObjects: string[];
}

const MobileSelectAllSpaces = ({objectId, targetObjects}: IMobileSelectAllSpacesProps) => {
	const dispatch = useAppDispatch();
	const {type, displayMode, currentObject} = useAppSelector(s =>
		extractSpacesPageSettings(s, objectId)
	);
	const pageSetting = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const pageSettings = useDeferredValue(pageSetting);

	const {data: object} = useObject(objectId);
	const {data: problemStatuses} = useProblemStatuses();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatuses();
	const {data: checkListsData} = useCheckLists();
	const {data: checkRecordStatuses} = useCheckRecordsStatuses();
	const translatedCheckRecordStatuses = useTranslatedDictionary(
		'checkRecordStatus',
		checkRecordStatuses
	);
	// Получение выбранных помещений
	const {selectedByObject, selectedByType} = useAppSelector(state =>
		extractSelectedSpacesForObjectIds(state, objectId, targetObjects || [])
	);

	const {data: checkItemsByLists} = useExtractCheckItemsGroupedByLists();
	const objects = useVisibleObjects(objectId, targetObjects);

	const currentIndex = useMemo(() => {
		if (currentObject) {
			const index = objects.indexOf(currentObject);
			return index === -1 ? 0 : index;
		}
		return 0;
	}, [currentObject, objects]);

	const allSelectableSpacesByObjectsAndTypes = useSpacesGroupedByObjectsWithOneType(
		[objects[currentIndex]],
		type
	) as unknown as Record<string, IEnrichedSpace[]>;

	// Общее количество помещений выбранного типа
	const allVisibleSpacesWithOneType = useMemo(
		() => Object.values(allSelectableSpacesByObjectsAndTypes).flat(),
		[allSelectableSpacesByObjectsAndTypes, displayMode]
	);

	const convertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			allVisibleSpacesWithOneType.map((space: IEnrichedSpace): IConvertedSpace => {
				const convertedSpace: IConvertedSpace = {
					...space,
					problems: undefined,
					checkLists: undefined,
					workAcceptances: undefined,
					sortBase: space.name
				};

				convertedSpace.problems = convertProblemsData(
					space.problems,
					problemStatuses,
					pageSettings.stage,
					pageSettings.processId
				);

				convertedSpace.workAcceptances = convertWorkAcceptancesData(
					space.workAcceptances,
					workAcceptanceStatuses
				);

				const filteredCheckLists = checkListsData
					? filterCheckLists(
							checkListsData,
							object?.companyId || '',
							space.objectId,
							space.type,
							pageSettings.stage,
							pageSettings.processId,
							pageSettings.filters.checkListIds,
							space.typeDecoration
					  )
					: [];
				convertedSpace.checkLists = convertCheckListsData(
					filteredCheckLists,
					checkItemsByLists,
					translatedCheckRecordStatuses,
					pageSettings.filters.checkListCategory,
					space.checkLists
				);

				return convertedSpace;
			}),

		[
			checkItemsByLists,
			checkListsData,
			translatedCheckRecordStatuses,
			workAcceptanceStatuses,
			object?.companyId,
			allVisibleSpacesWithOneType,
			pageSettings.filters.checkListIds,
			pageSettings.filters.checkListCategory,
			pageSettings.stage,
			pageSettings.processId,
			problemStatuses
		]
	);

	const filteredConvertedSpaces: IConvertedSpace[] | undefined = useMemo(
		() =>
			convertedSpaces?.map(space => {
				const filtered = filterSpace({
					space,
					spaceIndicators: space.indicators as unknown as string[],
					spaceStatus: undefined,
					spaceProblems: space.problems?.map(el => el.key) || [],
					filters: pageSettings.filters,
					checkLists: checkListsData,
					schemaView: undefined,
					spaceWorkAcceptances:
						space.workAcceptancesForSchema?.items?.map(el => el.status) || [],
					workAcceptanceProblems:
						space.workAcceptancesForSchema?.problems?.map(el => el.key) || [],
					workAcceptanceCategories:
						space.workAcceptancesForSchema?.items?.map(el => el.categoryId) || []
				});
				return {
					...space,
					filtered
				};
			}),
		[convertedSpaces, pageSettings.filters, checkListsData]
	);

	const filteredSpacesForChoosingAll = useMemo(
		() => groupFilteredSpacesByObjectId(filteredConvertedSpaces || []),
		[filteredConvertedSpaces]
	);

	// Обработчик выбора всех помещений
	const selectAll = useCallback(() => {
		dispatch(selectAllVisibleSpaces(objectId, filteredSpacesForChoosingAll));
	}, [dispatch, objectId, filteredSpacesForChoosingAll]);

	const selectedSpacesByObjectAndType: string[] = useMemo(() => {
		const spaceIds: string[] = (selectedByType[type as string] as string[]) ?? [];
		const selectedSpaceIds: string[] =
			(selectedByObject[objects[currentIndex]] as string[]) ?? [];
		const selectedSpaceSet: Set<string> = new Set<string>(selectedSpaceIds);
		return spaceIds.filter((id: string) => selectedSpaceSet.has(id));
	}, [selectedByType, type, selectedByObject, objects, currentIndex]);

	// Логика для чекбокса
	const [isChecked, isSemiChecked] = useMemo(() => {
		let allSelected = false;
		let partiallySelected = false;
		allSelected =
			allVisibleSpacesWithOneType.length === selectedSpacesByObjectAndType.length &&
			selectedSpacesByObjectAndType.length !== 0;
		partiallySelected =
			selectedSpacesByObjectAndType.length > 0 &&
			selectedSpacesByObjectAndType.length < allVisibleSpacesWithOneType.length &&
			selectedSpacesByObjectAndType.length !== 0;

		return [allSelected, partiallySelected];
	}, [selectedSpacesByObjectAndType.length, allVisibleSpacesWithOneType.length]);

	return (
		<div className="spaces-page__toolbar-mobile-right-side">
			<div>
				<span>Выбрать все</span>
			</div>
			<div className="spaces-page__toolbar-mobile-right-side-checkbox">
				<Checkbox
					checked={isChecked || isSemiChecked}
					semiChecked={isSemiChecked}
					onChange={selectAll}
				/>
			</div>
		</div>
	);
};

export default MobileSelectAllSpaces;
