import type {ITreeItem} from '../../interfaces/ITreeItem';
import {findNodeDepth} from './findNodeDepth';

type TreeItemWithLevel<T> = T & {level: number};

/**
 * @description Возвращает все открытые элементы в дереве, также добавляет уровни вложенности
 * @example getAllExpandedItems(tree, ['id1', 'id2'])
 * @param trees дерево
 * @param expandedIds идентификаторы открытых элементов
 */
export function getAllExpandedItems<T extends ITreeItem<T>>(trees: T[], expandedIds?: string[]) {
	const result: Array<TreeItemWithLevel<T>> = [];

	function traverse(node: T) {
		result.push({...node, level: findNodeDepth<T>(node.id, trees)});

		if (expandedIds?.includes(node.id)) {
			if (node.children) {
				node.children.forEach(traverse);
			}
		}
	}

	trees.forEach(traverse);

	return result;
}
