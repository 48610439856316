import {FiltersPattern} from '@src/components/FiltersPatterns/components/FiltersPattern/FiltersPattern';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';
import stiles from './FiltersPatternsList.module.less';

interface IFiltersPatternsListProps {
    patterns: IFiltersPattern[];
    activePatternId: number | undefined;
    onAccept: (pattern: IFiltersPattern) => void;
    onChange: (pattern: IFiltersPattern) => void;
    onDelete: (patternId: number) => void;
}

export const FiltersPatternsList = ({
                                        patterns,
                                        activePatternId,
                                        onAccept,
                                        onChange,
                                        onDelete
                                    }: IFiltersPatternsListProps) => (
    <div>
        <p className={stiles.title}>Сохраненные шаблоны фильтров:</p>
        <div className={stiles.list}>
            {patterns.map(pattern => (
                <FiltersPattern
                    pattern={pattern}
                    active={activePatternId === pattern.id}
                    onAccept={onAccept}
                    onChange={onChange}
                    onDelete={onDelete}
                    key={pattern.id}
                />
            ))}
        </div>
    </div>
);
