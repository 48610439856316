import {
	useInternalAcceptanceLocalProblemsEntities,
	useInternalAcceptanceProblemsEntities
} from './problems/hooks';
import {useInternalAcceptanceInspectionsEntities} from './inspections/hooks';
import {useMemo} from 'react';
import {IInternalAcceptanceEntity} from '@src/interfaces/IInternalAcceptanceEntity';
import {useQuery} from '@tanstack/react-query';
import {
	requestInternalAcceptance,
	IGetInternalAcceptanceResponse
} from '@src/api/backend/internalAcceptance';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

export const useInternalAcceptanceEntities = (objectId: string, checkId: string) => {
	const {data: problems} = useInternalAcceptanceProblemsEntities(objectId, checkId);
	const {data: localProblems} = useInternalAcceptanceLocalProblemsEntities(objectId, checkId);
	const {data: inspections} = useInternalAcceptanceInspectionsEntities(objectId, checkId);

	return useMemo(
		() =>
			[...(localProblems || []), ...(problems || []), ...(inspections || [])].sort(
				(a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0)
			) as IInternalAcceptanceEntity[],
		[problems, inspections, localProblems]
	);
};

/**
 * Хук для извлечения данных
 */
export const useInternalAcceptance = <T = IGetInternalAcceptanceResponse>(
	objectId: string,
	internalAcceptanceId?: string,
	select?: (data: IGetInternalAcceptanceResponse) => T
) =>
	useQuery({
		queryKey: internalAcceptanceId
			? [...internalAcceptancesQueryKeys.detail(internalAcceptanceId), objectId]
			: [],
		queryFn: () => {
			if (!internalAcceptanceId) {
				return undefined;
			}
			return requestInternalAcceptance(objectId, internalAcceptanceId);
		},
		enabled: !!internalAcceptanceId,
		meta: {
			error: 'при загрузке приёмки'
		},
		select
	});
