export const createTreeFilterWorker = <T extends {id: string; parentId?: string}>(
	treeData: T[],
	field: string,
	search: string
): Promise<{filteredData: T[]; expanded: T[]}> =>
	new Promise((resolve, reject) => {
		// @ts-ignore
		const worker = new Worker(new URL('./treeFilterWorker.ts', import.meta.url), {
			type: 'module'
		});

		worker.onmessage = (event: MessageEvent<{filteredData: T[]; expanded: T[]}>) => {
			resolve(event.data);
			worker.terminate();
		};

		worker.onerror = error => {
			reject(error);
			worker.terminate();
		};

		// Передача данных воркеру
		worker.postMessage({treeData, field, search});
	});
