import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {
	CategoriesSetFilterMobile,
	CreatedByFilterMobile,
	CreationDateFilterMobile,
	ObjectsFilterMobile,
	ModifiedDateFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {AcceptorsFilterMobile} from '@src/components/MobileEntitiesFilters/components/AcceptorsFilterMobile';
import {SubmittersFilterMobile} from '@src/components/MobileEntitiesFilters/components/SubmittersFilterMobile';
import {ExpiredFilterMobile} from '@src/components/MobileEntitiesFilters/components/ExpiredFilterMobile';
import {NestedObjectsFilterMobile} from '@src/components/MobileEntitiesFilters/components/NestedObjectsFilterMobile';
import {TypesFilterMobile} from '@src/components/MobileEntitiesFilters/components/TypesFilterMobile';
import {WorkAcceptanceStatusesFilterMobile} from '@src/components/MobileEntitiesFilters/components/WorkAcceptanceStatusesFilterMobile';
import {WorkAcceptancesAcceptDateFilterMobile} from '@src/components/MobileEntitiesFilters/components/WorkAcceptancesAcceptDateFilterMobile';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {useExtractWorkAcceptanceFrontTypesAsArray} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useExtractWorkAcceptanceTypesAsArray} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {expiredFilterOptions} from '@src/pages/WorkAcceptancesPage/components/filters/constants/expiredFilterOptions';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

export const WorkAcceptancesFiltersPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const location = useLocation();
	const {canGoBack} = location.state as {canGoBack: boolean};
	const {state, isStateChanged, change, mobileClear, apply} =
		useEntitiesFiltersCtx<IWorkAcceptancesFiltersState>();
	const nestedObjects = useObjectChildrenIds(objectId);

	const handleApply = useCallback(() => {
		if (canGoBack) {
			apply();
			goBack();
		}
	}, [location, apply]);

	useAppHeader(
		{
			title: t('filtersPage.title'),
			showBackBtn: true,
			mobileRightButtons: isCleanable<IWorkAcceptancesFiltersState>(
				state,
				objectId,
				undefined,
				!!nestedObjects?.length
			) ? (
				<LinkButton
					label={t('filterPage.resetFiltersBtn.label')}
					onClick={mobileClear}
				/>
			) : null
		},
		[state, objectId, nestedObjects]
	);

	useRouting(!canGoBack, objectId, entity);

	const {data: frontTypes} = useExtractWorkAcceptanceFrontTypesAsArray();
	const {data: types} = useExtractWorkAcceptanceTypesAsArray();

	return (
		<TranslatedFilterPage
			applyDisabled={!isStateChanged}
			onApplyClick={handleApply}
		>
			{objectId === undefined && (
				<ObjectsFilterMobile
					entity={entity}
					value={state?.objects}
				/>
			)}
			{!!nestedObjects?.length ? (
				<NestedObjectsFilterMobile
					entity={entity}
					value={state?.objects}
					pageObjectId={objectId}
				/>
			) : null}

			<WorkAcceptanceStatusesFilterMobile
				objectId={objectId}
				value={state?.statuses}
				onChange={change}
				entity={entity || ''}
				filterName="statuses"
			/>

			{types && (
				<TypesFilterMobile
					objectId={objectId}
					value={state?.types}
					onChange={change}
					entity={entity || ''}
					filterName="types"
					types={types}
					tKey={dictionaryKeys.workAcceptanceTypes}
				/>
			)}

			{frontTypes && (
				<TypesFilterMobile
					objectId={objectId}
					value={state?.frontTypes}
					onChange={change}
					entity={entity || ''}
					filterName="frontTypes"
					types={frontTypes}
					label={t('entitiesFilters.statusesFilter.workAcceptanceFrontTypes.label')}
					tKey={dictionaryKeys.workAcceptanceFrontTypes}
				/>
			)}

			<WorkAcceptancesAcceptDateFilterMobile
				objectId={objectId}
				from={state.acceptanceDateFrom}
				to={state.acceptanceDateTo}
				entity={entity || ''}
			/>

			<ExpiredFilterMobile
				value={state.expired}
				onChange={change}
				customExpiredOptions={expiredFilterOptions}
			/>

			<AcceptorsFilterMobile
				objectId={objectId ?? 'all'}
				label={t('entitiesFilters.acceptors.label')}
				value={state?.acceptors}
				entity={entity}
				onChange={change}
			/>

			<SubmittersFilterMobile
				objectId={objectId ?? 'all'}
				label={t('entitiesFilters.submitters.label')}
				value={state?.submitters}
				entity={entity}
				onChange={change}
			/>

			<CategoriesSetFilterMobile
				objectsIds={objectId ? [objectId] : undefined}
				currentObject={objectId}
				value={state?.categories}
				entity={entity}
				onChange={change}
			/>

			<CreatedByFilterMobile
				objectId={objectId}
				value={state?.createdBy}
				entity={entity}
				onChange={change}
			/>

			<CreationDateFilterMobile
				objectId={objectId}
				from={state.createdAtFrom}
				to={state.createdAtTo}
				entity={entity || ''}
			/>

			<ModifiedDateFilterMobile
				objectId={objectId}
				from={state.modifiedAtFrom}
				to={state.modifiedAtTo}
				entity={entity || ''}
			/>
		</TranslatedFilterPage>
	);
};
