import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, TreeSelect, TreeSelectTagOption} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {makeTreeData} from '@src/utils/makeTreeData';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractProblemTagsAsArray} from '@src/core/hooks/queries/problemTags/hooks';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {useFilteredProblemTagsSets} from '@src/core/hooks/queries/problemTagsSets/hooks';
import {useNoOptionsState} from '@src/core/hooks/filters/useNoOptionsState';

const ProblemTagsSetFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId = 'all', entity} = useStrictParams<{objectId?: string; entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{
		problemTags?: string[];
		stages?: string[];
		objects?: string[];
	}>();
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const {
		selectedOptions: selected,
		setSelectedOptions: setSelected,
		noOptions: noTags,
		toggleNoOptions: toggleNoTags
	} = useNoOptionsState(state.problemTags);

	const {data: allTags} = useExtractProblemTagsAsArray();
	const {data: tagsSets} = useFilteredProblemTagsSets(
		objectId === 'all' ? state.objects : [objectId],
		state.stages
	);

	const treeData = useMemo(() => makeTreeData(tagsSets, allTags), [tagsSets, allTags]);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({problemTags: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: t('problemsPage.problemTagsSetFilter.label'),
			showBackBtn: true,
			mobileRightButtons:
				selected?.length || selected === null ? (
					<LinkButton
						label={t('entitiesFilters.cancelButton.label')}
						onClick={handleClear}
					/>
				) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.problemTags, selected)}
			onApplyClick={handleApply}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
			noOptions
			noOptionsOnChange={toggleNoTags}
			noOptionsChecked={noTags}
			noOptionsContent={t('problemsPage.filters.noTags')}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
				TreeItem={TreeSelectTagOption}
				isCheckable
			/>
		</TranslatedFilterPage>
	);
};

export default ProblemTagsSetFilterPage;
