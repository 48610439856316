import {
    ActionButtons,
    Button,
    EditableFieldLabel,
    ModalDialog,
    TextField
} from '@tehzor/ui-components';
import {useEffect, useMemo, useState} from 'react';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';
import styles from './ChangePatternDialog.module.less'

interface IChangePatternDialogProps {
    patternToChange: IFiltersPattern | undefined;
    onChange: (id: number, name: string) => void;
    onClose: () => void;
}

export const ChangePatternDialog = ({
                                        patternToChange,
                                        onChange,
                                        onClose
                                    }: IChangePatternDialogProps) => {
    const isOpen = useMemo(() => !!patternToChange, [patternToChange]);
    const [newPatternName, setNewPatternName] = useState<string>(patternToChange?.name || '');

    useEffect(() => {
        if (!patternToChange) return;
        setNewPatternName(patternToChange.name);
    }, [patternToChange]);

    const handleConfirm = () => {
        if (!patternToChange || !newPatternName) return;
        onChange(patternToChange.id, newPatternName);
        onClose();
    };

    return (
        <ModalDialog
            className={{root: styles.dialog}}
            open={isOpen}
            title="Редактировать шаблон:"
            footer={
                <ActionButtons>
                    <Button
                        type="cancel"
                        label="Отменить"
                        onClick={onClose}
                    />
                    <Button
                        type="accent-blue"
                        label="Сохранить"
                        onClick={handleConfirm}
                    />
                </ActionButtons>
            }
        >
            <EditableFieldLabel>Новое название шаблона:</EditableFieldLabel>
            <TextField
                value={newPatternName}
                onChange={setNewPatternName}
                elementType="input"
            />
        </ModalDialog>
    );
};
