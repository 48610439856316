import StatusesFilterPage from '../StatusesFilterPage';
import {useObjectsCitiesAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

const CitiesFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const cities = useObjectsCitiesAsArray();

	if (!cities) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={cities}
			label={t('objectsPage.filters.city')}
			filterName="cities"
		/>
	);
};

export default CitiesFilterPage;
