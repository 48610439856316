import {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';
import AutoSizer, {Size} from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import {VariableSizeList} from 'react-window';
import SelectOption from '../SelectOption';

interface LoadableOptionsListProps {
	items: Array<{id: string, content: string}>;
	total: number;
	handleLoad: () => void;
	maxListHeight: number;
	rowHeight: number;
	optionType?: 'radio' | 'checkbox';
	isAdvanced?: boolean;
}

interface LoadableOptionsListItemProps {
	index: number;
	style: CSSProperties;
	items: Array<{id: string, content: string}>;
	type?: 'radio' | 'checkbox';
	isAdvanced?: boolean;
}

const Option = ({index, style, items, type = 'radio', isAdvanced = false}: LoadableOptionsListItemProps) => (
	<div style={style}>
		{isAdvanced
			? (
				<SelectOption
					key={items[index].id}
					itemKey={items[index]}
					content={items[index].content}
					inputType={type}
				/>
			)
			: (
				<SelectOption
					key={items[index].id}
					itemKey={items[index].id}
					content={items[index].content}
					inputType={type}
				/>
			)}
	</div>
);

const LoadableOptionsList = ({
	items,
	handleLoad,
	total,
	maxListHeight,
	rowHeight,
	optionType = 'radio',
	isAdvanced = false
}: LoadableOptionsListProps) => {
	const [startIndexCache, setIndexCache] = useState<number[]>([]);
	const listRef = useRef<VariableSizeList | null>(null);
	const getSize = useCallback(() => rowHeight, []);
	const isItemLoaded = (index: number) => items.length === total || !!items[index];
	const loadMoreItems = (startIndex: number) => {
		if (startIndexCache.includes(startIndex)) return;
		setIndexCache([...startIndexCache, startIndex]);
		handleLoad();
	};

	useEffect(() => {
		setIndexCache([]);
	}, [total]);

	const listHeight = useCallback(() => {
		if (items.length < 8) {
			return items.length * Number(getSize());
		}
		return maxListHeight;
	}, [items]);

	return (
		<InfiniteLoader
			loadMoreItems={loadMoreItems}
			isItemLoaded={isItemLoaded}
			itemCount={total}
		>
			{({onItemsRendered, ref}) => (
				<AutoSizer
					ref={ref}
					disableHeight
				>
					{({width}: Size) => (
						<VariableSizeList
							ref={listRef}
							onItemsRendered={onItemsRendered}
							width={width}
							height={listHeight()}
							itemCount={items.length}
							itemSize={getSize}
						>
							{({...props}) => (
								<Option
									{...props}
									items={items}
									type={optionType}
									isAdvanced={isAdvanced}
								/>
							)}
						</VariableSizeList>
					)}
				</AutoSizer>
			)}
		</InfiniteLoader>
	);
};

LoadableOptionsList.displayName = 'LoadableOptionsList';

export default LoadableOptionsList;
