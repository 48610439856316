import {memo} from 'react';
import classNames from 'classnames';
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {IConvertedWorkAcceptanceSpaceData} from '@tehzor/tools/interfaces/spaces/IConvertedWorkAcceptancesData';
import {defaultItem} from './const';
import styles from  './WorkAcceptancesDiagram.module.less';

interface IWorkAcceptancesDiagramProps {
	workAcceptances: IConvertedWorkAcceptanceSpaceData | undefined;
	disabled?: boolean;
	size?: number;
	children?: JSX.Element;
	onClick?: () => void;
}

export const WorkAcceptancesDiagram = memo(
	({
		workAcceptances,
		children,
		disabled = false,
		size = 56,
		onClick
	}: IWorkAcceptancesDiagramProps) => {
		const segments = workAcceptances?.items
			? workAcceptances.items.map(item => ({...item, value: 1}))
			: [];
		const problems = workAcceptances?.problems || [];

		const hideInnerStroke = segments.length <= 1;
		const outerRadius = size / 2;

		return (
			<div className={classNames(styles.wrapper, disabled && styles.wrapperDisabled)}>
				<ResponsiveContainer>
					<PieChart style={{cursor: disabled ? 'initial' : 'pointer'}}>
						<circle
							cx="50%"
							cy="50%"
							r={hideInnerStroke ? outerRadius : outerRadius - 1}
							fill="white"
							className={styles.shadow}
						/>
						<Pie
							data={segments.length ? segments : [defaultItem]}
							dataKey="value"
							outerRadius={outerRadius}
							legendType="none"
							blendStroke={hideInnerStroke}
							startAngle={-270}
							isAnimationActive={false}
							onClick={onClick}
						>
							{segments.length ? (
								segments.map(segment => (
									<Cell
										fill={segment.color}
										className={styles.innerCell}
									/>
								))
							) : (
								<Cell fill={defaultItem.color}/>
							)}
						</Pie>
						{problems && problems.length && (
							<Pie
								data={problems}
								dataKey="value"
								outerRadius={outerRadius}
								innerRadius={outerRadius - 3}
								legendType="none"
								blendStroke
								startAngle={-270}
								isAnimationActive={false}
							>
								{problems.map(problem => (
									<Cell
										key={problem.key}
										fill={problem.color}
									/>
								))}
							</Pie>
						)}
						{children}
					</PieChart>
				</ResponsiveContainer>
			</div>
		);
	}
);
