import {IProblem} from '../../../../interfaces/problems/IProblem';
import {InvalidRestrictionError} from '../../../../errors/InvalidRestrictionError';
import {ProblemStatusId} from '../../../../interfaces/problems/IProblemStatus';
import {IWorkingGroup} from '../../../../interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '../../../../interfaces/users/IBriefUser';

type ProblemModified = Omit<IProblem, 'createdBy' | 'modifiedBy'> & {
	createdBy: IBriefUser;
	modifiedBy: IBriefUser;
};

export function problem_problemsCreatedByUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: ProblemModified}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.createdBy) {
		return userId === problem.createdBy.id;
	}
	return false;
}

export function problem_problemsHavePerformerUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.performers) {
		return problem.performers.includes(userId);
	}
	return false;
}

export function problem_problemsHaveInspectorUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.inspectors) {
		return problem.inspectors.includes(userId);
	}
	return false;
}

export function problem_problemsHaveWatcherUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.watchers) {
		return problem.watchers.includes(userId);
	}
	return false;
}

export function problem_availableStatuses(
	restriction: unknown,
	{status}: {status?: ProblemStatusId}
) {
	if (!Array.isArray(restriction) || !status) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return restriction.includes(status);
	}
	return false;
}

problem_availableStatuses.logicalOperator = 'and';

export function problem_problemsHaveLeaderInPerformersActiveGroup(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return problem.performersActiveGroupLeader === userId;
	}
	return false;
}

export function problem_problemsHaveLeaderInInspectorsActiveGroup(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction) {
		return problem.inspectorsActiveGroupLeader === userId;
	}
	return false;
}

/**
 * Только пользователи и группы одного текущего уровня могут быть выбраны ответственными
 *
 * @param restriction
 * @param problem
 * @param workingGroup
 * @param performers
 * @param activeGroup
 */
export function problem_onlyPerformersActiveGroup(
	restriction: unknown,
	{
		problem,
		workingGroup,
		performers,
		activeGroup
	}: {
		problem?: IProblem;
		workingGroup?: IWorkingGroup;
		performers?: string[];
		activeGroup?: IWorkingGroup;
	}
) {
	if (typeof restriction !== 'boolean' || !problem) {
		throw new InvalidRestrictionError();
	}
	if (!restriction) {
		return false;
	}
	// Если уже имеется предыдущая активная рабочая группа
	if (workingGroup) {
		if (performers?.length) {
			return performers.every(id => workingGroup.performers?.includes(id));
		}
		if (activeGroup) {
			return activeGroup.parentId === workingGroup.id;
		}
		// Если была уже установлена группа и пришли данные о пустых новых ответственных,
		// то разрешаем такое действие по сбросу ответственных
		return true;
	}
	// Если не были ранее выбраны ответственные, то должна быть возможность выбрать только из групп самого верхнего,
	// доступного уровня, то есть у кого нет родительских групп.
	// Для такой проверки необходимо всё дерево групп для конкретной ситуации, так как могут быть группы с parentId
	// и в то же время являться верхнеуровневой при определенных параметрах.
	// Поэтому пропускаем за неимением такой проверки.
	return true;
}

export function problem_onlyInspectorsActiveGroup(
	restriction: unknown,
	{
		problem,
		workingGroup,
		inspectors,
		activeGroup
	}: {
		problem?: IProblem;
		workingGroup?: IWorkingGroup;
		inspectors?: string[];
		activeGroup?: IWorkingGroup;
	}
) {
	if (typeof restriction !== 'boolean' || !problem) {
		throw new InvalidRestrictionError();
	}
	if (!restriction) {
		return false;
	}
	// Если уже имеется предыдущая активная рабочая группа
	if (workingGroup) {
		if (inspectors?.length) {
			return inspectors.every(id => workingGroup.performers?.includes(id));
		}
		if (activeGroup) {
			return activeGroup.parentId === workingGroup.id;
		}
		// Если была уже установлена группа и пришли данные о пустых новых ответственных,
		// то разрешаем такое действие по сбросу ответственных
		return true;
	}
	// Если не были ранее выбраны ответственные, то должна быть возможность выбрать только из групп самого верхнего,
	// доступного уровня, то есть у кого нет родительских групп.
	// Для такой проверки необходимо всё дерево групп для конкретной ситуации, так как могут быть группы с parentId
	// и в то же время являться верхнеуровневой при определенных параметрах.
	// Поэтому пропускаем за неимением такой проверки.
	return true;
}

problem_onlyPerformersActiveGroup.logicalOperator = 'and';
problem_onlyInspectorsActiveGroup.logicalOperator = 'and';

export function problem_problemsHaveResponsibleUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}
	if (restriction && problem.performers) {
		return problem.performers.includes(userId);
	}
	return false;
}

export function problem_haveParticipantUser(
	restriction: unknown,
	{userId, problem}: {userId?: string; problem?: IProblem}
) {
	if (typeof restriction !== 'boolean' || !userId || !problem) {
		throw new InvalidRestrictionError();
	}

	if (!restriction) {
		return false;
	}

	const isUserPerformer = problem.performers?.includes(userId);
	const isUserInspector = problem.inspectors?.includes(userId);
	const isUserWatcher = problem.watchers?.includes(userId);
	const isUserCreatedBy = problem.createdBy === userId;

	return Boolean(isUserInspector || isUserPerformer || isUserWatcher || isUserCreatedBy);
}
