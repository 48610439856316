import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingComment} from '@src/interfaces/saving/ISavingComment';
import {IEditCommentResponse, makeCommentEditRequest} from '@src/api/backend/comment';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IEditProblemCommentPayload = IEditCommentResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCommentResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editProblemComment.error.title')});
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет комментарий нарушения
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editProblemComment = (
	objectId: string,
	commentId: string,
	fields: ISavingComment
) =>
	createApiAction<IEditCommentResponse>(request, success, failure, () =>
		makeCommentEditRequest(objectId, commentId, fields)
	);
