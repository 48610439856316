import {useCallback, useState} from 'react';
import {
	ActionButtons,
	Button,
	EntityGridItem,
	InlineButton,
	ReadMore,
	TextField
} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useEditInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useEditInternalAcceptance';

interface InternalAcceptanceDescriptionProps {
	objectId: string;
	internalAcceptanceId: string;
	value: string | undefined;
	canEdit?: boolean;
}

const descriptionIcon = <i className="tz-description-20" />;
const editIcon = <i className="tz-edit-16" />;

const DescriptionDesktop = ({
	objectId,
	internalAcceptanceId,
	value,
	canEdit
}: InternalAcceptanceDescriptionProps) => {
	const {t} = useTranslation();
	const {mutateAsync: editInternalAcceptance} = useEditInternalAcceptance();
	const [isEditable, setIsEditable] = useState(false);
	const [text, setText] = useState(value);
	const [saving, setSaving] = useState(false);
	const handleChange = useCallback((v: string) => {
		setText(v);
	}, []);
	const handleSave = useCallback(async () => {
		setSaving(true);
		try {
			await editInternalAcceptance({
				objectId,
				internalAcceptanceId,
				fields: {description: text}
			});
		} finally {
			setSaving(false);
			setIsEditable(false);
		}
	}, [text]);

	return (
		<EntityGridItem
			label={t('internalAcceptancePage.info.description.label')}
			icon={descriptionIcon}
			fullRow
			buttons={
				canEdit && !isEditable ? (
					<InlineButton
						className="internal-acceptance-page__documents-edit-icon"
						type="accent"
						leftIcon={editIcon}
						onClick={() => setIsEditable(true)}
					/>
				) : null
			}
		>
			{isEditable ? (
				<TextField
					value={text}
					cleanable
					elementType="textarea"
					onChange={handleChange}
					className={{element: 'internal-acceptance-page__documents-description'}}
				/>
			) : value ? (
				<ReadMore
					className={{
						root: 'internal-acceptance-page__documents-description',
						button: 'internal-acceptance-page__documents-description-button'
					}}
					text={value}
					maxLength={100}
				/>
			) : null}
			{isEditable && (
				<ActionButtons className="internal-acceptance-page__info-buttons">
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={() => {
							setText(value);
							setIsEditable(false);
						}}
					/>
				</ActionButtons>
			)}
		</EntityGridItem>
	);
};
export default DescriptionDesktop;
