import './InternalAcceptancePage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import {useInternalAcceptance} from '@src/core/hooks/queries/internalAcceptance/hook';

const InternalAcceptancePage = () => {
	useScrollRestoration();

	const {objectId, internalAcceptanceId} = useStrictParams<{
		objectId: string;
		internalAcceptanceId: string;
	}>();
	const {data: internalAcceptance} = useInternalAcceptance(objectId, internalAcceptanceId);
	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				internalAcceptanceId={internalAcceptanceId}
				internalAcceptance={internalAcceptance}
			/>
		);
	}
	return (
		<Mobile
			objectId={objectId}
			internalAcceptanceId={internalAcceptanceId}
			internalAcceptance={internalAcceptance}
		/>
	);
};

export default InternalAcceptancePage;
