import {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';
import './ExportTemplatesInstruction.less';
import InstructionElement from './InstructionElement/InstructionElement';
import {data} from './constants/data';

interface IExportTemplatesInstructionProps {
	destination?: ExportTemplatesDestinationId | 'fixed';
	hideSubtitle?: boolean;
}

const ExportTemplatesInstruction = (props: IExportTemplatesInstructionProps) => {
	const {destination, hideSubtitle} = props;
	if (!destination || !data[destination]) {
		return (
			<div className="instruction__title">
				Здесь будет перечень доступных параметров, после того как вы выберете страницу для
				экспорта
			</div>
		);
	}
	const records = data[destination]?.records;
	const title = data[destination]?.title;

	return (
		<div className="instruction">
			<div className="instruction__title">{title}</div>
			{records?.map(record => (
				<InstructionElement
					key={record.field}
					index={0}
					field={record.field}
					description={record.description}
					hideSubtitle={hideSubtitle}
				>
					{record.children && record.children.length ? record.children : undefined}
				</InstructionElement>
			))}
		</div>
	);
};

export default ExportTemplatesInstruction;
