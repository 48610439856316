import {FixDateTag} from '@src/components/FixDateTag';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {CellProps} from 'react-table';

export const ProblemFixCell = ({row}: CellProps<{data: IProblem}>) => {
	const {plannedFixDate, actualFixDate} = row.original.data;

	if (!plannedFixDate) {
		return null;
	}

	return (
		<>
			{format(plannedFixDate, dateTimeCommaSeparatedFormat)}
			{compareFixDate(actualFixDate, plannedFixDate) && (
				<FixDateTag
					className="fix-date-view-field__tag"
					plannedFixDate={plannedFixDate}
					actualFixDate={actualFixDate}
				/>
			)}
		</>
	);
};
