import {Suspense, useMemo} from 'react';
import {CellProps} from 'react-table';
import {TableRowSelect, FilesCount} from '@tehzor/ui-components';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useWorkAcceptanceAcceptors} from '@src/pages/SpacePage/hooks/useWorkAcceptanceAcceptors';
import {useWorkAcceptanceCompany} from '@src/pages/SpacePage/hooks/useWorkAcceptanceCompany';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {useCheckListStatuses} from '@src/pages/SpacePage/hooks/useCheckListStatuses';
import {WorkAcceptanceScope} from '../WorkAcceptanceScope';
import './WorkAcceptanceMobileCell.less';
import WorkAcceptanceInfo from '@src/components/tableCells/WorkAcceptanceInfo';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {WorkAcceptanceAcceptors} from '@src/components/tableCells/WorkAcceptanceAcceptors';
import {WorkAcceptanceStatus} from '@src/components/tableCells/WorkAcceptanceStatusCell';
import {WorkAcceptanceCheckListStatuses} from '@src/components/tableCells/WorkAcceptanceCheckListStatuses';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';
import {WorkAcceptanceDeadlineMobile} from './WorkAcceptanceDeadlineMobile';

const WorkAcceptanceMobileCell = ({row}: CellProps<{data: IListWorkAcceptance}>) => {
	const {
		id,
		objectId,
		stage,
		acceptorsActiveGroup,
		acceptors,
		attachments,
		description,
		frontType,
		structureIds,
		categoryId
	} = row.original.data;

	const {data: workAcceptance} = useWorkAcceptance(id, objectId);

	const structureTypes = useMemo(
		() =>
			workAcceptance?.structures
				? Array.from(new Set(workAcceptance.structures.map(str => str.type)))
				: undefined,
		[workAcceptance?.structures]
	);
	const {acceptorsGroup, acceptors: currentAcceptors} = useWorkAcceptanceAcceptors(
		objectId,
		stage,
		acceptors,
		acceptorsActiveGroup
	);
	const company = useWorkAcceptanceCompany(acceptorsActiveGroup, acceptorsGroup);

	const checkLists = useAvailableCheckLists(
		objectId,
		undefined,
		stage,
		ProcessIds.ACCEPTANCE_CONTROL,
		structureTypes,
		categoryId
	);

	const checkListsStatuses = useCheckListStatuses(checkLists, id);

	return (
		<div className="m-work-acceptance-info-cell">
			<div className="m-work-acceptance-info-cell__info">
				<div className="m-work-acceptance-info-cell__work-acceptance-header">
					<WorkAcceptanceInfo data={row.original.data} />
					<ClickPreventWrap>
						<TableRowSelect
							{...row.getToggleRowSelectedProps()}
							style={{marginTop: '12px'}}
						/>
					</ClickPreventWrap>
				</div>

				{description && (
					<div className="m-work-acceptance-info-cell__work-acceptance-desc">
						{description}
					</div>
				)}

				<div className="m-work-acceptance-info-cell__work-acceptance-deadline">
					<WorkAcceptanceDeadlineMobile workAcceptance={row.original.data} />
				</div>

				{acceptorsGroup && currentAcceptors && company && (
					<div className="work-acceptance-info-cell__acceptors">
						<WorkAcceptanceAcceptors
							acceptorsGroup={acceptorsGroup}
							currentAcceptors={currentAcceptors}
							company={company}
						/>
					</div>
				)}

				<div className="m-work-acceptance-info-cell__work-acceptance-status-row">
					<WorkAcceptanceStatus workAcceptance={row.original.data} />
				</div>

				{structureIds && (
					<Suspense>
						<WorkAcceptanceScope
							objectId={objectId}
							structureIds={structureIds}
							frontType={frontType}
						/>
					</Suspense>
				)}

				<div className="work-acceptance-info-cell__indicators">
					{attachments && <FilesCount count={attachments?.length} />}
					{checkListsStatuses && (
						<WorkAcceptanceCheckListStatuses checkListStatuses={checkListsStatuses} />
					)}
				</div>
			</div>
		</div>
	);
};

export default WorkAcceptanceMobileCell;
