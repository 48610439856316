import {useCallback, useState} from 'react';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {MobileDescription} from '../description/Description.mobile';
import {EntityAdding} from '../actions/EntityAdding';
import {Plate} from '@tehzor/ui-components';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Outlet, Route, Routes} from 'react-router-dom';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {mobileColumns} from '../table/columns.mobile';
import {CheckLists} from '../list';
import {Table} from '../table/Table.mobile';
import {ListDetailMobile} from '@src/pages/CheckListPage/components/detail/ListDetail/ListDetail.mobile';
import {MobileItemDetail} from '@src/pages/CheckListPage/components/detail/ItemDetail/ItemDetail.mobile';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useCommentsCtx} from '@src/components/Comments/utils/CommentsCtx';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {formWorkAcceptanceCommentsLink} from '@tehzor/tools/utils/links';
import {ICommentReferenceState} from '@src/components/Comments/hooks/useCommentReference';
import MobileLastReply from '@src/components/Comments/components/LastReply.mobile';
import {useChangePath} from '@src/core/hooks/useChangePath';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import MobileLinks from '../links/Links.mobile';

interface IMobileRoutesProps {
	workAcceptance: IWorkAcceptance;
	objectId: string;
	checkLists: ICheckList[];
	settings?: IEntitySettings;
}

export const MobileRoutes = ({
	workAcceptance,
	objectId,
	checkLists,
	settings
}: IMobileRoutesProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const displayMode = useAppSelector(
		s => s.settings.pages.workAcceptances.byPage[objectId]?.displayMode
	);
	const {lastReply} = useCommentsCtx();
	const {pushPath} = useChangePath();
	const [workAcceptanceImagesAttachments] = useSplitAttachments(workAcceptance);
	const workAcceptanceImages = useAttachmentsImages(workAcceptanceImagesAttachments);
	const workAcceptanceImagesData = useAttachmentsImagesObjects(workAcceptanceImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(lastReply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);

	const goToComments = useCallback(() => {
		pushPath(formWorkAcceptanceCommentsLink(objectId, workAcceptance.id));
	}, [objectId, workAcceptance.id]);

	const goToCommentsWithAdding = useCallback(
		(comment: IComment) => {
			pushPath(formWorkAcceptanceCommentsLink(objectId, workAcceptance.id), {
				reference: {
					type: 'add',
					comment
				} as ICommentReferenceState
			});
		},
		[objectId, workAcceptance.id]
	);
	const [imagesViewer, , openReplyImage] = useEntityAndReplyViewerMobile(
		workAcceptanceImages,
		replyImages,
		AttachmentFileDestination.WORK_ACCEPTANCE,
		AttachmentFileDestination.WORK_ACCEPTANCE_COMMENT_OR_REPLY,
		workAcceptanceImagesData,
		replyImagesData,
		'work-acceptance',
		'Фото приемки работ',
		lastReply?.number,
		workAcceptance.id,
		lastReply?.id
	);
	const checkListsRoute = (
		<div className="page-cont check-list-page check-list-page__m-container">
			<Routes>
				<Route path="check-lists">
					<Route
						path=":listId"
						element={<ListDetailMobile />}
					/>
					<Route
						path=":listId/:pathParam?/:itemId?"
						element={<MobileItemDetail />}
					/>
				</Route>
				<Route
					path=""
					element={
						<CheckLists
							checkLists={checkLists}
							workAcceptance={workAcceptance}
						/>
					}
				/>
			</Routes>
			<Outlet />
		</div>
	);

	const entitiesRoute = (
		<div className="work-acceptance-page__d-column work-acceptance-page__d-column_entities">
			<Plate withoutPadding>
				<Table
					objectId={objectId}
					columns={mobileColumns}
					selectedRows={selectedRows}
					workAcceptance={workAcceptance}
					checkLists={checkLists}
					onSelectedRowsChange={setSelectedRows}
					hideHead
				/>
				<EntityAdding
					objectId={objectId}
					workAcceptance={workAcceptance}
				/>
			</Plate>
		</div>
	);

	const descriptionRoute = (
		<div className="page-cont check-list-page check-list-page__m-container">
			<MobileLinks
				objectId={objectId}
				workAcceptance={workAcceptance}
			/>

			<MobileDescription
				objectId={objectId}
				workAcceptance={workAcceptance}
				checkLists={checkLists}
				settings={settings}
			/>

			<div className="work-acceptance-page__last-reply-mobile">
				<MobileLastReply
					onImageClick={openReplyImage}
					goToComments={goToComments}
					goToCommentsWithAdding={goToCommentsWithAdding}
				/>
			</div>
			{imagesViewer}
		</div>
	);

	if (displayMode === WorkAcceptanceDisplayMode.CHECK_LISTS) {
		return checkListsRoute;
	}

	if (displayMode === WorkAcceptanceDisplayMode.ENTITIES) {
		return entitiesRoute;
	}

	if (displayMode === WorkAcceptanceDisplayMode.DESCRIPTION) {
		return descriptionRoute;
	}
	return null;
};
