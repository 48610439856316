import {IAddCommentResponse} from '../comment';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import ISavingComment from '@src/interfaces/ISavingComment';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Добавляет официальный ответ
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментрия
 * @param links ссылки на другие сущности
 * @param fields данные для сохранения
 */
export async function makeReplyAddRequest(
	objectId: string,
	commentTypeId: ICommentTypeId,
	links: IComment['links'] | undefined,
	fields: ISavingComment
) {
	const response = await httpRequests.withToken.post<IAddCommentResponse>(`replies`, {
		objectId,
		commentTypeId,
		links,
		...fields
	});
	return response.data;
}
