import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {EntitiesMap} from './EntitiesMap';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {convert} from '../../../../utils/convertEntity';

export const prepareEntities = <T extends IListProblem | IListInspection>(
	entities: T[] | undefined,
	isVisible: boolean,
	entityType: T extends IListProblem ? 'problem' : 'inspection',
	result: IStructureEntity[],
	checksSubRows: EntitiesMap,
	workAcceptancesSubRows: EntitiesMap
) => {
	if (!isVisible || !entities) return;

	entities.forEach(entity => {
		if (!entity.links) return;

		const {workAcceptanceId, checkId} = entity.links;
		const converted = convert(entity, entityType) as IStructureEntity;

		if (workAcceptanceId) {
			workAcceptancesSubRows.add(workAcceptanceId, converted);
		} else if (checkId) {
			checksSubRows.add(checkId, converted);
		} else {
			result.push(converted);
		}
	});
};
