import {ReactNode} from 'react';
import {FixDateDisplayValue} from '@src/components/FixDateDisplayValue/FixDateDisplayValue';
import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {
	EntityInfoDateWithCountFiles,
	FilesCount,
	PhotoPreview,
	TableRowSelect
} from '@tehzor/ui-components';
import classNames from 'classnames';
import {TableToggleRowsSelectedProps} from 'react-table';
import styles from './PresaleCheckProblemInfoMobileCell.module.less';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {useTranslation} from 'react-i18next';
import {IPresaleCheckProblemCellProps} from '@src/interfaces/IPresaleCheckProblemCellProps';
import {ProblemStatus} from '@src/components/tableCells/ProblemStatusCell';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import ProblemReply from '@src/components/tableCells/ProblemReply';

interface IProblemInfoMobileProps {
	problem: IListProblem;
	depth: number;
	subTitle?: ReactNode;
	replies?: Record<string, IComment>;
	allProblemTags?: Record<string, IProblemTag>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
	positionFilesCount?: 'subtitle' | 'after';
}

export const ProblemInfoMobile = ({
	problem,
	depth,
	replies,
	allProblemTags,
	getToggleRowSelectedProps,
	positionFilesCount = 'subtitle'
}: IProblemInfoMobileProps) => {
	const {
		status,
		plannedFixDate,
		performers,
		performersActiveGroup,
		inspectors,
		inspectorsActiveGroup,
		description,
		critical,
		problemTags,
		actualFixDate
	} = problem;
	const reply = replies?.[problem.id];

	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();

	const [problemImagesAttachments] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);

	const [imagesViewer, openProblemImage, openReplyImage] = useEntityAndReplyViewerMobile(
		problemImages,
		replyImages,
		AttachmentFileDestination.PROBLEM,
		AttachmentFileDestination.COMMENT_OR_REPLY,
		problemImagesData,
		replyImagesData,
		'problem',
		t('problem.imageTitle'),
		reply?.number,
		problem.id,
		reply?.id
	);
	const colors = allProblemTags ? problemTags?.map(id => allProblemTags[id]?.color) : undefined;

	const performersDisplayValue = useResponsibleDisplayValue(performers, performersActiveGroup);
	const inspectorsDisplayValue = useResponsibleDisplayValue(inspectors, inspectorsActiveGroup);

	return (
		<div>
			<div
				className={classNames(styles.presaleCheckProblemInfoCell, {
					[styles.problemWithPadding]: depth > 0
				})}
			>
				<div className={styles.problemHeader}>
					<ProblemInfo
						style={{marginBottom: depth > 0 ? '12px' : '16px'}}
						data={problem}
						depth={depth}
						subTitle={
							<EntityInfoDateWithCountFiles
								labelFrom={t(
									'components.tableCell.info.entityInfoDateWithCountFiles.labelFrom'
								)}
								data={problem}
								depth={depth}
								locale={dateFnsLocale}
							/>
						}
					/>
					<ClickPreventWrap>
						<TableRowSelect
							{...getToggleRowSelectedProps()}
							className={styles.problemHeaderCheckBox}
							style={{marginTop: depth > 0 ? '6px' : '12px'}}
						/>
					</ClickPreventWrap>
				</div>

				{description && <div className={styles.problemDesc}>{description}</div>}
				{colors?.length ? (
					<div className={styles.extraInfo}>
						<ProblemTagsMarker colors={colors} />
					</div>
				) : null}
				{plannedFixDate && (
					<div className={styles.extraInfo}>
						<i className={classNames('tz-calendar-20', styles.extraInfoIcon)} />
						<FixDateDisplayValue
							className={classNames(styles.extraInfoValue, styles.problemFixDate)}
							plannedFixDate={plannedFixDate}
							status={status}
							critical={critical}
							actualFixDate={actualFixDate}
							prefix={`${t(
								'components.tableCell.info.fixDateDisplayValue.labelBefore'
							)}: `}
						/>
					</div>
				)}
				{performersDisplayValue ? (
					<div className={styles.extraInfo}>
						<i className={classNames('tz-user-20', styles.extraInfoIcon)} />
						<div className={styles.extraInfoValue}>{performersDisplayValue}</div>
					</div>
				) : null}
				{inspectorsDisplayValue ? (
					<div className={styles.extraInfo}>
						<i className={classNames('tz-user-20', styles.extraInfoIcon)} />
						<div className={styles.extraInfoValue}>{inspectorsDisplayValue}</div>
					</div>
				) : null}

				<div className={styles.problemStatusRow}>
					<ProblemStatus problem={problem} />
					{problemImages.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								className={styles.problemImage}
								url={problemImages[0]}
								data={0}
								onClick={openProblemImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>
			{reply && (
				<ProblemReply
					className={styles.reply}
					reply={reply}
					images={replyImages}
					openImage={openReplyImage}
				/>
			)}
			{(problemImages.length || replyImages.length) && (
				<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
			)}
			{positionFilesCount === 'after' && <FilesCount count={problem?.attachments?.length} />}
		</div>
	);
};

const PresaleCheckProblemInfoMobileCell = (
	props: IPresaleCheckProblemCellProps<{data: IProblem}>
) => (
	<ProblemInfoMobile
		problem={props.row.original.data}
		depth={props.row.depth}
		replies={props.replies}
		allProblemTags={props.allProblemTags}
		getToggleRowSelectedProps={props.row.getToggleRowSelectedProps}
		positionFilesCount={props.positionFilesCount}
	/>
);

export {PresaleCheckProblemInfoMobileCell};
