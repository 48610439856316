import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {
	ITreeUserGroupsItem,
	ITreeUserGroupsItemType,
	makeUserGroupsTreeData
} from '../utils/makeUserGroupsTreeData';
import {useTranslation} from 'react-i18next';
import {
	FilterButton,
	ITreeItemProps,
	Select2,
	SelectOption,
	TabLink,
	Tabs,
	TreeSelect,
	TreeSelectOption
} from '@tehzor/ui-components';
import {useUsersAsArray, useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {
	flatFilter,
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {debounce} from 'lodash';
import {TFunction} from 'i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

interface IResponsiblesFilterProps {
	workingGroups?: IWorkingGroup[];
	respUsers?: string[];
	label?: string;

	onChange: (respUsers?: string[]) => void;
}

const getFilteredData = (tabIndex: number, search: string, users?: IBriefUser[]) => {
	if (tabIndex !== 0 || !users) {
		return undefined;
	}
	return flatFilter(users, 'fullName', search);
};

const getTreeData = (
	tabIndex: number,
	workingGroups?: IWorkingGroup[],
	usersMap?: Record<string, IBriefUser>
) => {
	if (tabIndex !== 1 || !usersMap || !workingGroups?.length) {
		return [];
	}

	return makeUserGroupsTreeData(workingGroups, usersMap);
};

const getFilteredTreeData = (
	tabIndex: number,
	search: string,
	treeData: ITreeUserGroupsItem[],
	changeExpanded: (expanded: string[]) => void
) => {
	if (tabIndex !== 1) {
		return [];
	}

	const {filteredData, expanded} = treeFilter(treeData, 'name', search);
	if (expanded?.length) {
		changeExpanded(expanded.map(item => item.id));
	}
	return filteredData;
};

const getContent = (data: ITreeUserGroupsItem, t: TFunction) => (
	<div className="entities-filters__responsibles-item">
		<div className="entities-filters__responsibles-item-content">{data.content}</div>
		<div className="entities-filters__responsibles-item-additional-content">
			{data.additionalContent}
		</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className="entities-filters__responsibles-item-leader">
				{t('entitiesFilters.responsiblesFilter.leaderLabel')}
			</div>
		)}
	</div>
);

const getTreeItem = (props: ITreeItemProps<ITreeUserGroupsItem>, t: TFunction) => (
	<TreeSelectOption
		{...props}
		getContent={(data: ITreeUserGroupsItem) => getContent(data, t)}
	/>
);

export const ResponsiblesFilter = memo(
	({workingGroups, respUsers, label, onChange}: IResponsiblesFilterProps) => {
		const {t} = useTranslation();

		const [selectedUsers, setSelectedUsers] = useState(respUsers);
		const [expandedUsers, setExpandedUsers] = useState<string[]>([]);

		useUpdateEffect(() => setSelectedUsers(respUsers), [respUsers]);

		const [tabIndex, setTabIndex] = useState(0);
		const links = [
			<TabLink label={t('entitiesFilters.responsiblesFilter.tabLink.byUser')} />,
			<TabLink label={t('entitiesFilters.responsiblesFilter.tabLink.byWorkingGroup')} />
		];

		const filterLabel = label ?? t('entitiesFilters.responsiblesFilter.label');

		const [search, setSearch] = useState('');
		const changeSearch = useMemo(() => debounce(setSearch, 300), []);
		const clearSearch = useCallback(() => setSearch(''), []);

		const {data: users} = useUsersAsArray();
		const filteredData = useMemo(
			() => getFilteredData(tabIndex, search, users),
			[tabIndex, search, users]
		);

		const {data: usersMap} = useUsersAsMap();
		const treeData = useMemo(
			() => getTreeData(tabIndex, workingGroups, usersMap),
			[tabIndex, workingGroups, usersMap]
		);

		const treeFilteredData = useMemo(
			() => getFilteredTreeData(tabIndex, search, treeData, setExpandedUsers),
			[tabIndex, search, treeData]
		);

		const handleApply = () => {
			onChange(selectedUsers);
			clearSearch();
		};

		const handleChange = (v?: string[]) => {
			setSelectedUsers(v);
		};

		const handleClear = useCallback(() => {
			setSelectedUsers([]);
			clearSearch();
		}, [clearSearch]);

		const handleFullClear = () => {
			setSelectedUsers([]);
			clearSearch();
			onChange(undefined);
		};

		const handleCancel = () => {
			setSelectedUsers(respUsers);
			clearSearch();
		};

		const handleSelectAll = () => {
			switch (tabIndex) {
				case 0: {
					setSelectedUsers(users?.map(user => user.id));
					break;
				}
				case 1: {
					const allUsers = workingGroups?.map(group => {
						const groupUsers: string[] = [];
						if (group.performers) {
							groupUsers.push(...group.performers);
						}
						if (group.leader) {
							groupUsers.push(group.leader);
						}

						return groupUsers;
					});

					setSelectedUsers(allUsers?.flat());
					break;
				}
			}
			clearSearch();
		};

		const handleChangeTab = (index: number) => {
			handleClear();
			setTabIndex(index);
		};

		const getUsersTabContent = () => {
			if (tabIndex !== 0 || filteredData === undefined) {
				return null;
			}

			return (
				<Select2
					multiple
					value={selectedUsers}
					onChange={handleChange}
				>
					{filteredData?.map(item => (
						<SelectOption
							dataTestId="MenuItem"
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
				</Select2>
			);
		};

		const getWorkingGroupsTabContent = () => {
			if (tabIndex !== 1 || treeFilteredData === undefined) {
				return null;
			}

			return (
				<TreeSelect
					data={treeFilteredData}
					multiple
					value={selectedUsers}
					onChange={handleChange}
					expandedValue={expandedUsers}
					onExpand={setExpandedUsers}
					TreeItem={props => getTreeItem(props, t)}
				/>
			);
		};

		if (!users?.length) {
			return null;
		}

		return (
			<div>
				<TranslatedSelectPopup
					className="entities-filters-with-tabs"
					onCancel={handleCancel}
					onApply={handleApply}
					onClear={handleClear}
					onSelectAll={handleSelectAll}
					clearButton={!!selectedUsers?.length}
					count={selectedUsers?.length}
					footer
					tabs={
						<Tabs
							links={links}
							activeTab={tabIndex}
							onActiveTabChange={handleChangeTab}
						/>
					}
					trigger={
						<FilterButton
							className="entities-filters__item"
							label={makeFilterLabel<IBriefUser>(filterLabel, respUsers, users)}
							active={!!respUsers?.length}
							onClear={handleFullClear}
						/>
					}
					search={
						<TranslatedSelectSearch
							value={search}
							onChange={changeSearch}
						/>
					}
				>
					<Select2
						multiple
						value={selectedUsers}
						onChange={handleChange}
					>
						<>
							{getUsersTabContent()}
							{getWorkingGroupsTabContent()}
						</>
					</Select2>
				</TranslatedSelectPopup>
			</div>
		);
	}
);
