import {useMemo} from 'react';
import {LegendGroup} from './LegendGroup';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {LegendItem} from './LegendItem';
import {ISpaceStatus, isSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedProblemStatuses} from '@src/core/hooks/translations/useProblemStatuses';
import {useCheckRecordStatusesArray} from '@src/core/hooks/translations/useCheckRecordStatusesArray';
import {useExtractSpaceStatusesAsArrayByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {LegendGroupWithSubGroups} from './LegendGroupWithSubGroups';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {useShowStageByProcessId} from '@src/core/hooks/processes/useShowStageByProcessId';
import {useTranslatedWorkAcceptanceStatuses} from '@src/core/hooks/translations/useTranslatedWorkAcceptanceStatuses';

export const LegendContent = () => {
	const {t} = useTranslation();

	const {objectId} = useStrictParams<{objectId: string}>();
	const {schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	// наборы индикаторов и статусов помещений могут быть разные для каждого вложенного объекта
	// сейчас для упрощения задачи - наборы получаем по идентификатору родительского объекта
	const problemStatuses = useTranslatedProblemStatuses();
	const workAcceptanceStatuses = useTranslatedWorkAcceptanceStatuses();

	const {data: spaceStatuses} = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const {data: stagesMap} = useObjectStagesMap();
	const translatedCheckItemStatuses = useCheckRecordStatusesArray('check-item');

	const {processId: pageProcessId} = useAppSelector(state =>
		extractSpacesPageSettings(state, objectId)
	);
	const stageId = useShowStageByProcessId(pageProcessId);
	const {data: availableIndicatorsSets} = useActualUnitIndicatorsSetsExtended(
		objectId,
		stageId ?? undefined,
		false
	);

	const spaceStatusesWithStages = useMemo(
		() =>
			spaceStatuses?.reduce<Array<ISpaceStatus | {id: string; name: string}>>(
				(acc, spaceStatus) => {
					const currentStage = stagesMap && stagesMap[spaceStatus.stage];
					const lastAccStatus = acc.at(-1);

					if (isSpaceStatus(lastAccStatus) && currentStage) {
						if (lastAccStatus?.stage === currentStage.id) {
							acc.push(spaceStatus);
							return acc;
						}
					}
					acc.push(
						{
							id: currentStage?.id ?? '',
							name: t(`objectStages.${currentStage?.id}`) ?? ''
						},
						spaceStatus
					);
					return acc;
				},
				[]
			),
		[spaceStatuses, stagesMap, t]
	);

	if (schemaView === SpacesSchemaVariants.PROBLEMS) {
		return (
			<div className="spaces-page__legend-content">
				<LegendGroup
					heading={t('spacesPage.legendContent.legendGroup.heading.problemStatuses')}
					iconType="problem-status"
					items={problemStatuses}
				/>
				{availableIndicatorsSets && (
					<LegendGroupWithSubGroups
						heading={t('spacesPage.legendContent.legendGroup.heading.indicators')}
						groups={availableIndicatorsSets.filter(set => set.indicators.length > 0)}
					/>
				)}
				<LegendGroup
					heading={t('spacesPage.legendContent.legendGroup.heading.spaceStatuses')}
					iconType="space-status"
					items={spaceStatusesWithStages}
					className="spaces-page__legend-items-group-space-status"
				/>
			</div>
		);
	}

	/* ToDo переделать на динмический список когда будет ясность по цветам и их преобразованиям */

	if (schemaView === SpacesSchemaVariants.CHECK_LISTS) {
		return (
			<div className="spaces-page__legend-content">
				<div>
					<LegendGroup
						heading={t('spacesPage.legendContent.legendGroup.heading.checkListStatus')}
						iconType="check-list-status"
					>
						<LegendItem
							type="problem-status"
							title={t('checkRecordStatus.not-checked')}
							color="#C4D1E3"
						/>
						<LegendItem
							type="problem-status"
							title={t('checkRecordStatus.work-in-progress')}
							color="#1C85FD"
						/>
						<LegendItem
							type="problem-status"
							title={t('checkRecordStatus.accepted')}
							color="#90D857"
						/>
					</LegendGroup>
				</div>
				{availableIndicatorsSets && (
					<LegendGroupWithSubGroups
						heading={t('spacesPage.legendContent.legendGroup.heading.indicators')}
						groups={availableIndicatorsSets.filter(set => set.indicators.length > 0)}
					/>
				)}
				<LegendGroup
					heading={t('spacesPage.legendContent.legendGroup.heading.checkItemStatus')}
					iconType="check-item-status"
					items={translatedCheckItemStatuses}
					differentStyles
				/>
			</div>
		);
	}

	if (schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES) {
		return (
			<div className="spaces-page__legend-content">
				<LegendGroup
					heading={t('spacesPage.legendContent.legendGroup.heading.problemStatuses')}
					iconType="problem-status"
					items={problemStatuses}
					withoutCritical
				/>

				{availableIndicatorsSets && (
					<LegendGroupWithSubGroups
						heading={t('spacesPage.legendContent.legendGroup.heading.indicators')}
						groups={availableIndicatorsSets.filter(set => set.indicators.length > 0)}
					/>
				)}

				<LegendGroup
					heading={t('spacesPage.legendContent.legendGroup.heading.workAcptStatus')}
					iconType="work-acceptance-status"
					items={workAcceptanceStatuses}
				/>
			</div>
		);
	}
	return null;
};
