import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {makeInternalAcceptanceDeleteRequest} from '@src/api/backend/internalAcceptance';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDeleteInternalAcceptanceParams {
	objectId: string;
	internalAcceptanceId: string;
}

export const useDeleteInternalAcceptance = () => {
	const {t} = useTranslation();
	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.delete(),
		mutationFn: (params: IDeleteInternalAcceptanceParams) => {
			const {objectId, internalAcceptanceId} = params;
			return makeInternalAcceptanceDeleteRequest(objectId, internalAcceptanceId);
		},
		onSuccess: (_, params: IDeleteInternalAcceptanceParams) => {
			const {objectId} = params;
			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: internalAcceptancesQueryKeys.lists()
				}),
				queryClient.invalidateQueries({
					queryKey: internalAcceptancesQueryKeys.latest()
				}),
				queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()}),
				queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()}),
				queryClient.invalidateQueries({
					queryKey: [...spacesQueryKeys.internalAcceptances(), objectId]
				})
			]);
			addSuccessToast({title: t('toast.deleteInternalAcceptance.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteInternalAcceptance.error.title')});
		}
	});
};
