export enum EntityTypeId {
	CHECK = 'check',
	COMMENT = 'comment',
	INSPECTION = 'inspection',
	INTERNAL_ACCEPTANCE = 'internal-acceptance',
	OBJECT = 'object',
	PROBLEM = 'problem',
	PROBLEM_REPLY = 'problemReply',
	STRUCTURE = 'structure',
	TASK = 'task',
	UNIT = 'unit',
	WARRANTY_CLAIM = 'warranty-claim',
	WORK_ACCEPTANCE = 'work-acceptance'
}

export interface IEntityType {
	id: EntityTypeId;
	name: string;
}
