import useAppSelector from '@src/core/hooks/useAppSelector';
import {useSpaceCheckLists} from '@src/pages/CheckListPage/hooks/useSpaceCheckLists';

import {extractUncheckedCheckLists} from '@src/store/modules/pages/checkLists/selectors/records';

export const useNotCheckedCheckLists = (objectId: string, spaceId: string): boolean => {
	const checkLists = useSpaceCheckLists(objectId, spaceId, 'all', 'all');

	const unAcceptedCheckListsIds = useAppSelector(s => extractUncheckedCheckLists(s, checkLists));

	return !!unAcceptedCheckListsIds.length;
};
