import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage} from './slice';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractInspectionsPageSettings = createSelector(
	(state: IState) => state.settings.pages.inspections,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(state: IState, objectId: string | undefined, getInvisibleObjects?: boolean) =>
		getInvisibleObjects,
	(data, objectId, getInvisibleFilters) => {
		if (!data[objectId]) {
			return getInitialStateForPage();
		}

		const {filters, ...rest} = data[objectId];
		const {invisibleObjects, objects, ...restFilters} = filters;

		const newFilters = {
			...restFilters,
			objects: !objects?.length && getInvisibleFilters ? invisibleObjects : objects
		};

		return {...rest, filters: newFilters};
	}
);
