import {createContext, useCallback, useMemo} from 'react';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {convertChecks} from '../../utils/convertChecks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IPreparedCheck} from '../../interfaces/IPreparedCheck';
import ChecksFilters from '../ChecksFilters';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import ChecksPageCounter from '../ChecksPageCounter';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useChecksDocumentsStats} from '@src/core/hooks/queries/documentsStats';
import {
	useChecksQuery,
	useExtractChecksAsArray,
	useLocalChecks
} from '@src/core/hooks/queries/checks/hooks';
import {checksActions} from '@src/store/modules/settings/pages/checks/slice';
import {useChecksColumns} from '@src/pages/ChecksPage/hooks/useChecksColumns';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useDeleteCheck} from '@src/core/hooks/mutations/checks/useDeleteCheck';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

const pageSizes = [10, 20, 50, 100];

interface IChecksTableProps {
	objectId?: string;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedCheck>) => void
>(() => ({}));

const ChecksTable = ({objectId = 'all'}: IChecksTableProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {data: localChecks} = useLocalChecks(objectId);
	const {data: checks} = useExtractChecksAsArray(objectId);	
	const {data: checksData} = useChecksQuery(objectId);
	const pageSettings = useAppSelector(s => extractChecksPageSettings(s, objectId));
	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const {changePageSize, changeOffset, changeSort, changeSelectedRows} = checksActions;
	const {mutate: deleteCheck} = useDeleteCheck();

	const pagesCount = checksData?.total ? Math.ceil(checksData.total / pageSettings.pageSize) : 0;
	const currentPage = checksData?.offset
		? Math.floor(checksData.offset / pageSettings.pageSize)
		: 0;
	const memoizedCheckIds = useMemo(() => checks?.map(item => item.id), [checks]);
	const {data: documentsStats} = useChecksDocumentsStats(memoizedCheckIds);
	const preparedChecks = useMemo(() => {
		if (localChecks && checks) {
			return convertChecks([...localChecks, ...checks], networkStatus, documentsStats, user);
		}
		if (localChecks) {
			return convertChecks(localChecks, networkStatus, documentsStats, user);
		}
		if (checks) {
			return convertChecks(checks, networkStatus, documentsStats, user);
		}
		return undefined;
	}, [checks, localChecks, networkStatus, documentsStats, user]);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.checks.deleteTitle')
	});

	const handleRowClick = useCallback(
		(check: IPreparedCheck) => {
			if (check.object && !check.localNumber) {
				pushPath(`/objects/${check.object.id}/checks/${check.id}`);
			}
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows({objectId, selectedRows: value})),
		[changeSelectedRows, dispatch, objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort: value}));
		},
		[changeSort, dispatch, objectId]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			if (checksData) {
				dispatch(changePageSize({objectId, pageSize: value}));
				dispatch(changeOffset({objectId, offset: Math.floor(checksData.offset / value)}));
			}
		},
		[checksData, dispatch, changePageSize, objectId, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (checksData?.offset !== offset) {
				dispatch(changeOffset({offset, objectId}));
			}
		},
		[pageSettings.pageSize, checksData?.offset, dispatch, changeOffset, objectId]
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedCheck>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					deleteCheck({objectId: action.payload.object.id, checkId: action.payload.id});
				}
			}
		},
		[deleteCheck, getDeleteConfirmation]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = useChecksColumns({isDesktop: isLargeTablet, objectId});

	return (
		<>
			<div className="checks-page__filters-bar">
				<ChecksFilters objectId={objectId} />
			</div>
			<ChecksPageCounter objectId={objectId} />
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					{preparedChecks && (
						<EntitiesTable
							columns={columns}
							data={preparedChecks}
							selectedRows={pageSettings.selectedRows}
							sort={pageSettings.sort}
							selectable
							responsive={!isLargeTablet}
							onRowClick={handleRowClick}
							onSelectedRowsChange={handleSelectedRowsChange}
							onSortChange={handleSortChange}
						/>
					)}
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
};

export default ChecksTable;
