import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import * as types from '../constants';
import {makeWorkAcceptanceDeleteRequest} from '@src/api/backend/workAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {structuresQueryKeys} from '@src/fsd/src/entities/Structures';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IDeleteWorkAcceptancePayload {
	objectId: string;
	workAcceptanceId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, workAcceptanceId: string) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: structuresQueryKeys.workAcceptancesStatsAll()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithWorkAcceptanceStats()});

	addSuccessToast({title: t('toast.deleteWorkAcceptance.success.title')});
	return {
		type: types.DELETE_SUCCESS,
		payload: {objectId, workAcceptanceId} as IDeleteWorkAcceptancePayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteWorkAcceptance.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет приёмку работ
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 */
export const deleteWorkAcceptance = (objectId: string, workAcceptanceId: string) =>
	createApiAction(
		request,
		() => success(objectId, workAcceptanceId),
		failure,
		() => makeWorkAcceptanceDeleteRequest(objectId, workAcceptanceId)
	);
