import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useTranslation} from 'react-i18next';
import './WorkAcceptanceAcceptors.less';

interface WorkAcceptanceAcceptorsProps {
	acceptorsGroup: IWorkingGroup;
	currentAcceptors: string[];
	company: string;
}

export const WorkAcceptanceAcceptors = ({
	acceptorsGroup,
	currentAcceptors,
	company
}: WorkAcceptanceAcceptorsProps) => {
	const {t} = useTranslation();

	const hasMultipleAcceptors = currentAcceptors?.length > 1;
	const firstAcceptor = currentAcceptors?.[0];
	const remainingAcceptorsCount = hasMultipleAcceptors ? currentAcceptors.length - 1 : 0;

	return (
		<div className="work-acceptance-acceptors__container">
			<span className="work-acceptance-acceptors__label">
				{t('workAcceptancePage.description.acceptors.label')}:
			</span>
			<span className="work-acceptance-acceptors__group">{acceptorsGroup.name}</span>

			{firstAcceptor && (
				<span className="work-acceptance-acceptors__details">
					<span className="work-acceptance-acceptors__acceptor">
						{firstAcceptor}
						{company && (
							<span className="work-acceptance-acceptors__company">{company}</span>
						)}
					</span>
					{hasMultipleAcceptors && (
						<span className="work-acceptance-acceptors__remaining-count">
							+{remainingAcceptorsCount}
						</span>
					)}
				</span>
			)}
		</div>
	);
};
