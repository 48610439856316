import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useMutation} from '@tanstack/react-query';
import {makeInternalAcceptanceMoveRequest} from '@src/api/backend/internalAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IMakeTaskMoveRequestData {
	objectId: string;
	internalAcceptanceId: string;
	toObjectId: string;
	toSpaceId?: string;
}

export const useMoveInternalAcceptance = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.move(),
		mutationFn: ({
			objectId,
			internalAcceptanceId,
			toObjectId,
			toSpaceId
		}: IMakeTaskMoveRequestData) =>
			makeInternalAcceptanceMoveRequest(
				objectId,
				internalAcceptanceId,
				toObjectId,
				toSpaceId
			),
		onSuccess: ({id, objectId}) => {
			addSuccessToast({title: t('toast.moveInternalAcceptance.success.title')});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.latest()});
			void queryClient.invalidateQueries({
				queryKey: [...internalAcceptancesQueryKeys.detail(id), objectId]
			});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.details()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.details()});
		},
		onError: () => addErrorToast({title: t('toast.moveInternalAcceptance.error.title')})
	});
};
