import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IDeleteInternalAcceptanceResponse = void;

/**
 * Удаляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const makeInternalAcceptanceDeleteRequest = async (
	objectId: string,
	internalAcceptanceId: string
) => {
	const response = await httpRequests.withToken.delete<IDeleteInternalAcceptanceResponse>(
		`internal-acceptances/${internalAcceptanceId}`,
		{
			params: {objectId}
		}
	);

	return response.data;
};
