import {useMemo} from 'react';
import {useFetchObjectSettings} from '../queries/objectSettings/hooks';
import {useObject, useTargetObjects} from '../queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ObjectSettingIds} from '@tehzor/tools/interfaces/objects/IObjectSettings';
import {extractSelectedSpacesForObjectIds} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppSelector from '../useAppSelector';
import {useSpace} from '../queries/space';
import {useSpacesSelection} from '@src/pages/SpacesPage/hooks/useSpacesSelection';
import {useSelectedSpacesAsArray} from '../queries/spaces/hooks';

export const useObjectSettings = (objectId: string, spaceId?: string) => {
	const {data: space} = useSpace(spaceId, objectId);
	const {data: targetObjects} = useTargetObjects(objectId, true);
	const {selectedSpaces, singleObjectId} = useAppSelector(state =>
		extractSelectedSpacesForObjectIds(state, objectId, targetObjects || [])
	);

	const currentObjectId = singleObjectId || objectId;

	const {data: settings, isLoading: isSettingsLoading} = useFetchObjectSettings(currentObjectId);
	const {data: object, isLoading: isObjectLoading} = useObject(currentObjectId);
	const {isChoosingVisible} = useSpacesSelection(objectId);
	const {data: spaces} = useSelectedSpacesAsArray(currentObjectId, selectedSpaces);

	const isLoading = isSettingsLoading || isObjectLoading;

	const restrictedAddProblemsAfterWarrantyExpiration = useMemo(() => {
		if (isLoading) return true;

		const isRestricted =
			settings?.settings?.[
				ObjectSettingIds.RESTRICT_PROBLEM_CREATION_AFTER_WARRANTY_EXPIRATION
			] === true;

		const warrantyEndDate = object?.stages?.[ObjectStageIds.WARRANTY]?.to;
		const currentDate = Date.now();

		// **Сценарий 1: Проверка на наличие настройки у объекта и истечение гарантии у объекта**
		if (isRestricted && warrantyEndDate && currentDate > warrantyEndDate) {
			return true;
		}

		// **Сценарий 2: Проверка на наличие настройки у объекта и истечение гарантий помещения (добавление нарушений в объекте)**
		if (spaceId && space && isRestricted) {
			const warrantyDates = [
				space.constructiveWarrantyExpiredDate,
				space.decorationWarrantyExpiredDate,
				space.technicalEquipmentWarrantyExpiredDate
			].filter((date): date is number => typeof date === 'number');

			const maxWarrantyEndDate = warrantyDates.length > 0 ? Math.max(...warrantyDates) : null;

			if (maxWarrantyEndDate && currentDate > maxWarrantyEndDate) {
				return true;
			}
		}

		// **Сценарий 3: Проверка на наличие настройки у объекта и истечение гарантий помещений (добавление нарушений при множественном выборе)**
		if (spaces && spaces.length > 0 && isChoosingVisible && isRestricted) {
			return spaces.some(s => {
				const warrantyDates = [
					s?.constructiveWarrantyExpiredDate,
					s?.decorationWarrantyExpiredDate,
					s?.technicalEquipmentWarrantyExpiredDate
				].filter((date): date is number => typeof date === 'number');

				const maxWarrantyEndDate =
					warrantyDates.length > 0 ? Math.max(...warrantyDates) : null;

				return maxWarrantyEndDate !== null && currentDate > maxWarrantyEndDate;
			});
		}
		//* *Сценарий 4: Если не попадает под условия, то запрета на добавление нет
		return false;
	}, [settings, object, space, spaces, isChoosingVisible, isLoading, spaceId]);

	return {restrictedAddProblemsAfterWarrantyExpiration};
};
