import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {useCallback, useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {useStrictParams} from '../useStrictParams';

export const useMultipleEntitiesAddingPermissions = () => {
	const user = useAppSelector(s => s.auth.profile);
	const {objectId} = useStrictParams<{objectId: string}>();

	// Получаем список дочерних объектов (в случае если дочерних объектов нет, а есть один родительский, то в subObjectIds попадает id родительского объекта)
	const subObjectIds = useAppSelector(s =>
		Object.keys(s.settings.pages.spaces.byPage[objectId]?.selectedSubObjects ?? {})
	);

	// Функция для проверки прав по `UserRoleScopes.OBJECT` для всех subObjectIds
	const hasObjectScopePermission = useCallback(
		(
			objectIds: string[],
			roles: IUserRole[] | undefined,
			permissionKey: string,
			restrictionKey: string
		) =>
			objectIds.every(subId => {
				const permission = findPermission(
					roles || [],
					permissionKey,
					UserRoleScopes.OBJECT,
					subId
				);
				return permission ? !(permission.restrictions?.[restrictionKey] === true) : false;
			}),
		[]
	);

	// Проверка прав по `UserRoleScopes.OBJECT` для всех `subObjectIds`
	const addMultipleChecksObjectPermission = useMemo(
		() =>
			hasObjectScopePermission(
				subObjectIds,
				user.roles,
				'checksAdd',
				'checks-add-from-space'
			),
		[user.roles, subObjectIds]
	);
	const addMultipleInternalAcceptancesObjectPermission = useMemo(
		() =>
			hasObjectScopePermission(
				subObjectIds,
				user.roles,
				'internalAcceptancesAdd',
				'internal-acceptances-add-from-space'
			),
		[user.roles, subObjectIds]
	);
	const addMultiplePresaleChecksObjectPermission = useMemo(
		() =>
			hasObjectScopePermission(
				subObjectIds,
				user.roles,
				'presale-checks-add',
				'presale-checks-add-from-space'
			),
		[user.roles, subObjectIds]
	);
	const addMultipleOwnerAcceptancesObjectPermission = useMemo(
		() =>
			hasObjectScopePermission(
				subObjectIds,
				user.roles,
				'owner-acceptances-add',
				'owner-acceptances-add-from-space'
			),
		[user.roles, subObjectIds]
	);
	const addMultipleWarrantyClaimsObjectPermission = useMemo(
		() =>
			hasObjectScopePermission(
				subObjectIds,
				user.roles,
				'warranty-claims-add',
				'warranty-claims-add-from-space'
			),
		[user.roles, subObjectIds]
	);

	// Логика объединения для UserRoleScopes.OBJECT
	const canAddMultipleEntitiesObjectScope = useMemo(
		() =>
			addMultipleChecksObjectPermission ||
			addMultipleInternalAcceptancesObjectPermission ||
			addMultiplePresaleChecksObjectPermission ||
			addMultipleOwnerAcceptancesObjectPermission ||
			addMultipleWarrantyClaimsObjectPermission,
		[
			addMultipleChecksObjectPermission,
			addMultipleInternalAcceptancesObjectPermission,
			addMultiplePresaleChecksObjectPermission,
			addMultipleOwnerAcceptancesObjectPermission,
			addMultipleWarrantyClaimsObjectPermission
		]
	);

	// Проверяем, можно ли добавлять нарушения и осмотры
	const canAddProbremsAndInspections = useMemo(() => {
		const problemsAddObjectScopePermission = hasObjectScopePermission(
			subObjectIds,
			user.roles,
			'problems-add',
			'problems-add-from-space'
		);
		const inspectionsAddObjectScopePermission = hasObjectScopePermission(
			subObjectIds,
			user.roles,
			'inspections-add',
			'inspections-add-from-space'
		);

		return problemsAddObjectScopePermission || inspectionsAddObjectScopePermission;
	}, [subObjectIds, user.roles, hasObjectScopePermission]);

	// Итоговая проверка на добавление множества сущностей
	const canAddMultipleEntities = useMemo(
		() => canAddMultipleEntitiesObjectScope && canAddProbremsAndInspections,
		[canAddMultipleEntitiesObjectScope, canAddProbremsAndInspections]
	);

	return {
		canAddMultipleEntities
	};
};
