import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {CellProps} from 'react-table';
import {usePerformersDetails} from '../../../hooks/usePerformersDetail';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';

export const PerformersDesktopCell = ({row}: CellProps<{data: IListProblem | IListInspection}>) => {
	const {performers, performersActiveGroup} = row.original.data;
	const {detailPerformers, activeGroup, performersCount} = usePerformersDetails(
		performers,
		performersActiveGroup
	);

	return (
		<div className="structure-page__d-table-performers">
			{activeGroup && detailPerformers && (
				<div className="structure-page__d-table-performers-group">{activeGroup.name}</div>
			)}

			{detailPerformers?.[0] && (
				<div className="structure-page__d-table-performers-user">
					<div className="structure-page__d-table-performers-user-name">
						{detailPerformers[0].displayName}
					</div>
					{detailPerformers[0].companies?.[0]?.name && (
						<div className="structure-page__d-table-performers-user-company">
							{detailPerformers[0].companies[0].name}
						</div>
					)}
				</div>
			)}

			{performersCount > 0 && detailPerformers && activeGroup && (
				<div className="structure-page__d-table-performers-rest">+{performersCount}</div>
			)}
		</div>
	);
};
