import EntitiesFilters, {
	CreatedByFilter,
	CreationDateFilter,
	ObjectsFilter,
	StatusesFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IContractsFiltersState} from '@src/store/modules/settings/pages/contracts/reducers';
import {SigningDateFilter} from './filters/SigningDateFilter';
import {
	useCompaniesAsArray,
	useFilteredCompaniesContractorsAsArray
} from '@src/core/hooks/queries/companies/hooks';

export const ContractsFilters = () => {
	const {state} = useEntitiesFiltersCtx<IContractsFiltersState>();

	const {data: companies} = useCompaniesAsArray();
	const {data: contractors} = useFilteredCompaniesContractorsAsArray();

	return (
		<div className="contracts__filters">
			<EntitiesFilters
				objectId="all"
				entity="contracts"
			>
				<SigningDateFilter
					from={state?.signingDateFrom}
					to={state?.signingDateTo}
				/>

				{companies && companies.length > 1 && (
					<StatusesFilter
						label="Заказчики"
						filterName="companyIds"
						statuses={state?.companyIds}
						allStatuses={companies}
					/>
				)}

				{contractors && contractors.length > 1 && (
					<StatusesFilter
						label="Подрядчики"
						filterName="contractorIds"
						statuses={state?.contractorIds}
						allStatuses={contractors}
					/>
				)}

				<ObjectsFilter
					value={state?.objectIds}
					filterName="objectIds"
				/>

				<CreatedByFilter
					objectId="all"
					createdBy={state?.createdBy}
				/>

				<CreationDateFilter
					from={state?.createdAtFrom}
					to={state?.createdAtTo}
				/>
			</EntitiesFilters>
		</div>
	);
};
