import {memo, useCallback} from 'react';
import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef,
	Checkbox
} from '@tehzor/ui-components';
import './SpaceOwner.less';
import {useTranslation} from 'react-i18next';
import {
	useAllSpaceOwnersAsMap,
	useAllSpaceOwnersForSpaceAsArray
} from '@src/core/hooks/queries/spaceOwners/hooks';
import {useController, useFormContext} from 'react-hook-form';

interface ISpaceOwnerProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	spaceId: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	name: string;
	ownerIsNotClaimer?: boolean;
	onSetValue?: (name: string, value: unknown, options?: unknown) => void;
	handleOwnerIsNotClaimer?: () => void;
}

export const SpaceOwner = memo(
	<S extends {spaceOwnerId?: string}, E>(props: ISpaceOwnerProps<S, E>) => {
		const {
			className,
			style,
			spaceId,
			required,
			disabled,
			name,
			ownerIsNotClaimer,
			onSetValue,
			handleOwnerIsNotClaimer,
			label = 'Собственник',
			...restProps
		} = props;
		const {t} = useTranslation();

		const owners = useAllSpaceOwnersForSpaceAsArray(spaceId);
		const ownersMap = useAllSpaceOwnersAsMap();
		const isException = owners?.length === 0 || !owners;
		const {control} = useFormContext();
		const {
			field: {value},
			fieldState: {invalid}
		} = useController({
			name,
			control,
			rules: {required: required && !isException && !ownerIsNotClaimer}
		});

		const handleChange = useCallback(
			(v: string) => {
				if (onSetValue) {
					onSetValue(name, v);
					if (!v) {
						onSetValue(name, null);
					}
				}
			},
			[onSetValue, name]
		);

		const handleClear = useCallback(() => {
			handleChange('');
		}, [handleChange]);

		return (
			<div
				className={className}
				style={style}
				{...restProps}
			>
				<EditableFieldLabel>{label}</EditableFieldLabel>

				<SelectPopup
					trigger={
						<TextFieldWithForwardedRef
							value={(value && ownersMap && ownersMap[value]?.name) ?? ''}
							elementType="div"
							error={invalid ? 'Выберите собственника' : undefined}
							disabled={disabled}
							onClearClick={handleClear}
							cleanable
						/>
					}
				>
					<Select2
						value={value || undefined}
						onChange={handleChange}
					>
						{owners?.map(item => (
							<SelectOption
								key={item.id}
								itemKey={item.id}
								content={item.name}
							/>
						))}
					</Select2>
				</SelectPopup>

				{ownerIsNotClaimer !== undefined && handleOwnerIsNotClaimer !== undefined ? (
					<Checkbox
						className="editable-owner-is-not-claimer-checkbox"
						checked={ownerIsNotClaimer}
						onChange={handleOwnerIsNotClaimer}
					>
						<span className="editable-owner-is-not-claimer-checkbox__label">
							{t('components.editableFields.spaceOwner.checkbox.span')}
						</span>
					</Checkbox>
				) : null}
			</div>
		);
	}
);
