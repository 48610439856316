import {useMemo} from 'react';
import {Button} from '@tehzor/ui-components';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';
import {useStagesAndProcessesMap} from '@src/core/hooks/processes/useProcessesMap';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

const types: ICheckAddingEntityType[] = ['problem'];

interface IProblemAddingDesktopProps {
	objectId: string;
}
const plusIcon = <i className="tz-plus-20" />;

const ProblemAddingDesktop = ({objectId}: IProblemAddingDesktopProps) => {
	const {t} = useTranslation();
	const stagesAndProcessesMap = useStagesAndProcessesMap();
	const {data: object} = useObject(objectId);

	const checksPermissions = useChecksPermissions(objectId, 'problems');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'problems');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'problems');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'problems');
	const presaleChecksPermissions = usePresaleChecksPermissions(objectId, 'problems');

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			acceptances: ownersAcceptancesPermissions.canAdd,
			claims: warrantyClaimPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd,
			presaleChecks: presaleChecksPermissions.canAdd
		}),
		[
			checksPermissions,
			ownersAcceptancesPermissions,
			warrantyClaimPermissions,
			internalAcceptancePermissions,
			presaleChecksPermissions
		]
	);

	const defaultStageId = object?.stage || ObjectStageIds.BUILDING;
	const defaultProcessId = stagesAndProcessesMap[defaultStageId][0];

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		defaultStageId,
		defaultProcessId,
		permissions,
		undefined,
		undefined,
		types
	);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label={t('problemsPage.addButton.label')}
				onClick={openGlobalDialog}
			/>
			{globalDialog}
		</>
	);
};

export default ProblemAddingDesktop;
