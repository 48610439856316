import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useCurrentTreeObject, useObject} from '@src/core/hooks/queries/objects/hooks';
import {useObjectFieldSettings} from '@src/core/hooks/settings/useObjectFieldSettings';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {addErrorToast} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import usePermissionsForObject from '../ObjectsPage/utils/usePermissionsForObject';
import {Desktop} from './components/Desktop';
import {Menu} from './components/Menu';
import {Mobile} from './components/Mobile';
import './ObjectPage.less';

export const ObjectPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {t} = useTranslation();
	useScrollRestoration();

	let finalObject;
	const treeObject = useCurrentTreeObject(objectId);
	const {data: object} = useObject(objectId);

	if (treeObject) {
		finalObject = treeObject;
	} else {
		finalObject = object;
	}

	const stages = useTranslatedObjectStagesArray();

	const user = useAppSelector(extractUserProfile);
	const permissions = usePermissionsForObject(finalObject?.id || 'all', user);

	useEffect(() => {
		if (!permissions.canViewObject) {
			addErrorToast({title: t('toast.canView.error.title')});
		}
	}, [permissions.canViewObject]);

	useAppHeader(
		{
			title: finalObject ? finalObject.name : '',
			showBackBtn: true,
			mobileRightButtons: (
				<Menu
					object={finalObject}
					permissions={permissions}
				/>
			)
		},
		[finalObject, permissions]
	);

	const isDesktop = useIsDesktop();
	const {custom: customFieldsSettings} = useObjectFieldSettings(objectId);

	if (!permissions.canViewObject || !finalObject) {
		return null;
	}
	if (!stages) {
		return null;
	}

	if (isDesktop) {
		return (
			<Desktop
				object={finalObject}
				stages={stages}
				permissions={permissions}
				customFieldsSettings={customFieldsSettings}
			/>
		);
	}

	return (
		<Mobile
			object={finalObject}
			stages={stages}
			customFieldsSettings={customFieldsSettings}
		/>
	);
};
