import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';

export type IAddWarrantyClaimsResponse = IWarrantyClaim[];

/**
 * Добавляет гарантийные обращения
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные
 */
export const makeWarrantyClaimsAddRequest = async (
	objectId: string,
	spaceIds: string[],
	links: IWarrantyClaim['links'] | undefined,
	fields: ISavingWarrantyClaim,
	localId?: string
) => {
	fields.newAttachments = await addTempFiles(fields.newAttachments);

	const response = await httpRequests.withToken.post<IAddWarrantyClaimsResponse>(
		'/warranty-claims/add-list',
		{
			objectId,
			spaceIds,
			links,
			localId,
			...fields
		}
	);
	return response.data;
};
