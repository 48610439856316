import styles from './Trigger.module.less';
import {Button} from "@tehzor/ui-components";
import classNames from "classnames";
import {forwardRef, Ref} from "react";

const iconSettings = <i className="tz-settings"/>;

interface ITriggerProps {
    onClick: (() => void) | undefined
    readyToSave: boolean;
    label?: string
}

const Trigger = ({readyToSave, label, ...rest}: ITriggerProps, ref?: Ref<HTMLButtonElement>) => (
    <Button
        {...rest}
        ref={ref}
        className={{
            root: classNames(styles.wrapper, label && styles.wrapperActive),
            leftIcon: styles.icon
        }}
        leftIcon={
            <div>
                {iconSettings}
                {readyToSave && <div className={styles.indicator}/>}
            </div>
        }
        type="common"
        label={label && <p className={styles.label}>{label}</p>}
    />
);

export default forwardRef(Trigger)