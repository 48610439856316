import {useState} from 'react';
import {IconButton} from '../../../buttons';
import classNames from 'classnames';

export interface IItem {
	field: string;
	description?: string;
	children?: IItem[];
}

interface IInstructionElement {
	index: number;
	field: string;
	description?: string;
	children?: IItem[];
	hideSubtitle?: boolean;
}

const InstructionElement = (props: IInstructionElement) => {
	const {index, field, description, children, hideSubtitle} = props;
	const [isExpanded, setExpanded] = useState(false);

	const handleClick = () => {
		setExpanded(!isExpanded);
	};

	return (
		<div
			className="instruction__element"
			style={{marginLeft: `${index + 10}px`}}
		>
			<div className="instruction__container">
				<div className="instruction__element-header">
					<div className="instruction__element-button-container">
						{children?.length ? (
							<IconButton
								type="inline-blue"
								onClick={handleClick}
								className="instruction__element-button"
							>
								<i
									className={classNames(
										'tz-simple-arrow-16',
										'instruction__element-icon',
										{
											'instruction__element-icon_reverse': isExpanded
										}
									)}
								/>
							</IconButton>
						) : null}
					</div>

					<div className="instruction__field">{field}</div>
					<div className="instruction__description">{description}</div>
				</div>

				{children?.length ? (
					<div
						className={classNames('instruction__expandable', {
							instruction__expandable_hidden: !isExpanded
						})}
					>
						{!hideSubtitle && (
							<div
								className="instruction__subtitle"
								style={{marginLeft: `${index + 24}px`}}
							>
								вложенные поля:
							</div>
						)}

						<div
							className="instruction__children"
							style={{marginLeft: `${index + 20}px`}}
						>
							{children.map(item => (
								<InstructionElement
									key={item.field}
									hideSubtitle={hideSubtitle}
									index={index + 1}
									field={item.field}
									description={item.description}
								>
									{item.children && item.children.length
										? item.children
										: undefined}
								</InstructionElement>
							))}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default InstructionElement;
