import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useMatch} from 'react-router';

export const StructureTypesFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: structureTypes} = useExtractStructureTypesAsArray();
	const translatedStructureTypes = useTranslatedDictionaryArray(
		dictionaryKeys.structureTypes,
		structureTypes
	);

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={translatedStructureTypes}
			label={t('entitiesFilters.statusesFilter.structureTypes.label')}
			filterName="types"
		/>
	);
};
