import StatusesFilterPage from '../StatusesFilterPage';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useExtractCheckListsAsArrayFilteredBySelectedSpaceType} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractRootCheckItems} from '@src/core/hooks/queries/checkItems/hooks';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

export const CheckListCategoryFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: checkLists} = useExtractCheckListsAsArrayFilteredBySelectedSpaceType(objectId);
	const {state} = useEntitiesFiltersCtx<ISpacesFiltersState>();
	const checkList =
		checkLists && checkLists.length === 1
			? checkLists[0].id
			: state?.checkListIds?.length === 1
			? state.checkListIds[0]
			: '';

	const {data: checkListCategory} = useExtractRootCheckItems(checkList);

	return ((checkLists && checkLists.length === 1) || state?.checkListIds?.length === 1) &&
		checkListCategory ? (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={checkListCategory}
			label={t('spacesFiltersPage.checkListCategoryFilterMobile.label')}
			filterName="checkListCategory"
		/>
	) : null;
};
