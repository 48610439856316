import {isEmpty} from 'lodash';

export const checkFiltersIsEmpty = (object: Record<string, unknown>) => {
	const keys = Object.keys(object);
	const result = keys.filter(key => {
		if (Array.isArray(object[key])) {
			return !isEmpty(object[key]);
		}
		return !!object[key];
	});
	return !result.length;
};
