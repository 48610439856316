import {Column} from 'react-table';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import WorkAcceptanceMobileCell from '../cells/WorkAcceptanceMobileCell';
import CheckInfoMobileCell from '@src/components/tableCells/CheckInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ProblemInfoMobileCell} from '../cells/ProblemInfoMobileCell';

export const getMobileColumns = (
	replies?: Record<string, IComment>
): Array<Column<IStructureEntity>> => [
	{
		id: 'info',
		Cell: CellWrap({
			problem: ProblemInfoCellWrap(ProblemInfoMobileCell, undefined, replies),
			inspection: InspectionInfoMobileCell,
			'work-acceptance': WorkAcceptanceMobileCell,
			check: CheckInfoMobileCell
		})
	}
];
