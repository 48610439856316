export const getSectionsMenuItems = (objectId?: string) => [
	{
		id: 'filters',
		label: 'Выбрать фильтры',
		url: objectId ? `/objects/${objectId}/problems/filters` : '/problems/filters'
	},
	{
		id: 'patterns',
		label: 'Шаблоны фильтров',
		url: objectId
			? `/objects/${objectId}/problems/filters/patterns`
			: `/problems/filters/patterns`
	}
];
