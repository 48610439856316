import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';

export type IGetInternalAcceptanceResponse = ILinkedInternalAcceptance;

/**
 * Возвращает внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приёмки
 */
export const requestInternalAcceptance = async (objectId: string, internalAcceptanceId: string) => {
	const response = await httpRequests.withToken.get<IGetInternalAcceptanceResponse>(
		`internal-acceptances/${internalAcceptanceId}`,
		{params: {objectId}}
	);

	return response.data;
};
