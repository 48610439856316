import {HTMLProps, forwardRef, Ref, CSSProperties, ReactNode, MouseEvent} from 'react';
import './ButtonBase.less';
import classNames from 'classnames';
import ButtonBaseOuter from './ButtonBaseOuter';

export type OuterTagType = 'div' | 'a' | 'button';

export type OuterTagProps = HTMLProps<HTMLDivElement> | HTMLProps<HTMLAnchorElement> | HTMLProps<HTMLButtonElement>;

export interface IButtonBaseProps {
	className?: string;
	style?: CSSProperties;
	wrapClassName?: string;
	labelClassName?: string;
	leftIconClassName?: string;
	rightIconClassName?: string;
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	label?: ReactNode;
	disabled?: boolean;
	classNamePrefix: string;
	outerTagType?: OuterTagType;
	outerTagProps?: OuterTagProps;
	dataTestId?: string;

	onClick?: (event: MouseEvent) => void;
	onMouseEnter?: (event: MouseEvent) => void;
	onMouseLeave?: (event: MouseEvent) => void;
}

const ButtonBase = (props: IButtonBaseProps, ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>) => {
	const {
		className,
		style,
		wrapClassName,
		labelClassName,
		leftIconClassName,
		rightIconClassName,
		leftIcon,
		rightIcon,
		label,
		disabled = false,
		classNamePrefix: prefix,
		outerTagType = 'div',
		outerTagProps = {},
		dataTestId,
		onClick,
		onMouseEnter,
		onMouseLeave
	} = props;
	const classes = classNames(
		'button-base',
		`button-base_${outerTagType}`,
		prefix,
		{'button-base_disabled': disabled},
		{[`${prefix}_disabled`]: disabled},
		className
	);

	const leftIconClasses = classNames(`${prefix}__icon`, label || rightIcon ? `${prefix}__icon_left` : undefined, leftIconClassName, disabled && `${prefix}__icon_left-disabled`);

	const rightIconClasses = classNames(`${prefix}__icon`, label || leftIcon ? `${prefix}__icon_right` : undefined, rightIconClassName);

	return (
		<ButtonBaseOuter
			className={classes}
			style={style}
			tagType={outerTagType}
			tagProps={outerTagProps}
			onClick={!disabled ? onClick : undefined}
			onMouseEnter={!disabled ? onMouseEnter : undefined}
			onMouseLeave={!disabled ? onMouseLeave : undefined}
			ref={ref}
			dataTestId={dataTestId ?? 'BaseButton'}
		>
			<div
				className={classNames(`${prefix}__wrap`, wrapClassName)}
			>
				{leftIcon && <div className={leftIconClasses}>{leftIcon}</div>}

				{label && <div className={classNames(`${prefix}__label`, labelClassName)}>{label}</div>}

				{rightIcon && <div className={rightIconClasses}>{rightIcon}</div>}
			</div>
		</ButtonBaseOuter>
	);
};

ButtonBase.displayName = 'ButtonBase';

export default forwardRef(ButtonBase);
