import {addTempFiles} from '@src/api/cache/utils/addTempFiles';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type IAddProblemResponse = IProblem;

/**
 * Добавляет несколько нарушений в рамках передачи собственнику / гарантийных обращений
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные нарушения
 * @param processId id процесса,
 * @param stage id стадии
 */
export const makeProblemsAddMultipleRequest = async (
	objectId: string,
	spaceIds: string[],
	ownerAcceptanceIds: string[] | undefined,
	warrantyClaimIds: string[] | undefined,
	links: IProblem['links'] | undefined,
	fields: ISavingProblem,
	processId: ProcessIds,
	stage: ObjectStageIds
) => {
	const newAttachments = await addTempFiles(fields.newAttachments, TempFileLocation.S3);
	const newAttachmentsIds = newAttachments?.map(item => item.id);

	const response = await httpRequests.withToken.post<IAddProblemResponse[]>(
		'/problems/add-list',
		{
			objectId,
			spaceIds,
			ownerAcceptanceIds,
			warrantyClaimIds,
			savingData: {
				...fields,
				objectId,
				processId,
				stage,
				links,
				newAttachmentsIds
			}
		}
	);
	return response.data;
};
