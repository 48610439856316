import {useMemo} from 'react';
import {useWorkAcceptancesForStructureAsArray} from '@src/core/hooks/queries/workAcceptances';
import {extractStructureEntities} from '../selectors/extractStructureEntities';
import {useStructureInspectionsAsArray} from '../../inspections/hooks/useStructureInspectionsAsArray';
import {useStructureChecksAsArray} from '../../checks/hooks/useStructureChecksAsArray';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructureProblemsAsArray} from '../../problems/hooks/useStructureProblemsAsArray';

export const useStructureEntities = (objectId: string, structureId: string) => {
	const {entitiesTab, entitiesVisibility} = useAppSelector(
		state => state.settings.pages.structure.byPage
	);
	const {data: workAcceptances} = useWorkAcceptancesForStructureAsArray(objectId, structureId);
	const {data: problems} = useStructureProblemsAsArray(objectId, structureId);
	const {data: inspections} = useStructureInspectionsAsArray(objectId, structureId);
	const {data: checks} = useStructureChecksAsArray(objectId, structureId);
	return useMemo(
		() =>
			extractStructureEntities({
				workAcceptances,
				problems,
				inspections,
				checks,
				entitiesTab,
				entitiesVisibility
			}),
		[workAcceptances, problems, inspections, entitiesTab, entitiesVisibility, checks]
	);
};
