import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {
	extractInternalAcceptancesAsArray,
	extractInternalAcceptancesForStats,
	extractLocalSpaceInternalAcceptances
} from './selectors';
import {IInternalAcceptancesFiltersState} from '@src/store/modules/settings/pages/internalAcceptances/interfaces';
import useAppSelector from '../../useAppSelector';
import {extractInternalAcceptancesPageSettings} from '@src/store/modules/settings/pages/internalAcceptances/selectors';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useCallback, useMemo} from 'react';
import {useExtractLatestInternalAcceptancesAsArray} from './latest/hooks';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';

export const useInternalAcceptances = <T = IGetInternalAcceptancesResponse>(
	select?: (data: IGetInternalAcceptancesResponse) => T,
	filters?: IInternalAcceptancesFiltersState,
	objectId = 'all'
) => {
	const internalAcceptancesPageSettings = useAppSelector(s =>
		extractInternalAcceptancesPageSettings(s, objectId)
	);

	const permissions = useInternalAcceptancesPermissions();

	return useQuery<IGetInternalAcceptancesResponse, IError, T>({
		queryKey: internalAcceptancesQueryKeys.list(
			filters || internalAcceptancesPageSettings?.filters,
			internalAcceptancesPageSettings?.sort,
			internalAcceptancesPageSettings?.offset,
			internalAcceptancesPageSettings?.pageSize,
			objectId
		),
		meta: {
			error: 'при загрузке списка внутренних приемок'
		},
		enabled: permissions.canView,
		select
	});
};

export const useInternalAcceptancesIsLoading = () =>
	!!useIsFetching({queryKey: internalAcceptancesQueryKeys.lists()});

export const useInternalAcceptancesQuery = (
	objectId?: string,
	filters?: IInternalAcceptancesFiltersState
) => useInternalAcceptances(undefined, filters, objectId);

/**
 * Возвращает внутренние приемки как массив
 */
export const useInternalAcceptancesAsArray = (objectId?: string) =>
	useInternalAcceptances(extractInternalAcceptancesAsArray, undefined, objectId);

export const useLocalInternalAcceptances = <T = IListInternalAcceptance[]>(
	objectId = 'all',
	select?: (data: IListInternalAcceptance[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IListInternalAcceptance[], IError, T>({
		queryKey: [...internalAcceptancesQueryKeys.localList(), objectId],
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};

export const useExtractLocalSpaceInternalAcceptances = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListInternalAcceptance[]) => extractLocalSpaceInternalAcceptances(data, spaceId),
		[spaceId]
	);
	return useLocalInternalAcceptances(objectId, selector);
};

export const useMergedSpaceLocalAndLatestInternalAcceptances = (
	objectId: string,
	spaceId: string
) => {
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const {data: latestInternalAcceptances} = useExtractLatestInternalAcceptancesAsArray(
		objectId,
		spaceId
	);

	const {data: localInternalAcceptances} = useExtractLocalSpaceInternalAcceptances(
		objectId,
		spaceId
	);

	return useMemo(
		() =>
			appIsOffline
				? [...(latestInternalAcceptances || []), ...(localInternalAcceptances || [])]
				: localInternalAcceptances,
		[latestInternalAcceptances, localInternalAcceptances, appIsOffline]
	);
};
export const useInternalAcceptancesForStats = (objectId: string) =>
	useInternalAcceptances(extractInternalAcceptancesForStats, undefined, objectId);
