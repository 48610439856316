import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IGetStructureProblemRepliesResponse} from '../hooks/useStructureProblemReplies';
import {extractStructureProblemCommentsAsArray} from './extractStructureProblemCommentsAsArray';

export const extractStructureProblemCommentsByProblem = (
	data?: IGetStructureProblemRepliesResponse
) => {
	const array = extractStructureProblemCommentsAsArray(data);

	if (!array) return undefined;

	return array.reduce<Record<string, IComment>>((prev, item) => {
		if (item.links?.problemId && !prev[item.links.problemId]) {
			prev[item.links.problemId] = item;
		}
		return prev;
	}, {});
};
