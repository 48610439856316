import {ISpaceStatus, isSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {PropsWithChildren, memo, useMemo} from 'react';
import {IconType, LegendItem} from './LegendItem';
import {
	CheckRecordStatusId,
	ICheckRecordStatus
} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import classNames from 'classnames';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {useTranslation} from 'react-i18next';

const criticalIcon = <i className="tz-critical-20" />;

export interface ItemsTypeMap {
	'space-status': Array<ISpaceStatus | {id: string; name: string}>;
	'problem-status': IProblemStatus[];
	'work-acceptance-status': IWorkAcceptanceStatus[];
	'check-list-status': ICheckRecordStatus[];
	'check-item-status': ICheckRecordStatus[];
	indicator: ISpaceIndicator[];
}

interface ILegendGroupProps<T extends IconType> {
	heading: string;
	iconType?: T;
	items?: ItemsTypeMap[T];
	differentStyles?: boolean;
	className?: string;
	withoutCritical?: boolean;
}

export const LegendGroup = memo(
	<T extends IconType>({
		heading,
		iconType,
		items,
		differentStyles,
		className,
		children,
		withoutCritical = false
	}: PropsWithChildren<ILegendGroupProps<T>>) => {
		const {t} = useTranslation();
		const criticalItem = iconType === 'problem-status' && !withoutCritical && (
			<LegendItem
				key="critical"
				type={iconType}
				title={t('spacesPage.legendContent.legendGroup.legendItem.title.critical')}
				Icon={criticalIcon}
			/>
		);

		const renderItems = useMemo(() => {
			if (iconType !== 'space-status') {
				return (items as Array<{id: string; name: string; color: string}>)?.map(item => {
					if (item.id === CheckRecordStatusId.NOT_CHECKED) {
						return (
							<LegendItem
								key={item.id}
								type={iconType}
								title={item.name}
								color={item.color}
								onlyBorder={differentStyles}
							/>
						);
					}
					return (
						<LegendItem
							key={item.id}
							type={iconType}
							title={item.name}
							color={item.color}
						/>
					);
				});
			}

			return items?.map(item => {
				if (!isSpaceStatus(item)) {
					return (
						<div
							key={item.id}
							className="spaces-page__legend-space-status-title"
						>
							{item.name}
						</div>
					);
				}

				return (
					<LegendItem
						key={item.id}
						type={iconType}
						title={item.name}
						color={item.color}
					/>
				);
			});
		}, [iconType, items, differentStyles]);

		return (
			<div className={classNames('spaces-page__legend-items-group', className)}>
				<LegendItem>
					<div className="spaces-page__legend-item-header">{heading}</div>
				</LegendItem>

				{children || renderItems}
				{criticalItem}
			</div>
		);
	}
);
