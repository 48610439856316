import {ISavingInternalAcceptance} from '@src/interfaces/saving/ISavingInternalAcceptance';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

export type IEditInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Изменяет внутреннюю приемку
 *
 * @param objectId id объекта
 * @param internalAcceptanceId id внутренней приемки
 * @param fields данные внутренней приемки
 */
export const makeInternalAcceptanceEditRequest = async (
	objectId: string,
	internalAcceptanceId: string,
	fields: ISavingInternalAcceptance
) => {
	const response = await httpRequests.withToken.patch<IEditInternalAcceptanceResponse>(
		`internal-acceptances/${internalAcceptanceId}`,
		{
			objectId,
			...fields
		}
	);
	return response.data;
};
