import {FiltersPatternsPage} from '@src/pages/filters/FiltersPatternsPage/FiltersPatternsPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {getSectionsMenuItems} from '@src/pages/filters/ProblemsFiltersPage/utils/getSectionsMenuItems';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {stringify} from 'query-string';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';
import {useState} from 'react';

export const ProblemsFiltersPatternsPage = () => {
	const dispatch = useAppDispatch();
	const {t} = useTranslation();
	const {objectId} = useStrictParams<{objectId?: string; entity: string}>();
	const stateObjectId = objectId || 'all';
	const {pushPath} = useChangePath();

	const nestedObjects = useObjectChildrenIds(objectId);

	const {change, isLocal, state, apply} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	const search = stringify(state, {arrayFormat: 'index'});

	useAppHeader(
		{
			title: t('problemsFiltersPage.title'),
			showBackBtn: true,
			sectionsMenu: getSectionsMenuItems(objectId)
		},
		[objectId, t, nestedObjects]
	);

	const {filtersPatterns, activeFiltersPatternId} = useAppSelector(s =>
		extractProblemsPageSettings(s, objectId)
	);

	const [selectedPatternId, setSelectedPatternId] = useState<number | undefined>(
		activeFiltersPatternId
	);

	const {deleteFiltersPattern, changeFiltersPattern, changeActiveFiltersPatternId} =
		problemsActions;

	const onDeletePattern = (id: number) => {
		if (id === selectedPatternId) {
			setSelectedPatternId(undefined);
		}
		void dispatch(deleteFiltersPattern({objectId: stateObjectId, id}));
	};

	const onChangePattern = (id: number, name: string) => {
		void dispatch(changeFiltersPattern({objectId: stateObjectId, id, name}));
	};

	const onAcceptPattern = () => {
		apply();
		if (selectedPatternId) {
			dispatch(
				changeActiveFiltersPatternId({objectId: stateObjectId, id: selectedPatternId})
			);
		}
		if (isLocal) {
			pushPath(
				objectId ? `/objects/${objectId}/problems/?${search}` : `/problems/?${search}`
			);
		} else {
			pushPath(objectId ? `/objects/${objectId}/problems` : '/problems');
		}
	};

	const onSelectPattern = (pattern: IFiltersPattern) => {
		change(pattern.filters);
		setSelectedPatternId(pattern.id);
	};

	return (
		<FiltersPatternsPage
			applyDisabled={selectedPatternId === activeFiltersPatternId}
			filtersPatterns={filtersPatterns}
			selectedPatternId={selectedPatternId}
			onDeletePattern={onDeletePattern}
			onChangePattern={onChangePattern}
			onAcceptPattern={onAcceptPattern}
			onSelectPattern={onSelectPattern}
		/>
	);
};
