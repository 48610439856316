import {useCallback, useMemo} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {WorkAcceptanceDisplayMode} from '@src/interfaces/WorkAcceptanceDisplayMode';
import {changeWorkAcceptanceDisplayMode} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {useTranslation} from 'react-i18next';

const tabsIndexes = {
	[WorkAcceptanceDisplayMode.DESCRIPTION]: 0,
	[WorkAcceptanceDisplayMode.ENTITIES]: 1,
	[WorkAcceptanceDisplayMode.CHECK_LISTS]: 2
};
const tabsKeys = [
	WorkAcceptanceDisplayMode.DESCRIPTION,
	WorkAcceptanceDisplayMode.ENTITIES,
	WorkAcceptanceDisplayMode.CHECK_LISTS
];

interface IWorkAcceptanceViewTabsProps {
	objectId: string;
}

export const WorkAcceptanceViewTabs = ({objectId}: IWorkAcceptanceViewTabsProps) => {
	const {t} = useTranslation();
	const tabs = useMemo(
		() => [
			<TabLink label={t('workAcceptancePage.pageTabs.description.label')} />,
			<TabLink label={t('workAcceptancePage.pageTabs.problemsAndChecks.label')} />,
			<TabLink label={t('workAcceptancePage.pageTabs.checklists.label')} />
		],
		[t]
	);
	const displayMode = useAppSelector(
		s => s.settings.pages.workAcceptances.byPage[objectId]?.displayMode
	);
	const dispatch = useAppDispatch();

	const handleChange = useCallback(
		(index: number) => {
			dispatch(changeWorkAcceptanceDisplayMode(objectId, tabsKeys[index]));
		},
		[dispatch, objectId]
	);

	return (
		<Tabs
			className={{
				root: 'work-acceptance-page__plate-header-tabs',
				links: 'work-acceptance-page__plate-header-tabs-links'
			}}
			links={tabs}
			activeTab={tabsIndexes[displayMode]}
			onActiveTabChange={handleChange}
		/>
	);
};
