import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	IOwnerAcceptancesPagesSettingsState,
	IOwnerAcceptancesPageSettingsState,
	IOwnerAcceptancesFiltersState,
	IOwnerAcceptancesSortState
} from './interfaces';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangeOffsetPayload} from '@src/store/interfaces/IChangeOffsetPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeSelectedRowsPayload} from '@src/store/interfaces/IChangeSelectedRowsPayload';

export const getInitialStateForPage = (): IOwnerAcceptancesPageSettingsState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	offset: 0,
	pageSize: 20,
	selectedRows: []
});

const initialState: IOwnerAcceptancesPagesSettingsState = {};

const ownerAcceptancesPageSettingsSlice = createSlice({
	name: 'ownerAcceptancesPageSettings',
	initialState,
	reducers: {
		initializeByObjectId: (state, action: PayloadAction<{objectId: string}>) => {
			const {objectId} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
		},
		changeFilters: (
			state,
			action: PayloadAction<IChangeFiltersPayload<IOwnerAcceptancesFiltersState>>
		) => {
			const {objectId, filters} = action.payload;
			if (objectId !== 'all') {
				filters.objects = [objectId];
			}
			state[objectId].filters = filters;
		},
		clearFilters: (state, action: PayloadAction<IClearFiltersPayload>) => {
			const {objectId} = action.payload;
			const filters = {} as IOwnerAcceptancesFiltersState;
			if (objectId !== 'all') {
				filters.objects = [objectId];
			}
			state[objectId].filters = filters;
		},
		changeSort: (
			state,
			action: PayloadAction<IChangeSortPayload<IOwnerAcceptancesSortState>>
		) => {
			const {objectId, sort} = action.payload;
			state[objectId].sort = sort;
		},
		changeOffset: (state, action: PayloadAction<IChangeOffsetPayload>) => {
			const {objectId, offset} = action.payload;
			state[objectId].offset = offset;
		},
		changePageSize: (state, action: PayloadAction<IChangePageSizePayload>) => {
			const {objectId, pageSize} = action.payload;
			state[objectId].pageSize = pageSize;
		},
		changeSelectedRows: (state, action: PayloadAction<IChangeSelectedRowsPayload>) => {
			const {objectId, selectedRows} = action.payload;
			state[objectId].selectedRows = selectedRows;
		}
	}
});

export const ownerAcceptancesPageSettingsActions = ownerAcceptancesPageSettingsSlice.actions;
export const ownerAcceptancesPageSettings = ownerAcceptancesPageSettingsSlice.reducer;
