import {makeProblemCopyRequest} from '@src/api/backend/problem';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';
import {invalidateLinkedProblemsQueries} from '@src/cache/actions/problem/invalidateLinkedProblemsQueries';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useCallback} from 'react';
import {TempFileLocation} from "@tehzor/tools/enums/TempFileLocation";
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface ICopyProblemParams {
	objectId: string;
	problemId: string;
	links: IProblem['links'] | undefined;
	stage: ObjectStageIds | undefined;
	processId: ProcessIds;
	fields: ISavingProblem;
}

export const useCopyProblem = () => {
	const {t} = useTranslation();

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: async (params: ICopyProblemParams) => {
			const {links, problemId, objectId, stage, processId, fields} = params;
			return makeProblemCopyRequest(
				objectId,
				problemId,
				links,
				processId,
				{
					...fields,
					newAttachments: await addTempFiles(fields.newAttachments, TempFileLocation.S3)
				},
				stage
			);
		},
		onSuccess: (_, {objectId}) => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void invalidateLinkedProblemsQueries(objectId);
		},
		onError: () => {
			addErrorToast({title: t('toast.copyProblem.error.title')});
		}
	});

	/**
	 * Копирует нарушение с измененными полями
	 *
	 * @param objectId id объекта
	 * @param problemId id нарушения для копирования
	 * @param links ссылки на другие сущности
	 * @param stage стадия объекта
	 * @param fields данные нарушения
	 */

	return useCallback(
		(
			objectId: string,
			problemId: string,
			links: IProblem['links'] | undefined,
			stage: ObjectStageIds | undefined,
			processId: ProcessIds,
			fields: ISavingProblem
		) =>
			mutation.mutate({
				objectId,
				problemId,
				links,
				stage,
				processId,
				fields
			}),
		[mutation]
	);
};
