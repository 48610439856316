import {FiltersPatterns} from '@src/components/FiltersPatterns/FiltersPatterns';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';

interface IProblemsFiltersPatternsProps {
	objectId: string;
}

export const ProblemsFiltersPatterns = ({objectId}: IProblemsFiltersPatternsProps) => {
	const dispatch = useAppDispatch();
	const {filtersPatterns, activeFiltersPatternId, filters} = useAppSelector(s =>
		extractProblemsPageSettings(s, objectId)
	);
	const {addFiltersPattern, deleteFiltersPattern, changeFiltersPattern, acceptFiltersPattern} =
		problemsActions;

	const onAddPattern = (name: string, filters: Record<string, unknown>) => {
		void dispatch(addFiltersPattern({objectId, name, filters}));
	};

	const onDeletePattern = (id: number) => {
		void dispatch(deleteFiltersPattern({objectId, id}));
	};

	const onChangePattern = (id: number, name: string) => {
		void dispatch(changeFiltersPattern({objectId, id, name}));
	};

	const onAcceptPattern = (pattern: IFiltersPattern) => {
		void dispatch(acceptFiltersPattern({objectId, id: pattern.id}));
	};

	return (
		<FiltersPatterns
			filtersPatterns={filtersPatterns}
			activeFiltersPatternId={activeFiltersPatternId}
			filters={filters}
			onAddPattern={onAddPattern}
			onDeletePattern={onDeletePattern}
			onChangePattern={onChangePattern}
			onAcceptPattern={onAcceptPattern}
		/>
	);
};
