import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {CellProps} from 'react-table';

export const SpaceTypeCell = ({row}: CellProps<IListSpace>) => {
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const spaceTypeId = spaceTypes?.[row.original.type]?.id;

	const spaceTypeName = useTranslatedDictionaryById(dictionaryKeys.spaceTypes, spaceTypeId);

	return <>{spaceTypeName}</>;
};
