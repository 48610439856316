import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {TFunction} from "i18next";
import {Column} from 'react-table';
import {CreatedCell} from './CreatedCell';
import {FixDateCell} from './FixDateCell';
import {AcceptorsCell} from './AcceptorsCell';
import {ModifiedCell} from './ModifiedCell';
import {StatusChangeableCell} from './StatusChangableCell';
import {WorkAcceptanceDescriptionCell} from './WorkAcceptanceDescriptionCell';

export const getDesktopColumns = (tFunc: TFunction): Array<Column<IPreparedWorkAcceptance>> => ([
	{
		Header: '№',
		accessor: 'number',
		Cell: WorkAcceptanceDescriptionCell
	},
	{
		id: 'type',
		Header: tFunc('spacePage.workAcceptanceTable.columns.type.header'),
		accessor: item => item.type?.name,
		width: 100
	},
	{
		id: 'status',
		Header: tFunc('spacePage.workAcceptanceTable.columns.status.header'),
		Cell: StatusChangeableCell,
		width: 150
	},
	{
		id: 'plannedFixDate',
		Header: tFunc('spacePage.workAcceptanceTable.columns.plannedFixDate.header'),
		accessor: row => row.plannedAcceptanceDate,
		width: 120,
		Cell: FixDateCell
	},
	{
		id: 'acceptors',
		Header: tFunc('spacePage.workAcceptanceTable.columns.acceptors.header'),
		Cell: AcceptorsCell,
		width: 100
	},
	{
		Header: tFunc('spacePage.workAcceptanceTable.columns.createdAt.header'),
		accessor: 'createdAt',
		Cell: CreatedCell,
		width: 100,
		sortDescFirst: true
	},
	{
		Header: tFunc('spacePage.workAcceptanceTable.columns.modifiedAt.header'),
		accessor: 'modifiedAt',
		Cell: ModifiedCell,
		width: 100,
		sortDescFirst: true
	}
]);

export const getDesktopColumnsWithoutObject = (tFunc: TFunction) => getDesktopColumns(tFunc).filter(item => item.id !== 'object');
