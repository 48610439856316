/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {createHeightForDayCell, isCurrentDay} from '@src/pages/TasksPage/utils/timeDayUtils';
import {extractTasksScheduleSettings} from '@src/store/modules/settings/pages/tasks/selectors';
import {CellContext} from '@tanstack/react-table';
import classNames from 'classnames';
import {useAddingTaskDialog} from '@src/components/AddingTaskDialog';
import {useDayTasks} from '@src/pages/TasksPage/hooks/useDayTasks';
import {IScheduleData} from '@src/pages/TasksPage/utils/convertTasksToScheduleFormat';

const plusIcon = <i className="tz-plus-20" />;

export const DayCell = ({column, row, getValue}: CellContext<IScheduleData, IScheduleData>) => {
	const permissions = useTasksPermissions();
	const {settings} = useAppSelector(extractTasksScheduleSettings);
	const dayId = column.id;
	const value = getValue();

	const tasks = value.tasks.tasksForDayCell.tasks[parseInt(dayId)];
	const maxCountInRow = value.tasks.tasksForDayCell.maxCountInRow;

	const isCurrentDayFlag = isCurrentDay(settings.year, settings.month, dayId);

	const startTime = new Date(settings.year, settings.month, parseInt(dayId), 9).getTime();
	const endTime = new Date(settings.year, settings.month, parseInt(dayId), 18).getTime();

	const [taskDialog, handleOpenTaskDialog] = useAddingTaskDialog({
		objectId: value?.objectId,
		startTime: startTime.toString(),
		endTime: endTime.toString()
	});

	const {dayTasks} = useDayTasks({
		tasks,
		rowIndex: row.index,
		objectId: value?.objectId,
		startTime,
		endTime
	});

	const heightForCell = createHeightForDayCell(maxCountInRow);

	return (
		<div
			className={classNames('manage-task-schedule-page__day-cell', {
				'manage-task-schedule-page__day-cell_active': isCurrentDayFlag
			})}
			style={{height: heightForCell}}
		>
			<div className="manage-task-schedule-page__hover-layout" />
			{dayTasks && (
				<div
					className="manage-task-schedule-page__day-cell-tasks-wrapper"
					role="cell"
				>
					<div className="manage-task-schedule-page__day-cell-tasks">{dayTasks}</div>
				</div>
			)}
			{permissions.canAdd && (
				<div
					className="manage-task-schedule-page__day-cell-add"
					onClick={handleOpenTaskDialog}
					role="cell"
				>
					{plusIcon}
					{taskDialog}
				</div>
			)}
		</div>
	);
};
