import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {ISavingProblem} from '../../../../../interfaces/saving/ISavingProblem';
import {ISavingInspection} from '../../../../../interfaces/saving/ISavingInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {addTempFiles} from '../../../../../api/cache/utils/addTempFiles';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';

export interface IAddMultiplePresaleChecksResponse {
	presaleChecks: IPresaleCheck[];
	failedCreations: string[];
	problems: IProblem[];
	inspections: IInspection[];
}
/**
 * Добавляет предпродажные проверки с сущностями (нарушение/осмотр)
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param presaleCheckType тип выбранной сущности (нарушение/осмотр)
 * @param savingData данные для сохранения осмотра/нарушения
 */

export const requestAddMultiplePresaleChecks = async (
	objectId: string,
	spaceIds: string[],
	links: IPresaleCheck['links'] | undefined,
	savingData: ISavingProblem | ISavingInspection,
	presaleCheckType?: string
) => {
	savingData.newAttachments = await addTempFiles(savingData.newAttachments, TempFileLocation.S3);
	const response = await httpRequests.withToken.post<IAddMultiplePresaleChecksResponse>(
		'/presale-checks/add-with-entities',
		{
			objectId,
			spaceIds,
			links,
			presaleCheckType,
			savingData: {
				...savingData,
				processId: ProcessIds.PRESALE_CONTROL,
				objectId,
				newAttachmentsIds: savingData.newAttachments?.map(item => item.id)
			}
		}
	);

	return response.data;
};
