import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetLastReplyResponse = IComment | false | undefined;

/**
 * Возвращает последний ответ
 *
 * @param objectId id объекта
 * @param entityId id сущности
 * @param commentTypeId id типа комментария
 */
export async function requestLastReply(
	objectId: string,
	entityId: string,
	commentTypeId: ICommentTypeId
) {
	const response = await httpRequests.withToken.get<IGetLastReplyResponse>(
		`replies/${objectId}/${entityId}/${commentTypeId}`
	);
	return response.data;
}
