import {useObjectFieldSettings} from '@src/core/hooks/settings/useObjectFieldSettings';
import {
	IEditableObjectState,
	convertToSave,
	errorsFns,
	hasImageError,
	isEdited,
	useEditableObjectState
} from '@src/core/hooks/states/useEditableObjectState';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import IFile from '@tehzor/tools/interfaces/IFile';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import {EditableObject, fieldsSettings} from '../EditableObject';

export const useEditableObject = (
	objectId: string | undefined,
	companyId: string | undefined,
	defaultData: ISavingObject | undefined,
	saving: boolean,
	creating?: boolean
): [
	ReactNode,
	// eslint-disable-next-line function-paren-newline
	() => Promise<ISavingObject | undefined>,
	// eslint-disable-next-line function-paren-newline
	() => void,
	boolean
] => {
	const [editingState, editingDispatch] = useEditableObjectState(defaultData);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setBlocking] = useState(false);
	const {custom} = useObjectFieldSettings(objectId);

	usePageReloadPrevent(isBlocking);

	useEffect(() => {
		setBlocking(isEdited(editingState, defaultData) || isFilesExist(uploadingFilesState.value));
	}, [editingState, defaultData, uploadingFilesState]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		const file = files.length ? files[0] : undefined;

		const editingStateWithCompany = {
			...editingState,
			companyId: editingState.companyId || companyId
		};

		const editingStateWithImage: IEditableObjectState = {
			...editingStateWithCompany,
			image: {id: file?.key} as IFile
		};

		if (
			hasErrors(editingStateWithImage, errorsFns, fieldsSettings) ||
			(hasImageError(editingState, fieldsSettings) &&
				!isFilesExist(uploadingFilesState.value))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}

		if (file?.sizeError) {
			return undefined;
		}

		if (!isEdited(editingState, defaultData) && !isFilesExist(files)) {
			return undefined;
		}

		const savingData = convertToSave(editingStateWithCompany, defaultData, !creating);
		const savingFiles = convertFilesToSave(files);

		return {
			...savingData,
			newImage: savingFiles?.length ? savingFiles[0] : savingData.newImage
		};
	}, [editingState, defaultData, creating, companyId, uploadingFilesState.value]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [defaultData]);

	const fields = (
		<EditableObject
			objectId={objectId}
			companyId={companyId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
			customFieldsSettings={custom}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
