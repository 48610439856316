import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useStructureBreadcrumbs} from '@src/core/hooks/breadcrumbs/useStructureBreadcrumbs';
import {MainActionsDesktop} from './actions/MainActions.desktop';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useLocation} from 'react-router-dom';
import {DesktopMenu} from './actions/Menu.desktop';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

interface IPageBreadcrumbsProps {
	objectId: string;
	structure: IStructure;
}

export const PageBreadcrumbs = ({objectId, structure}: IPageBreadcrumbsProps) => {
	const location = useLocation();
	const breadcrumbs = useStructureBreadcrumbs(objectId, structure.id);
	const isDesktop = useIsDesktop();
	const isActionVisible = location.pathname === `/objects/${objectId}/structures/${structure.id}`;

	return (
		<AppBreadcrumbs
			className="structure-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && isActionVisible && (
				<>
					<MainActionsDesktop
						objectId={objectId}
						structureId={structure.id}
					/>
					<div>
						<DesktopMenu structure={structure} />
					</div>
				</>
			)}
		</AppBreadcrumbs>
	);
};
