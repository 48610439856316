import {StructureEntitiesTabs} from '@src/interfaces/StructureEntitiesTab';
import * as types from '../constants';

/**
 * Возвращает вкладку отображения сущностей структуры
 *
 * @param value значение фильтра
 */
export const changeEntitiesTab = (value: StructureEntitiesTabs) => ({
	type: types.CHANGE_ENTITIES_TAB,
	payload: value
});
