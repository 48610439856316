import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {CellProps} from 'react-table';
import './CategoryCell.less';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export const CategoryCell = ({
	row
}: CellProps<{data: IProblem | IInspection | IWorkAcceptance}>) => {
	const {data: category} = useExtractCategoryById(row.original.data.categoryId);

	if (!category) {
		return null;
	}
	return <div>{category.name}</div>;
};
