import {useObjectById} from '@src/core/hooks/queries/objects/hooks';
import {
	EntityTypeId,
	IBuiltinField,
	ICustomField,
	IFieldSetting,
	ICustomFieldSetting,
	IBuiltinFieldSetting
} from '@tehzor/tools/interfaces/fields';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useMemo} from 'react';
import {useFieldSettingsSetsByEntityType} from '../queries/fieldSettingsSets/hooks';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useFieldSettingsBySetIds} from '@/entities/FieldSettings';
import {useBuiltinFieldsMap, useCustomFieldsMap} from '@/entities/Fields';

export interface IEntitySetting {
	required?: boolean;
	fieldId: string;
	fieldKey: string;
	canBeCopied?: boolean;
}

export interface IEntitySettings {
	builtin: Record<string, IEntitySetting>;
	custom: Record<string, ICustomFieldSetting>;
}

const getObjectSettings = (
	objects: IObject[],
	objectFields: Array<keyof IObjectFieldsSettings>
) => {
	const builtin: Record<string, IEntitySetting> = {};

	for (const object of objects) {
		for (const objectField of objectFields) {
			const objectSettings = object.fieldsSettings?.[objectField];
			if (!objectSettings?.length) {
				continue;
			}

			for (const setting of objectSettings) {
				builtin[setting.fieldId] = {
					required: setting.isRequired,
					fieldId: setting.fieldId,
					fieldKey: setting.fieldId,
					canBeCopied: setting.canBeCopied
				};
			}
		}
	}

	return builtin;
};

const getFieldSettings = (
	settings: IFieldSetting[],
	customFieldsMap?: Record<string, ICustomField>,
	builtinFieldsMap?: Record<string, IBuiltinField>
) => {
	const custom: Record<string, ICustomFieldSetting> = {};
	const builtin: Record<string, IBuiltinFieldSetting> = {};

	for (const setting of settings) {
		const field = builtinFieldsMap?.[setting.fieldId] || customFieldsMap?.[setting.fieldId];

		if (!field) {
			continue;
		}
		if (customFieldsMap?.[setting.fieldId] && 'valueType' in field) {
			custom[field.key] = {
				...setting,
				fieldKey: field.key,
				fieldName: field.name,
				valueTypeId: field.valueType.id,
				iconName: field.icon
			};
		}
		if (builtinFieldsMap?.[setting.fieldId]) {
			builtin[field.key] = {...setting, fieldKey: field.key};
		}
	}

	return {custom, builtin};
};

/**
 * Получает настройки из объектов по конкретным сущностям
 *
 * @param objectId id объекта
 * @param objectFields массив названий полей настроек объекта
 * @param entityTypeId тип сущности
 * @param stage стадия объекта или помещения
 */
export const useEntitySettings = (
	objectId: string,
	objectFields: Array<keyof IObjectFieldsSettings> | undefined,
	entityTypeId: EntityTypeId,
	stage?: ObjectStageIds
): IEntitySettings => {
	const objects = useObjectById(objectId);

	const {data: settingsSets} = useFieldSettingsSetsByEntityType(
		entityTypeId,
		objectId !== 'all' ? objectId : undefined,
		stage
	);
	const {data: settings} = useFieldSettingsBySetIds(settingsSets?.map(set => set.id));
	const {data: customFieldsMap} = useCustomFieldsMap();
	const {data: builtinFieldsMap} = useBuiltinFieldsMap();

	return useMemo(() => {
		const entitySettings: IEntitySettings = {builtin: {}, custom: {}};

		if (objects?.length && objectFields) {
			const builtin = getObjectSettings(objects, objectFields);

			entitySettings.builtin = {...builtin};
		}

		if (settings?.length) {
			const {custom, builtin} = getFieldSettings(settings, customFieldsMap, builtinFieldsMap);

			entitySettings.custom = {...entitySettings.custom, ...custom};
			entitySettings.builtin = {...entitySettings.builtin, ...builtin};
		}

		return entitySettings;
	}, [objects, settingsSets, settings, customFieldsMap, builtinFieldsMap]);
};
