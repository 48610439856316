import styles from './EmptyMessage.module.less';
import classNames from 'classnames';

export const EmptyMessage = () => (
	<div className={styles.wrapper}>
		<i className={classNames(styles.icon, 'tz-search')} />
		<p className={styles.text}>
			У вас пока что нет сохраненных шаблонов фильтров. Выберите необходимые фильтры и
			сохраните свой первый шаблон
		</p>
	</div>
);
