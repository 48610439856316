import {useMemo} from 'react';
import {convertProblems} from '../utils/convertProblems';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {useMarkersAsArray} from '@src/core/hooks/queries/markers/hooks';
import {useExtractProblemTagsById} from '@src/core/hooks/queries/problemTags/hooks';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useProblemStatusesMap} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useProblemsDocumentsStats} from '@src/core/hooks/queries/documentsStats';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

interface IPreparedProblemsParams {
	allIds?: string[];
	problems?: IListProblem[];
	localProblems?: IListProblem[];
}

export const usePreparedProblems = ({problems, localProblems, allIds}: IPreparedProblemsParams) => {
	const networkStatus = useOnlineManager();
	const {data: documentsStats} = useProblemsDocumentsStats(allIds);
	const {data: statuses} = useProblemStatusesMap();
	const {data: usersMap} = useUsersAsMap();
	const {data: problemTagsMap} = useExtractProblemTagsById();
	const {data: markers} = useMarkersAsArray({problemIds: allIds});
	const user = useAppSelector(s => s.auth.profile);
	const {data: groupsMap} = useWorkingGroupsAsMap();
	return useMemo(
		() =>
			convertProblems(
				networkStatus
					? [...(localProblems || []), ...(problems || [])]
					: [...(localProblems || [])],
				problemTagsMap,
				statuses,
				usersMap,
				groupsMap,
				markers,
				networkStatus,
				documentsStats,
				user
			),
		[
			problems,
			problemTagsMap,
			statuses,
			usersMap,
			user,
			localProblems,
			groupsMap,
			documentsStats,
			markers,
			networkStatus
		]
	);
};
