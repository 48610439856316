import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {usePlansByIds} from '@src/core/hooks/queries/plans/hooks';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

const locationIcon = <i className="tz-plan-20" />;
const categoryIcon = <i className="tz-structure-types-20" />;
const nameIcon = <i className="tz-name-20" />;

interface IInfoProps {
	structure: IStructure;
	withBorders?: boolean;
}

export const Common = ({structure, withBorders = false}: IInfoProps) => {
	const {t} = useTranslation();
	const {data: types} = useExtractStructureTypesById();
	const {data: plans} = usePlansByIds(structure.objectId, structure.planIds);
	const translatedTypes = useTranslatedDictionaryMap(dictionaryKeys.structureTypes, types);

	return (
		<EntityGrid withBorders={withBorders}>
			<EntityGridItem
				label={t('structurePage.entityGridItems.name.label')}
				fullRow
				icon={nameIcon}
			>
				{structure.name}
			</EntityGridItem>

			<EntityGridItem
				label={t('structurePage.entityGridItems.structureType.label')}
				fullRow
				icon={categoryIcon}
			>
				{translatedTypes &&
					translatedTypes[structure.type] &&
					translatedTypes[structure.type].name}
			</EntityGridItem>

			{plans &&
				plans.length !== 0 &&
				plans.map(plan => (
					<EntityGridItem
						label={t('structurePage.entityGridItems.plan.label')}
						fullRow
						icon={locationIcon}
					>
						{plan.name}
					</EntityGridItem>
				))}
		</EntityGrid>
	);
};
