import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {sortByCreation} from '../../../../utils/sortByCreation';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {StructureEntitiesTabs} from '@src/interfaces/StructureEntitiesTab';
import {StructureEntitiesVisibility} from '@src/interfaces/StructureEntitiesVisibility';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {EntitiesMap} from '../utils/EntitiesMap';
import {prepareEntities} from '../utils/prepareEntities';
import {processEntities} from '../utils/processEntities';

interface IExtractStructureEntitiesArgs {
	checks?: IListCheck[];
	problems?: IListProblem[];
	workAcceptances?: IListWorkAcceptance[];
	inspections?: IListInspection[];
	entitiesTab?: StructureEntitiesTabs;
	entitiesVisibility: StructureEntitiesVisibility[];
}

export const extractStructureEntities = ({
	problems,
	inspections,
	checks,
	workAcceptances,
	entitiesTab,
	entitiesVisibility
}: IExtractStructureEntitiesArgs) => {
	const checksSubRows = new EntitiesMap();
	const workAcceptancesSubRows = new EntitiesMap();

	const allVisible = entitiesTab === StructureEntitiesTabs.ALL || entitiesTab === undefined;

	const checksVisible = allVisible || entitiesTab === StructureEntitiesTabs.CHECKS;
	const workAcceptancesVisible =
		allVisible || entitiesTab === StructureEntitiesTabs.WORK_ACCEPTANCE;
	const problemsVisible = entitiesVisibility.includes(StructureEntitiesVisibility.PROBLEMS);
	const inspectionsVisible = entitiesVisibility.includes(StructureEntitiesVisibility.INSPECTIONS);

	const result: IStructureEntity[] = [];

	prepareEntities(
		problems,
		problemsVisible,
		'problem',
		result,
		checksSubRows,
		workAcceptancesSubRows
	);
	prepareEntities(
		inspections,
		inspectionsVisible,
		'inspection',
		result,
		checksSubRows,
		workAcceptancesSubRows
	);

	if (checks && checksVisible) {
		result.push(...processEntities(checksSubRows, 'check', checks));
	}

	if (workAcceptances && workAcceptancesVisible) {
		result.push(...processEntities(workAcceptancesSubRows, 'work-acceptance', workAcceptances));
	}

	return result.sort(sortByCreation);
};
