import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {Dispatch, RefObject, SetStateAction} from 'react';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {IAddMultipleInternalAcceptancesWithEntitiesParams} from '@src/core/hooks/mutations/InternalAcceptances/useAddMultipleInternalAcceptancesWithEntities';
import {IAddMultipleInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances/add';

interface ISaveInternalAcceptancesProps {
	objectId: string;
	spaceIds: string[];
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	acceptanceType: IInternalAcceptanceAddingEntityType;
	links: IInternalAcceptance['links'];
	addMultipleInternalAcceptancesWithEntities: (
		params: IAddMultipleInternalAcceptancesWithEntitiesParams
	) => Promise<IAddMultipleInternalAcceptancesResponse>;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

/**
 * Логика для сохранения множества внутренних приемок.
 */
export const saveMultipleInternalAcceptances = async ({
	objectId,
	spaceIds,
	addingEntityRef,
	acceptanceType,
	links,
	addMultipleInternalAcceptancesWithEntities,
	setSaving,
	onClose
}: ISaveInternalAcceptancesProps) => {
	if (!addingEntityRef.current) {
		return;
	}
	const savingData = await addingEntityRef.current.getSavingData(true);
	if (!savingData) {
		return;
	}

	try {
		setSaving(true);
		onClose();
		await addMultipleInternalAcceptancesWithEntities({
			objectId,
			spaceIds,
			links,
			savingData,
			acceptanceType
		});
	} catch (error) {
		console.error('Ошибка при добавлении внутренних приемок:', error);
	} finally {
		setSaving(false);
	}
};
