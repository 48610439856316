import {useCallback} from 'react';
import {SwitchMenu, SwitchMenuOption} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeDisplayMode} from '@src/store/modules/settings/pages/spaces/actions';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

interface IDesktopDisplayModeSwitchProps {
	objectId: string;
}

export const DesktopDisplayModeSwitch = ({objectId}: IDesktopDisplayModeSwitchProps) => {
	const {displayMode} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const user = useAppSelector(s => s.auth.profile);
	const canUploadExternalId =
		!!user && !!user.roles
			? hasPermission(
					user.roles,
					'spaces-external-id-upload',
					UserRoleScopes.OBJECT,
					objectId
			  )
			: false;

	const handleChange = useCallback(
		(value: SpacesDisplayMode) => {
			dispatch(changeDisplayMode(objectId, value));
		},
		[objectId]
	);

	return (
		<SwitchMenu
			value={displayMode}
			onChange={handleChange}
		>
			<SwitchMenuOption value={SpacesDisplayMode.SCHEMA}>
				<i className="tz-schema-24" />
			</SwitchMenuOption>
			<SwitchMenuOption value={SpacesDisplayMode.LIST}>
				<i className="tz-list-24" />
			</SwitchMenuOption>
			{canUploadExternalId && (
				<SwitchMenuOption value={SpacesDisplayMode.UPLOAD}>
					<i className="tz-fields-28" />
				</SwitchMenuOption>
			)}
		</SwitchMenu>
	);
};
