import StatusesFilterPage from '../StatusesFilterPage';
import {useExtractSpaceStatusesAsArrayByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

const SpacesStatusesFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const spaceStatusesAsArrayByObjectId = useExtractSpaceStatusesAsArrayByObjectId(objectId);
	const statuses = spaceStatusesAsArrayByObjectId?.data;

	if (!statuses) return null;

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={statuses}
			label={t('spacesFiltersPage.spacesStatusesFilterMobile.baseListFilterMobile.label')}
			filterName="statuses"
		/>
	);
};

export default SpacesStatusesFilterPage;
