import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton} from '@tehzor/ui-components';
import {isCleanable} from '@src/components/MobileEntitiesFilters/utils/isCleanable';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {
    CategoriesSetFilterMobile,
    CreatedByFilterMobile,
    CreationDateFilterMobile,
    ObjectsFilterMobile,
    PlansFilterMobile
} from '@src/components/MobileEntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useInspectionSettings} from '@src/core/hooks/settings/useInspectionSettings';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {PerformersFilterMobile} from '@src/components/MobileEntitiesFilters/components/PerformersFilterMobile';
import {CustomFiltersMobile} from '@src/components/CustomFilters/CustomFiltersMobile';
import {NestedObjectsFilterMobile} from '@src/components/MobileEntitiesFilters/components/NestedObjectsFilterMobile';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';

const InspectionsFilterPage = () => {
    const {t} = useTranslation();
    const {goBack} = useChangePath();
    const {objectId, entity} = useStrictParams<{ objectId?: string; entity: string }>();
    const {canGoBack} = useLocation().state as { canGoBack?: boolean };
    const {state, isStateChanged, change, mobileClear, apply} =
        useEntitiesFiltersCtx<IInspectionsFiltersState>();
    const settings = useInspectionSettings(objectId);

    const nestedObjects = useObjectChildrenIds(objectId);

    const handleApply = useCallback(() => {
        if (canGoBack) {
            apply();
            goBack();
        }
    }, [canGoBack, apply]);

    useAppHeader(
        {
            title: t('filtersPage.title'),
            showBackBtn: true,
            mobileRightButtons: isCleanable<IInspectionsFiltersState>(
                state,
                objectId,
                undefined,
                !!nestedObjects?.length
            ) ? (
                <LinkButton
                    label={t('filterPage.resetFiltersBtn.label')}
                    onClick={mobileClear}
                />
            ) : null
        },
        [state, objectId, t, nestedObjects]
    );

    useRouting(!canGoBack, objectId, entity);

    return (
        <TranslatedFilterPage
            applyDisabled={!isStateChanged}
            onApplyClick={handleApply}
        >
            {objectId === undefined && (
                <ObjectsFilterMobile
                    entity={entity}
                    value={state?.objects}
                />
            )}

            {!!nestedObjects?.length ? (
                <NestedObjectsFilterMobile
                    entity={entity}
                    value={state?.objects}
                    pageObjectId={objectId}
                />
            ) : null}

            {settings.builtin.categoryId && (
                <CategoriesSetFilterMobile
                    objectsIds={objectId ? [objectId] : undefined}
                    currentObject={objectId}
                    value={state?.categories}
                    entity={entity}
                    onChange={change}
                    label={t('entitiesFilters.CategoriesSetFilter.label')}
                />
            )}

            {settings.builtin.performers && (
                <PerformersFilterMobile
                    objectId={objectId ?? 'all'}
                    label={t('entitiesFilters.performers.label')}
                    value={state.performers}
                    entity={entity}
                    onChange={change}
                />
            )}

            <CreatedByFilterMobile
                objectId={objectId}
                value={state?.createdBy}
                entity={entity}
                onChange={change}
                label={t('entitiesFilters.CreatedByFilter.label')}
            />

            <CreationDateFilterMobile
                objectId={objectId}
                from={state.createdAtFrom}
                to={state.createdAtTo}
                entity={entity}
                label={t('entitiesFilters.CreationDateFilter.label')}
            />

            {objectId !== undefined && (
                <PlansFilterMobile
                    objectId={objectId}
                    value={state?.plans}
                    entity={entity}
                    onChange={change}
                    label={t('entitiesFilters.PlansFilter.label')}
                />
            )}

            {settings.custom && (
                <CustomFiltersMobile
                    objectId={objectId ?? 'all'}
                    settings={settings.custom}
                    filtersData={state.custom}
                    entity={entity}
                />
            )}
        </TranslatedFilterPage>
    );
};

export default InspectionsFilterPage;
