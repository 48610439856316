import {useSplitAttachments} from '@src/core/hooks/useSplitAttachments';
import {useAttachmentsImages} from '@src/core/hooks/useAttachmentsImages';
import {useEntityAndReplyViewerMobile} from '@src/core/hooks/imagesViewers/mobile/useEntityAndReplyViewerMobile';
import {ProblemInfo} from '@src/components/tableCells/ProblemInfo/ProblemInfo';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useAttachmentsImagesObjects} from '@src/core/hooks/useAttachmentsImagesObject';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {FilesCount, PhotoPreview, TableRowSelect} from '@tehzor/ui-components';
import classNames from 'classnames';
import {ReactNode, useMemo} from 'react';
import {TableToggleRowsSelectedProps} from 'react-table';
import './ProblemInfoMobileCell.less';
import {ProblemTagsMarker} from '@src/components/ProblemTagsMarkers';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {ProblemStatus} from '@src/components/tableCells/ProblemStatusCell';
import ProblemReply from '@src/components/tableCells/ProblemReply';
import {MobilePlannedFixDate} from './MobilePlannedFixDate';
import {ProblemsPerformersMobile} from './ProblemsPerformersMobile';
import {CriticalMarker} from '@src/components/CriticalMarker';
import {useTranslation} from 'react-i18next';
import {useResponsibleDisplayValue} from '@src/core/hooks/useResponsibleDisplayValue';

interface IProblemInfoMobileProps {
	problem: IListProblem;
	depth: number;
	subTitle?: ReactNode;
	replies?: Record<string, IComment>;
	allProblemTags?: Record<string, IProblemTag>;
	getToggleRowSelectedProps: (
		props?: Partial<TableToggleRowsSelectedProps>
	) => TableToggleRowsSelectedProps;
	positionFilesCount?: 'subtitle' | 'after';
}

export const ProblemInfoMobile = ({
	problem,
	depth,
	subTitle,
	replies,
	allProblemTags,
	getToggleRowSelectedProps,
	positionFilesCount = 'subtitle'
}: IProblemInfoMobileProps) => {
	const {plannedFixDate, performers, description, critical, problemTags, actualFixDate} = problem;
	const reply = replies?.[problem.id];

	const [problemImagesAttachments] = useSplitAttachments(problem);
	const problemImages = useAttachmentsImages(problemImagesAttachments);
	const problemImagesData = useAttachmentsImagesObjects(problemImagesAttachments);
	const [replyImagesAttachments] = useSplitAttachments(reply);
	const replyImages = useAttachmentsImages(replyImagesAttachments);
	const replyImagesData = useAttachmentsImagesObjects(replyImagesAttachments);
	const performersDisplayValue = useResponsibleDisplayValue(performers);

	const {t} = useTranslation();

	const [imagesViewer, openProblemImage, openReplyImage] = useEntityAndReplyViewerMobile(
		problemImages,
		replyImages,
		AttachmentFileDestination.PROBLEM,
		AttachmentFileDestination.COMMENT_OR_REPLY,
		problemImagesData,
		replyImagesData,
		'problem',
		t('problem.imageTitle'),
		reply?.number,
		problem.id,
		reply?.id
	);
	const colors = allProblemTags ? problemTags?.map(id => allProblemTags[id]?.color) : undefined;

	const problemInfoSubTitle = useMemo(
		() => (
			<div>
				{subTitle}
				{positionFilesCount === 'subtitle' && (
					<FilesCount count={problem?.attachments?.length} />
				)}
			</div>
		),
		[subTitle, problem?.attachments?.length, positionFilesCount]
	);

	return (
		<div className="m-problem-info-cell">
			<div
				className={classNames('m-problem-info-cell__problem', {
					'm-problem-info-cell__problem_with-padding': depth > 0
				})}
			>
				<div className="m-problem-info-cell__problem-header">
					<div className="m-problem-info-cell__problem-title">
						<ProblemInfo
							style={{marginBottom: depth > 0 ? '12px' : '16px'}}
							data={problem}
							depth={depth}
							subTitle={problemInfoSubTitle}
						/>
						{critical && <CriticalMarker />}
					</div>

					<ClickPreventWrap>
						<TableRowSelect
							{...getToggleRowSelectedProps()}
							className="m-problem-info-cell__problem-header-check-box"
							style={{marginTop: depth > 0 ? '6px' : '12px'}}
						/>
					</ClickPreventWrap>
				</div>
				{description !== undefined && (
					<div className="m-problem-info-cell__problem-desc">{description}</div>
				)}
				{colors?.length ? (
					<div className="m-problem-info-cell__extra-info">
						<ProblemTagsMarker colors={colors} />
					</div>
				) : null}
				{plannedFixDate && (
					<MobilePlannedFixDate
						actualFixDate={actualFixDate}
						plannedFixDate={plannedFixDate}
					/>
				)}
				{performersDisplayValue && (
					<ProblemsPerformersMobile
						className="m-problem-info-cell__problem-performers"
						performersDisplayValue={performersDisplayValue}
					/>
				)}
				<div className="m-problem-info-cell__problem-status-row">
					<ProblemStatus problem={problem} />

					{problemImages.length > 0 ? (
						<ClickPreventWrap>
							<PhotoPreview
								className="m-problem-info-cell__problem-image"
								url={problemImages[0]}
								data={0}
								onClick={openProblemImage}
							/>
						</ClickPreventWrap>
					) : null}
				</div>
			</div>
			{reply !== undefined && (
				<ProblemReply
					className="m-problem-info-cell__reply"
					reply={reply}
					images={replyImages}
					openImage={openReplyImage}
				/>
			)}
			{problemImages.length || replyImages.length ? (
				<ClickPreventWrap>{imagesViewer}</ClickPreventWrap>
			) : null}
			{positionFilesCount === 'after' && <FilesCount count={problem?.attachments?.length} />}
		</div>
	);
};
