import {IGetChecksResponse} from '@src/api/backend/checks';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import _ from 'lodash';

export const extractAllLatestChecksAsArray = (data: IGetChecksResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractLatestChecksAsArray = (
	data: IGetChecksResponse,
	userId: string,
	objectId: string,
	spaceId?: string,
	structureId?: string
) => {
	const arr = extractAllLatestChecksAsArray(data);
	return arr.filter(
		item =>
			item.objectId === objectId &&
			item.createdBy?.id === userId &&
			(!spaceId || spaceId === item.links?.spaceId) &&
			(!structureId || structureId === item.links?.structureId)
	);
};

export const extractLatestCheckId = (
	data: IGetChecksResponse,
	userId: string,
	objectId: string,
	spaceId?: string,
	structureId?: string,
	localCheck?: IListCheck[]
) => {
	const latestCheck = extractLatestChecksAsArray(data, userId, objectId, spaceId, structureId);
	const filteredLocalChecks = localCheck?.filter(
		item => item.objectId === objectId && (!spaceId || spaceId === item.links?.spaceId)
	);
	if (filteredLocalChecks?.length) {
		return _.maxBy(filteredLocalChecks, 'modifiedAt')?.id;
	}
	if (latestCheck.length) {
		return _.maxBy(latestCheck, 'modifiedAt')?.id;
	}
	return undefined;
};
