import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import {CheckListCategoryFilterPage} from '@src/pages/filters/CheckListCategoryFilterPage';
import {CheckListCategoryStatusesFilterPage} from '@src/pages/filters/CheckListCategoryStatusesFilterPage';
import {CheckListIdsFilterPage} from '@src/pages/filters/CheckListIdsFilterPage';
import {CheckListStatusesFilterPage} from '@src/pages/filters/CheckListStatusesFilterPage';
import IndicatorsFilterPage from '@src/pages/filters/IndicatorsFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage/ObjectsFilterPage';
import {SpaceTypeDecorationsFilterPage} from '@src/pages/filters/SpaceTypeDecorationsFilterPage/SpaceTypeDecorationsFilterPage';
import SpaceTypesFilterPage from '@src/pages/filters/SpaceTypesFilterPage';
import SpacesFiltersPage from '@src/pages/filters/SpacesFiltersPage';
import SpacesProblemStatusesFilterPage from '@src/pages/filters/SpacesProblemStatusesFilterPage';
import SpacesStatusesFilterPage from '@src/pages/filters/SpacesStatusesFilterPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';

export const spacesFilterRoutes = [
	{
		path: 'stages',
		element: <StagesFilterPage />
	},
	{
		path: 'statuses',
		element: <SpacesStatusesFilterPage />
	},
	{
		path: 'types',
		element: <SpaceTypesFilterPage />
	},
	{
		path: 'problemStatuses',
		element: <SpacesProblemStatusesFilterPage />
	},
	{
		path: 'indicators',
		element: <IndicatorsFilterPage />
	},
	{
		path: 'objects',
		element: <ObjectsFilterPage />
	},
	{
		path: 'checkListStatuses',
		element: <CheckListStatusesFilterPage />
	},
	{
		path: 'checkListCategoryStatuses',
		element: <CheckListCategoryStatusesFilterPage />
	},
	{
		path: 'checkListCategory',
		element: <CheckListCategoryFilterPage />
	},
	{
		path: 'checkListIds',
		element: <CheckListIdsFilterPage />
	},
	{
		path: 'typeDecoration',
		element: <SpaceTypeDecorationsFilterPage />
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage />
	},
	{
		path: '',
		element: <SpacesFiltersPage />
	}
];
