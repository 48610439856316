import {IGetChecksResponse} from '@src/api/backend/checks';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IProblemTarget} from '@src/api/backend/problem';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import {moveProblem} from '@src/store/modules/pages/problem/actions/move';
import {useQueryClient} from '@tanstack/react-query';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {
	ActionButtons,
	Button,
	Checkbox,
	Dialog,
	LoadableOptionsList,
	Select2,
	SelectOption,
	SelectPopup,
	SelectSearch,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useCallback, useEffect, useState, ChangeEvent, useMemo} from 'react';
import './MovingProblemsDialog.less';
import {useTranslation} from 'react-i18next';
import {useAddCheckOnline} from '@src/core/hooks/mutations/checks/useAddCheckOnline';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCheck} from '@src/core/hooks/queries/check/hook';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useSpacesAsArray} from '@src/core/hooks/queries/spaces/hooks';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useInternalAcceptance} from '@src/core/hooks/queries/internalAcceptance/hook';

interface IMovingProblemsDialogProps {
	selectedEntities: ICheckEntity[];
	objectId: string;
	target: IProblemTarget;
	isOpen: boolean;
	restrictions?: {[key: string]: boolean};
	onClose: () => void;
	onSuccess?: () => void;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

/**
 * Окно перемещения нарушения
 */
const MovingProblemsDialog = ({
	selectedEntities,
	objectId,
	target,
	isOpen,
	restrictions,
	onClose,
	onSuccess
}: IMovingProblemsDialogProps) => {
	const {t} = useTranslation();

	const {data: object} = useObject(objectId);
	const addInternalAcceptance = useAddInternalAcceptance(object);
	const {checkId, internalAcceptanceId} = useStrictParams<{
		objectId: string;
		checkId: string;
		internalAcceptanceId: string;
	}>();
	const {data: check} = useCheck(checkId, objectId);
	const isCheck = !!target.checkId;
	const isInternalAcceptance = !!target.internalAcceptanceId;
	const dispatch = useAppDispatch();
	const {data: internalAcceptance} = useInternalAcceptance(objectId, internalAcceptanceId);
	const user = useAppSelector(s => s.auth.profile);
	const [disabled, setDisabled] = useState(false);
	const [toEntityId, setToEntityId] = useState<string>('');
	const [toNewEntity, setToNewEntity] = useState<boolean>(false);
	const [entities, setEntities] = useState<
		Array<{id: string; content: string; spaceId?: string}>
	>([]);
	const [offset, setOffset] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const handleEntityChange = useCallback((value: string) => {
		setToEntityId(value);
	}, []);
	const {data: spaceTypesMap} = useExtractSpaceTypesById();
	const currentSpace = isCheck ? check?.space : internalAcceptance?.space;
	const [toSpaceType, setToSpaceType] = useState<SpaceTypeId | undefined>(currentSpace?.type);
	const {data: spaces} = useSpacesAsArray(objectId);
	const spaceTypeIds = useMemo(
		() => Array.from(new Set(spaces.map(space => space.type))),
		[spaces]
	);
	const [spaceSearch, setSpaceSearch] = useState('');
	const [toSpaceId, setToSpaceId] = useState<string | undefined>(currentSpace?.id);

	const haveSpaceId = useMemo(
		() =>
			((check && check.links?.spaceId) ||
				(internalAcceptance && internalAcceptance.links?.spaceId)) &&
			selectedEntities.every(entity => entity.data.links?.spaceId),
		[check, selectedEntities, internalAcceptance]
	);

	const filtredSpace = spaces
		.filter(
			el =>
				toSpaceType &&
				el.type === toSpaceType &&
				el.name.toLowerCase().indexOf(spaceSearch.toLowerCase()) > -1
		)
		.sort((a: IListSpace, b: IListSpace) => parseInt(a.name) - parseInt(b.name));

	const queryClient = useQueryClient();
	const {mutateAsync: addCheckOnline} = useAddCheckOnline();

	const formList = useCallback(
		(value: IListCheck[] | IListInternalAcceptance[]) => {
			const arr: Array<{id: string; content: string; spaceId?: string}> = [];
			for (const item of value) {
				const text = isCheck
					? `${t('movingProblemsDialog.title.inspection')} №`
					: isInternalAcceptance
					? `${t('movingProblemsDialog.title.internalAcceptance')} №`
					: `${t('movingProblemsDialog.title.unknown')} №`;
				arr.push({
					id: item.id,
					content: `${text}${item.number}`,
					spaceId: item.links?.spaceId
				});
			}
			setEntities([...entities, ...arr]);
		},
		[entities, isCheck, isInternalAcceptance, t]
	);

	const getEntities = useCallback(async () => {
		let items = {} as IGetInternalAcceptancesResponse | IGetChecksResponse;
		if (isCheck) {
			const filters = {
				objects: [objectId],
				createdBy: restrictions && restrictions.checksCreatedByUser ? [user.id] : undefined
			};
			items = await queryClient.fetchQuery({
				queryKey: [...checksQueryKeys.list(), filters, undefined, offset, 100]
			});
		} else if (isInternalAcceptance) {
			const filters = {
				objects: [objectId],
				createdBy:
					restrictions && restrictions.internalAcceptancesCreatedByUser
						? [user.id]
						: undefined
			};
			items = await queryClient.fetchQuery({
				queryKey: internalAcceptancesQueryKeys.list(filters, undefined, offset, 100)
			});
		}
		formList([...Object.values(items.byId)]);
		setOffset(offset + 100);
		setTotal(items.total);
	}, [
		isCheck,
		isInternalAcceptance,
		formList,
		offset,
		objectId,
		restrictions,
		user.id,
		queryClient
	]);

	useEffect(() => {
		const entityId = isCheck
			? check?.id
			: isInternalAcceptance
			? internalAcceptance?.id
			: undefined;
		setToEntityId(`${entityId}`);
	}, [check, internalAcceptance, isCheck, isInternalAcceptance]);

	useEffect(() => {
		if (entities.length === 0) {
			void getEntities();
		}
	}, [entities.length, getEntities]);

	const handleNewEntity = (event: ChangeEvent<HTMLInputElement>) => {
		setToNewEntity(event.target.checked);
	};

	const handleSave = useCallback(async () => {
		setDisabled(true);
		const problemsIds = selectedEntities
			.filter(item => item.type === 'problem')
			.map(({data}) => {
				const {id} = data as IProblem;
				return id;
			});

		if (isCheck) {
			if (toNewEntity && problemsIds.length) {
				const links = toSpaceId ? {spaceId: toSpaceId} : undefined;
				const newCheck = await addCheckOnline({
					objectId,
					links,
					stage: check?.stage
				});
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(
							objectId,
							{checkId: newCheck?.id, spaceId: toSpaceId},
							problemId
						)
					);
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(objectId, {checkId: toEntityId, spaceId: toSpaceId}, problemId)
					);
				}
			}
		}
		if (isInternalAcceptance && internalAcceptance) {
			if (toNewEntity && problemsIds.length) {
				const links = toSpaceId ? {spaceId: toSpaceId} : undefined;
				const newInternalAcceptance = addInternalAcceptance({
					links,
					stage: internalAcceptance.stage,
					objectId
				});

				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(
							objectId,
							{internalAcceptanceId: newInternalAcceptance?.id, spaceId: toSpaceId},
							problemId
						)
					);
				}
			}
			if (toEntityId && !toNewEntity && problemsIds.length) {
				for (const problemId of problemsIds) {
					await dispatch(
						moveProblem(
							objectId,
							{internalAcceptanceId: toEntityId, spaceId: toSpaceId},
							problemId
						)
					);
				}
			}
		}
		if (onSuccess) {
			onSuccess();
		}
		onClose();
	}, [
		selectedEntities,
		isCheck,
		isInternalAcceptance,
		onSuccess,
		onClose,
		toNewEntity,
		toEntityId,
		toSpaceId,
		addCheckOnline,
		objectId,
		check?.stage,
		dispatch,
		internalAcceptance,
		internalAcceptance?.stage,
		addInternalAcceptance
	]);

	const handleSpaceTypeChange = useCallback((value: SpaceTypeId) => {
		setToSpaceType(value);
		setToSpaceId(undefined);
		setToEntityId('');
	}, []);

	const handleSpaceTypeClear = useCallback(() => {
		setToSpaceType(undefined);
		setToSpaceId(undefined);
		setToEntityId('');
	}, []);

	const handleSpaceChange = useCallback((value: string) => {
		setToSpaceId(value);
		setToEntityId('');
	}, []);

	const handleSpaceClear = useCallback(() => {
		setToSpaceId(undefined);
		setToEntityId('');
	}, []);

	const clearSpaceSearch = useCallback(() => setSpaceSearch(''), []);

	const filteredEntities = useMemo(
		() => entities.filter(entity => !toSpaceId || entity.spaceId === toSpaceId),
		[entities, toSpaceId]
	);

	return (
		<Dialog
			className="check-page__problems-moving"
			isOpen={isOpen}
			footer={
				<ActionButtons>
					<Button
						disabled={
							disabled ||
							((target.checkId === toEntityId ||
								target.internalAcceptanceId === toEntityId) &&
								!toNewEntity) ||
							(!toEntityId && !toNewEntity)
						}
						type="accent-blue"
						label={t('movingProblemsDialog.save')}
						onClick={handleSave}
					/>
					<Button
						disabled={disabled}
						type="cancel"
						label={t('movingProblemsDialog.cancel')}
						onClick={onClose}
					/>
				</ActionButtons>
			}
		>
			<div className="check-page__problems-moving__select">
				{haveSpaceId && (
					<div>
						<div className="moving-check-dialog__select-title">
							{t('components.movingCheckDialog.spaceType')}
						</div>
						<SelectPopup
							noHeader
							trigger={
								<TextFieldWithForwardedRef
									elementType="div"
									value={toSpaceType && spaceTypesMap?.[toSpaceType]?.name}
									icon={arrowIcon}
									cleanable
									onClearClick={handleSpaceTypeClear}
								/>
							}
						>
							<Select2
								value={toSpaceType}
								onChange={handleSpaceTypeChange}
							>
								{spaceTypeIds.map(id => (
									<SelectOption
										inputType="radio"
										key={id}
										itemKey={id}
										content={spaceTypesMap?.[id]?.name}
									/>
								))}
							</Select2>
						</SelectPopup>
					</div>
				)}

				{haveSpaceId && (
					<div>
						<div className="moving-check-dialog__select-title">
							{t('components.movingCheckDialog.newSpace')}
						</div>
						<SelectPopup
							search={
								<SelectSearch
									value={spaceSearch}
									onChange={setSpaceSearch}
									onClear={clearSpaceSearch}
								/>
							}
							trigger={
								<TextFieldWithForwardedRef
									elementType="div"
									value={
										toSpaceId
											? spaces.find(s => s.id === toSpaceId)?.name
											: undefined
									}
									icon={arrowIcon}
									cleanable
									onClearClick={handleSpaceClear}
								/>
							}
						>
							<Select2
								value={toSpaceId}
								onChange={handleSpaceChange}
							>
								{filtredSpace.map(item => (
									<SelectOption
										key={item.id}
										itemKey={item.id}
										content={item.name}
										inputType="radio"
									/>
								))}
							</Select2>
						</SelectPopup>
					</div>
				)}

				<div>
					{isCheck
						? t('movingProblemsDialog.choose.inspection')
						: isInternalAcceptance
						? t('movingProblemsDialog.choose.internalAcceptance')
						: t('movingProblemsDialog.choose.unknown')}
				</div>
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							disabled={toNewEntity}
							elementType="div"
							value={entities.find(e => e.id === toEntityId)?.content}
							icon={arrowIcon}
						/>
					}
				>
					<Select2
						value={toEntityId}
						onChange={handleEntityChange}
					>
						<LoadableOptionsList
							items={filteredEntities}
							total={total}
							handleLoad={getEntities}
							maxListHeight={400}
							rowHeight={48}
						/>
					</Select2>
				</SelectPopup>
				<Checkbox
					className="check-page__problems-moving-cb"
					checked={toNewEntity}
					onChange={handleNewEntity}
				>
					{isCheck
						? t('movingProblemsDialog.moveTo.inspection')
						: isInternalAcceptance
						? t('movingProblemsDialog.moveTo.internalAcceptance')
						: t('movingProblemsDialog.moveTo.unknown')}
				</Checkbox>
			</div>
		</Dialog>
	);
};

export default MovingProblemsDialog;
