import {queryClient} from '@src/api/QueryClient';
import {checksQueryKeys} from '../../../api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {structuresQueryKeys} from '@src/fsd/src/entities/Structures';

export const invalidateLinkedInspectionsQueries = async (
	objectId: string,
	links?: IInspection['links']
) => {
	if (links?.checkId) {
		await queryClient.invalidateQueries({
			queryKey: checksQueryKeys.checkInspections(objectId, links.checkId)
		});
	}
	if (links?.presaleCheckId) {
		await queryClient.invalidateQueries({
			queryKey: presaleChecksQueryKeys.presaleCheckInspections(objectId, links.presaleCheckId)
		});
	}
	if (links?.internalAcceptanceId) {
		await queryClient.invalidateQueries({
			queryKey: internalAcceptancesQueryKeys.internalAcceptanceInspections(
				objectId,
				links.internalAcceptanceId
			)
		});
	}
	if (links?.spaceId) {
		await queryClient.invalidateQueries({
			queryKey: [...spacesQueryKeys.inspections(), objectId]
		});
	}
	if (links?.structureId) {
		await queryClient.invalidateQueries({
			queryKey: [...structuresQueryKeys.inspectionsById(objectId, links?.structureId)]
		});
	}
	if (!links) {
		await queryClient.invalidateQueries({
			queryKey: [...spacesQueryKeys.inspections(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...checksQueryKeys.inspections(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...presaleChecksQueryKeys.inspections(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...internalAcceptancesQueryKeys.inspections(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...structuresQueryKeys.inspections(), objectId]
		});
	}
	await queryClient.invalidateQueries({
		queryKey: internalAcceptancesQueryKeys.inspectionsStats()
	});
};
