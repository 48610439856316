import IComment from '@tehzor/tools/interfaces/comments/IComment';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetCommentsResponse extends INormalizedData<IComment> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список комментариев и ответов
 */
export async function requestComments(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	const response = await httpRequests.withToken.post<IGetCommentsResponse>(
		'comments/get',
		params
	);
	return response.data;
}