import {IDocumentLink} from '@tehzor/tools/interfaces/documents/IDocument';

const entityTypeToPlural: Record<string, string> = {
	object: 'objects',
	problem: 'problems',
	check: 'checks',
	user: 'users',
	inspection: 'inspections',
	task: 'tasks',
	'check-list': 'check-lists',
	comment: 'comments',
	'checks-problems': 'checks-problems',
	'internal-acceptance': 'internal-acceptances',
	space: 'spaces',
	'unit-handover': 'unit-handovers',
	'warranty-claim': 'warranty-claims',
	'work-acceptance': 'work-acceptances'
};

export const createLink = (link: IDocumentLink): string => {
	const pluralType = entityTypeToPlural[link.entityType];

	if (!pluralType) {
		throw new Error(`Неизвестный тип сущности: ${link.entityType}`);
	}
	return `/objects/${link.objectId}/${pluralType}/${link.entityObjectId}`;
};
