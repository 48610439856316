import {useMutation} from '@tanstack/react-query';
import {
	IEditInternalAcceptanceResponse,
	makeInternalAcceptanceEditRequest
} from '@src/api/backend/internalAcceptance/edit';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {ISavingInternalAcceptance} from '@src/interfaces/saving/ISavingInternalAcceptance';
import IError from '@tehzor/tools/interfaces/IError';
import {queryClient} from '@src/api/QueryClient';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';

type IEditInternalAcceptancePayload = IEditInternalAcceptanceResponse;

export const useEditInternalAcceptance = () =>
	useMutation<
		IEditInternalAcceptancePayload,
		IError,
		{objectId: string; internalAcceptanceId: string; fields: ISavingInternalAcceptance}
	>({
		mutationKey: internalAcceptancesQueryKeys.edit(),
		mutationFn: ({objectId, internalAcceptanceId, fields}) =>
			makeInternalAcceptanceEditRequest(objectId, internalAcceptanceId, fields),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.lists()});
			void queryClient.invalidateQueries({
				queryKey: internalAcceptancesQueryKeys.details()
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.editInternalAcceptance.error.title')});
		}
	});
