import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingComment} from '@src/interfaces/saving/ISavingComment';
import {IEditCommentResponse} from '@src/api/backend/comment';
import {makeReplyEditRequest} from '@src/api/backend/reply';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {queryClient} from '@src/api/QueryClient';
import {structuresQueryKeys} from '@/entities/Structures';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCommentResponse) => {
	if (response.links?.problemId) {
		void queryClient.invalidateQueries({
			queryKey: [...problemsQueryKeys.detail(response.links.problemId), response.objectId]
		});
	}
	void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: problemsQueryKeys.comments()});
	if (response.links?.warrantyClaimId)
		void queryClient.invalidateQueries({
			queryKey: warrantyClaimsQueryKeys.claimProblemsReplies(
				response.links.warrantyClaimId,
				response.objectId
			)
		});
	if (response.links?.structureId) {
		void queryClient.invalidateQueries({
			queryKey: structuresQueryKeys.structureProblemReplies(
				response.objectId,
				response.links.structureId
			)
		});
	}
	if (response.links?.spaceId) {
		void queryClient.invalidateQueries({
			queryKey: spacesQueryKeys.spaceProblemReplies(response.objectId, response.links.spaceId)
		});
	}
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editProblemReply.error.title')});
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет ответ на нарушение
 *
 * @param objectId id объекта
 * @param commentId id комментария
 * @param fields данные для сохранения
 */
export const editProblemReply = (
	objectId: string,
	commentId: string,
	fields: ISavingComment
) =>
	createApiAction<IEditCommentResponse>(request, success, failure, () =>
		makeReplyEditRequest(objectId, commentId, fields)
	);
