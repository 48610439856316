import {useMutation} from '@tanstack/react-query';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {makeChecksAddRequest} from '@src/api/backend/checks';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';

export interface IAddMultipleChecksWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: ICheck['links'];
	savingData: ISavingProblem | ISavingInspection;
	checkType: ICheckAddingEntityType;
}

/**
 * Хук для добавления нескольких проверок в рамках различных сущностей
 */
export const useAddMultipleChecksWithEntities = () =>
	useMutation({
		mutationFn: async ({
			objectId,
			spaceIds,
			links,
			savingData,
			checkType
		}: IAddMultipleChecksWithEntitiesParams) =>
			makeChecksAddRequest(objectId, spaceIds, links, checkType, savingData),
		onError: error => {
			console.error('Ошибка при создании проверок:', error);
		}
	});
