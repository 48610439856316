import StatusesFilterPage from '../StatusesFilterPage';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router';

const CompaniesFilterPage = () => {
	const {t} = useTranslation();
	const match = useMatch('/objects/:objectId/:entity/*');
	const objectId = match?.params.objectId;
	const entity = match?.params.entity;
	const {data: companies} = useCompaniesAsArray();

	return (
		<StatusesFilterPage
			objectId={objectId}
			entity={entity}
			statuses={companies}
			label={t('entitiesFilters.companiesFilter.label')}
			filterName="companies"
		/>
	);
};

export default CompaniesFilterPage;
